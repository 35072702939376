import axios from '@/util/axios';
import constantes from '@/util/constantes';
import fabricantesApi from "@/services/fabricantesApi";

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {
      fabricantes: [],
      fabricanteAtual: {
          nome: ''
      },
      constantes: constantes,
      modoEdicao: false,
      carregando: false,
      totalFabricantes: 0
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await fabricantesApi.listar(options, rootState.empresaAtual);
        commit('SET_FABRICANTES', response.fabricantes);
        commit('SET_TOTAL_FABRICANTES', response.total);
      } catch (error) {
        commit('SET_FABRICANTES', []);
        commit('setMensagemErro', 'Não foi possível listar os fabricantes', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({commit, state, rootState}){
      commit('SET_CARREGANDO', true);
      let fabricante = Object.assign({}, state.fabricanteAtual);
      try{
        let response = await fabricantesApi.salvar(fabricante, rootState.empresaAtual);
        commit('ADICIONAR_FABRICANTE', response);
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    },
    async atualizar({commit, rootState}, fabricante) {
      await fabricantesApi.atualizar(fabricante, rootState.empresaAtual);
      commit('ATUALIZAR_FABRICANTE', fabricante);
    },
    async excluir({commit, rootState}, fabricante){
      commit('SET_CARREGANDO', true);
      axios.delete('/fabricantes/' + fabricante._id)
        .then((response) => {
          commit('EXCLUIR_FABRICANTE', fabricante._id);
          commit('setMensagemSucesso', 'Fabricante Removido com Sucesso', {root: true});
        })
        .catch((erro) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
        });
    }
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_FABRICANTES(state, fabricantes) {
      state.fabricantes = fabricantes;
      state.carregando = false;
    },
    SET_TOTAL_FABRICANTES(state, total) {
      state.totalFabricantes = total;
    },
    SET_FABRICANTE(state, fabricanteAtual) {;
      state.fabricanteAtual = fabricanteAtual
    },
    EXCLUIR_FABRICANTE(state, id) {
      let index = state.fabricantes.findIndex(fabricante => fabricante._id == id);
      if (index > -1) {
        state.fabricantes.splice(index, 1);
      }
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.fabricanteAtual = {
        nome: ''
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.modoEdicao = true;
      state.fabricanteAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_FABRICANTE(state, fabricante) {
      state.fabricantes.unshift(fabricante);
      state.carregando = false;
    },
    ATUALIZAR_FABRICANTE(state, fabricante) {
      let fabricante_encontrado = state.fabricantes.find(fabricante_ => fabricante_.id == fabricante.id);
      if (fabricante_encontrado) {
        fabricante_encontrado = fabricante;
      }
      state.carregando = false;
    },
    estadoInicial(state){
      state.fabricanteAtual = {
        nome: ''
      }
      state.carregando = false;
    }
  }
}
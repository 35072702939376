import axios from '@/util/axios';

export default{
    
    namespaced: true,

    //equivalente ao data
    state: {

      arquivos: [],
      configuracao: {
        importar_clientes: false,
        importar_produtos: false, 
        importar_transportadores: false
      },
      totalArquivos: 0,
      carregando: false,
      exibirProgresso: false,
    },

    //equivalente ao computed properties
    getters: {

      percentualCarregado(state){
        if(state.totalArquivos !== 0){
          return (state.arquivos.length / state.totalArquivos) * 100;
        } 
        else{
          return 0;
        }
      }
    },

    //equivalente aos methods
    //responsável por chamar a api externa
    actions: {

      carregarXML({commit, rootState}, arquivo){
        commit('setArquivo', arquivo);
      },

      importarArquivos({commit, state, rootState}){

        commit('setCarregando', true)
        let arquivos = state.arquivos;
        let configuracao = state.configuracao;
        
        let novosArquivos = [];
        arquivos.forEach(arquivo => {
            let novo = {
                conteudo: btoa(arquivo.conteudo)
            }
            novosArquivos.push(novo)
        });

        let importacao = {
            arquivos: novosArquivos,
            configuracao: configuracao
        }

        axios.post('/importacoes/xml', importacao)
        .then((response) => {
            let resultado = response.data;
            let relatorio = '';
            if(resultado.notas){
              relatorio += 'Notas Informatas: ' + Number(resultado.notas) + ' ';
            }
            if(resultado.produtos){
              relatorio += 'Produtos Importados: ' + Number(resultado.produtos) + ' ';
            }
            if(resultado.clientes){
              relatorio += 'Clientes Importados: ' + Number(resultado.clientes) + ' ';
            }
            if(resultado.transportadores){
              relatorio += 'Transportadores Importados: ' + Number(resultado.transportadores) + ' ';
            }

            if(relatorio.length !== 0){
              commit('setMensagemSucesso', relatorio, {root: true});
            }
            else{
              commit('setMensagemSucesso', 'Nenhuma Informação nova importada', {root: true});
            }
            commit('setEstadoInicial');
        })
        .catch((erro) => {
          commit('setMensagemErro', erro.response.data.motivo, {root: true})
          commit('setEstadoInicial');
        });
      }
    },

    //responsável por alterar o state.
    mutations: {

      setArquivo(state, arquivo){
        state.totalArquivos = arquivo.total;
        state.arquivos.push(arquivo);

        if(state.arquivos.length === arquivo.total){
            state.exibirProgresso = false;
        }
        else{
            state.exibirProgresso = true;
        }
      },
      
      setCarregando(state, valor){
          state.carregando = valor;
      },

      setEstadoInicial(state){

        state.arquivos = [];
        state.configuracao = {
          importar_clientes: false,
          importar_produtos: false, 
          importar_transportadores: false
        };
        state.totalArquivos = 0;
        state.carregando = false;
        state.exibirProgresso = false;
      }
    }
}
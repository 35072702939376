<template>
  <div>
    <v-text-field :loading="loading" label="CNPJ/CPF" ref="cnpjCpfTransportador" :value="value" append-icon="search" @keyup.enter="consultarCnpjCpfTransportador($event)" @input="onInput" @click:append="abrirDialogo"> </v-text-field>

    <dialog-buscar-transportador :dialog="dialogo" @transportadorSelecionado="adicionarTransportadorDialog" @fechar="dialogo = !dialogo"></dialog-buscar-transportador>
  </div>
</template>

<script>
  
  import axios from "@/util/axios";
  import DialogBuscarTransportador from '@/components/dialogs/DialogBuscarTransportador';
  
  export default {
    name: 'BuscarTransportador',
    props: {
      value: {
        type: [Number,String],
        default: null
      },
    },

    components: {
      DialogBuscarTransportador,
    },

    data() {
      return {
        dialogo: false,
        loading: false
      }
    },

    methods: {
      onInput(value) {
        this.$emit('input', value);
      },

      abrirDialogo() {
        this.dialogo = true;
      },

      consultarCnpjCpfTransportador(event){
        
        this.loading = true;
        let empresa = this.$store.state.empresaAtual;

        axios.get(`/${empresa._id}/transportadores/cnpjcpf?cnpjcpf=${event.target.value}`)
        .then(response => {
          this.$emit('transportadorSelecionado', response.data);
          this.loading = false;
        })
        .catch(erro => {
          this.loading = false;
          this.$store.commit('setMensagemErro', erro.response.data.motivo);
        });
      },

      adicionarTransportadorDialog(transportador) {
        this.dialogo = false;
        this.$emit('transportadorSelecionado', transportador);
      }, 
    }
  }
</script>

<style scoped>

</style>
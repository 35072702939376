export default [
  {
    path: '/cfop',
    name: 'listaImportarCfop',
    component: () => import( /* webpackChunkName: "listaImportacaoCFOP" */ '@/views/cadastros/ListaImportarCFOP'),
    meta: { requiresAuth: true, nivel: 2}
  },
  {
    path: '/cfop/detalhar',
    name: 'formularioCfopDetalhar',
    component: () => import( /* webpackChunkName: "formularioCFOP" */ '@/views/cadastros/FormularioCFOP'),
    meta: { requiresAuth: true, nivel: 2}
  },
];
import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual) {
    return axios.get(`/ordensServicos?` + qs.stringify(options))
      .then(response => response.data);
  }
  
  function filtrar(options, empresaAtual) {
    return axios.get(`/ordensServicos/filtro?` + qs.stringify(options))
      .then(response => response.data);
  }

  function salvar(ordem_servico, empresaAtual) {
    return axios.post(`/ordensServicos`, ordem_servico)
      .then(response => response.data);
  }

  function atualizar(ordem_servico, empresaAtual) {
    return axios.put(`/ordensServicos/${ordem_servico._id}`, ordem_servico)
      .then(response => response.data);
  }

  function excluir(id, empresaAtual) {
    return axios.delete(`/ordensServicos/${id}`)
      .then(response => response.data);
  }

  function alterarStatus(ordem_servico, empresaAtual, status) {
    return axios.put(`/ordensServicos/${ordem_servico._id}/status`, { status: status })
      .then(response => response.data);
  }
  
  function fechamento(ordem_servico, empresaAtual, fechamento) {
    return axios.put(`/ordensServicos/${ordem_servico._id}/fechamento`, { ordem_servico: ordem_servico, fechamento: fechamento })
      .then(response => response.data);
  }

  export default {
    listar,
    filtrar,
    salvar,
    atualizar,
    excluir,
    alterarStatus,
    fechamento
  }
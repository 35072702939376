<template>
  <div id="appRoot">
    <dialog-confirmacao ref="dialog_confirm" />

    <template v-if="!$route.meta.requiresAuth">
      <v-app id="inspire" class="app">
        <router-view></router-view>
      </v-app>
    </template>
    <template v-else>
      <Home />
    </template>

    <div
      v-if="isDevelopment"
      style="font-family: Roboto,sans-serif; line-height: 1.5; color: white; font-size: 24px; position: fixed; bottom: 10px; left: 10px; z-index: 1000; font-weight: 700; opacity: 0.5; border-radius: 4px; background-color: red; padding: 0px 18px; pointer-events: none;"
    >
      Development
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DialogConfirmacao from "./components/dialogs/DialogConfirmacao";
import Home from "./views/Home";
import colors from "vuetify/lib/util/colors";

export default {
  name: "App",
  computed: {
    ...mapState({
      usuario: "usuario",
      snackbar: "snackbar",
    }),
    isDevelopment() {
      return !["https://easypdv.anronsoftware.com.br"].includes(window.location.origin);
    },
  },
  components: {
    Home,
    DialogConfirmacao,
  },
  async mounted() {
    this.$root.$confirmacao = this.$refs.dialog_confirm;
  },
  created() {
    if (this.isDevelopment) {
      this.$vuetify.theme.themes.light = {
        primary: colors.blueGrey.base,
        secondary: colors.blueGrey.lighten1,
        accent: colors.blueGrey.lighten2,
        error: colors.deepOrange.base,
        warning: colors.orange.lighten4,
        info: colors.yellow.base,
        success: colors.lightBlue.base,
      };
    }

    if (this.usuario && this.usuario.nivel_acesso !== "ANRON") {
      this.$store.dispatch("consultarStatusServico", false);
      this.$store.dispatch("consultarDestinadas");
    }
  },
};
</script>

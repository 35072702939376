import validador from './validador';

export function obrigatorio(value) {
  return !!value || 'Obrigatório';
}

export function minimo20Caracteres(value) {
  return (value && value.length > 10) || 'Tamanho mínimo 20 caracteres';
}

export function formatoPlanoContas(value) {
  return value.length === 8 || 'Código inválido';
}

export function valorMaiorQueZero(value) {
  return parseFloat(value) > 0 || 'O valor deve ser maior que zero';
}

export function numeroValido(value) {
  return !!value || (value.match('^[0-9]+(\,[0-9]{1,2})?$') !== null && validador.percentual(value)) || 'Percentual inválido';
}

export function percentual(value) {
  return !!value || (value.match('^[0-9]+(\,[0-9]{1,2})?$') !== null && validador.percentual(value)) || 'Percentual inválido';
}

export function email(value) {
  return validador.email(value) || "E-mail Inválido!";
}

export function cnpjCpf(value) {
  return (value && value.length === 14 ? validador.cpf(value) : (value.length === 18 ? validador.cnpj(value): false)) || "CNPJ/CPF Inválido"
}

export function maximo200Caracteres(value) {
  return (value && value.length <= 200) || 'Tamanho máximo são 200 caracteres';
}

export function dataNascimento(value) {
  return validador.dataNascimento(value) || 'Data inválida';
}

export function codigoDeBarras (value) {
  return (value && validador.codigoBarras(value)) || 'Código de Barras Inválido';
}

export function maximo100Caracteres(value) {
  return (value && value.length <= 100) || 'Tamanho máximo são 100 caracteres';
}

export function cest (value) {
  return (value && value.match('^[0-9]{7}$') !== null) || "CEST Inválido";
}

export function caracteresEspeciais (value) {
  return validador.caracteresEspeciais(value) || 'Caracteres não permitidos';
}

export function valorPositivo (value) {
  return parseFloat(value.replace(",", ".")) >= 0 || "Negativo não permitido";
}

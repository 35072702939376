import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual, mes) {
    return axios.get(`/aniversariantes/${mes}?` + qs.stringify(options))
      .then(response => response.data);
  }

  export default {
    listar,
  }
import axios from '@/util/axios';
import qs from "qs";

  function listar(data, empresaAtual, options) {
    return axios.get(`/movimentosCaixa/${data}?` + qs.stringify(options))
      .then(response => response.data);
  }
  
  function listarContasCorrentes(empresaAtual) {
    return axios.get(`/movimentosCaixa/listar-contas-correntes`)
      .then(response => response.data);
  }

  function creditar(movimento, empresaAtual) {
    return axios.post(`/movimentosCaixa/creditar`, movimento)
      .then(response => response.data);
  }
  
  function debitar(movimento, empresaAtual) {
    return axios.post(`/movimentosCaixa/debitar`, movimento)
      .then(response => response.data);
  }

  function excluir(id, empresaAtual) {
    return axios.delete(`/movimentosCaixa/${id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    listarContasCorrentes,
    creditar,
    debitar,
    excluir
  }
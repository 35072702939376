import axios from '@/util/axios';
import constantes from '@/util/constantes';
import contaCorrenteApi from "@/services/contaCorrenteApi";

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {
    contas: [],
    contaAtual: {
      numero: '',
      nome: '',
      agencia: '',
      banco: {
        codigo: '',
        descricao: ''
      }
    },
    bancos: [],
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalContas: 0
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await contaCorrenteApi.listar(options, rootState.empresaAtual);
        commit('SET_CONTAS', response.contas);
        commit('SET_TOTAL_CONTAS', response.total);
      } catch (error) {
        console.log(error)
        commit('SET_CONTAS', []);
        commit('setMensagemErro', 'Não foi possível listar as contas correntes', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    carregarBancos({commit, rootState}){
      commit('setCarregando', true);
      axios.get('/bancos')
      .then((response) => {
        commit('setBancos', response.data);
      })
      .catch((erro) => {
        commit('setBancos', []);
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      }); 
    },
    async salvar({commit, state, rootState}){
      commit('SET_CARREGANDO', true);

      try{
        let conta = Object.assign({}, state.contaAtual);
        let response = await contaCorrenteApi.salvar(conta, rootState.empresaAtual);
        commit('ATUALIZAR_CONTA', response);
        commit('setMensagemSucesso', 'Conta Corrente salva com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    },
    async atualizar({commit, rootState}, conta) {
      await contaCorrenteApi.atualizar(conta, rootState.empresaAtual)
        .then((response) => {
          commit('ATUALIZAR_CONTA', conta);
          commit('setMensagemSucesso', 'Conta Corrente atualizada com Sucesso', {root: true});
        })
        .catch((erro) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
        });
    },
    async excluir({commit, rootState}, conta){
        commit('SET_CARREGANDO', true);
        await contaCorrenteApi.excluir(conta._id, rootState.empresaAtual)
          .then((response) => {
            commit('EXCLUIR_CONTA', conta._id);
            commit('setMensagemSucesso', 'Conta Corrente removida com Sucesso', {root: true});
          })
          .catch((erro) => {
            commit('SET_CARREGANDO', false);
            commit('setMensagemErro', erro.response.data.motivo, {root: true});
          });
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_CONTAS(state, contas) {
      state.contas = contas;
      state.carregando = false;
    },
    SET_TOTAL_CONTAS(state, total) {
      state.totalContas = total;
    },
    SET_CONTA(state, contaAtual) {;
      state.contaAtual = contaAtual
    },
    EXCLUIR_CONTA(state, id) {
      let index = state.contas.findIndex(conta => conta._id == id);
      if (index > -1) {
        state.contas.splice(index, 1);
      }
      state.carregando = false;
    },
    setBanco(state, banco){
      state.contaAtual.banco = banco;
    },
    novo(state){
      state.modoEdicao = false;
      state.contaAtual = {
        numero: '',
        nome: '',
        agencia: '',
        banco: {
          codigo: '',
          descricao: ''
        }
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.modoEdicao = true;
      state.contaAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_CONTA(state, conta) {
      state.contas.unshift(conta);
      state.carregando = false;
    },
    ATUALIZAR_CONTA(state, conta) {
      let conta_encontrada = state.contas.find(conta_ => conta_.id == conta.id);
      if (conta_encontrada) {
        conta_encontrada = conta;
      }
      state.carregando = false;
    },
    estadoInicial(state){
      state.contaAtual = {
        numero: '',
        nome: '',
        agencia: '',
        banco: {
          codigo: '',
          descricao: ''
        }
      }
      state.carregando = false;
    }
  }
}
<template>
  <div>
    <v-subheader class="text-subtitle-1 text--primary">
      Cheques
    </v-subheader>
    <dialogo-status-cheque :dialog="exibirDialogoStatusCheque" :cheque="chequeSelecionado" @fechar="fecharDialogoStatus()" @alterarStatus="confirmarAlterarStatus" />
    <dialogo-filtro-cheques :dialog="exibirDialogoFiltroCheques" :cheque="chequeSelecionado" @limpar="filtro_ativo = false" @fechar="fecharDialogoFiltro()" @filtrar="filtrarCheques" />
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="white">
          <v-row>
            <v-col xs="12" sm="6">
              <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @keyup.enter="filtrarBusca($event)" @click:clear="limparBusca" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col xs="12" sm="3" class="text-right pr-0">
              <v-select :items="lista_status" filled dense hide-details :item-value="'value'" v-model="status" @change="filtrarStatus($event)"></v-select>
            </v-col>
            <v-col xs="12" sm="3" class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    class="mr-4"
                    v-bind="attrs"
                    v-on="on"
                    v-show="filtro_ativo" @click="listar"
                  >
                    mdi-notification-clear-all
                  </v-icon>
                </template>
                <span>Limpar filtro</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    class="mr-4"
                    v-bind="attrs"
                    v-on="on"
                    :loading="carregando" @click="abrirDialogoFiltro"
                  >
                    mdi-filter
                  </v-icon>
                </template>
                <span>Filtro</span>
              </v-tooltip>
              <v-btn color="primary" @click="novo">Novo Cheque</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="cheques" :loading="carregando">
          <template v-slot:item="{item}">
            <tr>
              <td>
                <span>{{ item.emitente }}</span><br>
                <span><b>CLIENTE: {{ item.responsavel.numero }} - {{ item.responsavel.razao_nome }}</b></span>
              </td>
              <td>{{ item.cnpj_cpf }}</td>
              <td>{{ item.vencimento | formatarData }}</td>
              <td>{{ item.numero | formatarPadZero(5) }}</td>
              <td><v-chip style="cursor: pointer" :color="definirCor(item.status)" @click="alterarStatus(item)">{{ item.status }}</v-chip></td>
              <td><span class="font-weight-bold">{{ item.valor | formatarMoeda }}</span></td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="item._id + 20" @click="editar(item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="item._id + 40" @click="excluir(item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="item._id + 50" @click="alterarStatus(item)">
                      <v-list-item-icon><v-icon>track_changes</v-icon></v-list-item-icon>
                      <v-list-item-title>Status</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
</div>
</template>
<script>
  import DialogoStatusCheque from './DialogoStatusCheque';
  import DialogoFiltroCheques from './DialogoFiltroCheques';
  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';
  import moment from 'moment';

  export default {
    name: "ListaCheques",
    components: {
      DialogoStatusCheque,
      DialogoFiltroCheques
    },
    data () {
      return {
        headers: [
          { text: 'Emitente', value: 'emitente' },
          { text: 'CNPJ/CPF', value: 'cnpj_cpf' },
          { text: 'Data', value: 'vencimento' },
          { text: 'Número', value: 'numero' },
          { text: 'Status', value: 'status', align: 'center' },
          { text: 'Valor', value: 'valor', align: 'right' },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        },
        status: '',
        lista_status: [
          {
            text: 'Todos',
            value: ''
          },
          {
            text: 'Vencidos',
            value: 'Vencidos'
          },
          {
            text: 'Em Caixa',
            value: 'Em Caixa'
          },
          {
            text: 'Compensados',
            value: 'Compensado'
          },
          {
            text: 'Repassados',
            value: 'Repassado'
          },
          {
            text: 'Devolvidos',
            value: 'Devolvido'
          },
          {
            text: 'Devolvidos Pagos',
            value: 'Devolvido Pago'
          },
          {
            text: 'A Compensar',
            value: 'A Compensar'
          },
        ],
        chequeSelecionado: null,
        exibirDialogoStatusCheque: false,
        exibirDialogoFiltroCheques: false,
        filtro_ativo: false,
      }
    },

    watch: {
      options: {
        handler () {
          this.listar();
        },
        deep: true,
      },
    },

    computed: {
      ...mapState('cheque', {
        cheques: 'cheques',
        cheque: 'chequeAtual',
        carregando: 'carregando',
        total: 'totalCheques'
      })
    },

    methods: {
      async listar(){
        this.filtro_ativo = false;
        await this.$store.dispatch('cheque/listar');
      },
      
      async filtrarBusca(){
        let newOptions = {
          ...this.options,
          query: this.busca
        }

        this.$store.dispatch('cheque/listar', newOptions);
        setTimeout(() => {
          event.target.select();
        }, 300);
      },

      filtrarStatus(status){
        let newOptions = {
          ...this.options,
          query: { status: status }
        }

        this.$store.dispatch('cheque/filtrar', newOptions);
      },

      async filtrarCheques(filtro){
        let newOptions = {
          ...this.options,
          query: filtro
        }
        this.exibirDialogoFiltroCheques = false;
        this.$store.dispatch('cheque/filtrar', newOptions);
      },

      editar(cheque){
        this.$store.commit('cheque/editar', cheque);
        this.$router.push({name: 'formularioChequeEditar'});
      },

      novo(){
        this.$store.commit('cheque/novo');
        this.$router.push({name: 'formularioChequeNovo'});
      },

      definirCor(status){
        if(status === constantes.EM_CAIXA){
          return 'green';
        }
        else if(status === constantes.COMPENSADO){
          return 'blue';
        }
        else if(status === constantes.REPASSADO){
          return 'blue';
        }
        else if(status === constantes.DEVOLVIDO){
          return 'error';
        }
        else if(status === constantes.DEVOLVIDO_PAGO){
          return 'orange';
        }
      },

      excluir(cheque){
        this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('cheque/excluir', cheque);
          }
        });
      },

      abrirDialogoFiltro(){
        this.filtro_ativo = true;
        this.exibirDialogoFiltroCheques = true;
      },

      alterarStatus(cheque){
        this.chequeSelecionado = cheque;
        this.$store.commit('cheque/SET_CHEQUE_ATUAL', this.chequeSelecionado);
        this.exibirDialogoStatusCheque = true;
      },

      fecharDialogoFiltro(){
        this.exibirDialogoFiltroCheques = false;
      },
      
      fecharDialogoStatus(){
        this.chequeSelecionado = null;
        this.exibirDialogoStatusCheque = false;
      },

      async confirmarAlterarStatus(status){
        this.chequeSelecionado = null;
        this.exibirDialogoStatusCheque = false;
        await this.$store.dispatch('cheque/alterarStatus', status);
      },

      limparBusca(){
        this.listar();
      },
    },
  }
</script>
<template>
  <div>
    <v-row style="margin-top: 50px" v-if="carregando">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>
    <v-form v-else v-model="valid" ref="formGruposubgrupo" lazy-validation >
      <v-container fluid>
        <v-widget title="Informações do Grupo de Produto" :temCampoObrigatorio="true">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field label="Nome do Grupo" autofocus v-model="grupo.nome" :rules="[rules.obrigatorio]"></v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-widget>
        <v-widget title="Subgrupos de Produto" class="mt-3">
          <template v-slot:widget-header-action>
            <v-btn text @click="dialogoSubgrupo = true">Novo</v-btn>
          </template>
          <template v-slot:widget-content>
            <v-data-table class="pb-3" v-if="grupo.subgrupos && grupo.subgrupos.length > 0" :headers="headers" :items="grupo.subgrupos" hide-default-footer>
              <template v-slot:item="{item}">
                <tr>
                  <td>{{ item.nome }}</td>
                  <td class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          @click="removerSubgrupo(key)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Excluir</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </template>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar">{{constantes.BOTAO_CANCELAR}}</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar">{{textoBtnConfirmar}}</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>
    <dialog-adicionar-subgrupo :dialog="dialogoSubgrupo" @subgrupoAdicionado="adicionarSubgrupo" @fechar="dialogoSubgrupo = false"></dialog-adicionar-subgrupo>
  </div>
</template>

<script>
  import DialogAdicionarSubgrupo from './DialogAdicionarSubgrupo';
  import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
  import * as regras from '@/util/validacaoFormulario';
  import constantes from '@/util/constantes';

  export default {
    name: "FormularioGrupo",
    components:{
      DialogAdicionarSubgrupo
    },

    data: () => ({
      valid: false,
      loading: false,
      rules: {...regras},
      constantes: constantes,
      headers: [
        { text: 'Subgrupo', value: 'nome', width: '90%', sortable: false },
        { text: '', value: 'name', width: '10%', align: 'center', sortable: false }
      ],
      dialogoSubgrupo: false,
    }),

    computed: {
      ...mapState('grupo', {
        grupo: 'grupoAtual',
        carregando: 'carregando'
      }),
      ...mapGetters('grupo', {
        estaEditando: 'estaEditando',
        textoBtnConfirmar: 'textoBtnConfirmar'
      })
    },

    methods: {
      ...mapMutations('grupo', {
        removerSubgrupo: 'removerSubgrupo'
      }),

      adicionarSubgrupo(subgrupo){
        this.dialogoSubgrupo = false;
        this.$store.dispatch('grupo/adicionarSubgrupo', subgrupo);
      },
      
      async salvar() {
        if(this.$refs.formGruposubgrupo.validate()){
          try{
            if (this.grupo._id) {
              await this.$store.dispatch('grupo/atualizar', this.grupo);
            } else {
              await this.$store.dispatch('grupo/salvar', this.grupo);
            }
            this.$router.push({ name: 'grupos' })
          }
          catch(erro){
            console.log('Erro ao salvar');
          }
        }
      },

      cancelar() {
        this.$store.commit('grupo/estadoInicial');
        this.$router.push({ name: 'grupos' });
      }
    },

    async created(){
      try{
        this.loading = true;
        let grupo = this.$store.state.grupo.grupoAtual;
        let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
    
        if(estaEditando && (!grupo._id || grupo._id === '')){
          this.$store.commit('setMensagemErro', 'Selecione um grupo para editar');
          this.$router.push({ name: 'grupos' });
        }
        if(!estaEditando){
          this.$store.commit('grupo/estadoInicial');
        }
      }
      catch(erro){
        console.log(erro);
      }
      finally{
        this.loading = false;
      }
    }
  }
</script>
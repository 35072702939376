import axios from '@/util/axios';
import constantes from '@/util/constantes';
import gruposApi from "@/services/gruposApi";

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {
    grupos: [],
    grupoAtual: {
      nome: '',
      subgrupos: []
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalGrupos: 0
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await gruposApi.listar(options, rootState.empresaAtual);
        commit('SET_GRUPOS', response.grupos);
        commit('SET_TOTAL_GRUPOS', response.total);
      } catch (error) {
        commit('SET_GRUPOS', []);
        commit('setMensagemErro', 'Não foi possível listar os grupos', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({commit, state, rootState}, dados){
      commit('SET_CARREGANDO', true);
      let grupo = Object.assign({}, state.grupoAtual);
    
      try{
        let response = await gruposApi.salvar(grupo, rootState.empresaAtual);
        commit('ADICIONAR_GRUPO', response);
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    },
    async atualizar({commit, rootState}, grupo) {
      await gruposApi.atualizar(grupo, rootState.empresaAtual);
      commit('ATUALIZAR_GRUPO', grupo);
    },
    async excluir({commit, rootState}, excluir){
      commit('SET_CARREGANDO', true);
      axios.delete(`/grupos/` + excluir._id)
        .then((response) => {
          commit('EXCLUIR_GRUPO', excluir._id);
          commit('setMensagemSucesso', 'Grupo Removido com Sucesso', {root: true});
        })
        .catch((erro) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
        });
    },
    adicionarSubgrupo({commit}, subgrupo) {
      commit('ADICIONAR_SUBGRUPO', subgrupo);
    }
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_GRUPOS(state, grupos) {
      state.grupos = grupos;
      state.carregando = false;
    },
    SET_TOTAL_GRUPOS(state, total) {
      state.totalGrupos = total;
    },
    SET_GRUPO(state, grupoAtual) {;
      state.grupoAtual = grupoAtual
    },
    EXCLUIR_GRUPO(state, id) {
      let index = state.grupos.findIndex(grupo => grupo._id == id);
      if (index > -1) {
        state.grupos.splice(index, 1);
      }
      state.carregando = false;
    },
    setGrupos(state, grupos){
      state.grupos = grupos;
      state.carregando = false;
    },
    setTotalGrupos(state, total){
      state.totalGrupos = total;
    },
    SET_CARREGANDO(state, valor){
      state.carregando = valor;
    },
    remover(state, removido){
      state.grupos = state.grupos.filter(grupo => grupo._id !== removido._id);
      state.carregando = false;
    },
    removerSubgrupo(state, index){
      state.grupoAtual.subgrupos.splice(index, 1);
    },
    novo(state){
      state.modoEdicao = false;
      state.grupoAtual = {
          nome: '',
          subgrupos: []
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.modoEdicao = true;
      state.grupoAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_GRUPO(state, grupo) {
      state.grupos.unshift(grupo);
      state.carregando = false;
    },
    ADICIONAR_SUBGRUPO(state, subgrupo){
      state.grupoAtual.subgrupos.push(subgrupo);
    },
    ATUALIZAR_GRUPO(state, grupo) {
      let grupo_encontrado = state.grupos.find(grupo_ => grupo_.id == grupo.id);
      if (grupo_encontrado) {
        grupo_encontrado = grupo;
      }
      state.carregando = false;
    },
    estadoInicial(state){
      state.grupoAtual = {
        nome: '',
        subgrupos: []
      }
      state.carregando = false;
    }
  }
}
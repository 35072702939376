<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>Buscar Cliente/Fornecedor</v-toolbar-title>
      </v-toolbar>
      <v-toolbar flat color="white">
        <v-text-field ref="input" flat filled rounded dense single-line hide-details clearable @keyup.enter="buscarNomeCliente" max-width="300px" prepend-inner-icon="search" label="Buscar Cliente" @focus="onFocus"></v-text-field>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="height: 400px">
        <v-data-table hide-default-footer :headers="headers" :search="busca" :items="clientes" item-key="_id" :loading="carregando">
          <template #item="{ item}">
            <tr @click="selecionarParticipante(item)" style="cursor: pointer" class="pt-1">
              <td>{{ item.cnpj_cpf | formatarCnpj }}</td>
              <td>{{ item.razao_nome }}</td>
              <td>{{ item.cidade }} - {{ item.estado}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="fechar()" text>Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";
import formatador from '../../util/formatador';

export default {
    
    data(){
      return {
        headers: [
          { text: "CNPJ/CPF", value: "cnpj_cpf", sortable: false },
          { text: "Razão/Nome", value: "razao_nome", sortable: false },
          { text: "Cidade - UF", value: "cidade", sortable: false },
        ],
        busca: '',
        dialog: false
      }
    },

    computed: {

      ...mapState('participante', {
        clientes: 'participantes',
        carregando: 'carregando'
      })
    },

    mounted () {
      this.$store.commit('participante/setParticipantes', []);
    },

    methods: {

      buscarNomeCliente(event){

        let nome = event.target.value;
        if(nome && nome.length > 0){  
          this.$store.dispatch('participante/buscarNomeCliente', nome);
          this.selecionarInput(event);
        }
        else{
          this.$store.commit('setMensagemErro', 'Busca Vazia');
        }
      },

      selecionarParticipante(participante){
        this.$emit('participanteSelecionado', participante);
        this.fechar();
      },

      abrir() {
        this.dialog = true;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);
      },

      fechar(){
        this.dialog = false;
        this.$emit('fechar');
      },

      onFocus(e) {
        this.selecionarInput(e);
      },

      selecionarInput(e) {
        e.target.selectionStart = 0;
        e.target.selectionEnd   = e.target.value.length;
      }
    }
}
</script>
<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formBanco" lazy-validation>
      <v-container fluid>
        <v-widget title="Informações do Banco">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field label="Código" v-model="banco.codigo" :rules="[rules.obrigatorio]"></v-text-field>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field label="Descrição" v-model="banco.descricao" :rules="[rules.obrigatorio]" required></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar">{{constantes.BOTAO_CANCELAR}}</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar">Salvar</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>
  </div>
</template>

<script>

import {mapState, mapGetters, mapActions} from "vuex";
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: "FormularioBanco",
  data: () => ({
    valid: false,
    constantes: constantes,
    rules: {...regras},
    loading: false
  }),

  computed: {
    ...mapState('banco', {
      banco: 'bancoAtual',
      carregando: 'carregando'
    }),
    
    ...mapGetters('banco', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar'
    })
  },

  methods: {
    async salvar() {
      if (this.$refs.formBanco.validate()) {
        try{
          if (this.banco._id) {
            await this.$store.dispatch('banco/atualizar', this.banco);
          } else {
            await this.$store.dispatch('banco/salvar', this.banco);
          }
          this.$router.push({ name: 'bancos' });
        }
        catch(erro){
          console.log('Houve um erro');
        }
      }
    },
    
    cancelar() {
      this.$store.commit('banco/estadoInicial');
      this.$router.push({name: 'bancos'});
    }
  },

  async created(){
    try{
      this.loading = true;
      let banco = this.$store.state.banco.bancoAtual;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
  
      if(estaEditando && (!banco._id || banco._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione um banco para editar');
        this.$router.push({ name: 'bancos' });
      }      
      if(!estaEditando){
        this.$store.commit('banco/estadoInicial');
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>
export default [
  {
    path: '/cadastros/transportadores',
    name: 'transportadores',
    component: () => import( /* webpackChunkName: "listaTransportadores" */ '@/views/transportadores/ListaTransportadores'),
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/cadastros/transportadores/novo',
    name: 'formularioTransportadorNovo',
    component: () => import( /* webpackChunkName: "formularioTransportador" */ '@/views/transportadores/FormularioTransportador'),
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/cadastros/transportadores/editar',
    name: 'formularioTransportadorEditar',
    component: () => import( /* webpackChunkName: "formularioTransportador" */ '@/views/transportadores/FormularioTransportador'),
    meta: { requiresAuth: true, nivel: 1}
  }
];
import axios from "@/util/axios";

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    notificacoes: [],
    notificacaoAtual: {
      titulo: "",
      conteudo: "",
    },
    todasNotificacoes: [],
    carregando: false,
    visualizar: false,
    visualizarTodas: false,
  },

  //equivalente ao computed properties
  getters: {
    notificacoesNaoLidas(state) {
      let naoLidas = [];
      state.notificacoes.forEach((notificacao) => {
        if (notificacao.lida == false) {
          naoLidas.push(notificacao);
        }
      });
      return naoLidas;
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    carregarNaoLidas({ commit, rootState }) {
      commit("setCarregando", true);
      axios
        .get("/notificacoes/query?lida=false")
        .then((response) => {
          commit("setNotificacoes", response.data);
        })
        .catch((erro) => {
          commit("setNotificacoes", []);
          commit("setCarregando", false);
          commit("setMensagemErro", erro.response.data.motivo, { root: true });
        });
    },

    carregarTodas({ commit, rootState }) {
      commit("setCarregando", true);
      axios
        .get("/notificacoes")
        .then((response) => {
          commit("setTodasNotificacoes", response.data);
          commit("visualizarTodas");
        })
        .catch((erro) => {
          commit("setTodasNotificacoes", []);
          commit("setCarregando", false);
          commit("setMensagemErro", erro.response.data.motivo, { root: true });
        });
    },

    marcarLida({ commit, state, rootState }, notificacao) {
      commit("setCarregando", true);
      axios
        .put("/notificacoes/" + notificacao._id)
        .then((response) => {
          commit("setCarregando", false);
          if (state.visualizar) {
            commit("marcarLida", notificacao);
          } else if (state.visualizarTodas) {
            commit("marcarLidaJanelaTodas", notificacao);
          }
        })
        .catch((erro) => {
          commit("setCarregando", false);
          commit("fechar");
          commit("setMensagemErro", erro.response.data.motivo, { root: true });
        });
    },

    marcarNaoLida({ commit, state, rootState }, notificacao) {
      commit("setCarregando", true);
      axios
        .patch("/notificacoes/" + notificacao._id)
        .then((response) => {
          commit("setCarregando", false);
          if (state.visualizar) {
            commit("marcarNaoLida", notificacao);
          } else if (state.visualizarTodas) {
            commit("marcarNaoLidaJanelaTodas", notificacao);
          }
        })
        .catch((erro) => {
          commit("setCarregando", false);
          commit("fechar");
          commit("setMensagemErro", erro.response.data.motivo, { root: true });
        });
    },
  },

  //responsável por alterar o state.
  mutations: {
    setNotificacoes(state, notificacoes) {
      state.notificacoes = notificacoes;
      state.carregando = false;
    },
    setTodasNotificacoes(state, notificacoes) {
      state.todasNotificacoes = notificacoes;
      if (notificacoes.length > 0) {
        state.notificacaoAtual = notificacoes[0];
      }
      state.carregando = false;
    },
    setNotificacao(state, notificacao) {
      state.notificacaoAtual = notificacao;
      state.visualizar = true;
    },
    detalharNotificacao(state, notificacao) {
      state.notificacaoAtual = notificacao;
    },
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    fechar(state) {
      state.visualizar = false;
    },
    fecharTodas(state) {
      state.visualizarTodas = false;
    },
    marcarLida(state, notificacao) {
      state.notificacaoAtual.lida = true;
      state.notificacoes.forEach((notif) => {
        if (notif._id == notificacao._id) {
          notif.lida = true;
        }
      });
    },
    marcarNaoLida(state, notificacao) {
      state.notificacaoAtual.lida = false;
      state.visualizar = true;
      state.notificacoes.forEach((notif) => {
        if (notif._id == notificacao._id) {
          notif.lida = false;
        }
      });
    },
    marcarLidaJanelaTodas(state, notificacao) {
      state.notificacaoAtual.lida = true;
      state.todasNotificacoes.forEach((notif) => {
        if (notif._id == notificacao._id) {
          notif.lida = true;
        }
      });
      state.notificacoes.forEach((notif) => {
        if (notif._id == notificacao._id) {
          notif.lida = true;
        }
      });
    },
    marcarNaoLidaJanelaTodas(state, notificacao) {
      state.notificacaoAtual.lida = true;
      state.todasNotificacoes.forEach((notif) => {
        if (notif._id == notificacao._id) {
          notif.lida = false;
        }
      });
      state.notificacoes.forEach((notif) => {
        if (notif._id == notificacao._id) {
          notif.lida = false;
        }
      });
    },
    visualizarTodas(state) {
      state.visualizarTodas = true;
    },
  },
};

<template>
    <v-form v-model="valid" ref="formAcertoVenda">
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex sm12>
          <v-widget title="Adicionar Forma de Pagamento">
            <div slot="widget-content">
              <v-container fluid>
                <v-layout row wrap>
                  <v-flex xs12 sm3>
                    <v-select label="Forma de Pagamento" :items="formasPagamentoDisponiveis" v-model="pagamento.forma"></v-select>
                  </v-flex>
                  <v-flex xs12 sm3>
                    <v-text-field label="Valor" v-model="pagamento.valor" :rules="validarValor"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm2>
                    <v-combobox :disabled="pagamento.forma === constantes.DINHEIRO" label="Prazo" v-model="pagamento.prazo" :items="constantes.PRAZO"></v-combobox>
                  </v-flex>
                  <v-flex xs6 sm2>
                    <v-combobox :disabled="pagamento.forma === constantes.DINHEIRO" label="Parcelas" v-model="pagamento.qtdParcelas" :items="constantes.PARCELAS"></v-combobox>
                  </v-flex>
                  <v-flex xs6 sm2>
                    <v-btn :disabled="pagamento.forma === constantes.DINHEIRO || pagamento.valor === '' || pagamento.prazo === '' || pagamento.qtdParcelas === ''" color="primary" @click="gerarParcelas()"><v-icon>av_timer</v-icon></v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container grid-list-md fluid>
                <v-layout row wrap>
                  <v-spacer></v-spacer>
                  <v-flex xs12 sm10>
                    <v-data-table :headers="headerParcelasPagamento" item-key="parcela" :items="pagamento.parcelas" hide-actions class="elevation-1">
                      <template v-slot:item="props">
                        <td>{{ props.item.numero }}</td>
                        <td>{{ props.item.vencimento }}</td>
                        <td>{{ props.item.valor | formatarMoeda }}</td>
                      </template>
                    </v-data-table>
                  </v-flex>
                  <v-flex xs12 sm2>
                    <v-btn color="primary" @click="adicionarPagamento()"><v-icon>add</v-icon></v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
          </v-widget>
        </v-flex>
        <v-flex sm12 class="mt-2">
          <v-widget title="Pagamentos">
            <div slot="widget-header-action">
                <v-chip color="red">A Pagar: {{ venda.total | formatarMoeda }}</v-chip>
                <v-chip color="primary">Pago: {{ totalPago | formatarMoeda }}</v-chip>
                <v-chip color="warning">Saldo: {{ saldoAPagar | formatarMoeda }}</v-chip>
            </div>
            <div slot="widget-content">
              <v-container grid-list-md fluid>
                <v-data-table hide-actions item-key="_id" :headers="headerPagamentos" :items="pagamentos" :loading="carregando">
                  <template v-slot:item="props">
                    <td>{{ props.item.forma }}</td>
                    <td>{{ props.item.parcelas.length }}</td>
                    <td>{{ props.item.valor | formatarMoeda }}</td>
                    <td class="align-right">
                      <v-icon @click="removerPagamento(props.index)">delete</v-icon>
                    </td>
                  </template>
                </v-data-table>
              </v-container>
            </div>
          </v-widget>
        </v-flex>
      </v-layout>
      <v-layout justify-end>
        <v-btn @click="limpar()">{{constantes.BOTAO_CANCELAR}}</v-btn>
        <v-btn color="primary" :loading="carregando" @click="enviar()" :disabled="!valid || saldoAPagar !== 0">Cadastrar</v-btn>
      </v-layout>
    </v-container>
  </v-form> 
</template>

<script>
  
  import conversor from '@/util/conversor';
  import formatador from '@/util/formatador';
  import constantes from '@/util/constantes';
  import { mapState } from 'vuex';
  import moment from 'moment';

  export default {

    data() { 
      return {
        valid: false,
        validarValor: [v => ((v && v == 0 || v == '') || (v && v.match('^[0-9]+(\,[0-9]{1,2})?$') !== null)) || "Valor Inválido"],
        constantes: constantes,
        pagamento: {
          forma: '',
          valor: '',
          quantidade: '',
          prazo: '',
          qtdParcelas: '',
          parcelas: []
        },
        pagamentos: [],
        headerParcelasPagamento: [
          { text: 'Parcela', value: 'parcela'},
          { text: 'Vencimento', value: 'vencimento'},
          { text: 'Valor', value: 'valor'}
        ],
        headerPagamentos: [
          { text: 'Forma', value: 'forma', sortable: false},
          { text: 'Parcelas', value: 'parcelas', sortable: false},
          { text: 'Valor', value: 'valor', sortable: false},
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        exibirErro: false,
        mensagem: ''
      }
    },


    computed: {
      ...mapState('venda', {
        venda: 'vendaAtual',
        carregando: 'carregando'
      }),

      formasPagamentoDisponiveis(){

        let formas = constantes.FORMAS_PAGAMENTO;
        let disponiveis = [];
        formas.forEach(forma => {
          let formaUtilizada = this.pagamentos.find(pagamento => pagamento.forma === forma.value);
          if(!formaUtilizada){
            disponiveis.push(forma);
          }
        })
        return disponiveis;
      },

      totalPago(){

        if(this.pagamentos && this.pagamentos.length > 0){
          return this.pagamentos.reduce((total, pagamento) => total + pagamento.valor, 0);
        }
        else{
          return 0;
        }
      },

      saldoAPagar(){

        let totalAPagar = this.$store.state.venda.vendaAtual.total;
        return totalAPagar - this.totalPago;
      }
    },

    methods: {

      enviar(){
        if(this.$refs.formAcertoVenda.validate()){

          this.pagamentos.forEach(pagamento => {

            //removendo campos desnecessários no backend
            if(pagamento.forma == constantes.DINHEIRO){
              delete(pagamento.parcelas);
            }
            delete(pagamento.prazo);
            delete(pagamento.qtdParcelas);
            delete(pagamento.quantidade); 

            //formatar Data
            if(pagamento.parcelas){

              pagamento.parcelas.forEach(parcela => {
                parcela.vencimento = moment(parcela.vencimento, 'DD/MM/YY').format('YYYY-MM-DD');
              })
            }
          });

          this.$store.dispatch('venda/acertarVenda', {pagamentos: this.pagamentos, router: this.$router});
          this.limpar();
          this.pagamentos = [];
        }
      },

      gerarParcelas(dados){

        this.pagamento.parcelas = [];

        let valorAPagar = conversor.stringParaNumero(this.pagamento.valor);
        let prazo = conversor.stringParaNumero(this.pagamento.prazo);
        let parcelas = conversor.stringParaNumero(this.pagamento.qtdParcelas);
        let valorParcela = formatador.formatarMoeda(valorAPagar / parcelas);

        let somaParcelas = 0;
        for(let parcela = 1; parcela <= parcelas; parcela++){
          
          let dias = parcela * prazo;
          somaParcelas += conversor.stringParaNumero(valorParcela);
          
          let novaParcela = {
            numero: parcela + '',
            vencimento: moment().add(dias, 'days').format('DD/MM/YY'),
            valor: conversor.stringParaNumero(valorParcela)
          }

          this.pagamento.parcelas.push(novaParcela);
        }

        if(somaParcelas != valorAPagar){
          let diferenca = valorAPagar - somaParcelas;
          
          let ultimaParcela = this.pagamento.parcelas[this.pagamento.parcelas.length - 1];
          ultimaParcela.valor = formatador.formatarMoeda(ultimaParcela.valor + diferenca);
          ultimaParcela.valor = conversor.stringParaNumero(ultimaParcela.valor);
        }
      },

      adicionarPagamento(){
        
        this.pagamento.valor = conversor.stringParaNumero(this.pagamento.valor);
        
        this.pagamentos.push(this.pagamento);
        this.limpar();
      },

      removerPagamento(index){

        this.pagamentos.splice(index, 1);
      },

      limpar(){
        this.pagamento = {
          forma: '',
          valor: '',
          quantidade: '',
          prazo: '',
          qtdParcelas: '',
          parcelas: []
        }
      }
    }
  }
</script>
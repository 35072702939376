import axios from '@/util/axios';
import constantes from '@/util/constantes';

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {

    pis: [],
    pisAtual: {
      codigo: '',
      descricao: ''
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false
  },

  //equivalente ao computed properties
  getters: {

    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {

    async carregar({commit, rootState}){

      commit('setCarregando', true);

      try{

        let response = await axios.get(`/pis`);
        if(response.status === 200){
          commit('setPis', response.data);
        }
      }
      catch(erro){
        commit('setPis', []);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    },
    async salvar({commit, state, rootState}){
        
      commit('setCarregando', true);

      let pis = Object.assign({}, state.pisAtual);

      if(state.modoEdicao){

        try{

          let response = await axios.put(`/pis`, pis);
          let retorno = response.data;
          if(retorno.status){
            commit('setMensagemErro', retorno.motivo, {root: true});
            commit('setCarregando', false);
          }
          else{
            commit('atualizar', retorno);
            commit('setMensagemSucesso', 'PIS Atualizado com Sucesso', {root: true});
          }
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
      else{
          
        delete(pis._id);
        commit('setCarregando', true);

        try{

          let response = await axios.post(`/pis`, pis);
          let resultado = response.data;
          if(resultado.status){
            commit('setMensagemErro', resultado.motivo, {root: true});
            commit('setCarregando', false);
          }
          else{
            commit('adicionar', resultado);
            commit('setMensagemSucesso', 'PIS Cadastrado com Sucesso', {root: true});
          }
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
    },
    remover({commit, rootState}, remover){
      commit('setCarregando', true);
      axios.delete('/pis', {
        data: { _id: remover._id }
      })
      .then((response) => {
        commit('remover', remover);
        commit('setMensagemSucesso', 'PIS Removido com Sucesso', {root: true});
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    }
  },

  //responsável por alterar o state.
  mutations: {

    setPis(state, pis){
      state.pis = pis;
      state.carregando = false;
    },
    setCarregando(state, valor){
      state.carregando = valor;
    },
    remover(state, removido){
      state.pis = state.pis.filter(p => p._id !== removido._id);
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.pisAtual = {
        codigo: '',
        descricao: ''
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.modoEdicao = true;
      state.pisAtual = editar;
      state.carregando = false;
    },
    adicionar(state, pis){
      state.pis.push(pis);
      state.carregando = false;
    },
    atualizar(state, atual){
      state.pis.forEach(p => {
        if(p._id === atual._id){
          p = atual;
        }
      });
      state.carregando = false;
    },
    estadoInicial(state){
      state.pisAtual = {
          codigo: '',
          descricao: ''
      }
      state.carregando = false;
    }
  }
}
import axios from '@/util/axios';
import moment from 'moment';
import constantes from '@/util/constantes';
import formatador from '@/util/formatador';
import caixaApi from '@/services/caixaApi';

export default{
  namespaced: true,

  //equivalente ao data
  state: {
    dataMovimentacao: moment().format('YYYY-MM-DD'),
    movimentosCaixa: [],
    movimentoAtual: {
      tipo: '',
      formaPagamento: '',
      data: '',
      valor: ''
    },
    constantes: constantes,
    modoEdicao: false,
    contascorrentes: [],
    carregando: false,
    totalMovimentosCaixa: 0
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
          return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
          return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
    movimentosCreditos(state){
      return (tipo) => state.movimentosCaixa.filter(movimento => {
          return movimento.tipo === tipo;
      })
    },
    movimentosDebitos(state){
      return (tipo) => state.movimentosCaixa.filter(movimento => {
          return movimento.tipo === tipo;
      })
    },
    movimentosTipoForma(state){
      return (formaPagamento, tipo) => state.movimentosCaixa.filter(movimento => {
          return movimento.formaPagamento === formaPagamento && movimento.tipo === tipo;
      })
    },
    movimentosForma(state){
      return (formaPagamento) => state.movimentosCaixa.filter(movimento => {
          return movimento.formaPagamento === formaPagamento;
      })
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({commit, rootState, state}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await caixaApi.listar(state.dataMovimentacao, rootState.empresaAtual, options);
        commit('SET_MOVIMENTOSCAIXA', response.movimentos);
        commit('SET_TOTAL_MOVIMENTOSCAIXA', response.total);
      } catch (error) {
        commit('SET_MOVIMENTOSCAIXA', []);
        commit('setMensagemErro', 'Não foi possível listar o Caixa', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async listarContasCorrentes({commit, rootState}) {
      try {
        commit('SET_CARREGANDO');
        let response = await caixaApi.listarContasCorrentes(rootState.empresaAtual);
        commit('SET_CONTAS_CORRENTES', response.contas);
      } catch (error) {
        commit('SET_CONTAS_CORRENTES', []);
        commit('setMensagemErro', 'Não foi possível listar as contas correntes', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async creditar({state, commit, rootState}, lancamento){
      try{
        commit('SET_CARREGANDO');
        commit('SET_MOVIMENTO', lancamento);
        let movimento = Object.assign({}, state.movimentoAtual);
        let response = await caixaApi.creditar(movimento, rootState.empresaAtual);
        let novoMovimento = response;
            novoMovimento.usuario = {
              _id: response.usuario,
              nome: rootState.usuario.nome
            }
        commit('ADICIONAR_MOVIMENTOCAIXA', novoMovimento);
        commit('setMensagemSucesso', 'Movimento salvo com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', "Erro ao adicionar o movimento", {root: true});
        throw erro;
      }
    },
    async debitar({state, commit, rootState}, lancamento){
      try{
        commit('SET_CARREGANDO');
        if (lancamento.conta) {
          lancamento.data_deposito = moment(lancamento.data_deposito).format('YYYY-MM-DD');
          lancamento.descricao = 'Retirada para Depósito (AG: ' + lancamento.conta.numero + ' CC: ' + lancamento.conta.numero + ' - ' + lancamento.conta.nome +')';
          let conta_id = lancamento.conta._id;
          delete lancamento.conta;
          lancamento.conta = conta_id;
        }
        commit('SET_MOVIMENTO', lancamento);
        let movimento = Object.assign({}, state.movimentoAtual);
        let response = await caixaApi.debitar(movimento, rootState.empresaAtual);
        let novoMovimento = response;
            novoMovimento.usuario = {
              _id: response.usuario,
              nome: rootState.usuario.nome
            }
        commit('ADICIONAR_MOVIMENTOCAIXA', novoMovimento);
        commit('setMensagemSucesso', 'Movimento salvo com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', "Erro ao adicionar o movimento", {root: true});
        throw erro;
      }
    },
    async excluir({commit, rootState}, id){
      commit('SET_CARREGANDO', true);
      await caixaApi.excluir(id, rootState.empresaAtual)
        .then((response) => {
          commit('EXCLUIR_MOVIMENTOCAIXA', id);
          commit('setMensagemSucesso', 'Lançamento Removido com sucesso', {root: true});
        })
        .catch((erro) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', "Erro ao excluir lançamento", {root: true});
        });
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_MOVIMENTOSCAIXA(state, movimentosCaixa) {
      state.movimentosCaixa = movimentosCaixa;
      state.carregando = false;
    },
    SET_TOTAL_MOVIMENTOSCAIXA(state, total) {
      state.totalMovimentosCaixa = total;
    },
    SET_MOVIMENTO(state, movimentoAtual) {;
      state.movimentoAtual = movimentoAtual
    },
    ADICIONAR_MOVIMENTOCAIXA(state, movimento) {
      state.movimentosCaixa.push(movimento);
      state.totalMovimentosCaixa = 1+state.totalMovimentosCaixa
      state.carregando = false;
    },
    EXCLUIR_MOVIMENTOCAIXA(state, id) {
      let index = state.movimentosCaixa.findIndex(movimentosCaixa => movimentosCaixa._id == id);
      if (index > -1) {
        state.movimentosCaixa.splice(index, 1);
      }
      state.totalMovimentosCaixa = state.totalMovimentosCaixa-1
      state.carregando = false;
    },
    SET_CONTAS_CORRENTES(state, contascorrentes) {
      state.contascorrentes = contascorrentes;
      state.carregando = false;
    },
    filtroData(state, data) {
      state.dataMovimentacao = data
    }
  }
}
import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual) {
    return axios.get(`/cfopsCompraEntrada?` + qs.stringify(options))
      .then(response => response.data);
  }

  function salvar(mapeamento, empresaAtual) {
    return axios.post(`/cfopsCompraEntrada`, mapeamento)
      .then(response => response.data);
  }

  function atualizar(mapeamento, empresaAtual) {
    return axios.put(`/cfopsCompraEntrada/${mapeamento._id}`, mapeamento)
      .then(response => response.data);
  }

  function excluir(id, empresaAtual) {
    return axios.delete(`/cfopsCompraEntrada/${id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    salvar,
    atualizar,
    excluir
  }
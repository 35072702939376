import axios from '@/util/axios';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import formatador from '@/util/formatador';
import moment from 'moment';

export default{
    
  namespaced: true,

    //equivalente ao data
  state: {

    movimentos: [],
    movimentoAtual: {
      tipo: '',
      produto: '',
      quantidade: '',
      responsavel: '',
      numero_nota: '',
      valor_compra: '',
      valor_venda: '',
      observacao: '',
      data: moment().format('YYYY-MM-DD')
    },
    produto: {
      codigo: '',
      descricao: ''
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalMovimentos: 0
  },

    //equivalente ao computed properties
  getters: {

    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
      
    async carregar({commit, rootState}, options){
        
      commit('setCarregando', true);

      try{

        let url = `/estoques?page=${options.page}&pageSize=${options.itemsPerPage}`;
        if(options.query && options.query !== ''){
          url = `${url}&query=${options.query}`;
        }

        let response = await axios.get(url);
        let resultado = response.data;

        commit('setMovimentos', resultado.movimentos);
        commit('setTotalMovimentos', resultado.total);
      }
      catch(erro){
        commit('setMovimentos', []);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    },
    async salvar({commit, state, rootState}){
        
      commit('setCarregando', true);
      
      let movimento = Object.assign({}, state.movimentoAtual);

      //conversoes de string para número
      movimento.valor_compra = conversor.stringParaNumero(movimento.valor_compra);
      movimento.valor_venda = conversor.stringParaNumero(movimento.valor_venda);
      movimento.quantidade = conversor.stringParaNumero(movimento.quantidade);
      movimento.produto = state.produto._id;

      if(state.modoEdicao){

        commit('setCarregando', true);

        try{

          let response = await axios.put(`/estoques`, movimento);
          let resultado = response.data;
          if(resultado.status){
            commit('setMensagemErro', resultado.motivo, {root: true});
            commit('setCarregando', false);
          }
          else{
            commit('atualizar', resultado);
            commit('setMensagemSucesso', 'Movimento Atualizado com Sucesso', {root: true});
          }
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
      else{

        delete(movimento._id);
        commit('setCarregando', true);

        try{

          let response = await axios.post('/estoques', movimento);
          let resultado = response.data;
          if(resultado.status){
            commit('setMensagemErro', resultado.motivo, {root: true});
            commit('setCarregando', false);
          }
          else{
            commit('adicionar', response.data);
            commit('setMensagemSucesso', 'Movimento Cadastrado com Sucesso', {root: true});
          }
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
    },
    remover({commit, rootState}, remover){
      commit('setCarregando', true);
      axios.delete('/estoques', {
        data: { '_id': remover._id }
      })
      .then((response) => {
        commit('remover', remover);
        commit('setMensagemSucesso', 'Movimento Removido com Sucesso', {root: true});
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    }
  },

    //responsável por alterar o state.
  mutations: {

    setMovimentos(state, movimentos){
      state.movimentos = movimentos;
      state.carregando = false;
    },
    setTotalMovimentos(state, total){
      state.totalMovimentos = total;
    },
    setProduto(state, produto){
      state.produto = produto;
    },
    setCarregando(state, valor){
      state.carregando = valor;
    },
    remover(state, removido){
      state.movimentos = state.movimentos.filter(item => item._id !== removido._id);
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.movimentoAtual = {
        tipo: '',
        produto: '',
        quantidade: '',
        responsavel: '',
        numero_nota: '',
        valor_compra: '',
        valor_venda: '',
        observacao: '',
        data: moment().format('YYYY-MM-DD')
      }
      state.produto = {
        codigo: '',
        descricao: ''
      }
      state.carregando = false;
    },
    editar(state, editar){
        
      state.modoEdicao = true;

      editar.valor_compra = formatador.formatarValor(editar.valor_compra);
      editar.valor_venda = formatador.formatarValor(editar.valor_venda);
      editar.quantidade = formatador.formatarValor(editar.quantidade);
      editar.data = moment(editar.data).format('YYYY-MM-DD');

      state.produto = editar.produto;
      state.movimentoAtual = editar;
      state.carregando = false;
    },
    adicionar(state, movimento){
      state.movimentos.push(movimento);
      state.carregando = false;
    },
    atualizar(state, atual){
      state.movimentos.forEach(movimento => {
        if(movimento._id === atual._id){
          movimento = atual;
        }
      });
      state.carregando = false;
    },
    estadoInicial(state){
      state.movimentoAtual = {
        tipo: '',
        produto: '',
        quantidade: '',
        responsavel: '',
        numero_nota: '',
        valor_compra: '',
        valor_venda: '',
        observacao: '',
        data: moment().format('YYYY-MM-DD')
      }
      state.produto = {
        codigo: '',
        descricao: ''
      }
      state.carregando = false;
    }
  }
}

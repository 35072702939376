<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formVendedor" lazy-validation>
      <v-container fluid>
        <v-widget title="Informações do Vendedor">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="8">
                <v-text-field label="Nome" autofocus v-model="vendedor.nome" :rules="[rules.obrigatorio]"></v-text-field>
              </v-col>  
              <v-col cols="12" sm="3">
                <v-text-field label="Comissão ( % )" v-model="vendedor.comissao" :rules="[rules.obrigatorio, rules.zero, rules.percentual]"></v-text-field>
              </v-col>  
              <v-col cols="12" sm="1">
                <v-checkbox label="Ativo" v-model="vendedor.ativo"></v-checkbox>
              </v-col>  
            </v-row>
          </template>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar">{{constantes.BOTAO_CANCELAR}}</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar">{{textoBtnConfirmar}}</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>  
  </div>
</template>

<script>

import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
import constantes from '@/util/constantes';
import validador from '@/util/validador';
import * as regras from '@/util/validacaoFormulario';

export default {

  data: () => ({
    valid: false,
    constantes: constantes,
    rules: {...regras},
    loading: false
  }),

  computed: {

    ...mapState('vendedor', {
      vendedor: 'vendedorAtual',
      carregando: 'carregando'
    }),
    
    ...mapGetters('vendedor', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar'
    })
  },

  methods: {

    async salvar(){
      if (this.$refs.formVendedor.validate()) {
        try{
          if (this.vendedor._id) {
            await this.$store.dispatch('vendedor/atualizar', this.vendedor);
          } else {
            await this.$store.dispatch('vendedor/salvar', this.vendedor);
          }
          this.$router.push({ name: 'vendedores' });
        }
        catch(erro){
          console.log('Houve um erro', erro);
        }
      }
    },

    cancelar() {
      this.$store.commit('vendedor/estadoInicial');
      this.$router.push({name: 'vendedores'});
    }
  },

  async created(){
    
    try{

      this.loading = true;
      
      let vendedor = this.$store.state.vendedor.vendedorAtual;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
  
      if(estaEditando && (!vendedor._id || vendedor._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione um vendedor para editar');
        this.$router.push({ name: 'vendedores' });
      }
      
      if(!estaEditando){
        this.$store.commit('vendedor/estadoInicial');
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>
<template>
  <div>
    <v-subheader class="text-subtitle-1 text--primary">
      Remessas de Cheques
    </v-subheader>
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="white">
          <v-row>
            <v-col xs="12" sm="6">
              <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @keyup.enter="filtrarBusca($event)" @click:clear="limparBusca" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col xs="12" sm="5" class="text-right">
              <v-btn color="primary" @click="nova">Nova</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="remessas" :loading="carregando">
          <template v-slot:item="{item}">
            <tr>
              <td><span><b>{{ item.numero }}</b></span></td>
              <td>{{ item.data | formatarData }}</td>
              <td>{{ item.conta.agencia }}</td>
              <td>{{ item.conta.numero }}</td>
              <td>{{ item.conta.nome }}</td>
              <td class="text-center font-weight-bold">{{ item.cheques.length }}</td>
              <td class="text-right font-weight-bold"><span>{{ item.valor | formatarMoeda }}</span></td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="item._id + 20" @click="editar(item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="item._id + 40" @click="excluir(item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
</div>
</template>
<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';
  import moment from 'moment';

  export default {
    name: "ListaRemessasCheques",
    components: {
      //
    },
    data () {
      return {
        headers: [
          { text: 'Número', value: 'emitente' },
          { text: 'Data', value: 'data' },
          { text: 'Agência', value: 'agencia' },
          { text: 'Conta', value: 'conta' },
          { text: 'Nome', value: 'conta_nome' },
          { text: 'Cheques', value: 'remessas', align: 'center' },
          { text: 'Valor', value: 'valor', align: 'right' },
          { text: 'Ações', value: 'name', align: 'center',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        },
        remessaSelecionado: null,
      }
    },

    watch: {
      options: {
        handler () {
          this.listar();
        },
        deep: true,
      },
    },

    computed: {
      ...mapState('remessasCheques', {
        remessas: 'remessas',
        remessa: 'remessaAtual',
        carregando: 'carregando',
        total: 'totalRemessasCheques'
      })
    },

    methods: {
      async listar(){
        this.filtro_ativo = false;
        await this.$store.dispatch('remessasCheques/listar');
      },
      
      async filtrarBusca(){
        let newOptions = {
          ...this.options,
          query: this.busca
        }

        this.$store.dispatch('remessasCheques/listar', newOptions);
        setTimeout(() => {
          event.target.select();
        }, 300);
      },

      async editar(remessa){
        this.$store.commit('remessasCheques/SET_REMESSA_ATUAL', remessa);
        await this.$store.dispatch('remessasCheques/listarChequesEmCaixa', {});
        await this.$store.dispatch('remessasCheques/listarContasCorrentes');
        this.$store.commit('remessasCheques/editar', this.remessa);
        this.$router.push({ name: 'RemessasChequesEditar' });
      },

      async nova(){
        this.$store.commit('remessasCheques/nova');
        this.$router.push({ name: 'RemessasChequesNovo' });
        await this.$store.dispatch('remessasCheques/listarChequesEmCaixa', {});
        await this.$store.dispatch('remessasCheques/listarContasCorrentes');
      },

      excluir(remessa){
        this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('remessasCheques/excluir', remessa);
          }
        });
      },

      limparBusca(){
        this.listar();
      },
    },
  }
</script>
import axios from '@/util/axios';
import constantes from '@/util/constantes';
import planoContasApi from "@/services/planoContasApi";

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {
    planosConta: [],
    planocontaAtual: {
      codigo: '',
      descricao: ''
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalPlanoContas: 0
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await planoContasApi.listar(options, rootState.empresaAtual);
        commit('SET_PLANOS', response);
        commit('SET_TOTAL_PLANOS', response.total);
      } catch (error) {
        console.log(error)
        commit('SET_PLANOS', []);
        commit('setMensagemErro', 'Não foi possível listar os planos de contas', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({commit, state, rootState}){
      commit('SET_CARREGANDO', true);

      try{
        let planoconta = Object.assign({}, state.planocontaAtual);
        let response = await planoContasApi.salvar(planoconta, rootState.empresaAtual);
        commit('ATUALIZAR_PLANOCONTA', response);
        commit('setMensagemSucesso', 'Plano de contas salvo com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    },
    async atualizar({commit, rootState}, planoconta) {
      await planoContasApi.atualizar(planoconta, rootState.empresaAtual)
        .then((response) => {
          commit('ATUALIZAR_PLANOCONTA', planoconta);
          commit('setMensagemSucesso', 'Plano de contas atualizado com sucesso', {root: true});
        })
        .catch((erro) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
        });
    },
    async excluir({commit, rootState}, planoconta){
        commit('SET_CARREGANDO', true);
        await planoContasApi.excluir(planoconta._id, rootState.empresaAtual)
          .then((response) => {
            commit('EXCLUIR_PLANOCONTA', planoconta._id);
            commit('setMensagemSucesso', 'Plano de contas removido com sucesso', {root: true});
          })
          .catch((erro) => {
            commit('SET_CARREGANDO', false);
            commit('setMensagemErro', erro.response.data.motivo, {root: true});
          });
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_PLANOS(state, planosConta) {
      state.planosConta = planosConta;
      state.carregando = false;
    },
    SET_TOTAL_PLANOS(state, total) {
      state.totalPlanoContas = total;
    },
    SET_PLANOCONTA(state, planocontaAtual) {;
      state.planocontaAtual = planocontaAtual
    },
    EXCLUIR_PLANOCONTA(state, id) {
      let index = state.planosConta.findIndex(planoconta => planoconta._id == id);
      if (index > -1) {
        state.planosConta.splice(index, 1);
      }
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.planocontaAtual = {
        codigo: '',
        descricao: ''
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.modoEdicao = true;
      state.planocontaAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_PLANOCONTA(state, planoconta) {
      state.planosConta.unshift(planoconta);
      state.carregando = false;
    },
    ATUALIZAR_PLANOCONTA(state, planoconta) {
      let planoconta_encontrado = state.planosConta.find(planoconta_ => planoconta_.id == planoconta.id);
      if (planoconta_encontrado) {
        planoconta_encontrado = planoconta;
      }
      state.carregando = false;
    },
    estadoInicial(state){
      state.planocontaAtual = {
        codigo: '',
        descricao: ''
      }
      state.carregando = false;
    }
  }
}
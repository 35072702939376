import axios from '@/util/axios';
import constantes from '@/util/constantes';

export default{
    
  namespaced: true,

    //equivalente ao data
  state: {

    transportadores: [],
    transportadorAtual: {
      cnpj_cpf: '',
      inscricao_rg: '',
      razao_nome: '',
      veiculos: [],
      codigo_ant: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      codigo_cidade: '',
      estado: '',
      pais: '',
      codigo_pais: ''
    },
    cidades: [],
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    carregando_cidade: false,
    totalTransportadores: 0
  },

    //equivalente ao computed properties
  getters: {

    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
    listaCidades(state) {
      return state.cidades.map(cidade => { 
        return {
          text: cidade.cidade, 
          value: cidade.codigo_cidade 
        };
      });
    }
  },

    //equivalente aos methods
    //responsável por chamar a api externa
  actions: {

    async filtrarCidadesEstado({commit}, estado){

      try{

        commit('setCarregandoCidades', true);
        let cidades = await axios.get(`/cidades/estado/${estado}`).then(response => response.data);
        commit('setCidades', cidades);
      } 
      catch (error) {
        commit('setCarregandoCidades', false);
        commit('setMensagemErro', 'Falha ao buscar cidades', {root: true});
        throw error;
      } finally {
        commit('setCarregandoCidades', false);
      }
    },

    filtrarNomeTransportador({ commit, rootState }, nome){

      commit('setCarregando', true);
      axios.get(`/transportadores/razao_nome?razao_nome=${nome}`)
      .then((response) => {
        commit('setTransportadores', response.data);
      })
      .catch((erro) => {
        commit('setTransportadores', []);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    },

    carregar({commit, rootState}, options){

      commit('setCarregando', true);

      let url = `/transportadores?page=${options.page}&pageSize=${options.itemsPerPage}`;
      if(options.query && options.query !== ''){
        url = `${url}&query=${options.query}`;
      }

      axios.get(url)
      .then((response) => {
        commit('setTotalTransportadores', response.data.total);
        commit('setTransportadores', response.data.transportadores);
      })
      .catch((erro) => {
        commit('setTransportadores', []);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });  
    },
    
    async salvar({commit, state, rootState}){
        
      commit('setCarregando', true);
      let transportador = Object.assign({}, state.transportadorAtual);
      transportador.cnpj_cpf = transportador.cnpj_cpf.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
      transportador.cep = transportador.cep.replace('.', '').replace('-', '');

      if(state.modoEdicao){

        try{

          let response = await axios.put(`/transportadores`, transportador);
          console.log(response);
          let retorno = response.data;
          commit('atualizar', retorno);
          commit('setMensagemSucesso', 'Transportador Atualizado com Sucesso', {root: true});
        }
        catch(erro){
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          commit('setCarregando', false);
          throw erro;
        }
      }
      else{

        delete(transportador._id);

        try{

          let response = await axios.post(`/transportadores`, transportador);
          let retorno = response.data;
          commit('adicionar', retorno);
          commit('setMensagemSucesso', 'Transportador Cadastrado com Sucesso', {root: true});
          commit('setCarregando', false);
        }
        catch(erro){
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          commit('setCarregando', false);
          throw erro;
        }
      }
    },
    remover({commit, rootState}, remover){
      commit('setCarregando', true);
      axios.delete('/transportadores', {
        data: { _id: remover._id }
      })
      .then((response) => {
        commit('remover', remover);
        commit('setMensagemSucesso', 'Transportador Removido com Sucesso', {root: true});
      })
      .catch((erro) => {
        commit('setCarregando', false)
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    }
  },

    //responsável por alterar o state.
  mutations: {

    setCidades(state, cidades){
      state.cidades = cidades;
    },
    setCidade(state, codigo_cidade){
      let cidade = state.cidades.find(cidade => cidade.codigo_cidade == codigo_cidade);

      state.transportadorAtual.cidade = cidade.cidade;
      state.transportadorAtual.codigo_cidade = cidade.codigo_cidade;
      state.transportadorAtual.codigo_estado = cidade.codigo_estado;
      state.transportadorAtual.pais = 'Brasil';
      state.transportadorAtual.codigo_pais = 1058;
    },
    setTransportadores(state, transportadores){
      state.transportadores = transportadores;
      state.carregando = false;
    },
    setTotalTransportadores(state, total){
      state.totalTransportadores = total;
    },
    adicionarVeiculo(state, dados){
      state.transportadorAtual.veiculos.push(dados);
    },
    removerVeiculo(state, dados){
      let veiculos = state.transportadorAtual.veiculos; 
      state.transportadorAtual.veiculos = veiculos.filter(veiculo => { return veiculo.placa !== dados.placa && veiculo.uf !== dados.uf})
    },
    setEndereco(state, endereco){
      state.transportadorAtual.logradouro = endereco.logradouro;
      state.transportadorAtual.bairro = endereco.bairro;
      state.transportadorAtual.estado = endereco.uf;
      state.transportadorAtual.cidade = endereco.localidade;
      state.transportadorAtual.codigo_cidade = endereco.ibge;
      state.transportadorAtual.pais = 'Brasil';
      state.transportadorAtual.codigo_pais = 1058;
    },
    setCarregando(state, valor){
      state.carregando = valor;
    },
    setCarregandoCidades(state, valor){
      state.carregando_cidade = valor;
    },
    remover(state, removido){
      state.transportadores = state.transportadores.filter(transportador => transportador._id !== removido._id);
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.transportadorAtual = {
        cnpj_cpf: '',
        inscricao_rg: '',
        razao_nome: '',
        veiculos: [],
        codigo_ant: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: ''
      }
    },
    editar(state, editar){
      state.modoEdicao = true;
      state.transportadorAtual = editar;
    },
    adicionar(state, transportador){
      state.transportadores.push(transportador);
      state.carregando = false;
    },
    atualizar(state, atual){
      state.transportadores.forEach(transportador => {
        if(transportador._id === atual._id){
          transportador = atual;
        }
      });
      state.carregando = false;
    },
    estadoInicial(state){
      state.clienteAtual = {
        cnpj_cpf: '',
        inscricao_rg: '',
        razao_nome: '',
        veiculos: [],
        codigo_ant: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: ''
      }
      state.carregando = false;
      state.cidades = [];
    }
  }
}
import Login from '@/views/Login';
import LoginAdmin from '@/views/LoginAdmin';
import FormularioCertificadoPadrao from '@/views/cadastros/FormularioCertificadoPadrao';
import FormularioInformacaoUsuario from '@/views/cadastros/FormularioInformacaoUsuario';
import Dashboard from '@/views/Dashboard';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/admin',
    name: 'loginAdmin',
    component: LoginAdmin
  },
  {
    path: '/configuracoes/certificado_digital',
    name: 'formularioCertificadoDigital',
    component: FormularioCertificadoPadrao,
    meta: { requiresAuth: true, nivel: 2}
  },
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    meta: { requiresAuth: true, nivel: 1}
  },
  { path: '/home', redirect: '/' },
  {
    path: '/minhaconta',
    name: 'minhaconta',
    component: FormularioInformacaoUsuario,
    meta: { requiresAuth: true, nivel: 1}
  }
];
import ServiceCupons from "@/services/cupons";
import { deepClone } from "@/util/helpers";
import moment from 'moment';

const cupom_zerado = {
  /* numero: '',
  participante: {},
  data: moment().format('YYYY-MM-DD'),
  entrega: '',
  quantidade: 0,
  empresa: '',
  observacao: '',
  valor: 0,
  produtos: [],
  status: 'Pendente',
  frete: 0,
  desconto: 0,
  duplicatas: [], */
};

export default {

  namespaced: true,

  //equivalente ao data
  state: {
    cupom: {},
    cupons: [],
    total: 0,
    carregando: false,
    busca: {
      data: moment().format('YYYY-MM-DD'),
      terminal: '',
    }
  },

  getters: {

  },

  mutations: {
    SET_ITENS(state, cupons) {
      state.cupons = cupons;
    },

    SET_TOTAL_ITENS(state, total) {
      state.total = total;
    },

    SET_ITEM(state, cupom) {
      state.cupom = cupom
    },

    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },

    ZERAR(state) {
      state.cupom = {};
    },
  },

  actions: {
    async buscar({commit}, busca) {
      try {
        commit('SET_CARREGANDO');
        let response = await ServiceCupons.buscar(busca);
        commit('SET_ITENS', response.cupons);
        commit('SET_TOTAL_ITENS', response.total);
      } catch (error) {
        commit('SET_ITENS', []);
        commit('setMensagemErro', 'Não foi possível listar os pedidos', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
  }
}
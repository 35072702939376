import moment from 'moment';
import constantes from '@/util/constantes';
import { deepClone } from "@/util/helpers";
import contasreceberApi from '@/services/contasreceberApi'

const conta_zerada = {
  documento: "",
  participante: {
    _id: "",
    razao_nome: ""
  },
  data_cadastro: moment().format('YYYY-MM-DD'),
  data_vencimento: "",
  valor: 0,
  tipo: "Duplicata",
  empresa: "",
  recebimentos: []
}

export default {
  namespaced: true,

  state: {
    contasAReceber: [],
    contasAReceberCliente: [],
    contaAtual: {...conta_zerada},
    clientes: [],
    clienteAtual: {},
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalContasAReceber: 0
  },

  getters: {
    estaEditando (state) {
      return state.modoEdicao;
    },
    textoBtnConfirmar (state) {
      if (state.modoEdicao) {
        return state.constantes.BOTAO_SALVAR_EDICAO;
      } else {
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO', true);
        let response = await contasreceberApi.listar(options, rootState.empresaAtual);
        commit('SET_CONTAS_A_RECEBER', response.contas);
        commit('SET_TOTAL_CONTAS_A_RECEBER', response.total);
      } catch (erro) {
        commit('SET_CONTAS_A_RECEBER', []);
        commit('setMensagemErro', "Erro ao listar as Contas a Receber", {root: true});
        throw erro;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async filtrar({commit, state, rootState}, options) {
      try {
        commit('SET_CARREGANDO', true);
        let response = await contasreceberApi.filtrar(options, state.clienteAtual, rootState.empresaAtual);
        commit('SET_CONTAS_A_RECEBER', response.filtro);
        commit('SET_CLIENTE', response.cliente);
        commit('SET_TOTAL_CONTAS_A_RECEBER', response.total);
      } catch (erro) {
        commit('SET_CONTAS_A_RECEBER', []);
        commit('setMensagemErro', "Erro ao carregar as Contas a Receber do cliente", {root: true});
        throw erro;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({commit, state, rootState}) {
      try {
        commit('SET_CARREGANDO', true);
        let contaAReceber = Object.assign({}, state.contaAtual);
        delete(contaAReceber._id);
        await contasreceberApi.salvar(contaAReceber, rootState.empresaAtual);
        commit('setMensagemSucesso', 'Conta a Receber cadastrada com sucesso', {root: true});
      } catch (erro) {
        commit('setMensagemErro', "Erro ao salvar a Conta a Receber", {root: true});
        throw erro;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async atualizar({commit, state, rootState}) {
      try {
        commit('SET_CARREGANDO', true);
        let contaAReceber = Object.assign({}, state.contaAtual);
        let response = await await contasreceberApi.atualizar(contaAReceber, rootState.empresaAtual);
        commit('ATUALIZAR', response);
        commit('setMensagemSucesso', 'Conta a Receber atualizada com sucesso', {root: true});
      } catch (erro) {
        commit('setMensagemErro', "Erro ao realizar a baixa", {root: true});
        throw erro;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvarBaixa({commit, state, rootState}, recebimento){
      try {
        commit('SET_CARREGANDO', true);
        recebimento.conta_a_receber = state.contaAtual._id;
        // let response = await axios.post(`/contasAReceber/${state.contaAtual._id}/recebimentos/baixar`, recebimento);
        let response = await contasreceberApi.salvarBaixa(state.contaAtual, recebimento, rootState.empresaAtual);
        commit('ATUALIZAR', response);
        commit('setMensagemSucesso', 'Conta a Receber atualizada com sucesso', {root: true});
      } catch (erro) {
        commit('setMensagemErro', "Erro ao realizar a baixa", {root: true});
        throw erro;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async excluirRecebimento({commit, state, rootState}, recebimento) {
      try {
        commit("SET_CARREGANDO");
        // let response = await axios.delete(`/contasAReceber/${state.contaAtual._id}/recebimentos/${recebimento._id}`);
        let response = await contasreceberApi.excluirRecebimento(state.contaAtual, recebimento, rootState.empresaAtual);
        commit('ATUALIZAR', response);
        commit('REMOVER_PAGAMENTO', recebimento);
        commit('setMensagemSucesso', 'Recebimento excluído com sucesso', {root: true});
      } catch (erro) {
        commit('setMensagemErro', "Erro ao remover recebimento", {root: true});
        throw erro;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async excluir({commit, rootState}, conta){
      try {
        commit('SET_CARREGANDO');
        await contasreceberApi.excluir(conta._id, rootState.empresaAtual);
        commit('EXCLUIR_CONTA', conta._id);
        commit('setMensagemSucesso', 'Conta a Receber excluida com sucesso', {root: true});
      } catch (erro) {
        commit('setMensagemErro', "Erro ao excluir a Conta a Receber", {root: true});
        throw erro;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    }
  },

  mutations: {
    SET_CARREGANDO(state, valor){
      state.carregando = valor;
    },
    SET_CONTAS_A_RECEBER(state, contas){
      state.contasAReceber = contas;
    },
    SET_PARTICIPANTE(state, participante) {
      state.contaAtual.participante = participante;
    },
    SET_CLIENTE(state, cliente) {
      state.clienteAtual = cliente;
    },
    SET_TOTAL_CONTAS_A_RECEBER(state, total) {
      state.totalContasAReceber = total;
    },
    EXCLUIR_CONTA(state, conta_excluida_id){
      let index = state.contasAReceber.findIndex((contaAReceber) => {
        return contaAReceber._id == conta_excluida_id;
      })

      if (index > -1) {
        state.contasAReceber.splice(index, 1);
      }
    },
    NOVO(state){
      state.modoEdicao = false;
      state.contaAtual = {...conta_zerada};
      state.carregando = false;
    },
    EDITAR(state, contaAReceber){
      state.modoEdicao = true;
      state.contaAtual = deepClone(contaAReceber);
      state.carregando = false;
    },
    ADICIONAR(state, contaAReceber){
      state.contasAReceber.unshift(contaAReceber);
      state.carregando = false;
    },
    ATUALIZAR(state, contaAtualizada){
      let index = state.contasAReceber.findIndex((contaAReceber) => {
        return contaAReceber._id == contaAtualizada._id;
      })

      if (index > -1) {
        state.contasAReceber.splice(index, 1, contaAtualizada);
      }

      state.contaAtual = contaAtualizada;
    },
    ESTADO_INICIAL(state){
      state.contaAtual = {...conta_zerada}
      state.carregando = false;
    },
    REMOVER_PAGAMENTO(state, pagamento) {
      let index = state.contaAtual.recebimentos.findIndex(_pagamento => _pagamento.id == pagamento.id);
      console.log(index);
      if (index > -1) {
        state.contaAtual.recebimentos.splice(index, 1);
      }
    },
  }
}
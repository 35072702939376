import ListaContasAReceber from '@/views/contasareceber/ListaContasAReceber';
import FormularioContaAReceber from '@/views/contasareceber/FormularioContaAReceber';
import FormularioBaixaContaAReceber from '@/views/contasareceber/FormularioBaixaContaAReceber';

export default [
  {
    path: '/movimentacoes/contasAReceber',
    name: 'contasAReceber',
    component: ListaContasAReceber,
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/movimentacoes/contasAReceber/nova',
    name: 'formularioContaAReceberNova',
    component: FormularioContaAReceber,
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/movimentacoes/contasAReceber/editar',
    name: 'formularioContaAReceberEditar',
    component: FormularioContaAReceber,
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/movimentacoes/contasAReceber/baixar',
    name: 'formularioBaixarContaAReceber',
    component: FormularioBaixaContaAReceber,
    meta: { requiresAuth: true, nivel: 1}
  },
];
<template>
  <div>
    <v-container>
      <v-row style="margin-top: 50px" v-if="carregando" dense>
        <v-col cols="12" class="text-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>

      <v-form v-else v-model="valid" ref="formOrdemServico" lazy-validation>
        <v-widget title="Nova Ordem de Serviço" :temCampoObrigatorio="true">
          <div slot="widget-content">
            <v-row dense>
              <v-col cols="12" xs="12" sm="6">
                <v-row dense>
                  <v-col cols="12" xs="12" sm="2">
                    <v-text-field v-model="ordem_servico.numero" name="numero" label="Número" :placeholder="!ordem_servico._id ? ' ######' : ''" autocomplete="off" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="4">
                    <campo-data label="Data" v-model="ordem_servico.data" :rules="[rules.obrigatorio]" ref="data" @keypress.native.enter="$refs.agendamento.focus()" :disabled="visualizar"></campo-data>
                  </v-col>
                  <v-col cols="12" xs="12" sm="4">
                    <campo-data label="Agendamento" ref="agendamento" v-model="ordem_servico.agendamento" @keypress.native.enter="$refs.placa.focus()" :disabled="visualizar"></campo-data>
                  </v-col>
                  <v-col cols="12" xs="12" sm="2">
                    <v-text-field label="Placa" ref="placa" v-mask="'AAA-#A##'" v-model="ordem_servico.placa" @keypress.native.enter="$refs.cliente.focus()" :disabled="visualizar"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="6">
                <v-row dense>
                  <v-col cols="4" xs="12" sm="4">
                    <buscar-participante label="Buscar Cliente" ref="cliente" v-model="ordem_servico.participante.cnpj_cpf" @participanteSelecionado="adicionarParticipante" :disabled="visualizar"></buscar-participante>
                  </v-col>
                  <v-col cols="8" xs="12" sm="8">
                    <v-text-field label="Cliente" v-model="ordem_servico.participante.razao_nome" readonly ref="razao_nome" :rules="[rules.obrigatorio]" @keypress.native.enter="$refs.descricao.focus()" :disabled="visualizar"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6">
                <v-textarea label="Descrição" ref="descricao" rows="10" v-model="ordem_servico.descricao" :rules="[rules.obrigatorio]" @keypress.enter="focusTecnico" :disabled="visualizar"></v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row dense>
                  <v-col cols="12" sm="12">
                    <v-card>
                      <v-toolbar flat dark color="primary" dense>
                        <v-toolbar-title>Produtos do Contrato</v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <v-card-text>
                        <v-data-table fixed-header height="212px" hide-default-footer :headers="headersProdutosContrato" :server-items-length="ordem_servico.participante.produtoscontrato ? ordem_servico.participante.produtoscontrato.length : 0" :items="ordem_servico.participante.produtoscontrato">
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ item.produto }}</td>
                              <td class="text-center">{{ item.serie }}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class=""></v-divider>
            <v-container>
              <v-row dense>
                <v-col class="pl-0 pr-0 pt-0 pb-0">
                  <v-card>
                    <v-toolbar flat dark color="primary" dense>
                      <v-toolbar-title>Produtos</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn text @click="abrirBuscaProdutos" v-show="!visualizar">Adicionar Produto</v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-data-table fixed-header height="220px" hide-default-footer :headers="headersProdutos" :server-items-length="ordem_servico.produtos.length" :items="ordem_servico.produtos">
                        <template v-slot:item="{item}">
                          <tr>
                            <td class="text-center">{{ item.codigo }}</td>
                            <td>{{ item.descricao }}</td>
                            <td class="text-center">{{ item.unidade }}</td>
                            <td class="text-center">{{ item.quantidade | formatarValor }}</td>
                            <td class="text-center">{{ item.valor | formatarMoeda }}</td>
                            <td class="text-center">{{ item.desconto | formatarValor }} %</td>
                            <td class="text-center">{{ item.subtotal | formatarMoeda }}</td>
                            <td class="text-center">
                              <v-menu top right transition="slide-x-transition">
                                <template v-slot:activator="{ on }">
                                  <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                                </template>
                                <v-list dense v-show="!visualizar">
                                  <v-list-item :key="item._id + 10" @click="editarProduto(item)">
                                    <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                                    <v-list-item-title>Editar</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item :key="item._id +15" @click="removerProduto(item)">
                                    <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                                    <v-list-item-title>Remover</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="8" sm="6">
                  <v-text-field label="Técnico" ref="tecnico" v-model="ordem_servico.tecnico" @keypress.native.enter="$refs.valor.focus()" :disabled="visualizar"></v-text-field>
                </v-col>
                <v-col cols="6" sm="2">
                  <as-campo-valor label="Valor" ref="valor" v-model="ordem_servico.valor" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" @keypress.native.enter="$refs.desconto.focus()" :disabled="visualizar"></as-campo-valor>
                </v-col>
                <v-col cols="6" sm="2">
                  <as-campo-valor label="Desconto" ref="desconto" v-model="ordem_servico.desconto" :decimais="2" @keypress.native.enter="salvar" :disabled="visualizar"></as-campo-valor>
                </v-col>
                <v-col cols="6" sm="2">
                  <as-campo-valor label="Valor Líquido" v-model="valorLiquido" :rules="[rules.valorMaiorQueZero]" readonly :disabled="visualizar"></as-campo-valor>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar" :disabled="salvando">{{ visualizar ? 'Voltar' : 'Cancelar' }}</v-btn>
            <v-btn v-if="!visualizar" color="primary" :loading="salvando" @click="salvar">Salvar</v-btn>
          </template>
        </v-widget>
      </v-form>
    </v-container>

    <v-dialog v-model="dialogQuantidade" max-width="800px" persistent scrollable>
      <v-form v-model="valid" ref="formQuantidadeItensProduto" lazy-validation>
        <v-card>
          <v-toolbar flat dark color="primary" dense>
            <v-toolbar-title>Quantidade do Produto</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="pt-1">
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field v-model="produto.codigo" name="codigo" label="Código" autocomplete="off" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field v-model="produto.descricao" name="descricao" label="Descrição" autocomplete="off" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-select label="Unidade" v-model="produto.unidade" :items="constantes.UNIDADES" :rules="[rules.obrigatorio]" ref="unidade" @keypress.native.enter="$refs.quantidade.focus()"></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <as-campo-valor label="Quantidade" v-model="produto.quantidade" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" ref="quantidade" @blur="calcularSubtotal" @keypress.native.enter="$refs.valor_quantidade.focus()"></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="2">
                <as-campo-valor label="Valor" v-model="produto.valor" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" ref="valor_quantidade" @blur="calcularSubtotal" @keypress.native.enter="$refs.desconto.focus()"></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="2">
                <as-campo-valor label="Desconto %" v-model="produto.desconto" :rules="[rules.obrigatorio, rules.valorPositivo]" :decimais="2" ref="desconto" @blur="calcularSubtotal" @keypress.native.enter="salvarProduto"></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor label="SubTotal" v-model="produto.subtotal" :decimais="2" ref="subtotal" readonly></as-campo-valor>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialogQuantidade=false" text>Cancelar</v-btn>
            <v-btn color="primary" @click="salvarProduto">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <dialog-buscar-produto ref="dialogBuscarProduto" @produtoSelecionado="selecionarProduto" :produto-oculto="produto._id"></dialog-buscar-produto>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
  import { mask } from 'vue-the-mask';
  import constantes from '@/util/constantes';
  import * as regras from '@/util/validacaoFormulario';
  import formatador from '@/util/formatador';
  import DialogBuscarProduto from '@/components/dialogs/DialogBuscarProduto';
  import { deepClone } from "@/util/helpers";

  export default {
    name: 'FormularioOrdensServicos',
    components:{
        DialogBuscarProduto
      },
    data () {
      return {
        valid: true,
        salvando: false,
        rules: {...regras},
        cnpjCpfParticipante: '',
        headersProdutosContrato: [
          { text: 'Produto', value: 'produto', sortable: false, width: '90%' },
          { text: 'Série', value: 'serie', sortable: false, width: '10%' , align: 'right' },
        ],
        headersProdutos: [
          { text: 'Código', value: 'codigo', sortable: false, width: '10%', align: 'center' },
          { text: 'Descrição', value: 'descricao', sortable: false, width: '40%' },
          { text: 'Unidade', value: 'unidade', sortable: false, width: '5%' , align: 'center'},
          { text: 'Quantidade', value: 'quantidade', sortable: false, width: '10%', align: 'center' },
          { text: 'Valor', value: 'valor', sortable: false, width: '10%', align: 'center' },
          { text: 'Desconto', value: 'desconto', sortable: false, width: '10%', align: 'center' },
          { text: 'Subtotal', value: 'subtotal', sortable: false, width: '10%', align: 'center' },
          { text: 'Ações', value: 'acoes', sortable: false, width: '5%', align: 'center' },
        ],
        produto: {},
        dialogQuantidade: false,
        constantes: constantes,
        modoEdicao: false,
      }
    },
    mounted() {
      if (!this.ordem_servico._id) {
        this.$refs.agendamento.focus();
      } else {
        this.$refs.descricao.focus();
      }
    },
    computed: {
      ...mapState('ordensServicos', {
        ordem_servico: 'ordemServicoAtual',
        carregando: 'carregando',
        visualizar: 'visualizar'
      }),
      valorLiquido() {
        let calculo = formatador.formatarValorUS(this.ordem_servico.valor) - formatador.formatarValorUS(this.ordem_servico.desconto)
        if (formatador.formatarValorUS(this.ordem_servico.valor) > 0) {
          return calculo;
        } else {
          return 0;
        }
      },
      totais() {
        let quantidade_total = this.ordem_servico.produtos.reduce((soma, produto) => {
          return soma + produto.quantidade;
        }, 0);

        let valor_total = this.ordem_servico.produtos.reduce((soma, produto) => {
          return soma + produto.subtotal
        }, 0);

        return {
          quantidade: quantidade_total,
          valor: valor_total
        }
      }
    },
    methods: {
      async salvar() {
        if (this.$refs.formOrdemServico.validate()) {
          if (this.ordem_servico.produtos.length > 0) {
            if (this.ordem_servico._id) {
              await this.$store.dispatch('ordensServicos/atualizar', this.ordem_servico);
            } else {
              await this.$store.dispatch('ordensServicos/salvar', this.ordem_servico);
            }
            this.$router.push({ name: 'OrdensServicosLista'});
          } else {
            this.abrirBuscaProdutos();
            this.$store.commit('setMensagemErro', 'Adicione pelo 1 produto a Ordem de Serviço');
          }
        }
      },
      cancelar() {
        this.$store.commit('ordensServicos/ZERAR');
        this.$router.push({ name: 'OrdensServicosLista'});
      },
      adicionarParticipante(participante){
        this.ordem_servico.participante.cnpj_cpf = formatador.formatarCnpj(this.ordem_servico.participante.cnpj_cpf);
        this.$store.commit('ordensServicos/SET_PARTICIPANTE', participante);
        this.$refs.descricao.focus();
      },
      abrirBuscaProdutos() {
        this.$refs.dialogBuscarProduto.abrir();
      },
      selecionarProduto(produto_selecionado) {
        this.produto = {
          produto: produto_selecionado._id,
          codigo: produto_selecionado.codigo,
          codigo_barra: produto_selecionado.codigo_barra,
          descricao: produto_selecionado.descricao,
          quantidade: 0,
          unidade: produto_selecionado.unidade,
          desconto: 0,
          valor: produto_selecionado.valor,
          subtotal: 0,
        };
        this.dialogQuantidade = true;
        setTimeout(() =>{
          this.$refs.quantidade.focus()
        }, 100);
      },
      calcularSubtotal() {
        this.produto.subtotal = (this.produto.quantidade * this.produto.valor) - ((this.produto.quantidade * this.produto.valor) * (this.produto.desconto / 100));
      },
      salvarProduto() {
        if(this.$refs.formQuantidadeItensProduto.validate()){
          if (this.modoEdicao) {
            this.$store.commit('ordensServicos/UPDATE_PRODUTO', this.produto);
            this.modoEdicao = false;
          } else {
            let index = this.ordem_servico.produtos.findIndex(produto => produto.codigo == this.produto.codigo);
            if (index > -1) {
              this.$store.commit('setMensagemErro', 'Produto já adicionado a Ordem de Serviço!');
            } else {
              this.$store.commit('ordensServicos/SET_PRODUTO', this.produto);
            }
          }
          this.$store.commit('ordensServicos/SET_TOTAIS', this.totais);
          this.produto = {};
          this.dialogQuantidade = false;
        }
      },
      removerProduto(item){
        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover este produto ?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.commit('ordensServicos/EXCLUIR_PRODUTO', item);
            this.$store.commit('ordensServicos/SET_TOTAIS', this.totais);
            this.produto = {};
          }
        });
      },
      editarProduto(produto_selecionado) {
        this.produto = deepClone(produto_selecionado);
        this.dialogQuantidade = true;
        this.modoEdicao = true;
      },
      focusTecnico(event) {
        if (event.keyCode == 13) {
          window.scrollTo(260,document.body.scrollHeight);
          if(event.shiftKey){
              event.stopPropagation();
          } else {
              this.$refs.tecnico.focus();
          }
        }
      }
    },
    async created(){
      try{
        this.loading = true;
        let ordem_servico = this.ordem_servico;
        let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
    
        if(estaEditando && (!ordem_servico._id || ordem_servico._id === '')){
          this.$store.commit('setMensagemErro', 'Selecione uma Ordem de Serviço para editar');
          this.$router.push({ name: 'OrdensServicosLista' });
        }
        if(!estaEditando){
          this.$store.commit('ordensServicos/ZERAR');
        }
      }
      catch(erro){
        console.log(erro);
      }
      finally{
        this.loading = false;
      }
    }
  }
</script>

<style>
  
</style>
import axios from "@/util/axios";
import constantes from "@/util/constantes";
import moment from 'moment';
import aniversariantesApi from "@/services/aniversariantesApi";

export default {
  namespaced: true,

  //equivalente ao data
  state: {
    produtos: [],
    quantidadeProdutos: {},
    quantidadeParticipantes: {},
    movimentosNotas: [],
    movimentosManifestos: [],
    diasCertificado: '...',
    constantes: constantes,
    carregando: false,
    carregandoAniversariantes: false,
    aniversariantes: [],
    totalAniversariantes: 0,
    mes_aniversariantes: moment().month()+1,
  },

  //equivalente ao computed properties
  getters: {},

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    produtosVendidos({ commit, rootState }, periodo) {
      commit("setCarregando", true);
      return axios
        .post("/relatorios/notas/produtos/periodo", periodo)
        .then((response) => {
          let produtos = response.data;
          let produtosGrafico = [];
          produtos.forEach((produto) => {
            let novo = {
              descricao: produto.descricao,
              quantidade: produto.quantidade,
              total: produto.valor * produto.quantidade,
            };
            produtosGrafico.push(novo);
          });
          commit("setProdutos", produtosGrafico);
          commit("setCarregando", false);
        })
        .catch((erro) => {
          commit("setProdutos", []);
          commit("setCarregando", false);
          commit("setMensagemErro", "Falha ao consultar Relatório de Produtos", { root: true });
        });
    },

    quantidadeNotas({ commit, rootState }, periodo) {
      commit("setCarregando", true);
      return axios
        .post("/relatorios/notas/periodo", periodo)
        .then((response) => {
          let movimentos = response.data;

          commit("setMovimentoNotas", movimentos);
          commit("setCarregando", false);
        })
        .catch((erro) => {
          commit('setMovimentoNotas', []);
          commit('setCarregando', false);
          commit('setMensagemErro', 'Falha ao consultar Relatório de Notas', {root: true});
        });
    },
    
    quantidadeManifestos({ commit, rootState }, periodo) {
      commit("setCarregando", true);
      return axios
        .post("/relatorios/manifestos/periodo", periodo)
        .then((response) => {
          let movimentos = response.data;

          commit("setMovimentoManifestos", movimentos);
          commit("setCarregando", false);
        })
        .catch((erro) => {
          commit('setMovimentoManifestos', []);
          commit('setCarregando', false);
          commit('setMensagemErro', 'Falha ao consultar Relatório de Manifestos', {root: true});
        });
    },

    consultarQuantidadeProdutos({ commit, rootState }) {
      commit("setCarregando", true);
      return axios
        .get("/relatorios/produtos/quantidade")
        .then((response) => {
          let quantidade = response.data;

          commit("setQuantidadeProdutos", quantidade);
          commit("setCarregando", false);
        })
        .catch((erro) => {
          commit("setQuantidadeProdutos", { quantidade: 0 });
          commit("setCarregando", false);
          commit("setMensagemErro", "Falha ao consultar Relatório de Produtos", { root: true });
        });
    },

    consultarQuantidadeParticipantes({ commit, rootState }) {
      commit("setCarregando", true);
      return axios
        .get("/relatorios/participantes/quantidade")
        .then((response) => {
          let quantidade = response.data;

          commit("setQuantidadeParticipantes", quantidade);
          commit("setCarregando", false);
        })
        .catch((erro) => {
          commit("setQuantidadeParticipantes", { quantidade: 0 });
          commit("setCarregando", false);
          commit("setMensagemErro", "Falha ao consultar Relatório", { root: true });
        });
    },

    async consultaDiasCertificado({commit, rootState}){

      commit('setCarregando', true);
      
      try{

        let response = await axios.get(`/empresas/certificado`);
        let retorno = response.data;
        if(retorno.status === 200){

          let dataValidade = retorno.validade;
          if(dataValidade && dataValidade !== ''){
            let dias = moment(dataValidade).diff(moment(), 'days');
            if(dias < 0){
              commit('setDiasCertificado', 'Vencido');
            }
            else if(dias === 0){
              commit('setDiasCertificado', '1 dia');
            }
            else{
              commit('setDiasCertificado', `${dias} dias`);
            }
          }
          commit('setCarregando', false);
        }
        else{
          commit('setCarregando', false);
          commit('setMensagemErro', 'Falha ao consultar certificado', {root: true});
        }
      }
      catch(erro){
        commit('setCarregando', false);
        commit('setMensagemErro', 'Falha ao consultar certificado', {root: true});
        throw erro;
      }
    },

    async aniversariantes({commit, rootState, state}, options) {
      try {
        commit('setCarregandoAniversariantes');
        let response = await aniversariantesApi.listar(options, rootState.empresaAtual, state.mes_aniversariantes);
        commit('SET_ANIVERSARIANTES', response.aniversariantes);
        commit('SET_TOTAL_ANIVERSARIANTES', response.total);
      } catch (error) {
        console.log(error)
        commit('SET_ANIVERSARIANTES', []);
        commit('setMensagemErro', 'Não foi possível listar os aniversariantes', {root: true});
        throw error;
      } finally {
        commit('setCarregandoAniversariantes', false);
      }
    }
  },

  //responsável por alterar o state.
  mutations: {
    setProdutos(state, produtos) {
      state.produtos = produtos;
    },
    setQuantidadeProdutos(state, quantidade) {
      state.quantidadeProdutos = quantidade.quantidade;
    },
    setQuantidadeParticipantes(state, quantidade) {
      state.quantidadeParticipantes = quantidade.quantidade;
    },
    setMovimentoNotas(state, movimentos) {
      state.movimentosNotas = movimentos;
    },
    setMovimentoManifestos(state, movimentos){
      state.movimentosManifestos = movimentos;
    },
    setCarregando(state, valor = true) {
      state.carregando = valor;
    },
    setDiasCertificado(state, diasCertificado){
      state.diasCertificado = diasCertificado;
    },
    
    // Aniversariantes
    setCarregandoAniversariantes(state, valor = true) {
      state.carregandoAniversariantes = valor;
    },
    SET_ANIVERSARIANTES(state, aniversariantes) {
      state.aniversariantes = aniversariantes;
    },
    SET_TOTAL_ANIVERSARIANTES(state, total) {
      state.totalAniversariantes = total;
    },
    SET_MES_ANIVERSARIANTES(state, mes) {
      state.mes_aniversariantes = mes;
    },
    
  },
};

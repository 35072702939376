<template>
  <v-text-field ref="input" v-bind="$attrs" :loading="loading" :value="value" @input="onInput" @change="onChange" @focus="onFocus" maxlength="9" data-cy="cep"></v-text-field>
</template>

<script>
import axios from "axios";

export default {
  name: "CampoCep",
  props: {
    value: {
      type: String,
      default: ""
    },
    buscarcep: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      cep: this.value,
      loading: false
    };
  },

  mounted() {
    // if (this.value.length > 0) {
    //     this.formatarCEP(this.value)
    // }
  },

  methods: {
    onInput(cep) {
      // this.cep = this.formatarCEP(cep)
      this.$emit("input", cep);
    },

    onChange(cep) {
      if (this.buscarcep) {
        this.buscarCep(cep);
      }
    },

    onFocus(e) {
      setTimeout(function() {
        e.target.select();
      }, 0);
    },

    onKeyUp(e) {
      this.$emit("keyup", e);
    },

    formatarCEP(cep) {
      cep = cep.replace(/\D/g, ""); //Remove tudo o que não é dígito
      cep = cep.replace(/^(\d{5})(\d)/, "$1-$2"); //Formata o CEP
      return cep.substring(0, 9);
    },

    buscarCep(cep) {
      if (/^[0-9]{5}-[0-9]{3}$/.test(cep)) {
        this.loading = true;
        axios.get("https://viacep.com.br/ws/" + cep + "/json/")
          .then(response => {
            this.loading = false;
            this.$emit("resultado", response.data);
          })
          .catch(error => {
            this.loading = false;
          });
      }
    },

    focus() {
      this.$refs.input.focus();
    }
  }
};
</script>

<style scoped>
</style>

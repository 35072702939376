import axios from '@/util/axios';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import formatador from '@/util/formatador';
import vendedoresApi from "@/services/vendedoresApi";

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {
    vendedores: [],
    vendedorAtual: {
      nome: '',
      comissao: '',
      ativo: true
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalVendedores: 0
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await vendedoresApi.listar(options, rootState.empresaAtual);
        commit('SET_VENDEDORES', response.vendedores);
        commit('SET_TOTAL_VENDEDORES', response.total);
      } catch (error) {
        commit('SET_VENDEDORES', []);
        commit('setMensagemErro', 'Não foi possível listar os vendedores', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({commit, state, rootState}){
      commit('SET_CARREGANDO', true);
      let vendedor = Object.assign({}, state.vendedorAtual);
      vendedor.comissao = conversor.stringParaNumero(vendedor.comissao);
      
      try{
        let response = await vendedoresApi.salvar(vendedor, rootState.empresaAtual);
        commit('ADICIONAR_VENDEDOR', response);
      }
      catch(erro){
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        commit('SET_CARREGANDO', false);
        throw erro;
      }
    },
    async atualizar({commit, rootState}, vendedor) {
      vendedor.comissao = formatador.formatarValorUS(vendedor.comissao)
      await vendedoresApi.atualizar(vendedor, rootState.empresaAtual);
      commit('ATUALIZAR_VENDEDOR', vendedor);
    },
    async excluir({commit, rootState}, excluir){
      commit('SET_CARREGANDO', true);
      axios.delete('/vendedores/' + excluir._id)
        .then((response) => {
          commit('EXCLUIR_VENDEDOR', excluir._id);
          commit('setMensagemSucesso', 'Vendedor Removido com Sucesso', {root: true});
        })
        .catch((erro) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
        });
    }
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_VENDEDORES(state, vendedores) {
      state.vendedores = vendedores;
      state.carregando = false;
    },
    SET_TOTAL_VENDEDORES(state, total) {
      state.totalVendedores = total;
    },
    SET_VENDEDOR(state, vendedorAtual) {;
      state.vendedorAtual = vendedorAtual
    },
    EXCLUIR_VENDEDOR(state, id) {
      let index = state.vendedores.findIndex(vendedor => vendedor._id == id);
      if (index > -1) {
        state.vendedores.splice(index, 1);
      }
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.vendedorAtual = {
        nome: '',
        comissao: '',
        ativo: true
      }
      state.carregando = false;
    },
    editar(state, editar){
      editar.comissao = formatador.formatarValor(editar.comissao);
      state.modoEdicao = true;
      state.vendedorAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_VENDEDOR(state, vendedor) {
      state.vendedores.unshift(vendedor);
      state.carregando = false;
    },
    ATUALIZAR_VENDEDOR(state, vendedor) {
      let vendedor_encontrado = state.vendedores.find(vendedor_ => vendedor_.id == vendedor.id);
      if (vendedor_encontrado) {
        vendedor_encontrado = vendedor;
      }
      state.carregando = false;
    },
    estadoInicial(state){
      state.vendedorAtual = {
        nome: '',
        comissao: '',
        ativo: true
      }
      state.carregando = false;
    }
  }
}
<template>
  <v-form v-model="valid" ref="formCertificadoPadrao" lazy-validation>
    <v-container grid-list-md fluid>
      <v-widget title="Certificado Digital" v-if="certificado.solicitante">
        <div slot="widget-content">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="CNPJ" :value="certificado.solicitante.cnpj | formatarCnpj" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field label="Emitido Para" :value="certificado.solicitante.razao" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="Data de Emissão" :value="certificado.emissao | formatarData" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="Data de Validade" :value="certificado.validade | formatarData" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="Dias de Certificado" :value="diasRestantes" readonly></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-widget>
      <v-widget title="Carregamento de Certificado" v-else>
        <div slot="widget-content">
          <v-row dense>
            <v-col cols="12" sm="9">
              <v-file-input
                outlined
                accept=".pfx"
                v-model="certLocal.arquivo"
                label="Certificado A1"
                placeholder="Selecione o arquivo do certificado A1"
                prepend-icon="mdi-paperclip"
              ></v-file-input>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field outlined label="Senha" :rules="validarSenha" v-model="certLocal.senha" type="password" required></v-text-field>
            </v-col>
          </v-row>
        </div>
        <template v-slot:widget-footer-action>
          <v-btn color="primary" :loading="carregando" @click="enviar()" :disabled="!valid">Cadastrar</v-btn>
        </template>
      </v-widget>
    </v-container>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
import constantes from "@/util/constantes";
import validador from "@/util/validador";

export default {

  data: () => ({
    valid: false,
    validarSenha: [(v) => !!v || "Senha Obrigatória"],
    certLocal: {
      arquivo: [],
      senha: "",
    },
    constantes: constantes,
  }),

  computed: {
    ...mapState("empresa", {
      certificado: "certificado",
      carregando: "carregando",
      certificadoCarregado: "certificadoCarregado",
    }),

    ...mapGetters("empresa", {
      diasRestantes: "diasRestantesCertificado",
    }),
  },

  methods: {
    ...mapActions("empresa", {
      consultarCertificado: "consultarCertificadoEmpresa",
    }),

    enviar() {
      let arquivo = this.certLocal.arquivo;
      if (arquivo) {
        var reader = new FileReader();
        var vm = this;
        reader.onload = (e) => {
          vm.atualizarConteudo(e.target.result);
        };
        reader.readAsBinaryString(arquivo, "ASCII");
      }
    },

    atualizarConteudo(certificado) {
      this.certLocal.arquivo = btoa(certificado);
      this.$store.dispatch("empresa/processarCertificado", this.certLocal);
    },
  },

  created() {
    this.consultarCertificado();
  },
};
</script>

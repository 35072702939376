import ListaTamanhos from "@/views/tamanhos/ListaTamanhos";
import FormularioTamanho from "@/views/tamanhos/FormularioTamanho";

export default [
  {
    path: "/tabelas/tamanhos",
    name: "tamanhos",
    component: ListaTamanhos,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/tabelas/tamanhos/novo",
    name: "formularioTamanhoNovo",
    component: FormularioTamanho,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/tabelas/tamanhos/editar",
    name: "formularioTamanhoEditar",
    component: FormularioTamanho,
    meta: { requiresAuth: true, nivel: 1 },
  },
];

<template>
  <v-container fluid class="fillHeight">
    <v-row v-if="carregando" class="fillHeight">
      <v-col cols="12" class="text-center ma-auto">
        <v-progress-circular color="primary" :size="72" indeterminate :width="7"></v-progress-circular>
        <p class="subtitle-1 mt-3">Carregando ...</p>
      </v-col>
    </v-row>

    <v-row dense v-else>
      <v-col cols="12" md="6" xs="12" class="pa-2">
        <div slot="widget-content" class="fillWidth">
          <v-card color="primary darken-2" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="headline font-weight-bold"
                  v-text="quantidadeParticipantes.toString()"
                ></v-card-title>
                <v-card-subtitle class="font-weight-bold">Clientes / Fornecedores</v-card-subtitle>
              </div>
              <v-icon size="48" class="mr-4">mdi-account-multiple</v-icon>
            </div>
          </v-card>

          <v-card v-show="configAniversariantes" color="primary" dark class="mt-3">
            <div class="d-flex flex-no-wrap justify-space-between" @click="exibirDialogoAniversariantes = true" style="cursor: pointer">
              <div>
                <v-card-title
                  class="headline font-weight-bold"
                  v-text="totalAniversariantes.toString()"
                ></v-card-title>
                <v-card-subtitle class="font-weight-bold">Aniversariantes</v-card-subtitle>
              </div>
              <v-icon size="36" class="mr-4">fas fa-birthday-cake</v-icon>
            </div>
          </v-card>

          <v-card color="primary" dark class="mt-3">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="headline font-weight-bold"
                  v-text="quantidadeProdutos.toString()"
                ></v-card-title>
                <v-card-subtitle class="font-weight-bold">Produtos</v-card-subtitle>
              </div>
              <v-icon size="36" class="mr-4">fas fa-tags</v-icon>
            </div>
          </v-card>

          <v-card color="secondary" dark class="mt-3 mb-1">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="headline font-weight-bold"
                  v-text="movimentosNotas.reduce((total, atual) => total + atual.quantidade, 0).toString()"
                ></v-card-title>
                <v-card-subtitle class="font-weight-bold">Notas Fiscais</v-card-subtitle>
              </div>
              <v-icon size="48" class="mr-4">description</v-icon>
            </div>
          </v-card>

          <v-card color="accent lighten-1" dark class="mt-3 mb-1">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="headline font-weight-bold"
                  v-text="movimentosManifestos.reduce((total, atual) => total + atual.quantidade, 0).toString()"
                ></v-card-title>
                <v-card-subtitle class="font-weight-bold">Manifestos de Documentos Fiscais</v-card-subtitle>
              </div>
              <v-icon size="48" class="mr-6">far fa-file</v-icon>
            </div>
          </v-card>

          <v-card color="warning darken-1" dark class="mt-3 mb-1">
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="headline font-weight-bold"
                  v-text="diasCertificado"
                ></v-card-title>
                <v-card-subtitle class="font-weight-bold">Certificado Digital</v-card-subtitle>
              </div>
              <v-icon size="48" class="mr-4">vpn_key</v-icon>
            </div>
          </v-card>
        </div>
      </v-col>

      <v-col cols="12" md="6" xs="12" class="pa-2">
        <v-widget title="Produtos mais Faturados" content-bg="white">
          <div slot="widget-content" class="fillWidth" style="min-height: 398px">
            <span
              v-if="produtos && produtos.length > 0"
              class="positionAbsolute"
            >{{mesAtual + " / " + new Date().getFullYear()}}</span>
            <span v-else class="semDados">Não há dados para exibir o gráfico</span>
            <chart
              class="fillWidth"
              v-if="!carregando && produtos.length > 0"
              :options="graficos.graficoProdutos(produtos, mesAtual)"
            ></chart>
          </div>
        </v-widget>
      </v-col>
      <v-col cols="12" md="6" xs="12" class="pa-2">
        <v-widget title="Notas Fiscais Emitidas" content-bg="white">
          <div slot="widget-content" class="fillWidth">
            <span class="positionAbsolute">Em quantidades</span>
            <chart
              class="fillWidth"
              v-if="!carregando && movimentosNotas.length > 0"
              :options="
                graficos.graficoBarra(
                  movimentosNotas.map((movimento) => movimento._id),
                  movimentosNotas.map((movimento) => movimento.quantidade)
                )
              "
            ></chart>
          </div>
        </v-widget>
      </v-col>
      <v-col cols="12" md="6" xs="12" class="pa-2">
        <v-widget title="Notas Fiscais Emitidas" content-bg="white">
          <div slot="widget-content" class="fillWidth">
            <span class="positionAbsolute">Em valores</span>
            <chart
              class="fillWidth"
              v-if="!carregando && movimentosNotas.length > 0"
              :options="
                graficos.graficoBarra(
                  movimentosNotas.map((movimento) => movimento._id),
                  movimentosNotas.map((movimento) => movimento.total),
                  movimentosNotas.map((movimento) => movimento.quantidade)
                )
              "
            ></chart>
          </div>
        </v-widget>
      </v-col>
    </v-row>
    <dialogo-aniversariantes ref="aniversariantes" :dialog="exibirDialogoAniversariantes" @fechar="exibirDialogoAniversariantes = false" />
    <dialogo-configuracoes ref="configuracoes" :dialog="exibirDialogoConfiguracoes" @fechar="exibirDialogoConfiguracoes = false" />
  </v-container>
</template>

<script>
import Material from "vuetify/es5/util/colors";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import graficos from "@/graficos/graficos";
import MiniStatistic from "@/components/widgets/MiniStatistic";
import DialogoAniversariantes from './aniversariantes/DialogoAniversariantes';
import DialogoConfiguracoes from './DialogoConfiguracoes';

export default {
  name: "Dashboard",
  components: {
    MiniStatistic,
    DialogoAniversariantes,
    DialogoConfiguracoes
  },

  data: () => ({
    color: Material,
    graficos: graficos,
    selectedTab: "tab-1",
    chartOptionsBar: {
      xAxis: {
        data: []
      }
    },
    mesAtual: "",
    exibirDialogoAniversariantes: false,
    exibirDialogoConfiguracoes: false,
  }),

  computed: {
    ...mapState("dashboard", {
      carregando: "carregando",
      produtos: "produtos",
      quantidadeProdutos: "quantidadeProdutos",
      quantidadeParticipantes: "quantidadeParticipantes",
      movimentosNotas: "movimentosNotas",
      movimentosManifestos: "movimentosManifestos",
      diasCertificado: "diasCertificado",
      totalAniversariantes: "totalAniversariantes"
    }),

    ...mapState("configuracao", {
      configAniversariantes: 'configAniversariantes'
    }),
  },

  methods: {
    ...mapActions("dashboard", {
      produtosVendidos: "produtosVendidos",
      quantidadeNotas: "quantidadeNotas",
      quantidadeManifestos: "quantidadeManifestos",
      consultarQuantidadeProdutos: "consultarQuantidadeProdutos",
      consultarQuantidadeParticipantes: "consultarQuantidadeParticipantes",
      consultarDiasCertificado: "consultaDiasCertificado"
    }),

    teclas(event) {
      if(event.ctrlKey && event.shiftKey && event.code === 'KeyY'){
        event.preventDefault();
        this.exibirDialogoConfiguracoes = true;
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.teclas);
  },
  
  async created() {
    window.addEventListener('keydown', this.teclas);
    await this.$store.dispatch('configuracao/consultarStatus', 'ExibirAniversariantes');
    this.mesAtual = moment(moment().month(), "M").format("MMMM");
    let primeiroDiaMes = moment().format("YYYY-MM-01");
    let ultimoDiaMes = moment().format("YYYY-MM-") + moment().daysInMonth();

    let periodo = {
      data_inicio: primeiroDiaMes,
      data_fim: ultimoDiaMes
    };

    const consultaProdutosVendidos = this.produtosVendidos(periodo);

    let ultimos12Meses_inicio = moment()
      .subtract(12, "months")
      .format("YYYY-MM-01");
    let ultimos12Meses_final =
      moment().format("YYYY-MM-") + moment().daysInMonth();
    let movimentoUltimos12Meses = {
      data_inicio: ultimos12Meses_inicio,
      data_final: ultimos12Meses_final
    };

    const consultaQuantNotas = this.quantidadeNotas(movimentoUltimos12Meses);
    const consultaQuantManifestos = this.quantidadeManifestos(movimentoUltimos12Meses);
    const consultaQuantProdutos = this.consultarQuantidadeProdutos();
    const consultaQuantParticipantes = this.consultarQuantidadeParticipantes();
    const consultaDiasCertificado = this.consultarDiasCertificado();

    await Promise.all([
      consultaProdutosVendidos, consultaQuantNotas, consultaQuantProdutos, consultaQuantParticipantes, 
      consultaDiasCertificado, consultaQuantManifestos
    ]);
  }
};
</script>

<style scoped>
.fillHeight {
  height: 100%;
}
.fillWidth {
  width: 100%;
}
.positionAbsolute {
  position: absolute;
}
.semDados {
  position: absolute;
  top: 50%;
  text-align: center;
  width: 220px;
  left: calc(50% - 110px);
}
</style>

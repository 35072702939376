<template>
  <div>
    <v-container>
      <v-subheader class="text-subtitle-1 text--primary">
        Mapeamento CFOPs - Compra x Entrada
      </v-subheader>
      <v-card>  
        <v-toolbar flat color="white">
          <v-row dense>
            <v-col cols="6">
              <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrarMapeamentos($event)" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-right" cols="4" xs="12" sm="2">
              <v-btn color="primary" @click="novo">Novo</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="mapeamentos" :search="busca" :loading="carregando">
          <template v-slot:item="{item}">
            <tr>
              <td>{{ item.cfopCompra }} - {{ item.descricaoCfopCompra }}</td>
              <td>{{ item.cfopEntrada }} - {{ item.descricaoCfopEntrada }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="item._id + 20" @click="editar(item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="item._id + 40" @click="excluir(item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    name: "ListaCFOPCompraEntrada",
    data () {
      return {
        headers: [
          { text: 'Compra', value: 'cfopCompra' },
          { text: 'Entrada', value: 'cfopEntrada' },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        }
      }
    },
    
    watch: {
      options: {
        handler () {
          this.listar();
        },
        deep: true,
      },
    },

    computed: {
      ...mapState('cfopCompraEntrada', {
          mapeamentos: 'mapeamentos',
          carregando: 'carregando',
          total: 'totalCfopCompraEntrada'
      })
    },

    methods: {
      async listar(){
        await this.$store.dispatch('cfopCompraEntrada/listar', this.options);
      },

      limparBusca(){
        this.listar();
      },

      filtrarMapeamentos(event){
        let newOptions = {
          ...this.options,
          query: this.busca
        }

        this.$store.dispatch('cfopCompraEntrada/listar', newOptions);
        setTimeout(() => {
          event.target.select();
        }, 300);
      },
      
      editar(cfopCompraEntrada){
        this.$store.commit('cfopCompraEntrada/editar', cfopCompraEntrada);
        this.$router.push({name: 'formularioMapeamentoEditar'});
      },

      novo(){
        this.$store.commit('cfopCompraEntrada/novo');
        this.$router.push({name: 'formularioMapeamentoNovo'});
      },
      
      excluir(mapeamento){
        this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('cfopCompraEntrada/excluir', mapeamento);
          }
        });
      }
    },
  }
</script>
<template>
  <v-app>
    <dialog-visualizar-notificacao></dialog-visualizar-notificacao>
    <dialog-visualizar-todas-notificacoes></dialog-visualizar-todas-notificacoes>
    <app-drawer></app-drawer>
    <app-toolbar></app-toolbar>

    <v-main class="grey lighten-4">
      <router-view></router-view>
    </v-main>

    <v-footer class="white pa-2 elevation-5" inset app>
      <div class="caption" style="max-width: 60%">&copy; {{ new Date().getFullYear() }} Anron Software</div>
      <v-sheet v-if="$store.state.usuario.ambiente == 'Homologação'" color="red" class="white--text caption pl-2 pr-2 ml-auto">{{ $store.state.usuario.ambiente }}</v-sheet>
    </v-footer>

    <app-fab></app-fab>

    <notificacao></notificacao>
    <v-snackbar data-cy="snackBar" :timeout="snackbar.color == 'red' ? 5000 : 5000" bottom multi-line :color="snackbar.color" v-model="snackbar.show">
      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

import AppDrawer from "@/views/layouts/AppDrawer";
import AppToolbar from "@/views/layouts/AppToolbar";
import AppFab from "@/views/layouts/AppFab";
import DialogVisualizarNotificacao from "@/components/dialogs/DialogVisualizarNotificacao";
import DialogVisualizarTodasNotificacoes from "@/components/dialogs/DialogVisualizarTodasNotificacoes";
import menu from "@/views/layouts/menus/menu";
import constantes from "@/util/constantes";
import Notificacao from "@/components/Notificacao";

export default {
  components: {
    AppDrawer,
    AppToolbar,
    AppFab,
    DialogVisualizarNotificacao,
    DialogVisualizarTodasNotificacoes,
    Notificacao,
  },

  data: () => ({}),

  computed: {
    ...mapState({
      snackbar: "snackbar",
    }),

    usuarioLogado() {
      return this.$store.getters.usuarioLogado;
    },

    empresaLogada() {
      return this.$store.getters.empresaAtual.fantasia;
    },

    itemsAcessiveis() {
      let usuario = this.$store.state.usuario;
      if (usuario) {
        if (usuario.nivel_acesso === "NORMAL") {
          let elementos = [];
          this.items.forEach((elemento) => {
            if (elemento.nivel === 1) {
              elementos.push(elemento);
            }
          });
          return elementos;
        } else if (usuario.nivel_acesso === "ADMINISTRADOR") {
          let elementos = [];
          this.items.forEach((elemento) => {
            if (elemento.nivel === 1 || elemento.nivel === 2) {
              elementos.push(elemento);
            }
          });
          return elementos;
        }
      }
    },
  },

  created() {
    window.getApp = this;
  },

  methods: {
    mudarRota(path) {
      this.$router.push(path);
    },

    processarMensagemMiddleware(mensagem) {
      if (mensagem.operacao) {
        if (mensagem.operacao === "StatusServico") {
          if (mensagem.status == 107) {
            this.$store.commit("setStatusServico", true);
            this.$store.commit("setMensagemSucesso", mensagem.motivo);
          } else {
            this.$store.commit("setMensagemErro", mensagem.motivo);
          }
        }
        if (mensagem.operacao === "StatusServicoMDFe") {
          if (mensagem.status == 107) {
            this.$store.commit("setStatusServicoMDFe", true);
            this.$store.commit("setMensagemSucesso", mensagem.motivo);
          } else {
            this.$store.commit("setMensagemErro", mensagem.motivo);
          }
        } else if (mensagem.operacao === "RetAutorizacaoNFe") {
          if (mensagem.status == 100) {
            this.$store.commit("nota/atualizarNota", { id: mensagem.id, status: constantes.STATUS_AUTORIZADA, retornoSefaz: mensagem.retornoSefaz });
            this.$store.commit("setMensagemSucesso", mensagem.motivo);
          } else {
            this.$store.commit("setMensagemErro", mensagem.motivo);
          }
        } else if (mensagem.operacao === "RetAutorizacaoMDFe") {
          if (mensagem.status == 100) {
            this.$store.commit("manifesto/atualizarManifesto", { id: mensagem.id, status: constantes.STATUS_AUTORIZADA, retornoSefaz: mensagem.retornoSefaz });
            this.$store.commit("setMensagemSucesso", mensagem.motivo);
          } else {
            this.$store.commit("setMensagemErro", mensagem.motivo);
          }
        } else if (mensagem.operacao === "RecepcaoEventoNFe") {
          if (mensagem.status == 128) {
            if (mensagem.tpEvento === "CANCELAMENTO") {
              this.$store.commit("nota/adicionarEventoNota", { id: mensagem.id, status: constantes.STATUS_CANCELADA, retornoSefaz: mensagem.retornoSefaz });
              this.$store.commit("setMensagemSucesso", mensagem.motivo);
            } else if (mensagem.tpEvento === "CORRECAO") {
              this.$store.commit("nota/adicionarEventoNota", { id: mensagem.id, status: undefined, retornoSefaz: mensagem.retornoSefaz });
              this.$store.commit("setMensagemSucesso", mensagem.motivo);
            }
          } else {
            this.$store.commit("setMensagemErro", mensagem.motivo);
          }
        } else if (mensagem.operacao === "RecepcaoEventoMDFe") {
          if (mensagem.status == 128) {
            if (mensagem.tpEvento === "CANCELAMENTO") {
              this.$store.commit("manifesto/adicionarEventoManifesto", { id: mensagem.id, status: constantes.STATUS_CANCELADA, retornoSefaz: mensagem.retornoSefaz });
              this.$store.commit("setMensagemSucesso", mensagem.motivo);
            } else if (mensagem.tpEvento === "ENCERRAMENTO") {
              this.$store.commit("manifesto/adicionarEventoManifesto", { id: mensagem.id, status: constantes.STATUS_ENCERRADO, retornoSefaz: mensagem.retornoSefaz });
              this.$store.commit("setMensagemSucesso", mensagem.motivo);
            } else if (mensagem.tpEvento === "INCLUSAO") {
              this.$store.commit("manifesto/adicionarEventoManifesto", { id: mensagem.id, status: undefined, retornoSefaz: mensagem.retornoSefaz });
              this.$store.commit("setMensagemSucesso", mensagem.motivo);
            }
          } else {
            this.$store.commit("setMensagemErro", mensagem.motivo);
          }
        } else if (mensagem.operacao === "InutilizarNFe") {
          if (mensagem.status == 102) {
            this.$store.dispatch("nota/carregarNotas", { itemsPerPage: 10, page: 1 });
            let inut = mensagem.retornoSefaz.inutilizacao;
            this.$store.commit("setMensagemSucesso", `Faixa de Numeração ${inut.nInicio} a ${inut.nFim} da série ${inut.serie} inutilizada`);
          } else {
            this.$store.commit("setMensagemErro", mensagem.motivo);
          }
        } else if (mensagem.operacao === "ConsultarDestinadasNFe") {
          if (mensagem.status == 138) {
            this.$store.dispatch("sefaz/carregarDestinadas", { itemsPerPage: 10, page: 1, status: "pendente" });
            this.$store.commit("setMensagemSucesso", mensagem.motivo);
          } else {
            this.$store.commit("setMensagemErro", mensagem.motivo);
          }
        } else if (mensagem.operacao === "RecepcaoEventoNFeAN") {
          if (mensagem.status == 128) {
            this.$store.dispatch("sefaz/carregarDestinadas", { itemsPerPage: 10, page: 1, status: "pendente" });
            this.$store.commit("setMensagemSucesso", mensagem.motivo);
          } else {
            this.$store.commit("setMensagemErro", mensagem.motivo);
          }
        }
      }
    },
  },

  mounted() {
    let empresa = this.$store.state.empresaAtual;
    // this.sockets.subscribe(empresa.cnpj.substr(0, 8), (data) => {
    //   if (data) {
    //     this.processarMensagemMiddleware(data);
    //   }
    // });
  },
};
</script>

<style lang="stylus" scoped>
.setting-fab
  top:50%!important;
  right:0;
  border-radius:0
.page-wrapper
  min-height:calc(100vh - 64px - 50px - 81px );
</style>

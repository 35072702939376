import axios from '@/util/axios';
import moment from 'moment';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import formatador from '@/util/formatador';
import chequesApi from '@/services/chequesApi'

export default{
  namespaced: true,

  //equivalente ao data
  state: {
    cheques: [],
    chequeAtual: {
      numero: '',
      agencia: '',
      conta: '',
      vencimento: '',
      cadastro: moment().format('YYYY-MM-DD'),
      valor: '',
      cnpj_cpf: '',
      emitente: '',
      cidade: '',
      estado: '',
      banco: {},
      responsavel: {},
      observacao: ''
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalCheques: 0
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await chequesApi.listar(options, rootState.empresaAtual);
        commit('SET_CHEQUES', response.cheques);
        commit('SET_TOTAL_CHEQUES', response.total);
      } catch (error) {
        commit('SET_CHEQUES', []);
        commit('setMensagemErro', 'Não foi possível listar os cheques', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async filtrar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await chequesApi.filtrar(options, rootState.empresaAtual);
        commit('SET_CHEQUES', response.cheques);
        commit('SET_TOTAL_CHEQUES', response.total);
      } catch (error) {
        commit('SET_CHEQUES', []);
        commit('setMensagemErro', 'Não foi possível listar os cheques', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({commit, state, rootState}){
      try{
        commit('SET_CARREGANDO', true);
        let cheque = Object.assign({}, state.chequeAtual);
        cheque.valor = conversor.stringParaNumero(cheque.valor);
        cheque.responsavel = cheque.responsavel._id;
        cheque.banco = cheque.banco._id;

        let response = await chequesApi.salvar(cheque, rootState.empresaAtual);
        commit('ATUALIZAR_CHEQUE', response);
        commit('setMensagemSucesso', 'Cheque Atualizado com Sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', "Erro ao salvar o cheque", {root: true});
        throw erro;
      }
    },
    async alterarStatus({commit, rootState, state}, status){
      try{
        commit('SET_CARREGANDO', true);
        let cheque = Object.assign({}, state.chequeAtual);
        let response = await chequesApi.alterarStatus(cheque, rootState.empresaAtual, status);
        commit('atualizarStatus', response);
        commit('setMensagemSucesso', 'Cheque atualizado com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', "Erro ao alterar o status do cheque", {root: true});
        throw erro;
      }
    },
    async atualizar({commit, rootState}, cheque) {
      try {
        cheque.responsavel = cheque.responsavel._id;
        cheque.banco = cheque.banco._id;

        await chequesApi.atualizar(cheque, rootState.empresaAtual)
        commit('ATUALIZAR_CHEQUE', cheque);
        commit('setMensagemSucesso', 'Cheque atualizado com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      }
    },
    async excluir({commit, rootState}, cheque){
      try {
        await chequesApi.excluir(cheque._id, rootState.empresaAtual)
        commit('EXCLUIR_CHEQUE', cheque._id);
        commit('setMensagemSucesso', 'Cheque removido com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_CHEQUES(state, cheques) {
      state.cheques = cheques;
      state.carregando = false;
    },
    SET_TOTAL_CHEQUES(state, total) {
      state.totalCheques = total;
    },
    EXCLUIR_CHEQUE(state, id) {
      let index = state.cheques.findIndex(cheque => cheque._id == id);
      if (index > -1) {
        state.cheques.splice(index, 1);
      }
      state.carregando = false;
    },
    setResponsavel(state, responsavel) {
      state.chequeAtual.responsavel = responsavel;
    },
    setBanco(state, banco) {
      state.chequeAtual.banco = banco;
    },
    setCidade(state, cidade) {
      state.chequeAtual.cidade = cidade.cidade;
      state.chequeAtual.estado = cidade.estado;
    },
    novo(state){
      state.modoEdicao = false;
      state.chequeAtual = {
        numero: '',
        agencia: '',
        conta: '',
        vencimento: '',
        cadastro: moment().format('YYYY-MM-DD'),
        valor: '',
        cnpj_cpf: '',
        emitente: '',
        cidade: '',
        estado: '',
        banco: {},
        responsavel: {},
        observacao: ''
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.modoEdicao = true;
      state.chequeAtual = editar;
      state.carregando = false;
    },
    SET_CHEQUE_ATUAL(state, cheque) {
      state.chequeAtual = cheque;
    },
    ADICIONAR_CHEQUE(state, cheque) {
      state.cheques.unshift(cheque);
      state.carregando = false;
    },
    ATUALIZAR_CHEQUE(state, cheque) {
      let cheque_encontrado = state.cheques.find(cheque_ => cheque_._id == cheque._id);
      if (cheque_encontrado) {
        cheque_encontrado = cheque;
      }
      state.carregando = false;
    },
    atualizarStatus(state, atual){
      state.cheques.forEach(cheque => {
        if(cheque._id == atual._id){
          cheque.status = atual.status;
        }
      });
      state.carregando = false;
    },
    estadoInicial(state){
      state.chequeAtual = {
        numero: '',
        agencia: '',
        conta: '',
        vencimento: '',
        cadastro: moment().format('YYYY-MM-DD'),
        valor: '',
        cnpj_cpf: '',
        emitente: '',
        cidade: '',
        estado: '',
        banco: {},
        responsavel: {},
        observacao: ''
      }
      state.carregando = false;
    }
  }
}
import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual) {
    return axios.get(`/mvas?` + qs.stringify(options))
      .then(response => response.data);
  }

  function salvar(mva, empresaAtual) {
    return axios.post(`/mvas`, mva)
      .then(response => response.data);
  }

  function atualizar(mva, empresaAtual) {
    return axios.put(`/mvas/${mva._id}`, mva)
      .then(response => response.data);
  }

  function excluir(mva, empresaAtual) {
    return axios.delete(`/mvas/${mva._id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    salvar,
    atualizar,
    excluir
  }
export default [
  {
    path: '/movimentacoes/pedidoscompra',
    name: 'PedidosCompraLista',
    component: () => import( '@/views/pedidoscompra/ListaPedidosCompra'),
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/movimentacoes/pedidoscompra/novo',
    name: 'PedidosCompraNovo',
    component: () => import('@/views/pedidoscompra/FormularioPedidosCompra'),
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/movimentacoes/pedidoscompra/editar',
    name: 'PedidosCompraEditar',
    component: () => import('@/views/pedidoscompra/FormularioPedidosCompra'),
    meta: { requiresAuth: true, nivel: 1}
  }
];
import axios from '@/util/axios';
import axiosCep from 'axios';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import moment from 'moment';

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {

    empresas: [],
    empresaAtual: {
      cnpj: '',
      fantasia: '',
      razao: '', 
      ie: '', 
      im: '',
      cnae: '',
      descricao_cnae: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      codigo_cidade: '',
      uf: '',
      estado: '',
      pais: '',
      codigo_pais: '',
      logotipo: '',
      tema: '',
      versao: '4.00',
      ambiente: '',
      tipo_certificado: 'A1',
      formato_impressao: '1',
      frete_padrao: '9',
      forma_pagamento_padrao: '0',
      certificado: '',
      senha: '',
      telefone: '',
      email: '',
      usuario: {
        nome: 'Administrador',
        email: '',
        foto: ''
      },
      ativa: true,
      plano: 'nfe',
      calculo_custo: constantes.CALCULO_MEDIA,
      configuracao_tributaria: {
        ramo_atividade: '',
        config_cfop_padrao: '1',
        cfop_padrao: '',
        regime_tributario: '',
        csosn: '102',
        serie_padrao: '',
        usa_icms_padrao: true,
        usa_icms_st_padrao: true,
        usa_pis_entrada_padrao: true,
        usa_pis_saida_padrao: true,
        usa_cofins_entrada_padrao: true,
        usa_cofins_saida_padrao: true,
        cst_icms: '00',
        cst_icms_st: '00',
        cst_pis_entrada: '01',
        cst_pis_saida: '01',
        cst_cofins_entrada: '01',
        cst_cofins_saida: '01',
        aliq_icms: 18.00,
        aliq_icms_st: 0,
        aliq_pis_entrada: 1.65,
        aliq_pis_saida: 1.65,
        aliq_cofins_entrada: 7.60,
        aliq_cofins_saida: 7.60,
        aliq_cred_sn: 18,
        info_complementar: '',
        empresa: null
      }
    },
    certificado: {},
    notificacoes: [],
    cidades: [],
    constantes: constantes,
    conversor: conversor,
    modoEdicao: false,
    carregando: false,
    carregando_cidade: false,
    certificadoCarregado: false,
    totalEmpresas: 0
  },

    //equivalente ao computed properties
  getters: {

    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
    possuiCertA1(state){
      if(state.empresaAtual){
        if(state.empresaAtual.tipo_certificado === 'A1'){
          return true;
        }
        else{
          return false;
        }
      }
      else{
        return false;
      }
    },
    diasRestantesCertificado(state){
      let dataValidade = state.certificado.validade;
      if(dataValidade && dataValidade !== ''){
        let dias = moment(dataValidade).diff(moment(), 'days');
        if(dias < 0){
          return 'Vencido';
        }
        else if(dias === 0){
          return '1';
        }
        else{
          return dias;
        }
      }
    },
    listaCidades(state) {
      return state.cidades.map(cidade => { 
        return {
          text: cidade.cidade, 
          value: cidade.codigo_cidade 
        };
      });
    }
  },

    //equivalente aos methods
    //responsável por chamar a api externa
  actions: {

    async filtrarCidadesEstado({commit}, estado){

      try {

        commit('setCarregandoCidades', true);
        let cidades = await axios.get(`/cidades/estado/${estado}`).then(response => response.data);
        commit('setCidades', cidades);

      } catch (error) {
        commit('setCarregandoCidades', false);
        commit('setMensagemErro', 'Falha ao buscar cidades', {root: true});
        throw error;
      } finally {
        commit('setCarregandoCidades', false);
      }
    },

    consultarCertificadoEmpresa({commit, rootState}){

      commit('setCarregando', true);
      axios.get(`/empresas/certificado`)
      .then(response => {
        
        let result = response.data;
        if(result.status === 200){
          commit('setCertificado', result);
          commit('setCarregando', false);
        }
        else{
          commit('setCarregando', false);
          commit('setMensagemErro', 'Falha ao tentar Carregar Certificado', {root: true});
        }
      })
      .catch(erro => {
          commit('setCarregando', false);
          commit('setMensagemErro', 'Falha ao tentar Carregar Certificado', {root: true});
      });
    },

    ativar({commit}, empresa){

      commit('setCarregando', true);
      axios.put('/empresasAdmin/ativar', { id: empresa._id})
      .then((response) => {
        commit('ativar', empresa._id);
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', 'Falha ao Ativar Empresa', {root: true});
      });
    },

    inativar({commit}, empresa){

      commit('setCarregando', true);
      axios.put('/empresasAdmin/inativar', { id: empresa._id})
      .then((response) => {
        commit('inativar', empresa._id);
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', 'Falha ao Inativar Empresa', {root: true});
      });
    },

    consultarCadastro({commit, rootState, dispatch}, cnpj){

      let consulta = {
        cnpj: cnpj
      };

      commit('setCarregando', true);
      axios.post('/servicos/consultar_cadastro', consulta)
      .then((response) => {
        let resultado = response.data;
        if(resultado.status == 111){
          commit('setDadosEmpresa', resultado.empresa);
          let cep = resultado.empresa.endereco.cep;
          if(cep.length === 8){
            dispatch('verificarCep', cep)
            .then(() => {

            });
          }

          let cnae = resultado.empresa.cnae;
          if(cnae.length === 7){
            dispatch('consultarCnae', cnae)
            .then(() => {
            
            });
          }

          if(resultado.empresa.situacao == 0){
            commit('setMensagemErro', 'Contribuinte não habilitado na SEFAZ', {root: true});        
          }
        }
        commit('setCarregando', false);
      })
      .catch((erro) => {
          commit('setCarregando', false);
          commit('setMensagemErro', 'Falha ao consultar CNPJ', {root: true});
      });
    },
    processarCertificado({commit, rootState}, certificado){

      commit('setCarregando', true);
      axios.put('/empresas/certificado', certificado)
      .then((response) => {
        commit('setCertificado', conteudoCertificado);
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', 'Falha ao tentar processar certificado', {root: true});
      });
    },
    consultarCnae({commit}, cnae){

      commit('setCarregando', true);
      axiosCep.get('https://servicodados.ibge.gov.br/api/v2/cnae/subclasses/' + cnae)
      .then((response) => {
        let dadosCnae = response.data;
        if(dadosCnae.length > 0){
          commit('setDescricaoCnae', dadosCnae);
        }
        else{
          commit('setMensagemErro', 'CNAE não Encontrado', {root: true});    
        }
        commit('setCarregando', false);
      })
      .catch((err) => {
        commit('setCarregando', false);
        commit('setMensagemErro', 'Falha ao tentar consultar CNAE', {root: true});
      });
    },
    carregar({commit}, options){
        
      commit('setCarregando', true);
      let url = `/empresas?page=${options.page}&pageSize=${options.itemsPerPage}`;
      if(options.query && options.query !== ''){
        url = `${url}&query=${options.query}`;
      }

      axios.get(url)
      .then((response) => {
        commit('setTotalEmpresas', response.data.total);
        commit('setEmpresas', response.data.empresas);
      })
      .catch((erro) => {
        commit('setEmpresas', []);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });  
    },
    carregarTodas({commit}, options){

      commit('setCarregando', true);
      let url = `/empresasAdmin?page=${options.page}&pageSize=${options.itemsPerPage}`;
      if(options.query && options.query !== ''){
        url = `${url}&query=${options.query}`;
      }

      axios.get(url)
      .then((response) => {
        commit('setTotalEmpresas', response.data.total);
        commit('setEmpresas', response.data.empresas);
      })
      .catch((erro) => {
        commit('setEmpresas', []);
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    },
    carregarEmpresa({commit}, idEmpresa){
      
      commit('setCarregando', true);
      axios.get('/empresas/' + idEmpresa)
      .then((response) => {
        commit('setEmpresa', response.data);
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    },
    carregarLogo({commit}, logotipo){

      commit('setCarregando', true);
      var reader = new FileReader();
      var vm = commit;
      reader.onload = (e) => {
        vm('setLogotipo', e.target.result);
        vm('setCarregando', false);
      }
      reader.readAsDataURL(logotipo);
    },
    alterarPersonalizacao({commit, state, rootState}, novaCor){

      state.empresaAtual.tema = novaCor;
      let empresa = Object.assign({}, state.empresaAtual);

      commit('setCarregando', true);
      axios.put('/empresas', empresa)
      .then((response) => {
        rootState.empresaAtual.logotipo = empresa.logotipo;
        rootState.empresaAtual.tema = novaCor;

        commit('setCarregando', false);
        commit('setMensagemSucesso', 'Alterações Salvas', {root: true});
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    },
    async salvar({commit, state}){

      commit('setCarregando', true);

      let empresa = Object.assign({}, state.empresaAtual);
      empresa.cnpj = empresa.cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
      empresa.cep = empresa.cep.replace('.', '').replace('-', '');
      empresa.cnae = empresa.cnae.replace('-', '').replace('/', '');
      empresa.telefone = empresa.telefone.replace('(', '').replace(')', '').replace('-', '');
      
      if(state.modoEdicao){
        try{

          let response = await axios.put('/empresas', empresa);
          let retorno = response.data;
          commit('atualizar', retorno);
          commit('setMensagemSucesso', 'Empresa Atualizada com Sucesso', {root: true});
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
      else{
        try{
          delete(empresa._id);
          empresa.tema = 'green';
          let response = await axios.post('/empresas', empresa);
          let retorno = response.data;

          commit('adicionar', retorno);
          commit('setMensagemSucesso', 'Empresa Cadastrada com Sucesso', {root: true});
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
    },
    remover({commit}, remover){
      commit('setCarregando', true);
      axios.delete('/empresas', {
        data: { _id: remover._id }
      })
      .then((response) => {
        commit('remover', remover);
        commit('setMensagemSucesso', 'Empresa Removida com Sucesso', {root: true});
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    }
  },

    //responsável por alterar o state.
  mutations: {

    setCidades(state, cidades){
      state.cidades = cidades;
    },
    setCertificado(state, certificado){
      state.certificado = certificado;
    },
    setCidade(state, codigo_cidade){
      let cidade = state.cidades.find(cidade => cidade.codigo_cidade == codigo_cidade);
      
      state.empresaAtual.cidade = cidade.cidade;
      state.empresaAtual.codigo_cidade = cidade.codigo_cidade;
      state.empresaAtual.uf = cidade.codigo_estado;
      state.empresaAtual.pais = 'Brasil';
      state.empresaAtual.codigo_pais = 1058;
    },
    setEmpresas(state, empresas){
      state.empresas = empresas;
      state.carregando = false;
    },
    setTotalEmpresas(state, total){
      state.totalEmpresas = total;
    },
    setEmpresa(state, empresa){
      state.empresaAtual = empresa;
      state.carregando = false;
    },
    setDadosEmpresa(state, empresa){
      state.empresaAtual.cnae = empresa.cnae;
      state.empresaAtual.ie = empresa.ie + '-' + empresa.uf;
      state.empresaAtual.razao = empresa.razao;
      state.empresaAtual.fantasia = empresa.fantasia;
      state.empresaAtual.numero = empresa.endereco.numero;
      state.empresaAtual.cep = empresa.endereco.cep;

      let encontrado = constantes.REGIMES_TRIBUTARIOS.find(regime => { return regime.text.toLowerCase().indexOf(empresa.regime.toLowerCase()) !== -1 });
      if(encontrado){
        state.empresaAtual.configuracao_tributaria.regime_tributario = encontrado.value;
      }
    },
    setLogotipo(state, logo){
        state.empresaAtual.logotipo = logo;
    },
    setEndereco(state, endereco){
        
      state.empresaAtual.logradouro = endereco.logradouro;
      state.empresaAtual.bairro = endereco.bairro;
      state.empresaAtual.uf = conversor.ufParaCodigo(endereco.uf);
      state.empresaAtual.estado = endereco.uf;
      state.empresaAtual.cidade = endereco.localidade;
      state.empresaAtual.codigo_cidade = endereco.ibge;
      state.empresaAtual.pais = 'Brasil';
      state.empresaAtual.codigo_pais = 1058;
    },
    setDescricaoCnae(state, dadosCnae){
      state.empresaAtual.descricao_cnae = dadosCnae[0].descricao;
    },
    setCarregando(state, valor){
      state.carregando = valor;
    },
    setCarregandoCidades(state, valor){
      state.carregando_cidade = valor;
    },
    setInfoComplementar(state, infoComplementar){
      state.empresaAtual.configuracao_tributaria.info_complementar = infoComplementar;
    },
    remover(state, removido){
      state.empresas = state.empresas.filter(empresa => empresa._id !== removido._id);
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.empresaAtual = {
        cnpj: '',
        fantasia: '',
        razao: '', 
        ie: '', 
        im: '',
        cnae: '',
        descricao_cnae: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        codigo_cidade: '',
        uf: '',
        estado: '',
        pais: '',
        codigo_pais: '',
        logotipo: '',
        tema: '',
        versao: '4.00',
        ambiente: '',
        tipo_certificado: 'A1',
        formato_impressao: '1',
        frete_padrao: '9',
        forma_pagamento_padrao: '0',
        certificado: '',
        senha: '',
        telefone: '',
        email: '',
        usuario: {
          nome: 'Administrador',
          email: '',
          foto: ''
        },
        ativa: true,
        plano: 'nfe',
        calculo_custo: constantes.CALCULO_MEDIA,
        configuracao_tributaria: {
          ramo_atividade: '',
          config_cfop_padrao: '1',
          cfop_padrao: '',
          regime_tributario: '',
          csosn: '102',
          serie_padrao: '',
          usa_icms_padrao: true,
          usa_icms_st_padrao: true,
          usa_pis_entrada_padrao: true,
          usa_pis_saida_padrao: true,
          usa_cofins_entrada_padrao: true,
          usa_cofins_saida_padrao: true,
          cst_icms: '00',
          cst_icms_st: '00',
          cst_pis_entrada: '01',
          cst_pis_saida: '01',
          cst_cofins_entrada: '01',
          cst_cofins_saida: '01',
          aliq_icms: 18.00,
          aliq_icms_st: 0,
          aliq_pis_entrada: 1.65,
          aliq_pis_saida: 1.65,
          aliq_cofins_entrada: 7.60,
          aliq_cofins_saida: 7.60,
          aliq_cred_sn: 18,
          info_complementar: '',
          empresa: null
        }
      };
      state.carregando = false;
      state.certificadoCarregado = false;
    },
    editar(state, editar){

      state.modoEdicao = true;
      state.empresaAtual = editar;
      state.carregando = false;
    },
    adicionar(state, empresa){
      state.empresas.push(empresa);
      state.carregando = false;
    },
    atualizar(state, atual){
      state.empresas.forEach(empresa => {
        if(empresa._id === atual._id){
          empresa = atual;
        }
      });
      state.carregando = false;
    },
    ativar(state, idEmpresa){
      state.empresas.forEach(empresa => {
        if(empresa._id === idEmpresa){
          empresa.ativa = true;
        }
      });
      state.carregando = false;
    },
    inativar(state, idEmpresa){
      state.empresas.forEach(empresa => {
        if(empresa._id === idEmpresa){
          empresa.ativa = false;
        }
      });
      state.carregando = false;
    },
    preencherEmailPadrao(state){
      state.empresaAtual.usuario.email = state.empresaAtual.email;
    },
    atualizarConfigCfop(state){
      if(state.empresaAtual.configuracao_tributaria.config_cfop_padrao === '1'){ // cfop definido pelo ramo de atividade
        if(state.empresaAtual.configuracao_tributaria.ramo_atividade === '1'){ // comércio
          state.empresaAtual.configuracao_tributaria.cfop_padrao = '5102';
        }
        else if(state.empresaAtual.configuracao_tributaria.ramo_atividade === '3'){ //industria
          state.empresaAtual.configuracao_tributaria.cfop_padrao = '5101';
        }
      }
    },
    estadoInicial(state){
      state.empresaAtual = {
        cnpj: '',
        fantasia: '',
        razao: '', 
        ie: '', 
        im: '',
        cnae: '',
        descricao_cnae: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        codigo_cidade: '',
        uf: '',
        estado: '',
        pais: '',
        codigo_pais: '',
        logotipo: '',
        tema: '',
        versao: '4.00',
        ambiente: '',
        tipo_certificado: 'A1',
        formato_impressao: '1',
        frete_padrao: '9',
        forma_pagamento_padrao: '0',
        certificado: '',
        senha: '',
        telefone: '',
        email: '',
        usuario: {
          nome: 'Administrador',
          email: '',
          foto: ''
        },
        ativa: true,
        plano: 'nfe',
        calculo_custo: constantes.CALCULO_MEDIA,
        configuracao_tributaria: {
          ramo_atividade: '',
          config_cfop_padrao: '1',
          cfop_padrao: '',
          regime_tributario: '',
          csosn: '102',
          serie_padrao: '',
          usa_icms_padrao: true,
          usa_icms_st_padrao: true,
          usa_pis_entrada_padrao: true,
          usa_pis_saida_padrao: true,
          usa_cofins_entrada_padrao: true,
          usa_cofins_saida_padrao: true,
          cst_icms: '00',
          cst_icms_st: '00',
          cst_pis_entrada: '01',
          cst_pis_saida: '01',
          cst_cofins_entrada: '01',
          cst_cofins_saida: '01',
          aliq_icms: 18.00,
          aliq_icms_st: 0,
          aliq_pis_entrada: 1.65,
          aliq_pis_saida: 1.65,
          aliq_cofins_entrada: 7.60,
          aliq_cofins_saida: 7.60,
          aliq_cred_sn: 18,
          info_complementar: '',
          empresa: null
        }
      };
      state.cidades = [];
      state.certificadoCarregado = false;
    }
  }
}
<template>
  <div>

    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formContaAPagar" lazy-validation>
      <v-container fluid>
        <v-widget title="Cadastro Conta a Pagar">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="4" sm="3">
                <buscar-participante label="Buscar Fornecedor" v-model="cnpjCpfParticipante" @participanteSelecionado="adicionarParticipante" :rules="[rules.obrigatorio]"></buscar-participante>
              </v-col>
              <v-col cols="8" sm="9">
                <v-text-field label="Fornecedor" v-model="contaAPagar.participante.razao_nome" readonly ref="razao_nome" @keypress.native.enter="$refs.documento.focus()"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field label="Documento" v-model="contaAPagar.documento" :rules="[rules.obrigatorio]" ref="documento" @keypress.native.enter="$refs.dataCadastro.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <campo-data label="Cadastro" v-model="contaAPagar.data_cadastro" :rules="[rules.obrigatorio]" ref="dataCadastro" @keypress.native.enter="$refs.dataVencimento.focus()"></campo-data>
              </v-col>
              <v-col cols="12" sm="">
                <campo-data label="Vencimento" v-model="contaAPagar.data_vencimento" :rules="[rules.obrigatorio]"  ref="dataVencimento" @keypress.native.enter="$refs.valor.focus()"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor label="Valor" v-model="contaAPagar.valor"  :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" ref="valor" @keypress.native.enter="$refs.notaFiscal.focus()"></as-campo-valor>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field label="Nota Fiscal" v-model="contaAPagar.nota_fiscal" ref="notaFiscal" @keypress.native.enter="$refs.tipo.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select :items="tipos" v-model="contaAPagar.tipo" label="Tipo" ref="tipo" @keypress.native.enter="$refs.obs.focus()"></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea name="obs" label="Observação" v-model="contaAPagar.observacao" ref="obs"></v-textarea>
              </v-col>
            </v-row>
          </template>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar">{{constantes.BOTAO_CANCELAR}}</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar" :disabled="!valid">{{textoBtnConfirmar}}</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>  
  </div>
</template>


<script>

import {mapState, mapGetters, mapActions} from "vuex";
import constantes from '@/util/constantes';
import formatador from '@/util/formatador';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'FormularioContaAPagar',
  data: () => ({
    valid: true,
    constantes: constantes,
    cnpjCpfParticipante: '',
    loading: false,
    rules: {...regras},
    tipos:['Boleto', 'Cheque', 'Duplicata', 'Nota Promissória', 'Vale', 'Imposto', 'Outros'],
  }),

  computed: {

    ...mapState('contaAPagar', {
      contaAPagar: 'contaAtual',
      carregando: 'carregando'
    }),
    
    ...mapGetters('contaAPagar', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar'
    })
  },

  methods: {

    async salvar() {
      if(this.$refs.formContaAPagar.validate()){
        try{
          await this.$store.dispatch('contaAPagar/salvar');
          this.$router.push({ name: 'contasAPagar' })
        }
        catch(erro){
          console.log('Erro ao salvar');
        }
      }
    },

    cancelar() {
      this.$store.commit('contaAPagar/estadoInicial');
      this.$router.push({ name: 'contasAPagar' });
    },

    adicionarParticipante(participante){
      this.cnpjCpfParticipante = formatador.formatarCnpj(participante.cnpj_cpf);
      this.$store.commit('contaAPagar/setParticipante', participante);
    }
  },

  async created(){
    
    try{

      this.loading = true;
      
      let conta = this.$store.state.contaAPagar.contaAtual;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
  
      if(estaEditando && (!conta._id || conta._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione uma conta para editar');
        this.$router.push({ name: 'contasAPagar' });
      }
      
      if(!estaEditando){
        this.$store.commit('contaAPagar/estadoInicial');
      } else {
        this.cnpjCpfParticipante = conta.participante.cnpj_cpf;
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>
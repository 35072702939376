<template>
  <div>
    <v-subheader class="text-subtitle-1 text--primary">
      Gráficos
    </v-subheader>
    <v-container class="fillHeight">
      <v-row dense>
        <v-spacer></v-spacer>
        <v-col cols="12" xs="12" sm="5">
          <v-row dense>
            <v-col cols="12" xs="12" sm="12">
              <v-autocomplete label="Gráfico" ref="grafico" :items="graficos" v-model="graficoSelecionado" @change="mudarGrafico"></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <grafico-vendas-por-mes ref="graficoVendasPorMes" :exibir="exibirGraficoVendasPorMes" @esconder="exibirGraficoVendasPorMes = false" />
      <grafico-vendas-por-intervalo-data ref="graficoVendasPorIntervaloData" :exibir="exibirGraficoVendasPorIntervaloData" @esconder="exibirGraficoVendasPorIntervaloData = false" />
    </v-container>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import GraficoVendasPorMes from './vendas/GraficoVendasPorMes';
  import GraficoVendasPorIntervaloData from './vendas/GraficoVendasPorIntervaloData';

  export default {
    name: 'Graficos',
    components: {
      GraficoVendasPorMes,
      GraficoVendasPorIntervaloData,
    },
    data() {
      return {
        graficoSelecionado: 1,
        graficos: [
          { text: '1. Vendas Por Mês', value: 1 },
          { text: '2. Vendas Por Intervalo de Data', value: 2 },
        ],
        exibirGraficoVendasPorMes: true,
        exibirGraficoVendasPorIntervaloData: false,
      }
    },
    
    mounted() {
      this.carregarGraficos();
    },

    computed: {
      ...mapState("graficos", {
        carregando: "carregando",
      })
    },

    methods: {
      carregarGraficos() {
        this.$refs.graficoVendasPorMes.carregar();
      },

      mudarGrafico(grafico) {
        switch(grafico) {
          case 1:
            this.exibirGraficoVendasPorMes = true;
            this.$refs.graficoVendasPorMes.carregar();
            break;
          case 2:
            this.exibirGraficoVendasPorIntervaloData = true;
            this.$refs.graficoVendasPorIntervaloData.carregar();
            break;
        }
      }
    }
  }
</script>

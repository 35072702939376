<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formContaAReceber" lazy-validation>
      <v-container fluid>
        <v-widget title="Conta a Receber">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="3">
                <buscar-participante label="Buscar Cliente" v-model="cnpjCpfParticipante" @participanteSelecionado="adicionarParticipante" :rules="[rules.obrigatorio]"></buscar-participante>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field label="Cliente" v-model="contaAReceber.participante.razao_nome" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="Documento" v-model="contaAReceber.documento" :rules="[rules.obrigatorio]" ref="documento" @keypress.native.enter="$refs.dataCadastro.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <campo-data label="Cadastro" v-model="contaAReceber.data_cadastro" :rules="[rules.obrigatorio]" ref="dataCadastro" @keypress.native.enter="$refs.dataVencimento.focus()"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <campo-data label="Vencimento" v-model="contaAReceber.data_vencimento" :rules="[rules.obrigatorio]" ref="dataVencimento" @keypress.native.enter="$refs.valor.focus()"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor label="Valor" v-model="contaAReceber.valor"  :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" ref="valor" @keypress.native.enter="salvar"></as-campo-valor>
              </v-col>
            </v-row>
          </template>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar" :disabled="carregando">Cancelar</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar" :disabled="!valid">Salvar</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>
  </div>
</template>

<script>
  import { mapState, mapGetters } from "vuex";
  import constantes from '@/util/constantes';
  import formatador from '@/util/formatador';
  import * as regras from '@/util/validacaoFormulario';

  export default {
    name: "FormularioContaAReceber",
    data: () => ({
      loading: false,
      valid: false,
      constantes: constantes,
      cnpjCpfParticipante: '',
      rules: {...regras},
    }),

    computed: {
      ...mapState ('contaAReceber', {
        contaAReceber: 'contaAtual',
        carregando: 'carregando'
      }),
      
      ...mapGetters ('contaAReceber', {
        estaEditando: 'estaEditando',
        textoBtnConfirmar: 'textoBtnConfirmar'
      })
    },

    methods: {
      async salvar () {
        if(this.$refs.formContaAReceber.validate()){
          try {
            if (this.contaAReceber._id) {
              await this.$store.dispatch('contaAReceber/atualizar');
            } else {
              await this.$store.dispatch('contaAReceber/salvar');
            }
            this.$router.push({ name: 'contasAReceber' })
          } catch (erro) {
            console.log('Erro ao salvar', erro);
          }
        }
      },

      cancelar () {
        this.$store.commit('contaAReceber/ESTADO_INICIAL');
        this.$router.push({ name: 'contasAReceber' });
      },

      adicionarParticipante (participante) {
        this.cnpjCpfParticipante = formatador.formatarCnpj(participante.cnpj_cpf);
        this.$store.commit('contaAReceber/SET_PARTICIPANTE', participante);
        setTimeout(() => {
          this.$refs.documento.focus();
        }, 500);
      }
    },

    async created () {
      try {
        this.loading = true;

        let conta = this.$store.state.contaAReceber.contaAtual;
        let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
    
        if(estaEditando && (!conta._id || conta._id === '')){
          this.$store.commit('setMensagemErro', 'Selecione uma conta para editar');
          this.$router.push({ name: 'contasAReceber' });
        }
        
        if(!estaEditando){
          this.$store.commit('contaAReceber/ESTADO_INICIAL');
        } else {
          this.cnpjCpfParticipante = conta.participante.cnpj_cpf;
        }
      } catch (erro) {
        console.log(erro);
      } finally {
        this.loading = false;
      }
    }
  }
</script>
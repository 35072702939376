<template>
  <div>
    <v-subheader class="text-subtitle-1 text--primary">
      Contas a Receber
    </v-subheader>
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="white">
          <v-row dense>
            <v-col cols="12" xs="8" sm="6">
              <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @keyup.enter="filtrarBusca($event)" @click:clear="limparBusca" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
            </v-col>
            <v-col class="pl-5 pt-2" cols="12" sm="3">
              <v-select v-show="!filtro_ativo" v-model="filtro.exibir" label="Exibir" :items="itens_exibir" @change="listarPorTipo" hide-details></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-right" cols="12" xs="4" sm="2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    class="mr-4"
                    v-bind="attrs"
                    v-on="on"
                    v-show="filtro_ativo" 
                    @click="limparFiltro"
                  >
                    mdi-notification-clear-all
                  </v-icon>
                </template>
                <span>Limpar filtro</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    dark
                    class="mr-4"
                    v-bind="attrs"
                    v-on="on"
                    :loading="carregando" 
                    @click="abrirDialogoFiltro"
                  >
                    mdi-filter
                  </v-icon>
                </template>
                <span>Filtro</span>
              </v-tooltip>
              <v-btn color="primary" @click="novo">Nova</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :server-items-length="totalContasAReceber" :footer-props="footerOptions" :headers="headers" :items="contasAReceber" :loading="carregando">
          <template v-slot:item="{item}">
            <tr>
              <td class="text-center">{{ item.documento }}</td>
              <td class="text-center">{{ item.participante.cnpj_cpf | formatarCnpj }}</td>
              <td>{{ item.participante.razao_nome }}</td>
              <td class="text-center"><v-chip :color="verificarVencimento(item.data_vencimento)">{{ item.data_vencimento | formatarData }}</v-chip></td>
              <td class="text-center">{{ item.valor | formatarMoeda }}</td>
              <td class="text-center">{{ item.valor_pago | formatarMoeda }}</td>
              <td class="text-center">{{ item.valor_restante | formatarMoeda }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="item._id + 10" @click="baixar(item)">
                      <v-list-item-icon><v-icon>monetization_on</v-icon></v-list-item-icon>
                      <v-list-item-title>Baixar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="item._id + 20" @click="editar(item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="item._id + 30" @click="excluir(item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <dialogo-recebimento ref="dialogoRecebimento" @finalizar="finalizarRecebimento"></dialogo-recebimento>
    <dialogo-filtro ref="dialogoFiltro" @filtrar="filtrar" @limpar="limparFiltro"></dialogo-filtro>
</div>
</template>

<script>
  import { mapState } from 'vuex';
  import constantes from '@/util/constantes';
  import moment from 'moment';
  import DialogoRecebimento from './DialogoRecebimento';
  import DialogoFiltro from './DialogoFiltro';

  export default {
    name: "ListaContasAReceber",
    components: {
      DialogoRecebimento,
      DialogoFiltro
    },

    data () {
      return {
        headers: [
          { text: 'Documento', value: 'documento', sortable: false, align: 'center' },
          { text: 'CNPJ/CPF', value: 'participante.cnpj_cpf', align: 'center' },
          { text: 'Cliente', value: 'participante.razao_nome' },
          { text: 'Vencimento', value: 'data_vencimento', align: 'center' },
          { text: 'Valor', value: 'valor', align: 'center' },
          { text: 'Valor Pago', value: 'valor_pago', align: 'center' },
          { text: 'Valor Restante', value: 'valor_restante', align: 'center' },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        },
        constantes: constantes,
        busca: '',
        itens_exibir: [
          'Todos',
          'Em Aberto',
          'Vencidos',
          'Pagos',
          'Pagos Parcialmente'
        ],
        filtro: {
          cliente: {},
          exibir: 'Todos'
        },
        filtro_ativo: false,
        tipo: {text: 'Duplicata', value: constantes.DUPLICATA}
      }
    },

    computed: {
      ...mapState('contaAReceber', {
        contasAReceber: 'contasAReceber',
        totalContasAReceber: 'totalContasAReceber',
        carregando: 'carregando'
      })
    },

    watch: {
      options: {
        handler () {
          this.listar();
        },
        deep: true,
      },
    },

    methods: {
      abrirDialogoFiltro() {
        this.filtro_ativo = true;
        this.$refs.dialogoFiltro.abrir();
      },

      async listar() {
        this.filtro_ativo = false;
        this.$store.dispatch('contaAReceber/listar', this.options);
      },
      
      async listarPorTipo() {
        this.busca = '';
        let newOptions = {
          ...this.options,
          query: this.filtro
        }
        this.$store.dispatch('contaAReceber/listar', newOptions);
      },
      
      async filtrarBusca(event) {
        let newOptions = {
          ...this.options,
          query: this.busca
        }

        this.$store.dispatch('contaAReceber/listar', newOptions);
        setTimeout(() => {
          event.target.select();
        }, 300);
      },

      async filtrar(filtro) {
        let newOptions = {
          ...this.options,
          query: filtro
        }
        this.$store.dispatch('contaAReceber/filtrar', newOptions);
      },

      excluir(conta) {
        this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('contaAReceber/excluir', conta);
          }
        });
      },

      verificarVencimento(vencimento){
        let emDia = moment().isBefore(vencimento);
        if(emDia){
          return 'secondary';
        }
        else{
          return 'error';
        }
      },

      editar(conta){
        this.$store.commit('contaAReceber/EDITAR', conta);
        this.$router.push({name: 'formularioContaAReceberEditar'});
      },

      novo(){
        this.$store.commit('contaAReceber/NOVO');
        this.$router.push({name: 'formularioContaAReceberNova'});
      },

      baixar(conta){
        this.$store.commit('contaAReceber/EDITAR', conta);
        this.$refs.dialogoRecebimento.abrir();
      },

      finalizarRecebimento() {
        this.filtro.exibir = "Todos";
        this.listar();
      },

      limparFiltro() {
        this.listar();
      },

      limparBusca() {
        this.listar();
      }
    },
  }
</script>
<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="white">
          <v-text-field flat solo single-line hide-details clearable v-model="busca" max-width="300px" prepend-inner-icon="search" label="Buscar Compra"></v-text-field>
          <v-btn color="primary" @click="importarXml({router : $router})">Nova Nota</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :footer-props="footerOptions" :headers="headers" :items="compras" :loading="carregando">
          <template v-slot:item="props">
            <tr>
              <td class="text-center">{{ props.item.numero }} - {{ props.item.serie }}</td>
              <td>{{ props.item.fornecedor.razao_nome }}</td>
              <td class="text-center">{{ props.item.data | formatarData }}</td>
              <td class="text-center">{{ props.item.data_entrada | formatarData }}</td>
              <td class="text-center">{{ props.item.quantidade_total_produtos | formatarValor }}</td>
              <td class="text-center">{{ props.item.total | formatarMoeda }}</td>
              <td class="justify-center layout px-0 text-center">
                <v-menu top right transition="slide-x-transition" @click.native.stop="">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item @click="editarCompra({ compra : props.item, router : $router})" data-cy="editar">
                      <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="remover(props.item)" data-cy="editar">
                      <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex';
import constantes from '@/util/constantes';

export default {
  data () {
    return {
      headers: [
        { text: 'Nota', value: 'numero', sortable: false, align: 'center' },
        { text: 'Fornecedor', value: 'fornecedor.razao_nome', sortable: false },
        { text: 'Data', value: 'data', sortable: false, align: 'center' },
        { text: 'Cadastro', value: 'data_entrada', sortable: false, align: 'center' },
        { text: 'Quantidade', value: 'quantidade_total_produtos', sortable: false, align: 'center' },
        { text: 'Valor', value: 'total', sortable: false, align: 'center' },
        { text: 'Ações', value: 'name', align:'center',  sortable: false, align: 'center' }
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1
      },
      footerOptions: {
        'items-per-page-options': constantes.ITEMS_POR_PAGINA 
      },
    }
  },

  computed: {
    ...mapState('compra', {
      compras: 'compras',
      carregando: 'carregando'
    })
  },

  methods: {
    ...mapActions('compra', {
      carregarCompras: 'carregar',
      editarCompra: 'editar',
      novaCompra: 'novo',
      importarXml: 'importar',
    }),

    remover(item){
      this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?')
      .then((confirmado) => {
        if(confirmado){
          this.$store.dispatch('compra/remover', item);
        }
      });
    }
  },

  created(){
    //chamada a action através do dispatch
    this.carregarCompras();
  }
}
</script>
<template>
  <v-app id="login" class="primary">
    <v-main>
      <div class="ocean" :style="isDevelopment ? 'background-color: #2f4c59;' : 'background-color: #1b5e20;'">
        <div :class="isDevelopment ? 'wavedev' : 'wave'"></div>
        <div :class="isDevelopment ? 'wavedev' : 'wave'"></div>
      </div>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-2 pa-4">
              <v-card-text>
                <div class="layout column align-center mb-10" :style="isDevelopment ? 'filter: grayscale(1);' : ''">
                  <img id="logo" src="../assets/easyweb.svg" alt="Anron Software" />
                </div>
                <v-form v-model="valid" ref="formLogin">
                  <v-text-field autofocus autocomplete="off" append-icon="person" label="E-mail" type="text" :rules="validaEmail" v-model="usuario.email" data-cy="email"></v-text-field>
                  <v-text-field append-icon="lock" label="Senha" type="password" :rules="validaSenha" v-model="usuario.senha" @keypress.enter="validar(usuario)" data-cy="senha"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="pa-4">
                <v-btn block color="primary darken-2" @click="validar(usuario)" :loading="carregando" data-cy="login">Acessar</v-btn>
              </v-card-actions>
            </v-card>
            <div id="copyright" class="mt-2 layout column align-center">&copy; {{ new Date().getFullYear() }} Anron Software - Todos direitos reservados</div>

            <v-snackbar :timeout="10000" bottom multi-line color="red" v-model="showErro">
              {{ erro }}
              <template v-slot:action>
                <v-btn dark text @click.native="showErro = false" icon>
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

import moment from "moment";
import axios from "@/util/axios";
import clientjs from "clientjs";
import colors from "vuetify/es5/util/colors";
import validador from "@/util/validador";
import { IS_PROD } from "@/util/config";

export default {
  data() {
    return {
      valid: false,
      validaEmail: [(v) => !!v || "Informe o E-Mail"],
      validaSenha: [(v) => !!v || "Informe a Senha"],
      usuario: {
        email: "",
        senha: "",
      },
      showErro: false,
      erro: "",
      carregando: false,
      colors: colors,
    };
  },

  mounted() {
    if (!IS_PROD) {
      this.usuario.email = "jprim@gmail.com";
      this.usuario.senha = "123";
    }
  },

  methods: {
    validar(usuario) {
      if (this.$refs.formLogin.validate()) {
        this.logar(usuario);
      } else {
      }
    },

    logar(usuario) {
      this.carregando = true;

      let client = new ClientJS();
      let dispositivo = client.getFingerprint();

      let logarUsuario = Object.assign({}, this.usuario);
      logarUsuario.senha = btoa(logarUsuario.senha);
      logarUsuario.dispositivo = dispositivo;

      axios
        .post("/login", logarUsuario)
        .then((response) => {
          localStorage.setItem("token", response.data.token);

          this.$store.commit("setUsuario", {
            nome: response.data.nome,
            email: this.usuario.email,
            foto: response.data.foto,
            empresas: response.data.empresas,
            empresa_padrao: response.data.empresa_padrao,
            ambiente: response.data.ambiente,
          });

          response.data.empresas.forEach((elemento) => {
            if (elemento.empresa._id == response.data.empresa_padrao) {
              this.$vuetify.theme.primary = this.colors[elemento.empresa.tema].base;
            }
          });

          this.$store.dispatch("consultarStatusServico", false);
          this.$cookies.set("token", response.data.token, "1d");
          this.$router.replace({ path: "/home" });

          let dias = moment(response.data.validade_certificado, "MMM DD hh:mm:ss YYYY GMT").diff(moment(), "days");
          if (dias <= 30) {
            let mensagem = "";
            if (dias == 0) {
              mensagem = "Seu certificado vence hoje";
            } else if (dias < 0) {
              mensagem = "Seu certificado está vencido";
            } else {
              mensagem = "Bem Vindo(a) " + response.data.nome + ". Seu certificado se vence em " + dias + " dias.";
            }
            this.$store.commit("setMensagemErro", mensagem);
          } else {
            this.$store.commit("setMensagemSucesso", "Bem Vindo(a) " + response.data.nome);
          }

          if (dias < 0) {
            this.$store.commit("setCertificadoVencido", true);
          } else {
            this.$store.commit("setCertificadoVencido", false);
          }
        })
        .catch((erro) => {
          if (erro.response) {
            this.erro = erro.response.data.motivo;
            this.showErro = true;
          } else {
            this.erro = erro.message;
            this.showErro = true;
          }
          localStorage.removeItem("token");
        })
        .then(() => {
          this.carregando = false;
        });
    },

    ...mapState({
      snackbar: "snackbar",
    }),
  },
  computed: {
    isDevelopment() {
      return !["https://easypdv.anronsoftware.com.br"].includes(window.location.origin);
    },
  },
};
</script>

<style scoped lang="css">
#login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
  background-color: lightgray !important;
  overflow: hidden;
}
#logo {
  max-width: 280px;
}
#copyright {
  text-align: center;
  font-size: 14px;
  color: white;
}

.ocean {
  z-index: -1;
  height: 30%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #1b5e20;
}

.wave {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3E%3Cstop stop-color='%238bc34a' stop-opacity='.25' offset='0%25'/%3E%3Cstop stop-color='%231b5e20' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z' transform='matrix(-1 0 0 1 1600 0)'/%3E%3C/svg%3E")
    repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wavedev {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1600' height='198'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='50%25' x2='50%25' y1='-10.959%25' y2='100%25'%3E%3Cstop stop-color='%23abbfc9' stop-opacity='.25' offset='0%25'/%3E%3Cstop stop-color='%232f4c59' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill='url(%23a)' fill-rule='evenodd' d='M.005 121C311 121 409.898-.25 811 0c400 0 500 121 789 121v77H0s.005-48 .005-77z' transform='matrix(-1 0 0 1 1600 0)'/%3E%3C/svg%3E")
    repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
</style>

<template>
<v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable>
  <v-card>
    <v-toolbar flat dark color="primary">
      <v-toolbar-title>Buscar Banco</v-toolbar-title>
    </v-toolbar>
    <v-toolbar flat color="white">
      <v-text-field ref="input" flat filled rounded dense single-line hide-details clearable @keyup.enter="buscarDescricaoBanco" max-width="300px" prepend-inner-icon="search" label="Buscar Banco" @focus="onFocus"></v-text-field>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text style="height: 400px">
      <v-data-table hide-default-footer :headers="headers" :search="busca" :items="bancos" item-key="_id" :loading="carregando">
        <template #item="{ item}">
          <tr @click="selecionarBanco(item)" style="cursor: pointer" class="pt-1">
            <td>{{ item.codigo }}</td>
            <td>{{ item.descricao }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="fechar()" text>Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";
import axios from '@/util/axios';

export default {
    
    data(){
      return {
        headers: [
            { text: "Código", value: "codigo", sortable: false },
            { text: "Descrição", value: "descricao", sortable: false }
        ],
        busca: '',
        dialog: false,
        bancos: [],
        carregando: false
      }
    },

    computed: {

    },

    mounted () {
      this.$store.commit('banco/SET_BANCOS', []);
    },

    methods: {

      buscarDescricaoBanco(event){

        let descricao = event.target.value;
        if(descricao && descricao.length > 0){

          let empresa = this.$store.state.empresaAtual;
          axios.get(`/${empresa._id}/bancos/consultar?descricao=${descricao}`)
          .then(response => {
            this.bancos = response.data;
            this.carregando = false;
          })
          .catch(erro => {
            this.carregando = false;
            this.$store.commit('setMensagemErro', erro.response.data.motivo);
          });

          this.selecionarInput(event);
        }
        else{
          this.$store.commit('setMensagemErro', 'Busca Vazia');
        }
      },

      selecionarBanco(banco){
        this.$emit('bancoSelecionado', banco);
        this.fechar();
      },

      abrir() {
        this.dialog = true;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);
      },

      fechar(){
        this.dialog = false;
        this.$emit('fechar');
      },

      onFocus(e) {
        this.selecionarInput(e);
      },

      selecionarInput(e) {
        e.target.selectionStart = 0;
        e.target.selectionEnd   = e.target.value.length;
      }
    }
}
</script>
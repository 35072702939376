<template>
<v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable>
  <v-card>
    <v-toolbar flat dark color="primary">
      <v-toolbar-title>Buscar Fabricante</v-toolbar-title>
    </v-toolbar>
    <v-toolbar flat color="white">
      <v-text-field ref="input" flat filled rounded dense single-line hide-details clearable v-model="busca_local" @keyup.enter="buscarNomeFabricante(busca_local)" max-width="300px" prepend-inner-icon="search" label="Buscar Fabricante" @focus="onFocus"></v-text-field>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text style="height: 400px" class="pt-1">
      <v-data-table hide-default-footer :headers="headers" :items="fabricantes" item-key="_id" :loading="carregando" disable-pagination>
        <template #item="{ item }">
          <tr @click="selecionarFabricante(item)" style="cursor: pointer">
            <!-- <td>{{ item.codigo | formatarPadZero(5) }}</td> -->
            <td>{{ item.nome }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="fechar()" text>Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";
import formatador from '@/util/formatador';

export default {
    name: 'dialogBuscarFabricante',

    props: {
      busca: {
        type: [Number,String],
        default: null
      },
    },
    
    data(){
      return {
        busca_local: '',
        headers: [
            // { text: "Código", value: "codigo", sortable: false },
            { text: "Nome", value: "nome", sortable: false }
        ],
        dialog: false
      }
    },

    computed: {

      ...mapState('fabricante', {
        fabricantes: 'fabricantes',
        carregando: 'carregando'
      }),
    },

    mounted () {
      this.$store.commit('fabricante/setFabricantes', []);
    },

    watch: {
      busca (value) {
        this.busca_local = value;
      }
    },

    methods: {

      ...mapActions('fabricante', {
        buscarNomeFabricante: 'buscarNomeFabricante'
      }),

      selecionarFabricante(fabricante){
        this.$emit('fabricanteSelecionado', fabricante);
        this.fechar();
      },

      abrir() {
        this.dialog = true;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);

        if (this.busca) {
          this.buscarNomeFabricante(this.busca);
        }
      },

      fechar(){
        this.dialog = false;
        this.$emit('fechar');
      },

      onFocus(e) {
        this.selecionarInput(e);
      },

      selecionarInput(e) {
        e.target.selectionStart = 0;
        e.target.selectionEnd   = e.target.value.length;
      }
    }
}
</script>
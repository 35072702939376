import ListaCompras from "@/views/compras/ListaCompras";
import FormularioCompra from "@/views/compras/FormularioCompra";
import FormularioAcertoCompra from "@/views/compras/FormularioAcertoCompra";
import FormularioImportarXMLCompra from "@/views/compras/FormularioImportarXMLCompra";

export default [
  {
    path: "/movimentacoes/compras",
    name: "compras",
    component: ListaCompras,
    meta: { requiresAuth: true, nivel: 1 },
  },
  /* {
    path: "/movimentacoes/compras/nova",
    name: "formularioCompraNova",
    component: FormularioCompra,
    meta: { requiresAuth: true, nivel: 1 },
  }, */
  {
    path: "/movimentacoes/compras/editar",
    name: "formularioCompraEditar",
    // component: FormularioCompra,
    component: FormularioImportarXMLCompra,
    meta: { requiresAuth: true, nivel: 1 },
  },
  /* {
    path: "/movimentacoes/compras/acerto",
    name: "formularioCompraAcerto",
    component: FormularioAcertoCompra,
    meta: { requiresAuth: true, nivel: 1 },
  }, */
  {
    path: "/movimentacoes/compras/importar",
    name: "formularioCompraImportar",
    component: FormularioImportarXMLCompra,
    meta: { requiresAuth: true, nivel: 1 },
  },
];

import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual) {
    return axios.get(`/contasCorrentes?` + qs.stringify(options))
      .then(response => response.data);
  }

  function salvar(conta, empresaAtual) {
    return axios.post(`/contasCorrentes`, conta)
      .then(response => response.data);
  }

  function atualizar(conta, empresaAtual) {
    return axios.put(`/contasCorrentes/${conta._id}`, conta)
      .then(response => response.data);
  }

  function excluir(id, empresaAtual) {
    return axios.delete(`/contasCorrentes/${id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    salvar,
    atualizar,
    excluir
  }
import axios from '@/util/axios';
import qs from "qs";

  function listar() {
    return axios.get(`/cidades`)
      .then(response => response.data);
  }
  
  function exibirCidades(estado) {
    return axios.get(`/cidades/estado/${estado.uf}`)
      .then(response => response.data);
  }

  export default {
    listar,
    exibirCidades,
  }
import axios from '@/util/axios';
import qs from "qs";

function listar(options, empresa) {
  return axios.get(`/movimentoProducao`, { params: options })
    .then(response => response.data);
}

function salvar (novo_movimento, empresa) {
  return axios.post(`/movimentoProducao`, novo_movimento)
    .then(response => response.data);
}

function excluir (movimento_id, empresa) {
  return axios.delete(`/movimentoProducao/${movimento_id}`)
    .then(response => response.data);
}

export default {
  listar,
  salvar,
  // atualizar,
  excluir,
}
import ListaContasAPagar from '@/views/contasapagar/ListaContasAPagar';
import FormularioContaAPagar from '@/views/contasapagar/FormularioContaAPagar';
import FormularioBaixaContaAPagar from '@/views/contasapagar/FormularioBaixaContaAPagar';

export default [
    {
      path: '/movimentacoes/contasAPagar',
      name: 'contasAPagar',
      component: ListaContasAPagar,
      meta: { requiresAuth: true, nivel: 1}
    },
    {
      path: '/movimentacoes/contasAPagar/nova',
      name: 'formularioContaAPagarNova',
      component: FormularioContaAPagar,
      meta: { requiresAuth: true, nivel: 1}
    },
    {
      path: '/movimentacoes/contasAPagar/editar',
      name: 'formularioContaAPagarEditar',
      component: FormularioContaAPagar,
      meta: { requiresAuth: true, nivel: 1}
    },
    {
      path: '/movimentacoes/contasAPagar/baixar',
      name: 'formularioBaixarContaAPagar',
      component: FormularioBaixaContaAPagar,
      meta: { requiresAuth: true, nivel: 1}
    },
];
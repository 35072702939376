<template>
  <div>
    <v-dialog v-model="dialogo" max-width="800px" persistent scrollable eager>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Cálculo Fronteira</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="6">
              <v-row dense>
                <v-col cols="12">
                  <as-campo-valor label="Valor Produto" v-model="produtoAtual.subtotal" :decimais="2" ref="valor_custo" readonly @keydown.native.enter="$refs.aliquota_icms.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <as-campo-valor label="Alíq. Origem" v-model="produtoAtual.aliquota_icms" :decimais="2" ref="aliquota_icms" @blur="recalcularImpostos" suffix="%" @keydown.native.enter="$refs.valor_icms.focus()" v-show="produtoAtual.base_calculo_icms_st == 0" hide-details dense filled></as-campo-valor>
                </v-col>
                <v-col cols="8">
                  <as-campo-valor label="Valor ICMS" v-model="produtoAtual.valor_icms" :decimais="2" ref="valor_icms" readonly @keydown.native.enter="$refs.aliquota_ipi.focus()" v-show="produtoAtual.base_calculo_icms_st == 0" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <as-campo-valor label="Alíq. IPI" v-model="produtoAtual.aliquota_ipi" :decimais="2" ref="aliquota_ipi" @blur="recalcularImpostos" suffix="%" @keydown.native.enter="$refs.valor_ipi.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
                <v-col cols="8">
                  <as-campo-valor label="Valor IPI" v-model="produtoAtual.valor_ipi" :decimais="2" ref="valor_ipi" readonly @keydown.native.enter="$refs.frete.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <as-campo-valor label="Frete" v-model="produtoAtual.fronteira.frete" :decimais="2" ref="frete" readonly @keydown.native.enter="$refs.outras.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <as-campo-valor label="Outros" v-model="produtoAtual.fronteira.outras" :decimais="2" ref="outras" readonly @keydown.native.enter="$refs.subtotal.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <as-campo-valor label="Subtotal" v-model="produtoAtual.fronteira.subtotal" :decimais="2" ref="subtotal" readonly @keydown.native.enter="$refs.aliquota_interna.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" style="border-left: 1px solid lightgray" v-show="produtoAtual.base_calculo_icms_st == 0">
              <v-row dense>
                <v-col cols="4">
                  <as-campo-valor label="Alíq. Interna %" v-model="produtoAtual.fronteira.aliquota_interna" :decimais="2" ref="aliquota_interna" suffix="%" @keydown.native.enter="$refs.valor_icms_interno.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
                <v-col cols="8">
                  <as-campo-valor label="Alíq. Interna Valor" v-model="produtoAtual.fronteira.valor_icms_interno" :decimais="2" ref="valor_icms_interno" readonly @keydown.native.enter="$refs.quantidade.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <as-campo-valor label="Quantidade" v-model="produtoAtual.quantidade" :decimais="2" ref="quantidade" readonly @keydown.native.enter="$refs.valor_icms_fronteira.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <as-campo-valor label="Valor ICMS" v-model="produtoAtual.fronteira.valor_icms" :decimais="2" ref="valor_icms_fronteira" readonly @keydown.native.enter="$refs.custo_produto.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <as-campo-valor label="Custo Produto" v-model="produtoAtual.fronteira.custo_produto" :decimais="2" ref="custo_produto" readonly @keydown.native.enter="$refs.margem_lucro_percentual.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <as-campo-valor label="Margem Lucro %" v-model="produtoAtual.fronteira.margem_lucro_percentual" :decimais="2" ref="margem_lucro_percentual" suffix="%" @keydown.native.enter="$refs.margem_lucro_valor.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
                <v-col cols="8">
                  <as-campo-valor label="Margem Lucro Valor" v-model="produtoAtual.fronteira.margem_lucro_valor" :decimais="2" ref="margem_lucro_valor" readonly @keydown.native.enter="$refs.das_percentual.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <as-campo-valor label="DAS %" v-model="produtoAtual.fronteira.das_percentual" :decimais="2" ref="das_percentual" suffix="%" @keydown.native.enter="$refs.das_valor.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
                <v-col cols="8">
                  <as-campo-valor label="DAS Valor" v-model="produtoAtual.fronteira.das_valor" :decimais="2" ref="das_valor" readonly @keydown.native.enter="$refs.valor_venda.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" style="border-left: 1px solid lightgray" v-show="produtoAtual.base_calculo_icms_st > 0">
              <v-row dense>
                <v-col cols="4">
                  <as-campo-valor label="MVA %" v-model="produtoAtual.fronteira.mva_percentual" :decimais="2" ref="mva_percentual" suffix="%" @keydown.native.enter="$refs.mva_valor.focus()" @blur="recalcularImpostos" hide-details dense filled></as-campo-valor>
                </v-col>
                <v-col cols="8">
                  <as-campo-valor label="MVA Valor" v-model="produtoAtual.fronteira.mva_valor" :decimais="2" ref="mva_valor" readonly @keydown.native.enter="$refs.base_calculo_icms_st.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <as-campo-valor label="Base Cálculo ST" v-model="produtoAtual.base_calculo_icms_st" :decimais="2" ref="base_calculo_icms_st" @keydown.native.enter="$refs.aliquota_interna.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <as-campo-valor label="Alíquota Interna %" v-model="produtoAtual.fronteira.aliquota_interna" :decimais="2" ref="aliquota_interna" suffix="%" @keydown.native.enter="$refs.valor_icms_interno.focus()" @blur="recalcularImpostos" hide-details dense filled></as-campo-valor>
                </v-col>
                <v-col cols="8">
                  <as-campo-valor label="Alíquota Interna Valor" v-model="produtoAtual.fronteira.valor_icms_interno" :decimais="2" ref="valor_icms_interno" readonly @keydown.native.enter="$refs.aliquota_icms.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <as-campo-valor label="Alíquota Origem %" v-model="produtoAtual.aliquota_icms" :decimais="2" ref="aliquota_icms" suffix="%" @keydown.native.enter="$refs.valor_icms.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
                <v-col cols="8">
                  <as-campo-valor label="Alíquota Origem Valor" v-model="produtoAtual.valor_icms" :decimais="2" ref="valor_icms" readonly @keydown.native.enter="$refs.quantidade.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <as-campo-valor label="Quantidade" v-model="produtoAtual.quantidade" :decimais="2" ref="quantidade" @keydown.native.enter="$refs.valor_icms_st.focus()" hide-details dense filled readonly></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <as-campo-valor label="Valor ICMS ST" v-model="produtoAtual.fronteira.valor_icms_st" :decimais="2" ref="valor_icms_st" @keydown.native.enter="$refs.custo_produto_st.focus()" hide-details dense filled readonly></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <as-campo-valor label="Custo Produto ST" v-model="produtoAtual.fronteira.custo_produto_st" :decimais="2" ref="custo_produto_st" @keydown.native.enter="$refs.margem_lucro_percentual.focus()" hide-details dense filled readonly></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <as-campo-valor label="Margem Lucro %" v-model="produtoAtual.fronteira.margem_lucro_percentual" :decimais="2" ref="margem_lucro_percentual" suffix="%" @keydown.native.enter="$refs.margem_lucro_valor.focus()" @blur="recalcularImpostos" hide-details dense filled></as-campo-valor>
                </v-col>
                <v-col cols="8">
                  <as-campo-valor label="Margem Lucro Valor" v-model="produtoAtual.fronteira.margem_lucro_valor" :decimais="2" ref="margem_lucro_valor" readonly @keydown.native.enter="$refs.das_percentual.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <as-campo-valor label="DAS %" v-model="produtoAtual.fronteira.das_percentual" :decimais="2" ref="das_percentual" suffix="%" @keydown.native.enter="$refs.das_valor.focus()" @blur="recalcularImpostos" hide-details dense filled></as-campo-valor>
                </v-col>
                <v-col cols="8">
                  <as-campo-valor label="DAS Valor" v-model="produtoAtual.fronteira.das_valor" :decimais="2" ref="das_valor" readonly @keydown.native.enter="$refs.valor_venda.focus()" hide-details dense filled></as-campo-valor>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense justify="end">
            <v-col cols="6" style="border-left: 1px solid lightgray">
              <as-campo-valor label="Valor Venda" v-model="produtoAtual.fronteira.valor_venda" :decimais="2" ref="valor_venda" hide-details dense filled></as-campo-valor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="fecharDialogo">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";

export default {
  name: 'DialogoFronteira',
  data () {
    return {
      dialogo: false,
    }
  },
  methods: {
    abrir () {
      this.dialogo = true;
      setTimeout(() => {
        this.$refs.valor_custo.focus();
      }, 500);
    },
    fecharDialogo () {
      this.dialogo = false;
    },
    recalcularImpostos() {
      this.$store.commit('compra/recalcularImpostos');
    }
  },
  computed: {
    ...mapState('compra', {
      compra: 'compraAtual',
      carregando: 'carregando',
      produtoAtual: 'produtoAtual'
    }),
}
}
</script>

<style>
  
</style>
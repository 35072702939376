<template>
  <div>
    <v-text-field :loading="loading" label="Buscar NCM" :value="value" append-icon="search" @keyup.enter="consultarCodigoNcm(value)" @input="onInput" @click:append="abrirDialogo" data-cy="campo-ncm" maxlength="8"></v-text-field>

    <dialog-buscar-ncm ref="dialogoBuscarNCM" @ncmSelecionado="adicionarNcmDialog" :busca="value"></dialog-buscar-ncm>
  </div>
</template>

<script>
  
  import axios from "@/util/axios";
  import DialogBuscarNcm from '@/components/dialogs/DialogBuscarNcm';
  
  export default {
    name: 'BuscarNcm',
    props: {
      value: {
        type: [Number,String],
        default: null
      },
    },

    components: {
      DialogBuscarNcm,
    },

    data() {
      return {
        loading: false
      }
    },

    methods: {
      onInput(value) {
        this.$emit('input', value);
      },

      abrirDialogo() {
        this.$refs.dialogoBuscarNCM.abrir();
      },

      consultarCodigoNcm(ncm){

        this.loading = true;
        if (ncm && ncm.length === 8) {

          axios.get(`/ibpt/filtro/${ncm}`)
          .then(response => {
            if(response.data.length > 0){
              this.$emit('ncmSelecionado', response.data[0]);
            }
            else{
              this.$store.commit('setMensagemErro', 'NCM não encontrado');
            }
            this.loading = false;
          })
          .catch((erro) => {
            this.loading = false;
            this.$store.commit('setMensagemErro', erro.response.data.motivo);
          });
        } else {
          this.$refs.dialogoBuscarNCM.abrir();
          this.loading = false;
        }
      },

      adicionarNcmDialog(ncm) {
        this.$emit('ncmSelecionado', ncm);
      }, 
    }
  }
</script>

<style scoped>

</style>
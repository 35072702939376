import Vue from "vue";

import formatador from "@/util/formatador";
import conversor from "@/util/conversor";


Vue.filter("formatarPlaca", formatador.formatarPlaca);
Vue.filter("formatarPadZero", formatador.formatarPadZero);
Vue.filter("formatarValor", formatador.formatarValor);
Vue.filter("formatarMoeda", formatador.formatarMoeda);
Vue.filter("formatarPercentual", formatador.formatarPercentual);
Vue.filter("formatarCnpj", formatador.formatarCnpj);
Vue.filter("formatarCep", formatador.formatarCep);
Vue.filter("formatarTelefone", formatador.formatarTelefone);
Vue.filter("formatarModeloFrete", formatador.formatarModeloFrete);
Vue.filter("formatarChave", formatador.formatarChave);
Vue.filter("formatarData", formatador.formatarData);
Vue.filter("formatarHora", formatador.formatarHora);
Vue.filter("formatarDataHora", formatador.formatarDataHora);
Vue.filter("formatarPlanoConta", formatador.formatarPlanoConta);

Vue.filter("tempoDecorrido", conversor.tempoDecorrido);
Vue.filter("stringParaNumero", conversor.stringParaNumero);
Vue.filter("ufParaCodigo", conversor.ufParaCodigo);
Vue.filter("codigoParaUf", conversor.codigoParaUf);
Vue.filter("codigoParaModeloFrete", conversor.codigoParaModeloFrete);
Vue.filter("codigoParaAmbiente", conversor.codigoParaAmbiente);
Vue.filter("codigoParaTipoOperacao", conversor.codigoParaTipoOperacao);
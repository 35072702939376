import axios from '@/util/axios';
import constantes from '@/util/constantes';
import moment from 'moment';
import conversor from '@/util/conversor';

export default{
    
    namespaced: true,

    //equivalente ao data
    state: {

        vendas: [],
        vendaAtual: {
            data: moment().format('YYYY-MM-DD'),
            cliente: {
                razao_nome: ''
            },
            desconto: '',
            total: '',
            itens: []
        },
        produtoAtual: {
            codigo: '',
            descricao: '',
            valor: '',
            desconto: 0,
            quantidade: 1,
            subtotal: ''
        },
        clientes: [],
        constantes: constantes,
        modoEdicao: false,
        carregando: false
    },

    //equivalente ao computed properties
    getters: {

        estaEditando(state){
            return state.modoEdicao;
        },
        textoBtnConfirmar(state){
            if(state.modoEdicao){
                return state.constantes.BOTAO_SALVAR_EDICAO;
            }
            else{
                return state.constantes.BOTAO_SALVAR_NOVO;
            }
        },
        clientesBusca(state) {
            let clienteFiltro = [];
            state.clientes.forEach(cliente => {
                clienteFiltro.push({ text: cliente.razao_nome, value: cliente._id });
            });
            return clienteFiltro;
        },
        dataFormatada(state){
            if(state.vendaAtual.data != ''){
              
              let data = moment(state.vendaAtual.data, 'YYYY-MM-DD').format('DD/MM/YY');
              return data;
            }
            else{
              return '';
            }
        },
        totalProdutos(state){
            if(state.vendaAtual.itens && state.vendaAtual.itens.length > 0){
                return state.vendaAtual.itens.reduce((total, item) => total + (item.valor * item.quantidade), 0);
            }
            else{
                return 0;
            }
        },
        totalDescontos(state){
            if(state.vendaAtual.itens && state.vendaAtual.itens.length > 0){
                return state.vendaAtual.itens.reduce((total, item) => total + item.desconto, 0);
            }
            else{
                return 0;
            }
        },
        totalLiquido(state){
            if(state.vendaAtual.itens && state.vendaAtual.itens.length > 0){
                return state.vendaAtual.itens.reduce((total, item) => total + item.subtotal, 0);
            }
            else{
                return 0;
            }
        }
    },

    //equivalente aos methods
    //responsável por chamar a api externa
    actions: {
        
        carregar({commit, rootState}){

            commit('setCarregando', true);
            axios.get('/vendas')
            .then((response) => {
                commit('setVendas', response.data);
            })
            .catch((erro) => {
                commit('setVendas', []);
                commit('setCarregando', false);
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });  
        },
        consultarClientes({commit, rootState}, filtro){

            commit('setCarregando', true);
            axios.get('/participantes/clientes/filtro/' + filtro)
            .then((response) => {
                commit('setClientes', response.data);
            })
            .catch((erro) => {
                commit('setClientes', []);
                commit('setCarregando', false);
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        },
        buscarCodigoProduto({commit, rootState}, codigo){

            commit('setCarregando', true);
            axios.get('/produtos/codigos/' + codigo)
            .then((response) => {
                let retorno = response.data;
                if(retorno.status){
                    commit('setMensagemErro', retorno.motivo, {root: true});
                    commit('setCarregando', false);
                }
                else{
                    commit('setProduto', response.data);
                }
            })
            .catch((erro) => {
                commit('setProduto', {});
                commit('setCarregando', false);
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        },
        salvar({commit, state, rootState}, dados){
            
            commit('setCarregando', true);

            if(state.modoEdicao){

                let venda = Object.assign({}, state.vendaAtual);
                venda.cliente = venda.cliente._id;

                let sequencia = 1;
                let totalDesconto = 0;
                let totalVenda = 0;
                venda.itens.forEach(item => {
                    item.sequencia = sequencia;

                    totalDesconto += item.desconto;
                    totalVenda += item.subtotal;
                    sequencia++;
                });

                venda.desconto = totalDesconto;
                venda.total = totalVenda;

                axios.put('/vendas', venda)
                .then((response) => {
                    let retorno = response.data;
                    if(retorno.status){
                        commit('setMensagemErro', retorno.motivo, {root: true});
                        commit('setCarregando', false);
                    }
                    else{
                        commit('atualizar', response.data);
                        commit('estadoInicial', dados.router);
                        commit('setMensagemSucesso', 'Venda Atualizada com Sucesso', {root: true});
                    }
                })
                .catch((erro) => {
                    commit('setMensagemErro', erro.response.data.motivo, {root: true});
                });
            }
            else{

                let venda = Object.assign({}, state.vendaAtual);
                venda.cliente = venda.cliente._id;

                let sequencia = 1;
                let totalDesconto = 0;
                let totalVenda = 0;
                venda.itens.forEach(item => {
                    item.produto = item._id;
                    delete(item._id);
                    item.sequencia = sequencia;

                    totalDesconto += item.desconto;
                    totalVenda += item.subtotal;
                    sequencia++;
                });

                venda.desconto = totalDesconto;
                venda.total = totalVenda;
                
                delete(venda._id);
                axios.post('/vendas', venda)
                .then((response) => {
                    let retorno = response.data;
                    if(retorno.status){
                        commit('setMensagemErro', retorno.motivo, {root: true});
                        commit('setCarregando', false);
                    }
                    else{
                        commit('adicionar', response.data);
                        commit('estadoInicial', dados.router);
                        commit('setMensagemSucesso', 'Venda Cadastrada com Sucesso', {root: true});
                    }
                })
                .catch((erro) => {
                    commit('setMensagemErro', erro.response.data.motivo, {root: true});
                });
            }
        },
        acertarVenda({commit, state, rootState}, dados){

            commit('setCarregando', true);

            let envio = {
                pagamentos: dados.pagamentos
            }

            axios.post('/vendas/' + state.vendaAtual._id + '/acertar', envio)
            .then((response) => {
                let retorno = response.data;
                if(retorno.status){
                    commit('setMensagemErro', retorno.motivo, {root: true});
                    commit('setCarregando', false);
                }
                else{

                    commit('atualizar', retorno);
                    commit('estadoInicial', dados.router);
                    commit('setMensagemSucesso', 'Venda Acertada com Sucesso', {root: true});
                }
            })
            .catch((erro) => {
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        },
        editar({commit}, dados){
            commit('setCarregando', true);
            dados.router.push({ name: "formularioVendaEditar" });
            commit('editar', dados.venda);
        },
        novo({commit}, dados){
            commit('setCarregando', true);
            dados.router.push({ name: "formularioVendaNova" });
            commit('novo');
        },
        acertar({commit}, dados){
            commit('setCarregando', true);
            dados.router.push({ name: "formularioVendaAcerto" });
            commit('acerto', dados.venda);
        },
        remover({commit, rootState}, remover){
            commit('setCarregando', true);
            axios.delete('/vendas', {
                data: { _id: remover._id }
            })
            .then((response) => {
                commit('remover', remover);
                commit('setMensagemSucesso', 'Venda Removida com Sucesso', {root: true});
            })
            .catch((erro) => {
                commit('setCarregando', false);
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        },
        cancelar({commit}, dados){
            commit('estadoInicial', dados.router);
        }
    },

    //responsável por alterar o state.
    mutations: {

        setVendas(state, vendas){
            state.vendas = vendas;
            state.carregando = false;
        },
        setClientes(state, clientes){
            state.clientes = clientes;
            state.carregando = false;
        },
        setCliente(state, idCliente){

            let clienteEncontrado = state.clientes.find(cliente => cliente._id == idCliente);
            if(clienteEncontrado){
                state.vendaAtual.cliente = clienteEncontrado;
            }
        },
        setProduto(state, produto){

            state.produtoAtual = {
                ...produto,
                quantidade: 1,
                desconto: 0,
                subtotal: produto.valor
            }
            state.carregando = false;
        },
        calcularSubtotal(state){
      
            let produto = state.produtoAtual;
            if(produto.quantidade != ''){
              let qtd = conversor.stringParaNumero(produto.quantidade);
              let desconto = conversor.stringParaNumero(produto.desconto);
              produto.subtotal = (produto.valor * qtd) - desconto;
            }
            else{
              produto.subtotal = 0;
            }
        },
        adicionarItem(state){

            let novoProduto = Object.assign({}, state.produtoAtual);
            novoProduto.quantidade = conversor.stringParaNumero(novoProduto.quantidade);
            novoProduto.desconto = conversor.stringParaNumero(novoProduto.desconto);

            let existeProduto = state.vendaAtual.itens.find(item => item.codigo == state.produtoAtual.codigo);
            if(existeProduto){
                
                existeProduto.quantidade += novoProduto.quantidade;
                existeProduto.desconto += novoProduto.desconto;
                existeProduto.subtotal = (existeProduto.quantidade * existeProduto.valor) - existeProduto.desconto;
            }
            else{
                state.vendaAtual.itens.push(novoProduto);
            }

            state.produtoAtual = {
                codigo: '',
                descricao: '',
                valor: '',
                desconto: 0,
                quantidade: 1,
                subtotal: ''
            }
        },
        removerItem(state, index){

            state.vendaAtual.itens.splice(index, 1);
        },
        setCarregando(state, valor){
            state.carregando = valor;
        },
        remover(state, removido){
            state.vendas = state.vendas.filter(venda => venda._id !== removido._id);
            state.carregando = false;
        },
        novo(state){
            state.modoEdicao = false;
            state.vendaAtual = {
                data: moment().format('YYYY-MM-DD'),
                cliente: {
                    razao_nome: ''
                },
                desconto: '',
                total: '',
                itens: []
            }
            state.carregando = false;
        },
        acerto(state, venda){
            state.modoEdicao = false;
            state.vendaAtual = venda;
            state.carregando = false;
        },
        editar(state, editar){
            state.modoEdicao = true;
            state.vendaAtual = editar;
            state.carregando = false;
        },
        adicionar(state, venda){
            state.vendas.push(venda);
            state.carregando = false;
        },
        atualizar(state, atual){
            state.vendas.forEach(venda => {
                if(venda._id === atual._id){
                    venda = atual;
                }
            });
            state.carregando = false;
        },
        estadoInicial(state, router){
            state.vendaAtual = {
                data: moment().format('YYYY-MM-DD'),
                cliente: {
                    razao_nome: ''
                },
                desconto: '',
                total: '',
                itens: []
            };
            state.produtoAtual = {
                codigo: '',
                descricao: '',
                valor: '',
                desconto: 0,
                quantidade: 1,
                subtotal: ''
            };
            router.push({ name: 'vendas'});
            state.carregando = false;
        }
    }
}
import axios from '@/util/axios';
import constantes from '@/util/constantes';

export default{
    
  namespaced: true,

  state: {

    usuarios: [],
    usuarioAtual: {
      nome: '',
      email: '',
      senha: ''
    },
    empresas: [],
    constantes: constantes,
    modoEdicao: false,
    carregando: false
  },

  getters: {

    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  actions: {

    carregar({commit, rootState}){

      commit('setCarregando', true);
      axios.get(`/usuarios`)
      .then((response) => {
        commit('setUsuarios', response.data);
      })
      .catch((erro) => {
        commit('setUsuarios', []);
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });  
    },
    async carregarEmpresas({commit}){

      try{

        commit('setCarregando', true);
        let response = await axios.get('/empresas');
        let result = response.data;
        commit('setEmpresas', result);
      }
      catch(erro){
        commit('setEmpresas', []);
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    },
    
    async salvar({commit, state, rootState}, empresas){
        
      commit('setCarregando', true);

      let usuario = Object.assign({}, state.usuarioAtual);
      usuario.empresas = empresas.map(empresa => { return { empresa: empresa._id, nivel_acesso: empresa.nivel_acesso } });

      if(state.modoEdicao){

        try{
          let response = await axios.put(`/usuarios`, usuario);
          let resultado = response.data;

          commit('setMensagemSucesso', 'Usuário Atualizado com Sucesso', {root: true});
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
      else{

        try{

          delete(usuario._id);
          let response = await axios.post(`/usuarios`, usuario);

          commit('setMensagemSucesso', 'Usuário Cadastrado com Sucesso', {root: true});
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
    },
    remover({commit, rootState}, remover){
      commit('setCarregando', true);
      axios.delete(`/usuarios`, {
        data: { _id: remover._id }
      })
      .then((response) => {
        commit('remover', remover);
        commit('setMensagemSucesso', 'Usuário Removido com Sucesso', {root: true});
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    }
  },

  mutations: {

    setUsuarios(state, usuarios){
      state.usuarios = usuarios;
      state.carregando = false;
    },
    setEmpresas(state, empresas){

      let empresasRetornadas = [];
      if(state.modoEdicao){
          
        empresas.forEach(empresa => {

          let empresaUsuario = null;
          state.usuarioAtual.empresas.forEach(emp => {
            if(emp.empresa == empresa._id){
              empresaUsuario = emp;
            }
          });

          let nova = null;
          if(empresaUsuario){
            nova = {
              _id: empresa._id,
              cnpj: empresa.cnpj,
              fantasia: empresa.fantasia,
              plano: empresa.plano,
              nivel_acesso: empresaUsuario.nivel_acesso
            }
          }
          else{
            nova = {
              _id: empresa._id,
              cnpj: empresa.cnpj,
              fantasia: empresa.fantasia,
              plano: empresa.plano,
              nivel_acesso: ''
            }
          }

          empresasRetornadas.push(nova);
        });
      }
      else{
        empresas.forEach(empresa => {
          let nova = {
            _id: empresa._id,
            cnpj: empresa.cnpj,
            fantasia: empresa.fantasia,
            plano: empresa.plano,
            nivel_acesso: ''
          }
          empresasRetornadas.push(nova);
        });
      }

      state.empresas = empresasRetornadas;
      state.carregando = false;
    },
    setCarregando(state, valor){
      state.carregando = valor;
    },
    remover(state, removido){
      state.usuarios = state.usuarios.filter(usuario => usuario._id !== removido._id);
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.usuarioAtual = {
        nome: '',
        email: '',
        senha: ''
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.modoEdicao = true;
      delete(editar.senha);
      state.usuarioAtual = editar;
      state.carregando = false;
    },
    adicionar(state, usuario){
      state.usuarios.push(usuario);
      state.carregando = false;
    },
    atualizar(state, atual){
      state.usuarios.forEach(usuario => {
        if(usuario._id === atual._id){
          usuario = atual;
        }
      });
      state.carregando = false;
    },
    estadoInicial(state){
      state.usuarioAtual = {
        nome: '',
        email: '',
        senha: ''
      }
      state.carregando = false;
    }
  }
}
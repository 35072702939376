<template>
  <div>
    <v-text-field :loading="loading" label="Buscar CFOP" ref="codigoCFOP" :value="value" append-icon="search" @keyup.enter="consultarCodigoCFOP($event)" @input="onInput" @click:append="abrirDialogo" data-cy="campo-cfop" maxlength="8"></v-text-field>

    <dialog-buscar-cfop ref="dialogoBuscarCFOP" @cfopSelecionado="adicionarCFOPDialog" :busca="value" />
  </div>
</template>

<script>
  import axios from "@/util/axios";
  import DialogBuscarCfop from '@/components/dialogs/DialogBuscarCfop';
  
  export default {
    name: 'BuscarCFOP',
    props: {
      value: {
        type: [Number,String],
        default: null
      },
    },

    components: {
      DialogBuscarCfop,
    },

    data() {
      return {
        loading: false
      }
    },

    methods: {
      focus() {
        this.$refs.codigoCFOP.focus();
      },

      onInput(value) {
        this.$emit('input', value);
      },

      abrirDialogo() {
        this.$refs.dialogoBuscarCFOP.abrir();
      },

      consultarCodigoCFOP(event){
        this.loading = true;
        let cfop = event.target.value;
        if (cfop && cfop.length === 8) {
          axios.get('/cfop/' + cfop)
            .then(response => {
              if(response.data.length > 0){
                this.$emit('cfopSelecionado', response.data[0]);
              } else{
                this.$store.commit('setMensagemErro', 'CFOP não encontrado');
              }
              this.loading = false;
          })
          .catch((erro) => {
            this.loading = false;
            this.$store.commit('setMensagemErro', erro.response.data.motivo);
          });
        } else {
          this.$refs.dialogoBuscarCFOP.abrir();
          this.loading = false;
        }
      },

      adicionarCFOPDialog(cfop) {
        this.$emit('cfopSelecionado', cfop);
      }, 
    }
  }
</script>

<style scoped>

</style>
import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual) {
    return axios.get(`/grupos?` + qs.stringify(options))
      .then(response => response.data);
  }

  function salvar(grupo, empresaAtual) {
    return axios.post(`/grupos`, grupo)
      .then(response => response.data);
  }

  function atualizar(grupo, empresaAtual) {
    return axios.put(`/grupos/${grupo._id}`, grupo)
      .then(response => response.data);
  }

  function excluir(id, empresaAtual) {
    return axios.delete(`/grupos/${id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    salvar,
    atualizar,
    excluir
  }
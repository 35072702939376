const Menu = [
  {
    titulo: "Painel",
    grupo: "Início",
    icone: "dashboard",
    nome: "Dashboard",
    path: "/home",
    nivel: 1,
  },
  { cabecalho: "Cadastros", nivel: 1 },
  { divider: true, nivel: 1 },
  { nome: "Clientes / Fornecedores", titulo: "Clientes / Fornecedores", path: "/cadastros/participantes", icone: "mdi-account-multiple", grupo: "Cadastros", nivel: 1 },
  { nome: "Produto", titulo: "Produtos", path: "/cadastros/produtos", icone: "fas fa-tags", iconeSize: 18, grupo: "Cadastros", nivel: 1 },
  { nome: "Estoque", titulo: "Estoque", path: "/movimentacoes/estoques", icone: "fas fa-boxes", iconeSize: 22, grupo: "Cadastros", nivel: 1 },
  { nome: "Transportador", titulo: "Transportadores", path: "/cadastros/transportadores", icone: "local_shipping", grupo: "Cadastros", nivel: 1 },
  { nome: "Filiais", titulo: "Filiais", path: "/filiais", icone: "store", grupo: "Cadastros", nivel: 1 },
  { cabecalho: "Fiscal", nivel: 1 },
  { divider: true, nivel: 1 },
  {
    titulo: "Notas Fiscais",
    grupo: "Fiscal",
    nome: "Nota",
    icone: "description",
    path: "/notas",
    nivel: 1,
  },
  {
    titulo: "Manifesto de Documentos",
    grupo: "Fiscal",
    nome: "Manifestos",
    icone: "far fa-file",
    iconeSize: 22,
    iconeClass: "ml-1",
    path: "/manifestos",
    nivel: 1,
  },
  {
    titulo: "Notas Destinadas",
    grupo: "Fiscal",
    nome: "NotasDestinadas",
    icone: "archive",
    path: "/destinadas",
    nivel: 1,
  },
  {
    titulo: "Arquivos Contabilidade",
    grupo: "Fiscal",
    nome: "Arquivos",
    icone: "fas fa-file-archive",
    iconeSize: 22,
    iconeClass: "ml-1",
    path: "/arquivos",
    nivel: 2,
  },
  {
    titulo: "Emitente",
    grupo: "Emitente",
    nome: "Emitente",
    icone: "how_to_reg",
    path: "/emitente",
    nivel: 2,
  },
  { divider: true, nivel: 1 },
  {
    titulo: "Configurações",
    grupo: "configuracoes",
    nome: "Configuração",
    icone: "mdi-cog",
    nivel: 2,
    items: [
      { nome: "Certificado Digital", titulo: "Certificado Digital", path: "/configuracoes/certificado_digital", icone: "vpn_key", grupo: "configuracao", nivel: 2 },
      { nome: "Usuários", titulo: "Usuários", path: "/configuracoes/usuarios", icone: "mdi-account", grupo: "configuracao", nivel: 2 },
      { nome: "Dispositívos", titulo: "Dispositívos", path: "/configuracoes/dispositivos", icone: "mdi-devices", grupo: "configuracao", nivel: 2 },
    ],
  },
];

export default Menu;

import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual) {
    return axios.get(`/centrosCustos?` + qs.stringify(options))
      .then(response => response.data);
  }

  function salvar(centroCusto, empresaAtual) {
    return axios.post(`/centrosCustos`, centroCusto)
      .then(response => response.data);
  }

  function atualizar(centroCusto, empresaAtual) {
    return axios.put(`/centrosCustos/${centroCusto._id}`, centroCusto)
      .then(response => response.data);
  }

  function excluir(id, empresaAtual) {
    return axios.delete(`/centrosCustos/${id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    salvar,
    atualizar,
    excluir
  }
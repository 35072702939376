<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formContaCorrente" >
      <v-container fluid>
        <v-widget title="Informações da Conta Corrente">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field label="Número" ref="numero" v-model="conta.numero" :rules="[rules.obrigatorio]" @keypress.enter="$refs.nome.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Nome" ref="nome" v-model="conta.nome" :rules="[rules.obrigatorio]" required @keypress.enter="$refs.agencia.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field label="Agência" ref="agencia" v-model="conta.agencia" :rules="[rules.obrigatorio]" @keypress.enter="$refs.codigo_banco.focus()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <buscar-banco ref="codigo_banco" v-model="codigoBanco" @bancoSelecionado="bancoSelecionado"></buscar-banco>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field label="Banco" v-model="conta.banco.descricao" :rules="[rules.obrigatorio]"></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:widget-footer-action>
            <v-btn text @click="cancelar">{{constantes.BOTAO_CANCELAR}}</v-btn>
            <v-btn color="primary" :loading="carregando" @click="salvar">Salvar</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>
  </div>
</template>

<script>

import {mapState, mapGetters, mapActions} from "vuex";
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: "FormularioContaCorrente",
  data: () => ({
    valid: false,
    constantes: constantes,
    rules: {...regras},
    codigoBanco: '',
    loading: false
  }),

  computed: {
    ...mapState('contaCorrente', {
      conta: 'contaAtual',
      carregando: 'carregando'
    }),
    
    ...mapGetters('contaCorrente', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar'
    })
  },

  methods: {
    async salvar() {
      if (this.$refs.formContaCorrente.validate()) {
        try{
          if (this.conta._id) {
            await this.$store.dispatch('contaCorrente/atualizar', this.conta);
          } else {
            await this.$store.dispatch('contaCorrente/salvar', this.conta);
          }
          this.$router.push({ name: 'contas' });
        }
        catch(erro){
          console.log('Houve um erro');
        }
      }
    },

    cancelar() {
      this.$store.commit('contaCorrente/estadoInicial');
      this.$router.push({name: 'contas'});
    },

    bancoSelecionado(banco){
      this.codigoBanco = banco.codigo;
      this.$store.commit('contaCorrente/setBanco', banco);
      this.salvar();
    }
  },

  async created(){
    try{
      this.loading = true;
      let conta = this.$store.state.contaCorrente.contaAtual;
      let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
  
      if(estaEditando && (!conta._id || conta._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione uma conta para editar');
        this.$router.push({ name: 'contas' });
      }
      if(!estaEditando){
        this.$store.commit('contaCorrente/estadoInicial');
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>
import axios from '@/util/axios';
import constantes from '@/util/constantes';
import coresApi from "@/services/coresApi";

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {
    cores: [],
    corAtual: {
      codigo: '',
      nome: ''
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalCores: 0
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await coresApi.listar(options, rootState.empresaAtual);
        commit('SET_CORES', response);
        commit('SET_TOTAL_CORES', response.total);
      } catch (error) {
        console.log(error)
        commit('SET_CORES', []);
        commit('setMensagemErro', 'Não foi possível listar as cores', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({commit, state, rootState}){
      commit('SET_CARREGANDO', true);

      try{
        let cor = Object.assign({}, state.corAtual);
        let response = await coresApi.salvar(cor, rootState.empresaAtual);
        commit('ATUALIZAR_COR', response);
        commit('setMensagemSucesso', 'Cor salva com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    },
    async atualizar({commit, rootState}, cor) {
      await coresApi.atualizar(cor, rootState.empresaAtual)
        .then((response) => {
          commit('ATUALIZAR_COR', cor);
          commit('setMensagemSucesso', 'Cor atualizada com sucesso', {root: true});
        })
        .catch((erro) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
        });
    },
    async excluir({commit, rootState}, cor){
      commit('SET_CARREGANDO', true);
      await coresApi.excluir(cor._id, rootState.empresaAtual)
        .then((response) => {
          commit('EXCLUIR_COR', cor._id);
          commit('setMensagemSucesso', 'Cor removida com sucesso', {root: true});
        })
        .catch((erro) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
        });
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_CORES(state, cores) {
      state.cores = cores;
      state.carregando = false;
    },
    SET_TOTAL_CORES(state, total) {
      state.totalCores = total;
    },
    SET_COR(state, corAtual) {;
      state.corAtual = corAtual
    },
    EXCLUIR_COR(state, id) {
      let index = state.cores.findIndex(cor => cor._id == id);
      if (index > -1) {
        state.cores.splice(index, 1);
      }
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.corAtual = {
        codigo: '',
        nome: ''
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.modoEdicao = true;
      state.corAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_COR(state, cor) {
      state.cores.unshift(cor);
      state.carregando = false;
    },
    ATUALIZAR_COR(state, cor) {
      let cor_encontrada = state.cores.find(cor_ => cor_.id == cor.id);
      if (cor_encontrada) {
        cor_encontrada = cor;
      }
      state.carregando = false;
    },
    estadoInicial(state){
      state.corAtual = {
        codigo: '',
        nome: ''
      }
      state.carregando = false;
    }
  }
}
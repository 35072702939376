const Menu =  [
  { cabecalho: 'Início', nivel: 1 },
  {
    titulo: 'Dashboard',
    grupo: 'Início',
    icone: 'dashboard',
    nome: 'Dashboard',
    path: '/home',
    nivel: 1
  },
  { cabecalho: 'Gerenciar', nivel: 3 },
  {
    titulo: 'Empresas',
    grupo: 'Cadastros',
    icone: 'domain',
    nome: 'Empresa',
    path: '/empresasAdmin',
    nivel: 3
  },
  { cabecalho: 'Gerencial', nivel: 1 },
  {
    titulo: 'Cadastros',
    grupo: 'cadastros',
    nome: 'Gerencial',
    icone: 'store',
    items: [
      { nome: 'Clientes/Fornecedores', titulo: 'Clientes/Fornecedores', path: '/cadastros/participantes', icone: 'people_outline', grupo: 'Cadastros', nivel: 1 },
      { nome: 'Produto', titulo: 'Produtos', path: '/cadastros/produtos', icone: 'kitchen', grupo: 'Cadastros', nivel: 1 },
      { nome: 'Transportador', titulo: 'Transportadores', path: '/cadastros/transportadores', icone: 'event_seat', grupo: 'Cadastros', nivel: 1 },
      { nome: 'Grupo', titulo: 'Grupos', path: '/cadastros/grupos', icone: 'group_work', grupo: 'Cadastros', nivel: 1 },
      { nome: 'Fabricante', titulo: 'Fabricantes', path: '/cadastros/fabricantes', icone: 'gavel', grupo: 'Cadastros', nivel: 1 },
      { nome: 'Vendedor', titulo: 'Vendedores', path: '/cadastros/vendedores', icone: 'sentiment_very_satisfied', grupo: 'Cadastros', nivel: 1 },
    ],
    nivel: 1
  },

  {
    titulo: 'Movimentações',
    grupo: 'movimentacoes',
    nome: 'Movimentações',
    icone: 'import_export',
    items: [
      { nome: 'Vendas', titulo: 'Vendas', path: '/movimentacoes/vendas', icone: 'shopping_cart', grupo: 'Movimentações', nivel: 1 },
      { nome: 'Contas a Receber', titulo: 'Contas a Receber', path: '/movimentacoes/contasAReceber', icone: 'vertical_align_bottom', grupo: 'Movimentações', nivel: 1 },
      { nome: 'Contas a Pagar', titulo: 'Contas a Pagar', path: '/movimentacoes/contasAPagar', icone: 'vertical_align_top', grupo: 'Movimentações', nivel: 1 },
      { nome: 'Cheques', titulo: 'Cheques', path: '/movimentacoes/cheques', icone: 'local_atm', grupo: 'Movimentações', nivel: 1 },
      { nome: 'Movimentos de Estoque', titulo: 'Movimento de Estoque', path: '/movimentacoes/estoques', icone: 'rv_hookup', grupo: 'Movimentações', nivel: 1 },
      { nome: 'Caixa', titulo: 'Caixa', path: '/movimentacoes/caixa', icone: 'swap_horiz', grupo: 'Movimentações', nivel: 1 },
      { nome: 'Ordens de Serviço', titulo: 'Ordens de Serviço', path: '/movimentacoes/ordens-servicos', icone: 'mdi-checkbox-marked-outline', grupo: 'Movimentações', nivel: 1 },
      { nome: 'Compras', titulo: 'Compras', path: '/movimentacoes/compras', icone: 'business_center', grupo: 'Movimentações', nivel: 1 },
      { nome: 'Pedidos de Clientes', titulo: 'Pedidos de Clientes', path: '/movimentacoes/pedidosclientes', icone: 'mdi-application', grupo: 'Movimentações', nivel: 1 },
      { nome: 'Pedidos de Compras', titulo: 'Pedidos de Compras', path: '/movimentacoes/pedidoscompra', icone: 'mdi-card-plus', grupo: 'Movimentações', nivel: 1 },
      { nome: 'Movimento de Produção', titulo: 'Movimento de Produção', path: '/movimentacoes/producao', icone: 'mdi-factory', grupo: 'Movimentações', nivel: 1 },
      { nome: 'Vendas', titulo: 'Vendas', path: '/movimentacoes/vendas', icone: 'shopping_cart', grupo: 'Movimentações', nivel: 1 },
      { nome: 'Remessas de Cheques', titulo: 'Remessas de Cheques', path: '/movimentacoes/remessas-cheques', icone: 'mdi-send-outline', grupo: 'Movimentações', nivel: 1 },
    ],
    nivel: 1
  },

  { 
    titulo: 'Tabelas', 
    grupo: 'tabelas', 
    nome: 'Tabela', 
    icone: 'table_chart', 
    items: [
      { nome: 'Conta Corrente', titulo: 'Conta Corrente', path: '/tabelas/contas', icone: 'monetization_on', grupo: 'Tabelas', nivel: 1 },
      { nome: 'Banco', titulo: 'Bancos', path: '/tabelas/bancos', icone: 'account_balance', grupo: 'Tabelas', nivel: 1 },
      { nome: 'PIS', titulo: 'PIS', path: '/tabelas/pis', icone: 'money_off', grupo: 'Tabelas', nivel: 1 },
      { nome: 'COFINS', titulo: 'COFINS', path: '/tabelas/cofins', icone: 'money_off', grupo: 'Tabelas', nivel: 1 },
      { nome: 'Cidade/Estado', titulo: 'Cidades/Estados', path: '/tabelas/cidadesEstados', icone: 'location_city', grupo: 'Tabelas', nivel: 1 },
      { nome: 'Cor', titulo: 'Cores', path: '/tabelas/cores', icone: 'format_color_fill', grupo: 'Tabelas', nivel: 1 },
      { nome: 'Tamanho', titulo: 'Tamanhos', path: '/tabelas/tamanhos', icone: 'format_size', grupo: 'Tabelas', nivel: 1 },
      { nome: 'MVA', titulo: 'MVAs', path: '/tabelas/mvas', icone: 'broken_image', grupo: 'Tabelas', nivel: 1 },
      { nome: 'Centro de Custo', titulo: 'Centros de Custos', path: '/tabelas/centrosCustos', icone: 'list_alt', grupo: 'Tabelas', nivel: 1 },
      { nome: 'CFOP Compra x Entrada', titulo: 'Mapeamento CFOPs', path: '/tabelas/mapeamentos', icone: 'zoom_out_map', grupo: 'Tabelas', nivel: 1 },
      { nome: 'Plano de Conta', titulo: 'Planos de Conta', path: '/tabelas/planosConta', icone: 'notes', grupo: 'Tabelas', nivel: 1 },
      { nome: 'Local de Estoque', titulo: 'Locais de Estoque', path: '/tabelas/locaisEstoque', icone: 'place', grupo: 'Tabelas', nivel: 1 },
    ],
    nivel: 1
  },

  {
    titulo: 'Gráficos',
    grupo: 'Graficos',
    nome: 'Graficos',
    icone: 'mdi-chart-bar',
    path: '/graficos',
    nivel: 1
  },

  { cabecalho: 'Fiscal', nivel: 1 },
  {
    titulo: 'Minhas Notas',
    grupo: 'Fiscal',
    nome: 'Nota',
    icone: 'chrome_reader_mode',
    path: '/notas',
    nivel: 1
  },
  {
    grupo: 'Fiscal',
    nome: 'Nota - Nova',
    path: '/notas/nova',
    nivel: 1,
    hidden: true
  },
  {
    grupo: 'Fiscal',
    nome: 'Nota - Edição',
    path: '/notas/editar',
    nivel: 1,
    hidden: true
  },
  {
    titulo: 'Notas Destinadas',
    grupo: 'Fiscal',
    nome: 'NotasDestinadas',
    icone: 'swap_horiz',
    path: '/destinadas',
    nivel: 1
  },
  {
    titulo: 'Manif. de Documentos',
    grupo: 'Fiscal',
    nome: 'Manifestos',
    icone: 'mdi-truck',
    path: '/manifestos',
    nivel: 1
  },
  {
    grupo: 'Fiscal',
    nome: 'Manifesto - Novo',
    path: '/manifestos/novo',
    nivel: 1,
    hidden: true
  },
  {
    grupo: 'Fiscal',
    nome: 'Manifesto - Editar',
    path: '/manifestos/editar',
    nivel: 1,
    hidden: true
  },
  {
    titulo: 'Arquivos Contabilidade',
    grupo: 'Fiscal',
    nome: 'Arquivos',
    icone: 'file_copy',
    path: '/arquivos',
    nivel: 2
  },
  
  { cabecalho: 'Avançado', nivel: 2 },
  {
    titulo: 'Importacão',
    grupo: 'Avançado',
    nome: 'Importação',
    icone: 'import_export',
    path: '/importacoes',
    nivel: 2
  },

  { divider: true, nivel: 1 },
  {
    titulo: "Configurações",
    grupo: "configuracoes",
    nome: "Configuração",
    icone: "mdi-cog",
    nivel: 2,
    items: [
      { nome: "Certificado Digital", titulo: "Certificado Digital", path: "/configuracoes/certificado_digital", icone: "vpn_key", grupo: "configuracao", nivel: 2 },
      { nome: "Usuários", titulo: "Usuários", path: "/configuracoes/usuarios", icone: "mdi-account", grupo: "configuracao", nivel: 2 },
      { nome: "Dispositívos", titulo: "Dispositívos", path: "/configuracoes/dispositivos", icone: "mdi-devices", grupo: "configuracao", nivel: 2 },
    ],
  },
  
];

export default Menu;

import axios from '@/util/axios';
import constantes from '@/util/constantes';

export default{
    
  namespaced: true,

  state: {

    estoquesDeposito: [],
    detalhesMovimentacao: [],
    constantes: constantes,
    carregando: false,
    totalEstoques: 0
  },

  getters: {

  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
      
    async carregar({commit, rootState}, options){
    
      commit('setCarregando', true);

      try{

        let url = `/estoquesDeposito?page=${options.page}&pageSize=${options.itemsPerPage}`;
        if(options.query && options.query !== ''){
          url = `${url}&query=${options.query}`;
        }

        let response = await axios.get(url);
        let resultado = response.data;

        commit('setEstoquesDeposito', resultado.estoques);
        commit('setTotalEstoques', resultado.total);
      }
      catch(erro){
        commit('setEstoquesDeposito', []);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    },

    async detalharMovimentacao({ commit, rootState }, produtoEstoque){

      try{

        let url = `/estoquesDeposito/filiais/${produtoEstoque.filial._id}/produtos/${produtoEstoque.produto._id}`;
        let response = await axios.get(url);
        let resultado = response.data;

        commit('setDetalhesMovimentacao', resultado);
      }
      catch(erro){
        commit('setDetalhesMovimentacao', []);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    }
  },

    //responsável por alterar o state.
  mutations: {

    setEstoquesDeposito(state, estoques){
      state.estoquesDeposito = estoques;
      state.carregando = false;
    },
    setDetalhesMovimentacao(state, detalhes){
      state.detalhesMovimentacao = detalhes;
      state.carregando = false;
    },
    setTotalEstoques(state, total){
      state.totalEstoques = total;
    },
    setCarregando(state, valor){
      state.carregando = valor;
    }
  }
}

<template>
  <v-dialog v-model="dialog" max-width="480px" @keydown.esc="fechar" persistent >
    <v-form v-model="valid" ref="formSubCentroCusto" lazy-validation>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title class="white--text">Adicionar Subcentro de Custo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field label="Descrição" v-model="subCentro.descricao" :rules="[rules.obrigatorio]" autofocus @keypress.enter="adicionarSubcentro"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="fechar()">Cancelar</v-btn>
          <v-btn color="primary" @click="adicionarSubcentro()">Adicionar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import * as regras from '@/util/validacaoFormulario';

  export default {
    name: "DialogoAdicionarSubCentro",  
    props: ['dialog'],
    data(){
      return {
        valid: false,
        rules: {...regras},
        subCentro: {
          descricao: ''
        } 
      }
    },

    methods: {
      adicionarSubcentro(){
        if(this.$refs.formSubCentroCusto.validate()){
          this.$emit('subCentroAdicionado', this.subCentro);
          this.subCentro = {
            descricao: ''
          };
        }
      },

      fechar(){
        this.subCentro = {
          descricao: ''
        };
        this.$emit('fechar');
      }
    }
  }
</script>

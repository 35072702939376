var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-subheader',{staticClass:"text-subtitle-1 text--primary"},[_vm._v(" Cheques ")]),_c('dialogo-status-cheque',{attrs:{"dialog":_vm.exibirDialogoStatusCheque,"cheque":_vm.chequeSelecionado},on:{"fechar":function($event){return _vm.fecharDialogoStatus()},"alterarStatus":_vm.confirmarAlterarStatus}}),_c('dialogo-filtro-cheques',{attrs:{"dialog":_vm.exibirDialogoFiltroCheques,"cheque":_vm.chequeSelecionado},on:{"limpar":function($event){_vm.filtro_ativo = false},"fechar":function($event){return _vm.fecharDialogoFiltro()},"filtrar":_vm.filtrarCheques}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"6"}},[_c('v-text-field',{attrs:{"filled":"","flat":"","single-line":"","hide-details":"","dense":"","clearable":"","rounded":"","max-width":"300px","prepend-inner-icon":"search","label":"Busca"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filtrarBusca($event)},"click:clear":_vm.limparBusca},model:{value:(_vm.busca),callback:function ($$v) {_vm.busca=$$v},expression:"busca"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right pr-0",attrs:{"xs":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.lista_status,"filled":"","dense":"","hide-details":"","item-value":'value'},on:{"change":function($event){return _vm.filtrarStatus($event)}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"xs":"12","sm":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.filtro_ativo),expression:"filtro_ativo"}],staticClass:"mr-4",attrs:{"color":"primary","dark":""},on:{"click":_vm.listar}},'v-icon',attrs,false),on),[_vm._v(" mdi-notification-clear-all ")])]}}])},[_c('span',[_vm._v("Limpar filtro")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary","dark":"","loading":_vm.carregando},on:{"click":_vm.abrirDialogoFiltro}},'v-icon',attrs,false),on),[_vm._v(" mdi-filter ")])]}}])},[_c('span',[_vm._v("Filtro")])]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.novo}},[_vm._v("Novo Cheque")])],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"options":_vm.options,"server-items-length":_vm.total,"footer-props":_vm.footerOptions,"headers":_vm.headers,"items":_vm.cheques,"loading":_vm.carregando},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('span',[_vm._v(_vm._s(item.emitente))]),_c('br'),_c('span',[_c('b',[_vm._v("CLIENTE: "+_vm._s(item.responsavel.numero)+" - "+_vm._s(item.responsavel.razao_nome))])])]),_c('td',[_vm._v(_vm._s(item.cnpj_cpf))]),_c('td',[_vm._v(_vm._s(_vm._f("formatarData")(item.vencimento)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatarPadZero")(item.numero,5)))]),_c('td',[_c('v-chip',{staticStyle:{"cursor":"pointer"},attrs:{"color":_vm.definirCor(item.status)},on:{"click":function($event){return _vm.alterarStatus(item)}}},[_vm._v(_vm._s(item.status))])],1),_c('td',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatarMoeda")(item.valor)))])]),_c('td',{staticClass:"text-center"},[_c('v-menu',{attrs:{"top":"","right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{key:item._id + 20,on:{"click":function($event){return _vm.editar(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v("Editar")])],1),_c('v-list-item',{key:item._id + 40,on:{"click":function($event){return _vm.excluir(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v("Remover")])],1),_c('v-list-item',{key:item._id + 50,on:{"click":function($event){return _vm.alterarStatus(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("track_changes")])],1),_c('v-list-item-title',[_vm._v("Status")])],1)],1)],1)],1)])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
<div>
  <v-form v-model="valid" ref="formCompra">
    <v-container grid-list-md fluid>
      <v-row dense>
        <v-col cols="12">
          <v-widget title="Informações da Nota">
            <div slot="widget-header-action">
              <input id="inputArquivo" type="file" v-show="false" :multiple="false" @change="carregarConteudo" :disabled="carregando">
              <v-btn color="primary" class="lighten-2 ml-2" depressed @click="escolherArquivo()" v-show="compra.numero == ''">Abrir Arquivo</v-btn>
            </div>
            <div slot="widget-content">
              <v-container fluid>

                <v-tabs v-model="tab_atual">
                  <v-tab key="nfe">Nota Fiscal</v-tab>
                  <v-tab key="impostos">Impostos</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab_atual">
                  <v-tab-item key="nfe">
                    <v-row dense>
                      <v-col cols="12" sm="2">
                        <v-text-field label="Número - Série" :value="compra.numero ? compra.numero + ' - ' + compra.serie : ''" required readonly hide-details dense filled></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <v-text-field label="Razão/Nome do Fornecedor" v-model="compra.fornecedor.razao_nome" required readonly hide-details dense filled></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <v-text-field label="Chave de Acesso" v-model="compra.chave" required readonly hide-details dense filled append-icon="mdi-content-copy" @click:append="copiarChaveAcesso"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="4">
                        <v-text-field label="CNPJ/CPF" v-model="compra.fornecedor.cnpj_cpf" required readonly hide-details dense filled></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field label="Cidade" v-model="compra.fornecedor.cidade" required readonly hide-details dense filled></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field label="Estado" v-model="compra.fornecedor.estado" required readonly hide-details dense filled></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Data Emissão" :value="compra.data | formatarData" readonly hide-details dense filled></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <campo-data label="Data Entrada" v-model="compra.data_entrada" hide-details dense filled></campo-data>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <as-campo-valor label="Total Produtos" v-model="compra.valor_produtos" :decimais="2" ref="valor_produtos" readonly hide-details dense filled></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <as-campo-valor label="Total NFe" v-model="compra.valor_nfe" :decimais="2" ref="valor_nfe" readonly hide-details dense filled></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <as-campo-valor label="Quantidade" v-model="compra.quantidade_total_produtos" :decimais="2" ref="quantidade_total_produtos" readonly hide-details dense filled></as-campo-valor>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="impostos">
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <h3>ICMS</h3>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <h3>ICMS-ST</h3>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="Base de Cálculo" v-model="compra.valor_base_calculo_icms" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_base_calculo_icms" hide-details dense filled></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="ICMS" v-model="compra.valor_icms" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_icms" hide-details dense filled></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="Base de Cálculo" v-model="compra.valor_base_calculo_st" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_base_calculo_st" hide-details dense filled></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="ICMS-ST" v-model="compra.valor_icms_st" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_icms_st" hide-details dense filled></as-campo-valor>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <h3>IPI / PIS E COFINS</h3>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="4">
                        <as-campo-valor label="IPI" v-model="compra.valor_ipi" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_ipi" hide-details dense filled></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <as-campo-valor label="PIS" v-model="compra.valor_pis" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_pis" hide-details dense filled></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <as-campo-valor label="COFINS" v-model="compra.valor_cofins" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_cofins" hide-details dense filled></as-campo-valor>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <h3>OUTROS</h3>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="Seguro" v-model="compra.valor_seguro" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_seguro" hide-details dense filled></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="Desconto" v-model="compra.valor_desconto" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_desconto" hide-details dense filled></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="Frete" v-model="compra.valor_frete" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_frete" hide-details dense filled></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="Outros" v-model="compra.valor_outro" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_outro" hide-details dense filled></as-campo-valor>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>

              </v-container>
            </div>
          </v-widget>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="mt-2">
          <v-widget title="Produtos">
            <div slot="widget-header-action">
              <v-toolbar-title>
                <span class="subtitle-2">{{ compra.itens.length }} Itens</span>
              </v-toolbar-title>
            </div>
            <div slot="widget-content">
              <v-container grid-list-md fluid>
                <v-data-table hide-default-footer disable-pagination fixed-header :headers="headers" :items="compra.itens" :loading="carregando" :height="compra.itens.length  > 9 ? '500px' : ''">
                  <template v-slot:item="{item, index}">
                    <tr :class="definirCor(item)" style="cursor:pointer" @click="definirProdutoMapeamento(item)">
                      <td class="text-center">{{ item.codigo_entrada }}</td>
                      <td class="text-center">{{ item.codigo }}</td>
                      <td>{{ item.descricao }}</td>
                      <td class="text-center">{{ item.unidade }}</td>
                      <td class="text-center">{{ item.quantidade | formatarValor }}</td>
                      <td class="text-center">{{ item.valor_custo | formatarMoeda }}</td>
                      <td class="text-center">{{ item.subtotal | formatarMoeda }}</td>
                      <td class="text-center">
                        <v-menu top right transition="slide-x-transition" @click.native.stop="">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                          </template>
                          <v-list dense>
                            <v-list-item data-cy="editar" @click="editarProduto(item, index)">
                              <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                              <v-list-item-title>Editar</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item data-cy="fronteira" @click="abrirFronteira(item)">
                              <v-list-item-icon><v-icon>mdi-calculator</v-icon></v-list-item-icon>
                              <v-list-item-title>Cálculo Fronteira</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item data-cy="editar" @click="excluirProduto(index)">
                              <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                              <v-list-item-title>Excluir</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-container>
            </div>
          </v-widget>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6" class="mt-2">
          <v-widget title="Contas a Pagar" altura="300px">
            <div slot="widget-header-action">
              <v-btn depressed color="primary" class="lighten-2" @click="abrirDialogoGerarDuplicatas">Gerar Duplicatas</v-btn>
            </div>
            <div slot="widget-content">
              <v-container grid-list-md fluid>
                <v-data-table hide-default-footer disable-pagination fixed-header :headers="headerPagamento" :items="compra.pagamentos" :loading="carregando"  :height="compra.pagamentos.length > 0 ? '250px' : ''">
                  <template v-slot:item="{item, index}">
                    <tr>
                      <td>{{ item.parcela }}</td>
                      <td>{{ item.vencimento | formatarData }}</td>
                      <td>{{ item.valor | formatarMoeda }}</td>
                      <td class="text-center">
                        <v-menu top right transition="slide-x-transition" @click.native.stop="">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                          </template>
                          <v-list dense>
                            <v-list-item data-cy="editar" @click="editarDuplicata(item, index)">
                              <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                              <v-list-item-title>Editar</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item data-cy="editar" @click="excluirDuplicata(index)">
                              <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                              <v-list-item-title>Excluir</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-container>
            </div>
          </v-widget>
        </v-col>
        <v-col cols="12" sm="6" class="mt-2">
         <v-widget title="Observações / Outros" altura="300px">
            <div slot="widget-content">
              <v-textarea label="Observações" ref="observacoes" v-model="compra.observacoes" rows="4" readonly></v-textarea>
              <v-row dense>
                <v-col cols="12" sm="4">
                  <v-checkbox label="Estoque Nulo" v-model="compra.estoque_nulo" color="primary" hide-details :disabled="compra.informativa"></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-checkbox label="Cancelada" v-model="compra.cancelada" color="primary" hide-details></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-checkbox label="Informativa" v-model="compra.informativa" color="primary" hide-details :disabled="compra.numero!=''" @click="ativarEstoqueNulo"></v-checkbox>
                </v-col>
              </v-row>
            </div>
          </v-widget>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="mt-2">
          <v-spacer></v-spacer>
          <v-btn @click="limpar()">{{constantes.BOTAO_CANCELAR}}</v-btn>
          <v-btn color="primary" :loading="carregando" @click="salvarNota" :disabled="!valid || compra.itens.length == 0">{{textoBtnConfirmar}}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>

  <dialog-buscar-produto ref="dialogoBuscarProduto" @produtoSelecionado="escolherProdutoMapeamento"></dialog-buscar-produto>

  <dialogo-editar-produto-compra ref="dialogoEditarProduto"></dialogo-editar-produto-compra>

  <v-dialog v-model="dialogoGerarDuplicatas" max-width="600px" persistent scrollable eager>
    <v-card>
      <v-toolbar flat dark color="primary" dense>
        <v-toolbar-title>Gerador de Duplicatas</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="4">
            <campo-data label="Vencimento 1ª parcela" v-model="gerador_duplicatas.vencimento_parcela1" ref="vencimento_parcela1" hide-details dense filled></campo-data>
          </v-col>
          <v-col cols="12" sm="4">
            <as-campo-valor label="Intervalo Parcelas" v-model="gerador_duplicatas.intervalo_parcelas" :decimais="0" ref="intervalo_parcelas" hide-details dense filled></as-campo-valor>
          </v-col>
          <v-col cols="12" sm="4">
            <as-campo-valor label="Quantidade Parcelas" v-model="gerador_duplicatas.parcelas" :decimais="0" ref="quantidade_parcelas" hide-details dense filled></as-campo-valor>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialogoGerarDuplicatas=false">Cancelar</v-btn>
        <v-btn color="primary" @click="gerarDuplicatas">Gerar Duplicatas</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogoEdicaoDuplicata" max-width="600px" persistent scrollable eager>
    <v-card>
      <v-toolbar flat dark color="primary" dense>
        <v-toolbar-title>Edição de Duplicata</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="4">
            <v-text-field label="Parcela" v-model="duplicata.parcela" ref="duplicata_parcela" hide-details dense filled></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <campo-data label="Vencimento" v-model="duplicata.vencimento" ref="duplicata_vencimento" hide-details dense filled></campo-data>
          </v-col>
          <v-col cols="12" sm="4">
            <as-campo-valor label="Valor" v-model="duplicata.valor" :decimais="2" ref="duplicata_valor" hide-details dense filled></as-campo-valor>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialogoEdicaoDuplicata=false">Cancelar</v-btn>
        <v-btn color="primary" @click="salvarDuplicata">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <dialogo-fronteira ref="dialogoFronteira"></dialogo-fronteira>

  <input type="hidden" id="input-chave" :value="compra.chave" />
</div>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import constantes from '@/util/constantes';
import DialogBuscarProduto from '@/components/dialogs/DialogBuscarProduto';
import DialogoEditarProdutoCompra from './DialogoEditarProdutoCompra';
import DialogoFronteira from './DialogoFronteira';
import * as regras from '@/util/validacaoFormulario';
import moment from 'moment';

export default {
  components:{
    DialogBuscarProduto,
    DialogoEditarProdutoCompra,
    DialogoFronteira
  },

  data: () => ({
    headers: [
      { text: 'Cód. Forn.', value: 'codigo_entrada', align: 'center', width: '10%' },
      { text: 'Código', value: 'codigo', align: 'center', width: '10%' },
      { text: 'Descrição', value: 'descricao', width: '35%' },
      { text: 'Unidade', value: 'unidade', align: 'center', width: '10%' },
      { text: 'Quantidade', value: 'quantidade', align: 'center', width: '10%' },
      { text: 'Valor', value: 'valor_custo', align: 'center', width: '10%' },
      { text: 'Subtotal', value: 'subtotal', align: 'center', width: '10%' },
      { text: 'Ações', value: 'acoes', align: 'center', width: '5%' },
    ],
    headerPagamento: [
      { text: 'Parcela', value: 'parcela', width: '40%' },
      { text: 'Vencimento', value: 'vencimento', width: '40%' },
      { text: 'Valor', value: 'valor', width: '15%' },
      { text: 'Ações', value: 'acoes', width: '5%' },
    ],
    valid: false,
    constantes: constantes,
    codigoProduto: '',
    exibirDialogBuscaProduto: false,
    produtoSelecionado: null,
    dialogEditarProduto: false,
    tab_atual: 'nfe',
    rules: {...regras},
    dialogoGerarDuplicatas: false,
    gerador_duplicatas: {
      vencimento_parcela1: moment().format('YYYY-MM-DD'),
      intervalo_parcelas: 30,
      parcelas: 0,
    },
    dialogoEdicaoDuplicata: false,
    duplicata: {
      vencimento: '',
      valor: 0,
      parcela: ''
    },
    duplicata_indice: -1,
  }),

  computed: {
    ...mapState('compra', {
      compra: 'compraAtual',
      carregando: 'carregando',
      produtoAtual: 'produtoAtual',
      modoEdicao: 'modoEdicao',
      reprocessarDuplicatas: 'reprocessarDuplicatas',
    }),

    ...mapGetters('compra', {
      textoBtnConfirmar: 'textoBtnConfirmar',
      dataCompra: 'dataFormatada',
      totalProdutos: 'totalProdutos',
      totalDescontos: 'totalDescontos',
      totalLiquido: 'totalLiquido'
    }),
  },

  methods: {
    salvarNota() {
      if (this.$refs.formCompra.validate()) {
        if (this.modoEdicao) {
          this.$root.$confirmacao.open('Reprocessar Duplicatas', 'Tem certeza que deseja reprocessar as duplicatas desta nota? (Algumas delas já podem ter sido pagas)')
          .then((confirmado) => {
            if(confirmado){
              this.$store.commit('compra/setReprocessarDuplicatas', true);
            }
            this.$store.dispatch('compra/atualizarNota', {router: this.$router});
          });
        } else {
          this.$store.dispatch('compra/salvarNota', {router: this.$router});
        }
      }
    },

    definirFornecedor(idFornecedor){
      this.$store.commit('compra/setFornecedor', idFornecedor);
    },

    limpar() {
      this.$store.dispatch('compra/cancelar', {router: this.$router});
    },

    buscarCodigoProduto(codigo){
      this.$store.dispatch('compra/buscarCodigoProduto', codigo);
    },

    adicionarProduto(){
      this.codigoProduto = '';
      this.$store.commit('compra/adicionarItem');
    },

    calcularSubtotal(){
      this.$store.commit('compra/calcularSubtotal');
    },

    removerItem(index){
      this.$store.commit('compra/removerItem', index);
    },

    escolherArquivo(){
        document.getElementById('inputArquivo').click();
    },

    carregarConteudo(evento){
      const arquivo = evento.target.files[0];
      if (arquivo) {
        var reader = new FileReader();
        reader.onload = (e) => {
          let arquivoLido = {
            chave: '',
            conteudo: e.target.result
          }
          this.enviarConteudo(arquivoLido);
        }
        reader.readAsBinaryString(arquivo, 'ASCII');
      }
    },

    enviarConteudo(arquivo){
      this.$store.dispatch('compra/processarArquivo', arquivo);
    },

    definirProdutoMapeamento(produto){
      if (!produto.informativo) {
        this.produtoSelecionado = produto;
        this.$refs.dialogoBuscarProduto.buscarProdutoNcm(produto.ncm);
      }
    },

    escolherProdutoMapeamento(produto_selecionado){
      let produto = produto_selecionado;

      this.$refs.dialogoBuscarProduto.fechar();
      this.$store.commit('compra/definirProdutoMapeamento', {produtoNota: this.produtoSelecionado, produto: produto});
    },

    editarProduto (produto_selecionado, index) {
      produto_selecionado.indice_original = index;

      this.$store.commit('compra/setProduto', produto_selecionado);
      this.$refs.dialogoEditarProduto.abrir();
    },
    abrirDialogoGerarDuplicatas () {
      this.dialogoGerarDuplicatas = true;
    },
    gerarDuplicatas () {
      let valor_parcela = this.compra.valor_nfe / this.gerador_duplicatas.parcelas;
      let duplicatas = [];
      let ultimo_vencimento = this.gerador_duplicatas.vencimento_parcela1;

      for (let i=1; i<=this.gerador_duplicatas.parcelas; i++) {
        duplicatas.push({
          parcela: this.compra.numero + ' - ' + i,
          valor: valor_parcela,
          vencimento: ultimo_vencimento
        });
        ultimo_vencimento = moment(ultimo_vencimento, 'YYYY-MM-DD').add(this.gerador_duplicatas.intervalo_parcelas, 'd').format('YYYY-MM-DD');
      }

      this.compra.pagamentos = [];
      this.compra.pagamentos = duplicatas;

      this.dialogoGerarDuplicatas = false;
    },
    editarDuplicata(duplicata_selecionada, indice) {
      this.duplicata_indice = indice;
      this.duplicata = JSON.parse(JSON.stringify(duplicata_selecionada));
      this.dialogoEdicaoDuplicata = true;
    },
    salvarDuplicata () {
      this.compra.pagamentos.splice(this.duplicata_indice,1,this.duplicata);
      this.duplicata = {
        vencimento: '',
        valor: 0,
        parcela: ''
      };
      this.duplicata_indice = -1;
      this.dialogoEdicaoDuplicata = false;
    },
    excluirDuplicata (indice) {
      this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover esta duplicata?')
        .then((confirmado) => {
          if(confirmado){
            this.compra.pagamentos.splice(indice, 1);
            this.duplicata_indice = -1;
          }
        });
    },
    excluirProduto (indice) {
      this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover este produto?')
        .then((confirmado) => {
          if(confirmado){
            this.compra.itens.splice(indice, 1);
          }
        });
    },
    definirCor (produto) {
      if (produto.informativo) {
        return 'blue--text';
      } else if (!produto.informativo && !produto.codigo) {
        return 'red--text';
      } else {
        return '';
      }
    },
    ativarEstoqueNulo () {
      this.compra.estoque_nulo = this.compra.informativa;
    },
    abrirFronteira (produto_selecionado) {
      this.$store.commit('compra/setProduto', produto_selecionado);
      this.$refs.dialogoFronteira.abrir();
    },
    copiarChaveAcesso() {
      let inputLinkToCopy = document.querySelector("#input-chave");

      inputLinkToCopy.setAttribute("type", "text");
      inputLinkToCopy.select();
      document.execCommand("copy");
      inputLinkToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();

      this.$store.commit('setMensagemSucesso', 'Chave de Acesso copiada para a área de transferência', {root: true});
    }
  }
};
</script>

<style scoped>
.container.grid-list-md .layout .flex{
  padding-top: 0px;
  padding-right: 4px;
  padding-bottom: 0px;
  padding-left: 4px;
}
</style>
import axios from '@/util/axios';
import qs from "qs";


function listar(options) {
  return axios.get("/pedidosClientes", { params: options })
    .then(response => response.data);
}

function salvar(item) {
  return axios.post("/pedidosClientes", item)
    .then(response => response.data);
}

function atualizar(item) {
  return axios.put(`/pedidosClientes/${item._id}`, item)
    .then(response => response.data);
}

function excluir(id) {
  return axios.delete(`/pedidosClientes/${id}`)
    .then(response => response.data);
}

export default {
  salvar,
  listar,
  atualizar,
  excluir
}
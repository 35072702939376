import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual) {
    return axios.get(`/planosConta?` + qs.stringify(options))
      .then(response => response.data);
  }

  function salvar(planoconta, empresaAtual) {
    return axios.post(`/planosConta`, planoconta)
      .then(response => response.data);
  }

  function atualizar(planoconta, empresaAtual) {
    return axios.put(`/planosConta/${planoconta._id}`, planoconta)
      .then(response => response.data);
  }

  function excluir(id, empresaAtual) {
    return axios.delete(`/planosConta/${id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    salvar,
    atualizar,
    excluir
  }
import FormularioProduto from '@/views/produtos/FormularioProduto';

export default [
  {
    path: '/cruds',
    name: 'CrudLista',
    component: () => import( /* webpackChunkName: "CrudLista" */ '@/views/crud/ListaCrud'),
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/cruds/novo',
    name: 'CrudNovo',
    component: () => import( /* webpackChunkName: "CrudNovo" */ '@/views/crud/FormularioCrud'),
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/cruds/editar',
    name: 'CrudEditar',
    component: () => import( /* webpackChunkName: "CrudEditar" */ '@/views/crud/FormularioCrud'),
    meta: { requiresAuth: true, nivel: 1}
  }
];
<template>
  <v-form v-model="valid" ref="formProduto" lazy-validation>
    <v-container grid-list-sm fluid>
      <v-row dense>
        <v-col cols="12">
          <v-widget title="Informações do Produto">
            <div slot="widget-content" max-height="50px">
              <v-tabs v-model="abaAtiva">
                <v-tab :key="1">Informações Gerais</v-tab>
                <v-tab :key="2">Impostos</v-tab>
                <v-tab :key="3">Fabricante</v-tab>
                <v-tab :key="4">Formação de Preço</v-tab>
                <v-tab :key="5">Outros</v-tab>
              </v-tabs>
              <v-tabs-items v-model="abaAtiva">
                <v-divider></v-divider>
                <v-tab-item :key="1" eager>
                  <v-container grid-list-md fluid>
                    <v-row dense>
                      <v-col cols="12" sm="3">
                        <v-text-field autofocus label="Código" v-model="produto.codigo" :rules="[rules.obrigatorio, rules.caracteresEspeciais]" ref="codigo" @keypress.native.enter="$refs.codigo_barra.focus()" :disabled="edicao"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Código de Barras" v-model="produto.codigo_barra" :rules="[rules.obrigatorio]" ref="codigo_barra" @keypress.native.enter="$refs.descricao.focus()"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field label="Descrição" v-model="produto.descricao" :rules="[rules.obrigatorio, rules.maximo100Caracteres]" ref="descricao" @keypress.native.enter="$refs.descricao_orcamento.focus()"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-text-field label="Descrição no Orçamento" v-model="produto.descricao_orcamento" ref="descricao_orcamento" @keypress.native.enter="$refs.origem.focus()"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select label="Origem" :items="constantes.ORIGEM_PRODUTO" v-model="produto.origem" ref="origem">></v-select>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <buscar-ncm ref="campoNcm" v-model="produto.ncm.codigo" @ncmSelecionado="ncmSelecionado">></buscar-ncm>
                      </v-col>
                      <v-col cols="12" sm="9">
                        <v-text-field label="Descrição do NCM *" v-model="produto.ncm.descricao" :rules="[rules.obrigatorio]" required readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <buscar-cest ref="campoCest" v-model="produto.cest.cest" @cestSelecionado="cestSelecionado"></buscar-cest>
                      </v-col>
                      <v-col cols="12" sm="9">
                        <v-text-field label="Descrição do CEST" v-model="produto.cest.descricao" required readonly></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-select label="Grupo" :items="grupos" item-text="nome" item-value="_id" return-object v-model="produto.grupo" ref="grupo" @keypress.native.enter="$refs.subgrupo.focus()"></v-select>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-select label="SubGrupo" :items="produto.grupo.subgrupos" item-text="nome" item-value="nome" v-model="produto.subgrupo" ref="subgrupo" @keypress.native.enter="$refs.unidade.focus()"></v-select>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-select label="Unidade" v-model="produto.unidade" :items="constantes.UNIDADES" :rules="[rules.obrigatorio]" ref="unidade" @keypress.native.enter="$refs.valor.focus()"></v-select>
                      </v-col>
                      <v-col cols="12" sm="3"> 
                        <as-campo-valor label="Valor" v-model="produto.valor" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]" :decimais="2" ref="valor" @keypress.native.enter="$refs.estoque.focus()"></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="Estoque" v-model="produto.estoque" :decimais="2" ref="estoque"></as-campo-valor>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item :key="2" eager>
                  <v-container grid-list-md fluid>
                    <v-row dense class="mt-4">
                      <v-col cols="12" sm="12">
                        <span class="title">ICMS</span>
                      </v-col>
                    </v-row>
                    <v-row row>
                      <v-col cols="12" sm="10">
                        <v-select label="CST" v-model="produto.cst_icms" :items="constantes.CST_ICMS" ref="cst_icms" @keypress.native.enter="$refs.aliq_icms.focus()" :rules="[rules.obrigatorio]"></v-select>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <as-campo-valor label="% ICMS" v-model="produto.aliq_icms" :decimais="2" :rules="[rules.obrigatorio, rules.valorPositivo]" ref="aliq_icms" @keypress.native.enter="$refs.cst_pis_entrada.focus()"></as-campo-valor>
                      </v-col>
                    </v-row>
                    <v-spacer></v-spacer>
                    <v-row dense class="mt-4">
                      <v-col cols="12" sm="12">
                        <span class="title">PIS</span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="10">
                        <v-select label="CST PIS Entrada" v-model="produto.cst_pis_entrada" :items="pis" ref="cst_pis_entrada" @keypress.native.enter="$refs.aliq_pis_entrada.focus()" :rules="[rules.obrigatorio]"></v-select>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <as-campo-valor label="% PIS Entrada" v-model="produto.aliq_pis_entrada" :decimais="2" :rules="[rules.obrigatorio, rules.valorPositivo]" ref="aliq_pis_entrada" @keypress.native.enter="$refs.cst_pis_saida.focus()"></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="10">
                        <v-select label="CST PIS Saída" v-model="produto.cst_pis_saida" :items="pis" ref="cst_pis_saida" @keypress.native.enter="$refs.aliq_pis_saida.focus()" :rules="[rules.obrigatorio]"></v-select>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <as-campo-valor label="% PIS Saída" v-model="produto.aliq_pis_saida" :decimais="2" :rules="[rules.obrigatorio, rules.valorPositivo]" ref="aliq_pis_saida" @keypress.native.enter="$refs.cst_cofins_entrada.focus()"></as-campo-valor>
                      </v-col>
                    </v-row>

                    <v-spacer></v-spacer>

                    <v-row dense class="mt-4">
                      <v-col cols="12">
                        <span class="title">COFINS</span>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="10">
                        <v-select label="CST COFINS Entrada" v-model="produto.cst_cofins_entrada" :items="cofins" ref="cst_cofins_entrada" @keypress.native.enter="$refs.aliq_cofins_entrada.focus()" :rules="[rules.obrigatorio]"></v-select>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <as-campo-valor label="% COFINS Entrada" v-model="produto.aliq_cofins_entrada" :decimais="2" :rules="[rules.obrigatorio, rules.valorPositivo]" ref="aliq_cofins_entrada" @keypress.native.enter="$refs.cst_cofins_saida.focus()"></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="10">
                        <v-select label="CST COFINS Saída" v-model="produto.cst_cofins_saida" :items="cofins" ref="cst_cofins_saida" @keypress.native.enter="$refs.aliq_cofins_saida.focus()" :rules="[rules.obrigatorio]"></v-select>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <as-campo-valor label="% COFINS Saída" v-model="produto.aliq_cofins_saida" :decimais="2" :rules="[rules.obrigatorio, rules.valorPositivo]" ref="aliq_cofins_saida"></as-campo-valor>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item :key="3" eager>
                  <v-container fluid grid-list-md>
                    <v-row dense>
                      <v-col cols="12">
                        <buscar-fabricante ref="campoFabricante" v-model="produto.fabricante.nome" @fabricanteSelecionado="fabricanteSelecionado">></buscar-fabricante>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field label="Referência Fabricante" v-model="produto.referencia_fabricante"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="9">
                        <v-row dense>
                          <p>Fator de Conversão</p>
                          <v-col cols="12" sm="3">
                            <as-campo-valor label="Entrada" v-model="produto.fator_conversao.entrada" :decimais="2" :rules="[rules.valorPositivo]"></as-campo-valor>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <as-campo-valor label="Saída" v-model="produto.fator_conversao.saida" :decimais="2" :rules="[rules.valorPositivo]"></as-campo-valor>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item :key="4" eager>
                  <v-container fluid grid-list-md>
                    <v-row dense>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="Valor de Custo" v-model="produto.valor_custo" :decimais="2" :rules="[rules.valorPositivo]" @blur="calcularPrecos" ref="valor_custo" @keypress.native.enter="$refs.lucro.focus()"></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="Lucro (%)" v-model="produto.lucro" :decimais="2" :rules="[rules.valorPositivo]" @blur="calcularPrecos" ref="lucro" @keypress.native.enter="$refs.ipi.focus()"></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="IPI (%)" v-model="produto.ipi" :decimais="2" :rules="[rules.valorPositivo]" @blur="calcularPrecos" ref="ipi" @keypress.native.enter="$refs.ii.focus()"></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="II (%)" v-model="produto.ii" :decimais="2" :rules="[rules.valorPositivo]" @blur="calcularPrecos" ref="ii" @keypress.native.enter="$refs.frete_tipo.focus()"></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="1">
                        <v-select label="Tipo" :items="tiposInformacaoFrete" v-model="produto.frete.tipo" @blur="calcularPrecos" ref="frete_tipo" @keypress.native.enter="$refs.frete_valor.focus()"></v-select>
                      </v-col>
                      <v-col cols="12" sm="2">
                        <as-campo-valor label="Frete" v-model="produto.frete.valor" :decimais="2" :rules="[rules.valorPositivo]" @blur="calcularPrecos" ref="frete_valor" @keypress.native.enter="$refs.outros.focus()"></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="Outros (%)" v-model="produto.outros" :decimais="2" :rules="[rules.valorPositivo]" @blur="calcularPrecos" ref="outros" @keypress.native.enter="$refs.valor_vista.focus()"></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="À Vista" v-model="produto.valor_vista" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_vista" @keypress.native.enter="$refs.valor_prazo.focus()"></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <as-campo-valor label="À Prazo" v-model="produto.valor_prazo" :decimais="2" :rules="[rules.valorPositivo]" ref="valor_prazo"></as-campo-valor>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>

                <v-tab-item :key="5" eager>
                  <v-container fluid grid-list-md>
                    <v-row dense>
                      <v-col cols="12" sm="4">
                        <v-select label="IAT" :items="constantes.IATs" v-model="produto.iat" ref="iat" @keypress.native.enter="$refs.ippt.focus()" :rules="[rules.obrigatorio]"></v-select>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-select label="IPPT" :items="constantes.IPPTs" v-model="produto.ippt" ref="ippt" @keypress.native.enter="$refs.estoque_minimo.focus()" :rules="[rules.obrigatorio]"></v-select>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <as-campo-valor label="Estoque Mínimo" v-model="produto.estoque_minimo" :decimais="2" :rules="[rules.valorPositivo]" ref="estoque_minimo" @keypress.native.enter="$refs.localizacao.focus()"></as-campo-valor>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-text-field label="Localização" v-model="produto.localizacao" ref="localizacao"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </div>

            <template v-slot:widget-footer-action>
              <v-btn text @click="limpar">{{constantes.BOTAO_CANCELAR}}</v-btn>
              <v-btn color="primary" :loading="carregando" @click="enviar" :disabled="!valid">{{textoBtnConfirmar}}</v-btn>
            </template>
          </v-widget>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
import constantes from '@/util/constantes';
import * as regras from '@/util/validacaoFormulario';

export default {
  name: 'FormularioProduto',

  props: {
    cadastroNfe: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    valid: true,
    rules: {...regras},
    constantes: constantes,
    abaAtiva: '3',
    filtroNcm: null,
    filtroCest: null,
    filtroFabricante: null,
    tiposInformacaoFrete: [{text: 'R$', value: 'valor'}, {text: '%', value: 'percentual'}],
    codigoNcm: '',
    codigoCest: '',
  }),

  computed: {
    ...mapState('produto', {
      produto: 'itemAtual',
      pis: 'pis',
      cofins: 'cofins',
      carregando: 'carregando',
      edicao: 'modoEdicao',
      produtoNfe: 'produtoNfe'
    }),
    ...mapGetters('produto', {
      estaEditando: 'estaEditando',
      textoBtnConfirmar: 'textoBtnConfirmar',
    }),

    ...mapState('fabricante', {
      fabricantes: 'fabricantes',
    }),

    ...mapState('grupo', {
      grupos: 'grupos',
    })
  },

  watch: {
    filtroNcm(val){
      if(!this.carregando && val && val.length > 3){
        this.$store.dispatch('produto/consultarNcm', val);
      }
    },
    filtroCest(val){
      if(!this.carregando && val && val.length >= 3){
        this.$store.dispatch('produto/consultarCest', val);
      }
    },
    filtroFabricante(val){
      if(!this.carregando && val && val.length >= 3){
        this.$store.dispatch('produto/consultarFabricante', val);
      }
    }
  },

  methods: {
    ...mapActions('produto', {
      carregarPis: 'carregarPis',
      carregarCofins: 'carregarCofins'
    }),

    ...mapActions('fabricante', {
      carregarFabricantes: 'carregar'
    }),

    ...mapActions('grupo', {
      carregarGrupos: 'carregar',
    }),

    async enviar() {
      if (this.$refs.formProduto.validate()) {
        await this.$store.dispatch('produto/salvar');
        if (this.cadastroNfe) {
          this.$emit('fecharDialogo', 'cadastrado');
        } else {
          this.$router.push('/cadastros/produtos');
       }
       this.$store.commit('produto/novo');
      }
    },

    limpar() {
      this.$store.commit('produto/novo');
      if (this.cadastroNfe) {
        this.$emit('fecharDialogo', 'cancelado');
      } else {
        this.$router.push({name: 'produtos'});
      }
    },

    carregarConfiguracoesTributarias(){
      this.$store.dispatch('produto/carregarConfiguracoesTributarias');
    },

    definirNcm(idNcm){
      this.$store.commit('produto/setNcm', idNcm);
    },

    definirCest(idCest){
      this.$store.commit('produto/setCest', idCest);
    },

    definirFabricante(idFabricante){
      this.$store.commit('produto/setFabricante', idFabricante);
    },

    ncmSelecionado(ncm){
      this.codigoNcm = ncm.codigo;
      this.$store.commit('produto/setNcm', ncm);
    },

    cestSelecionado(cest){

      this.codigoCest = cest.cest;
      this.$store.commit('produto/setCest', cest);
    },

    fabricanteSelecionado(fabricante){
      this.$store.commit('produto/setFabricante', fabricante);
    },

    calcularPrecos () {
      let despesas = 0;
      despesas += this.produto.valor_custo * (this.produto.ipi / 100);
      despesas += this.produto.valor_custo * (this.produto.ii / 100);
      despesas += this.produto.valor_custo * (this.produto.outros / 100);
      
      if(this.produto.frete.tipo == 'valor'){
          despesas += this.produto.frete.valor;
      }
      else if(this.produto.frete.tipo == 'percentual'){
          despesas += this.produto.valor_custo * (this.produto.frete.valor / 100);
      }

      let valor = this.produto.valor_custo + this.produto.valor_custo * (this.produto.lucro / 100) + despesas;
      this.produto.valor_vista = valor;
      this.produto.valor_prazo = valor;
      this.produto.valor = valor;
    },

    preencherDadosProdutoNfe (produtoNfe) {
      this.produto.codigo = produtoNfe.codigo_entrada;
      this.produto.descricao = produtoNfe.descricao;
      this.produto.unidade = produtoNfe.unidade;
      this.produto.ncm.codigo = produtoNfe.ncm;
      this.produto.referencia_fabricante = produtoNfe.codigo_entrada;
      this.produto.valor_custo = produtoNfe.valor_custo;
      this.produto.iat = 'T';
      this.produto.ippt = 'T';

      this.$refs.campoNcm.consultarCodigoNcm(produtoNfe.ncm);
    }
  },

  created(){
    this.carregarGrupos();
    this.carregarPis();
    this.carregarCofins();
  }

};
</script>

<style scoped>
.container.grid-list-md .row .flex{
  padding-top: 0px;
  padding-right: 4px;
  padding-bottom: 0px;
  padding-left: 4px;
}
</style>
import ListaCor from "@/views/cores/ListaCor";
import FormularioCor from "@/views/cores/FormularioCor";

export default [
  {
    path: "/tabelas/cores",
    name: "cores",
    component: ListaCor,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/tabelas/cores/nova",
    name: "formularioCorNova",
    component: FormularioCor,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/tabelas/cores/editar",
    name: "formularioCorEditar",
    component: FormularioCor,
    meta: { requiresAuth: true, nivel: 1 },
  },
];

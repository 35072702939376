const temas = [
    {
      key: 'blue',
      value: {
        sideNav: 'blue',
        mainNav: 'blue',
        sideManu: 'white'
      }
    },
    {
      key: 'teal',
      value: {
        sideNav: 'teal',
        mainNav: 'teal',
        sideManu: 'white'
      }
    },
    {
      key: 'red',
      value: {
        sideNav: 'red',
        mainNav: 'red',
        sideManu: 'white'
      }
    },
    {
      key: 'orange',
      value: {
        sideNav: 'orange',
        mainNav: 'orange',
        sideManu: 'white'
      }
    },
    {
      key: 'purple',
      value: {
        sideNav: 'purple',
        mainNav: 'purple',
        sideManu: 'white'
      }
    },
    {
      key: 'indigo',
      value: {
        sideNav: 'indigo',
        mainNav: 'indigo',
        sideManu: 'white'
      }
    },
    {
      key: 'cyan',
      value: {
        sideNav: 'cyan',
        mainNav: 'cyan',
        sideManu: 'white'
      }
    },
    {
      key: 'pink',
      value: {
        sideNav: 'pink',
        mainNav: 'pink',
        sideManu: 'white'
      }
    },
    {
      key: 'green',
      value: {
        sideNav: 'green',
        mainNav: 'green',
        sideManu: 'white'
      }
    }
  ];

  export default temas;
import axios from '@/util/axios';
import constantes from '@/util/constantes';

export default{
    
    namespaced: true,

    //equivalente ao data
    state: {

        itens: [],
        itemAtual: {
            
        },
        constantes: constantes,
        modoEdicao: false,
        carregando: false
    },

    //equivalente ao computed properties
    getters: {

        
    },

    //equivalente aos methods
    //responsável por chamar a api externa
    actions: {
        
        tratarConteudo({commit}, dados){
            
            commit('setCarregando', true);

            let linhas = dados.split('\n');
            let cabecalhos = linhas[0];
            let keys = cabecalhos.split(';');
            
            let objetos = [];
            for(var indiceLinha = 1; indiceLinha < linhas.length; indiceLinha++){
                let linha = linhas[indiceLinha];
                let campos = linha.split(';');
                let objeto = {};
                for(var i = 0; i < keys.length; i++){
                    if(campos[i]){

                        let campoFormatado = campos[i].replace('"', '');
                        campoFormatado = campoFormatado.replace('"', '');
                        objeto[keys[i]] = campoFormatado;
                    }
                }
                objetos.push(objeto);
            }

            axios.post('/ibpt/PE', objetos)
                .then(response => {
                    commit('setItens', response.data);
                    commit('setCarregando', false);
                })
                .catch(err => {
                    commit('setCarregando', false);
                });
        },

        carregarItens({commit}){
            
            commit('setCarregando', true);
            axios.get('/ibpt')
            .then(response => {
                commit('setItens', response.data);
                commit('setCarregando', false);
                commit('setMensagemSucesso', 'IBPTs Cadastrados com Sucesso', {root: true});
            })
            .catch(erro => {
                commit('setItens', []);
                commit('setCarregando', false);
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        },

        detalhar({commit}, dados){
            commit('setCarregando', true);
            dados.router.push('/ibpt/detalhar');
            commit('detalhar', dados.item);
            commit('setCarregando', false);
        }
    },

    //responsável por alterar o state.
    mutations: {

        setCarregando(state, valor){
            state.carregando = valor
        },
        setItens(state, itens){
            itens.forEach(item => {
                state.itens.push(item);
            });
        },
        detalhar(state, item){
            state.itemAtual = item;
        }
    }
}
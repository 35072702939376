<template>
  <div class="text-center ma-2">
    <v-snackbar v-model="snackbar.show" :timeout="5000" :color="snackbar.color" multi-line top>
      {{ snackbar.text }}
      <v-btn dark text @click.native="snackbar.show = false" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    snackbar: {
      show: false,
      color: '',
      text: ''
    },
    text: "",
  }),

  computed: {

  },

  mounted(){
    // let empresa = this.$store.state.empresaAtual;
    // console.log(empresa.cnpj)
    // this.sockets.subscribe(empresa.cnpj.substr(0, 8), (data) => {
      
      
    //   let color = '';
    //   if(data.status > 300){
    //     color = 'red';
    //   }
    //   else{
    //     color = 'green';
    //   }
    //   this.snackbar.show = true;
    //   this.snackbar.text = data.motivo;
    //   this.snackbar.color = color;

    // });
  }
};
</script>

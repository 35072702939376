const Menu = [
  {
    icon: "account_box",
    href: "/minhas_informacoes",
    title: "Minha Conta",
  },
  {
    icon: "exit_to_app",
    href: "/sair",
    title: "Sair",
  },
];

export default Menu;

import ListaVendedores from '@/views/vendedores/ListarVendedores';
import FormularioVendedor from '@/views/vendedores/FormularioVendedor';

export default [
  {
    path: '/cadastros/vendedores',
    name: 'vendedores',
    component: ListaVendedores,
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/cadastros/vendedores/novo',
    name: 'formularioVendedorNovo',
    component: FormularioVendedor,
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/cadastros/vendedores/editar',
    name: 'formularioVendedorEditar',
    component: FormularioVendedor,
    meta: { requiresAuth: true, nivel: 1}
  }
];
<template>
  <div>
    <v-container>
      <v-subheader class="text-subtitle-1 text--primary">
        Plano de Contas
      </v-subheader>
      <v-card>
        <v-toolbar flat color="white">
          <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="novo">Novo Plano de Contas</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="planocontas" :search="busca" :loading="carregando">
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.codigo }}</td>
              <td>{{ props.item.descricao }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="props.item._id + 20" @click="editar(props.item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="props.item._id + 40" @click="excluir(props.item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
</div>
</template>

<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    name: "ListaPlanoContas",
    data () {
      return {
        headers: [
          { text: 'Código', value: 'codigo' },
          { text: 'Descrição', value: 'descricao' },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        }
      }
    },

    watch: {
      options: {
        handler () {
          this.listar();
        },
        deep: true,
      },
    },

    computed: {
      ...mapState('planoContas', {
        planocontas: 'planosConta',
        carregando: 'carregando',
        total: 'totalPlanoContas'
      })
    },

    methods: {
      async listar(){
        await this.$store.dispatch('planoContas/listar');
      },

      excluir(plano){
        this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('planoContas/excluir', plano);
          }
        });
      },

      editar(plano){
        this.$store.commit('planoContas/editar', plano);
        this.$router.push({name: 'formularioPlanoContaEditar'});
      },

      novo(){
        this.$store.commit('planoContas/novo');
        this.$router.push({name: 'formularioPlanoContaNovo'});
      }
    },
  }
</script>
export default [
  {
    path: '/empresasAdmin',
    name: 'empresasAdmin',
    component: () => import( /* webpackChunkName: "listaEmpresasAdmin" */ '@/views/empresas/ListarEmpresasAdmin'),
    meta: { requiresAuth: true, nivel: 3}
  },
  {
    path: '/empresasAdmin/nova',
    name: 'formularioEmpresaNova',
    component: () => import( /* webpackChunkName: "formularioEmpresa" */ '@/views/empresas/FormularioEmpresa'),
    meta: { requiresAuth: true, nivel: 3}
  },
  {
    path: '/empresasAdmin/editar',
    name: 'formularioEmpresaEditar',
    component: () => import( /* webpackChunkName: "formularioEmpresa" */ '@/views/empresas/FormularioEmpresa'),
    meta: { requiresAuth: true, nivel: 3}
  }
];

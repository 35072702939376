import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual) {
    return axios.get(`/cheques?` + qs.stringify(options))
      .then(response => response.data);
  }
  
  function filtrar(options, empresaAtual) {
    return axios.get(`/cheques/filtro?` + qs.stringify(options))
      .then(response => response.data);
  }

  function salvar(cheque, empresaAtual) {
    return axios.post(`/cheques`, cheque)
      .then(response => response.data);
  }

  function alterarStatus(cheque, empresaAtual, status) {
    return axios.put(`/cheques/${cheque._id}/status`, status)
      .then(response => response.data);
  }
  
  function atualizar(cheque, empresaAtual) {
    return axios.put(`/cheques/${cheque._id}`, cheque)
      .then(response => response.data);
  }

  function excluir(id, empresaAtual) {
    return axios.delete(`/cheques/${id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    filtrar,
    salvar,
    atualizar,
    excluir,
    alterarStatus
  }
import axios from "@/util/axios";
import constantes from "@/util/constantes";
import graficosApi from "@/services/graficosApi";

export default {
  namespaced: true,

  state: {
    vendasPorMes: [],
    vendasPorIntervaloData: [],
    constantes: constantes,
    carregando: false,
  },

  getters: {},

  actions: {
    async vendasPorMes({ commit, rootState }, periodo) {
      try {
        commit("setCarregando");
        let response = await graficosApi.vendasPorMes(rootState.empresaAtual, periodo)
        commit("setVendasPorMes", response);
      } catch (erro) {
        commit("setVendasPorMes", []);
        commit("setMensagemErro", "Falha ao carregar o gráfico", { root: true });
      } finally {
        commit("setCarregando", false);
      } 
    },
    async vendasPorIntervaloData({ commit, rootState }, periodo) {
      try {
        commit("setCarregando");
        let response = await graficosApi.vendasPorIntervaloData(rootState.empresaAtual, periodo)
        commit("setVendasPorIntervaloData", response);
      } catch (erro) {
        commit("setVendasPorIntervaloData", []);
        commit("setMensagemErro", "Falha ao carregar o gráfico", { root: true });
      } finally {
        commit("setCarregando", false);
      } 
    },
  },

  mutations: {
    setCarregando(state, valor = true) {
      state.carregando = valor;
    },
    setVendasPorMes(state, vendas) {
      state.vendasPorMes = vendas;
    },
    setVendasPorIntervaloData(state, vendas) {
      state.vendasPorIntervaloData = vendas;
    },
  },
};

export default [
  {
    path: '/tabelas/cidadesEstados',
    name: 'cidadesEstados',
    component: () => import( /* webpackChunkName: "listaCidadesEstados" */ '@/views/cadastros/ListaCidadesEstados'),
    meta: { requiresAuth: true, nivel: 1}
  },

  {
    path: '/emitente',
    name: 'formularioEmitente',
    component: () => import( /* webpackChunkName: "formularioEmitente" */ '@/views/empresas/FormularioEmitente'),
    meta: { requiresAuth: true, nivel: 2}
  },
  
  {
    path: '/importacoes',
    name: 'ImportarXML',
    component: () => import( /* webpackChunkName: "importarXml" */ '@/views/ferramentas/ImportarXML'),
    meta: { requiresAuth: true, nivel: 2}
  },

  {
    path: '/arquivos',
    name: 'ArquivosContabilidade',
    component: () => import( /* webpackChunkName: "formularioArquivosContabilidade" */ '@/views/fiscal/FormularioArquivosContabilidade'),
    meta: { requiresAuth: true, nivel: 2}
  },

  {
    path: '/cidades',
    name: 'listaImportarCidades',
    component: () => import( /* webpackChunkName: "listaImportacaoCidades" */ '@/views/cadastros/ListaImportarCidades'),
    meta: { requiresAuth: true, nivel: 2}
  }
];
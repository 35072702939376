import axios from '@/util/axios';
import constantes from '@/util/constantes';

export default{
    
    namespaced: true,

    //equivalente ao data
    state: {

        cests: [],
        constantes: constantes,
        carregando: false
    },

    //equivalente ao computed properties
    getters: {

    },

    //equivalente aos methods
    //responsável por chamar a api externa
    actions: {
        
        buscarDescricaoCest({commit, rootState}, descricao){

            commit('setCarregando', true);
            axios.get(`/cest/descricao/${descricao}`)
            .then((response) => {
                if(response.status === 200){
                    commit('setCests', response.data);
                }
            })
            .catch((erro) => {
                commit('setCests', []);
                commit('setCarregando', false);
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });  
        },
        
    },

    //responsável por alterar o state.
    mutations: {

        setCests(state, cests){
            state.cests = cests;
            state.carregando = false;
        },
        setCarregando(state, valor){
            state.carregando = valor;
        }
    }
}
export default [
  {
    path: '/filiais',
    name: 'filiais',
    component: () => import( /* webpackChunkName: "listaEmpresas" */ '@/views/filiais/ListaFiliais'),
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/filiais/nova',
    name: 'formularioFilialNova',
    component: () => import( /* webpackChunkName: "formularioEmpresa" */ '@/views/filiais/FormularioFilial'),
    meta: { requiresAuth: true, nivel: 2}
  },
  {
    path: '/filiais/editar',
    name: 'formularioFilialEditar',
    component: () => import( /* webpackChunkName: "formularioEmpresa" */ '@/views/filiais/FormularioFilial'),
    meta: { requiresAuth: true, nivel: 2}
  },
];
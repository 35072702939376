<template>
  <v-app-bar color="primary darken-2" fixed dark app>
    <v-app-bar-nav-icon @click.stop="handleDrawerToggle"></v-app-bar-nav-icon>
    <v-toolbar-title class="pa-1">{{ this.$store.state.empresaAtual.fantasia }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <span v-if="certificadoVencido">Certificado Vencido</span>
    <v-spacer></v-spacer>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon @click="handleFullScreen()">
          <v-icon>{{ !fullscreen ? "fullscreen" : "fullscreen_exit" }}</v-icon>
        </v-btn>
      </template>
      <span>Modo Tela Cheia</span>
    </v-tooltip>

    <v-menu v-if="usuario.nivel_acesso !== 'ANRON'" offset-y origin="right top" class="elelvation-1" :nudge-bottom="14" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn icon text v-on="on">
          <v-badge v-model="carregou" color="red" overlap>
            <span slot="badge" v-if="quantidadeNotificacoes > 0">{{ quantidadeNotificacoes }}</span>
            <v-icon medium>notifications</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <notification-list :notificacoes="notificacoes"></notification-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import NotificationList from "@/components/widgets/NotificationList";
import Util from "@/util";
import { mapGetters, mapState } from "vuex";

export default {
  name: "app-toolbar",
  components: {
    NotificationList,
  },

  data() {
    return { fullscreen: false };
  },

  computed: {
    ...mapState({
      usuario: "usuario",
      empresa: "empresaAtual",
      certificadoVencido: "certificadoVencido",
      carregando: "carregando",
    }),

    ...mapGetters("notificacao", {
      notificacoes: "notificacoesNaoLidas",
    }),

    carregou() {
      if (this.$store.state.notificacao.carregando) {
        return false;
      } else {
        return true;
      }
    },

    quantidadeNotificacoes() {
      let notificacoes = this.$store.getters["notificacao/notificacoesNaoLidas"];
      if (notificacoes) {
        return notificacoes.length;
      } else {
        return 0;
      }
    },

    toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },
  },

  methods: {
    handleDrawerToggle() {
      window.getApp.$emit("APP_DRAWER_TOGGLED");
    },

    handleFullScreen() {
      Util.toggleFullScreen();
      setTimeout(() => (this.fullscreen = document.fullscreenElement), 100);
    },

    executarSelecao(item) {
      if (item.href === "/sair") {
        this.$root.$confirmacao.open("Logout", "Tem certeza que deseja sair?").then((confirmado) => {
          if (confirmado) {
            this.$store.commit("logout", { router: this.$router });
          }
        });
      } else {
        this.$router.push(item.href);
      }
    },
  },

  created() {
    if (this.$store.state.usuario.nivel_acesso !== "ANRON") {
      this.$store.dispatch("notificacao/carregarNaoLidas");
    }
  },
};
</script>

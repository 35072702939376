<template>
<v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable eager>
  <v-card>
    <v-toolbar flat dark color="primary" dense>
      <v-toolbar-title>Buscar Produto</v-toolbar-title>
    </v-toolbar>
    <v-toolbar flat color="white">
      <v-text-field ref="input" flat filled rounded dense single-line hide-details clearable v-model="busca" @keyup.enter="buscarDescricaoProduto($event, busca)" max-width="300px" prepend-inner-icon="search" label="Buscar Produtos" @focus="onFocus"></v-text-field>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text style="height: 400px">
      <v-data-table hide-default-footer :headers="headers" :items="produtos" item-key="_id" :loading="carregando" disable-pagination>
        <template #item="{ item:produto }">
          <tr @click="selecionarProduto(produto)" style="cursor: pointer" class="pt-1" v-if="produto._id!=produtoOculto">
            <td class="text-center">{{ produto.codigo }}</td>
            <td>{{ produto.descricao }}</td>
            <td class="text-center">{{ produto.estoque | formatarValor }}</td>
            <td class="text-center">{{ produto.valor | formatarMoeda }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="fechar()" text>Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";

export default {
    props: {
      produtosProprios: {
        type: Boolean,
        default: false,
      },
      produtoOculto: {
        type: String,
        default: ''
      },
    },

    data(){
      return {
        headers: [
            { text: "Código", value: "codigo", sortable: false, width: "20%", align: 'center' },
            { text: "Descrição", value: "descricao", sortable: false, width: "50%" },
            { text: "Estoque", value: "estoque", sortable: false, width: "10%", align: 'center'  },
            { text: "Preço", value: "valor", sortable: false, width: "20%", align: 'center' },
        ],
        busca: '',
        dialog: false
      }
    },

    computed: {

      ...mapState('produto', {
        produtos: 'produtos_busca',
        carregando: 'carregando'
      })
    },

    mounted () {
      this.$store.commit('produto/setProdutosBusca', []);
    },

    methods: {

      buscarDescricaoProduto(e, descricao){
        let params = {
          busca: descricao,
          produtosProprios: this.produtosProprios,
          ncm: ''
        };

        this.$store.dispatch('produto/buscarDescricaoProduto', params);
        this.selecionarInput(e);
      },

      selecionarProduto(produto){
        this.$emit('produtoSelecionado', produto);
        this.fechar();
      },

      abrir(){
        this.dialog = true;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 500);
      },

      buscarProdutoNcm(ncm) {
        this.dialog = true;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 500);

        let params = {
          busca: '',
          produtosProprios: false,
          ncm: ncm
        };

        this.$store.dispatch('produto/buscarDescricaoProduto', params);
      },

      fechar(){
        this.dialog = false;
        this.$emit('fechar');
      },

      onFocus(e) {
        this.selecionarInput(e);
      },

      selecionarInput(e) {
        e.target.selectionStart = 0;
        e.target.selectionEnd   = e.target.value.length;
      }
    }
}
</script>
import ListaGrupo from '@/views/grupos/ListaGrupo';
import FormularioGrupo from '@/views/grupos/FormularioGrupo';

export default [
    {
      path: '/cadastros/grupos',
      name: 'grupos',
      component: ListaGrupo,
      meta: { requiresAuth: true, nivel: 1}
    },
    {
      path: '/cadastros/grupos/novo',
      name: 'formularioGrupoNovo',
      component: FormularioGrupo,
      meta: { requiresAuth: true, nivel: 1}
    },
    {
      path: '/cadastros/grupos/editar',
      name: 'formularioGrupoEditar',
      component: FormularioGrupo,
      meta: { requiresAuth: true, nivel: 1}
    }
];
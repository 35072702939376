import ListaLocaisEstoque from "@/views/locaisestoque/ListaLocaisEstoque";
import FormularioLocalEstoque from "@/views/locaisestoque/FormularioLocalEstoque";

export default [
  {
    path: "/tabelas/locaisEstoque",
    name: "locaisestoque",
    component: ListaLocaisEstoque,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/tabelas/locaisEstoque/novo",
    name: "formularioLocalEstoqueNovo",
    component: FormularioLocalEstoque,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/tabelas/locaisEstoque/editar",
    name: "formularioLocalEstoqueEditar",
    component: FormularioLocalEstoque,
    meta: { requiresAuth: true, nivel: 1 },
  },
];

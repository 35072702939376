import axios from '@/util/axios';
import constantes from '@/util/constantes';
import centroCustosApi from '@/services/centroCustosApi'

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {
    centrosCustos: [],
    centrocustoAtual: {
      descricao: '',
      subCentrosCustos: []
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await centroCustosApi.listar(options, rootState.empresaAtual);
        commit('SET_CENTROCUSTOS', response);
        commit('SET_TOTAL_CENTROCUSTOS', response.total);
      } catch (error) {
        commit('SET_CENTROCUSTOS', []);
        commit('setMensagemErro', 'Não foi possível listar os Centros de Custos', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({commit, state, rootState}){
      try{
        commit('SET_CARREGANDO');
        let centrocusto = Object.assign({}, state.centrocustoAtual);
        let response = await centroCustosApi.salvar(centrocusto, rootState.empresaAtual);
        commit('ATUALIZAR_CENTROCUSTO', response);
        commit('setMensagemSucesso', 'Centro de Custo salvo com sucesso', {root: true});
      } catch(erro) {
        commit('setMensagemErro', 'Erro ao salvar o Centro de Custo', {root: true});
        throw erro;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async atualizar({commit, rootState}, centrocusto) {
      try {
        commit('SET_CARREGANDO');
        let response = await centroCustosApi.atualizar(centrocusto, rootState.empresaAtual)
        commit('ATUALIZAR_CENTROCUSTO', response);
        commit('setMensagemSucesso', 'Centro de Custo atualizado com sucesso', {root: true});
      } catch(erro) {
        commit('setMensagemErro', 'Erro ao atualizar o Centro de Custo', {root: true});
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async excluir({commit, rootState}, centrocusto){
      try {
        commit('SET_CARREGANDO');
        await centroCustosApi.excluir(centrocusto._id, rootState.empresaAtual)
        commit('EXCLUIR_CENTROCUSTO', centrocusto._id);
        commit('setMensagemSucesso', 'Centro de Custo removido com sucesso', {root: true});
      } catch(erro) {    
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_CENTROCUSTOS(state, centroCustos) {
      state.centrosCustos = centroCustos;
      state.carregando = false;
    },
    SET_TOTAL_CENTROCUSTOS(state, total) {
      state.totalCentroCustos = total;
    },
    EXCLUIR_CENTROCUSTO(state, id) {
      let index = state.centrosCustos.findIndex(centrocusto => centrocusto._id == id);
      if (index > -1) {
        state.centrosCustos.splice(index, 1);
      }
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.centrocustoAtual = {
        descricao: '',
        subCentrosCustos: []
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.modoEdicao = true;
      state.centrocustoAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_CENTROCUSTO(state, centrocusto) {
      state.centrosCustos.unshift(centrocusto);
      state.carregando = false;
    },
    ATUALIZAR_CENTROCUSTO(state, centrocusto) {
      let centrocusto_encontrado = state.centrosCustos.find(centrocusto_ => centrocusto_.id == centrocusto.id);
      if (centrocusto_encontrado) {
        centrocusto_encontrado = centrocusto;
      }
      state.carregando = false;
    },
    adicionarSubcentro(state, subCentro){
      let _id = Math.floor((100000 + Math.random()) * 0x10000).toString(16)
      subCentro._id = _id;
      state.centrocustoAtual.subCentrosCustos.push(subCentro);
    },
    removerSubcentro(state, id){
      let index = state.centrocustoAtual.subCentrosCustos.findIndex(subcentro => subcentro._id == id);
      if (index >= 0) {
        state.centrocustoAtual.subCentrosCustos.splice(index, 1);
      }
    },
    estadoInicial(state){
      state.centrocustoAtual = {
        descricao: '',
        subCentrosCustos: []
      }
      state.carregando = false;
    }
  }
}
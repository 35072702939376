<template>
  <div>
    <v-dialog v-model="dialogo" max-width="1000px" persistent scrollable eager>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Detalhamento Produto</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab x-small color="primary" class="lighten-1" @click="mudarProdutoTela('primeiro')" :disabled="produtoAtual.indice_original == 0">
            <v-icon dark>mdi-arrow-collapse-left</v-icon>
          </v-btn>
          <v-btn fab x-small color="primary" class="lighten-1" style="margin-left: 8px" @click="mudarProdutoTela('anterior')" :disabled="produtoAtual.indice_original == 0">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn fab x-small color="primary" class="lighten-1" style="margin-left: 8px" @click="mudarProdutoTela('proximo')" :disabled="produtoAtual.indice_original == (compra.itens.length-1)">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
          <v-btn fab x-small color="primary" class="lighten-1" style="margin-left: 8px" @click="mudarProdutoTela('ultimo')" :disabled="produtoAtual.indice_original == (compra.itens.length-1)">
            <v-icon>mdi-arrow-collapse-right</v-icon>
          </v-btn>
        </v-toolbar>
        <v-banner single-line v-show="produtoAtual.produto==undefined && !produtoAtual.informativo">
          <v-icon slot="icon" color="error" size="36">mdi-alert</v-icon>
          <b class="red--text">Produto não cadastrado</b>
        </v-banner>
        <v-banner single-line v-show="produtoAtual.informativo">
          <v-icon slot="icon" color="blue" size="36">mdi-information</v-icon>
          <b class="blue--text">Produto informativo</b>
        </v-banner>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="Código" v-model="produtoAtual.codigo" readonly hide-details dense filled></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field label="Descrição" v-model="produtoAtual.descricao" readonly hide-details dense filled></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="Código Fornecedor" v-model="produtoAtual.codigo_entrada" readonly hide-details dense filled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="Unidade" v-model="produtoAtual.unidade" readonly hide-details dense filled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="NCM" v-model="produtoAtual.ncm" readonly hide-details dense filled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="Fator Conversão" readonly hide-details dense filled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-btn color="primary" :disabled="produtoAtual.produto!=undefined || produtoAtual.informativo" block large @click="abrirDialogoCadastroProduto">Cadastrar Produto</v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Quantidade" v-model="produtoAtual.quantidade" :decimais="2" ref="quantidade" @input="calcularSubtotalProduto" @keydown.native.enter="$refs.valor_custo.focus()" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor" v-model="produtoAtual.valor_custo" :decimais="2" ref="valor_custo" @input="calcularSubtotalProduto" @keydown.native.enter="$refs.descontos.focus()" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Subtotal" v-model="produtoAtual.subtotal" :decimais="2" ref="subtotal" readonly hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Descontos" :decimais="2" ref="descontos" @keydown.native.enter="$refs.seguro.focus()" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Seguro" :decimais="2" ref="seguro" @keydown.native.enter="$refs.frete.focus()" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Frete" :decimais="2" ref="frete" @keydown.native.enter="$refs.base_calculo_icms.focus()" hide-details dense filled></as-campo-valor>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Base Cálculo ICMS" v-model="produtoAtual.base_calculo_icms" :decimais="2" ref="base_calculo_icms" @keydown.native.enter="$refs.aliquota_icms.focus()" @blur="recalcularImpostos" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Alíquota ICMS" v-model="produtoAtual.aliquota_icms" :decimais="2" ref="aliquota_icms" @keydown.native.enter="$refs.valor_icms.focus()" @blur="recalcularImpostos" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor ICMS" v-model="produtoAtual.valor_icms" :decimais="2" ref="valor_icms" @keydown.native.enter="$refs.base_calculo_ii.focus()" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Base Cálculo II" v-model="produtoAtual.base_calculo_ii" :decimais="2" ref="base_calculo_ii" @keydown.native.enter="$refs.valor_ii.focus()" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor II" v-model="produtoAtual.valor_ii" :decimais="2" ref="valor_ii" @keydown.native.enter="$refs.aduaneira.focus()" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Aduaneira" v-model="produtoAtual.aduaneira" :decimais="2" ref="aduaneira" @keydown.native.enter="$refs.base_calculo_icms_st.focus()" hide-details dense filled></as-campo-valor>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Base Cálculo ICMS ST" v-model="produtoAtual.base_calculo_icms_st" :decimais="2" ref="base_calculo_icms_st" @keydown.native.enter="$refs.aliquota_icms_st.focus()" hide-details dense filled @blur="recalcularImpostos"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Alíquota ICMS ST" v-model="produtoAtual.aliquota_icms_st" :decimais="2" ref="aliquota_icms_st" @keydown.native.enter="$refs.valor_icms_st.focus()" hide-details dense filled @blur="recalcularImpostos"></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor ICMS ST" v-model="produtoAtual.valor_icms_st" :decimais="2" ref="valor_icms_st" @keydown.native.enter="$refs.base_calculo_ipi.focus()" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Base Cálculo IPI" v-model="produtoAtual.base_calculo_ipi" :decimais="2" ref="base_calculo_ipi" @keydown.native.enter="$refs.aliquota_ipi.focus()" @blur="recalcularImpostos" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="1">
              <as-campo-valor label="Alíquota IPI" v-model="produtoAtual.aliquota_ipi" :decimais="2" ref="aliquota_ipi" @keydown.native.enter="$refs.valor_ipi.focus()" @blur="recalcularImpostos" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor IPI" v-model="produtoAtual.valor_ipi" :decimais="2" ref="valor_ipi" @keydown.native.enter="$refs.icms_sn.focus()" hide-details dense filled></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="1">
              <as-campo-valor label="ICMS-SN" v-model="produtoAtual.icms_sn" :decimais="2" ref="icms_sn" hide-details dense filled></as-campo-valor>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-select label="CST" v-model="produtoAtual.cst_csosn" :items="constantes.CST_ICMS" ref="cst_csosn" hide-details dense filled></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2">
              <v-text-field label="CFOP" v-model="produtoAtual.cfop" ref="cfop" maxlength="4" @keydown.native.enter="buscarCfop" @blur="buscarCfop" hide-details dense filled></v-text-field>
            </v-col>
            <v-col cols="10">
              <v-text-field label="Descrição CFOP" v-model="produtoAtual.cfop_descricao" ref="cfop_descricao" readonly hide-details dense filled></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2">
              <v-text-field label="PIS" v-model="produtoAtual.pis" ref="pis" maxlength="2" @keydown.native.enter="buscarPis" @blur="buscarPis" hide-details dense filled></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field label="Descrição PIS" v-model="produtoAtual.pis_descricao" ref="pis_descricao" readonly hide-details dense filled></v-text-field>
            </v-col>
            <v-col cols="2">
              <as-campo-valor label="Valor PIS" v-model="produtoAtual.valor_pis" :decimais="2" ref="valor_pis" hide-details dense filled></as-campo-valor>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="2">
              <v-text-field label="COFINS" v-model="produtoAtual.cofins" ref="cofins" maxlength="2" @keydown.native.enter="buscarCofins" @blur="buscarCofins" hide-details dense filled></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field label="Descrição COFINS" v-model="produtoAtual.cofins_descricao" ref="cofins_descricao" readonly hide-details dense filled></v-text-field>
            </v-col>
            <v-col cols="2">
              <as-campo-valor label="Valor COFINS" v-model="produtoAtual.valor_cofins" :decimais="2" ref="valor_cofins" hide-details dense filled></as-campo-valor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-checkbox v-model="produtoAtual.informativo" class="mt-0" v-show="produtoAtual.produto==undefined && !compra.informativa" label="Produto Informativo, dispensa cadastro, não contabiliza no estoque" hide-details></v-checkbox>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="fecharDialogo">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogoCadastroProduto" max-width="1000px" scrollable eager persistent>
      <formulario-produto ref="formCadastroProduto" :cadastro-nfe="true" @fecharDialogo="fecharDialogoCadastroProduto"></formulario-produto>
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import FormularioProduto from '@/views/produtos/FormularioProduto';
import constantes from '@/util/constantes';

  export default {
  name: 'DialogoEditarProdutoCompra',
  components: {
    FormularioProduto,
  },
  data () {
    return {
      dialogo: false,
      dialogoCadastroProduto: false,
      produto: {},
      constantes: constantes,
    }
  },
  methods: {
    abrir () {
      this.dialogo = true;
      setTimeout(() => {
        this.$refs.quantidade.focus();
      }, 500);
    },
    calcularSubtotalProduto () {
      this.produtoAtual.subtotal = this.produtoAtual.quantidade * this.produtoAtual.valor_custo;
    },
    fecharDialogo () {
      this.dialogo = false;
    },
    mudarProdutoTela (botao_clicado) {
      let produto = {};
      let ultimo_indice = (this.compra.itens.length-1);

      if (botao_clicado == 'primeiro') {
        produto = this.compra.itens[0];
        produto.indice_original = 0;

      } else if (botao_clicado == 'ultimo') {
        produto = this.compra.itens[ultimo_indice];
        produto.indice_original = ultimo_indice;

      } else if (botao_clicado == 'anterior') {
        let indice_atual = this.produtoAtual.indice_original;
        let indice_anterior = indice_atual-1;
        let novo_indice = indice_anterior < 0 ? 0 : indice_anterior;

        produto = this.compra.itens[novo_indice];
        produto.indice_original = novo_indice;
      } else if (botao_clicado == 'proximo') {
        let indice_atual = this.produtoAtual.indice_original;
        let ultimo_indice = this.compra.itens.length-1;
        let proximo_indice = indice_atual+1;
        let novo_indice = proximo_indice > ultimo_indice ? ultimo_indice : proximo_indice;

        produto = this.compra.itens[novo_indice];
        produto.indice_original = novo_indice;
      }

      this.$store.commit('compra/setProduto', produto);
    },
    abrirDialogoCadastroProduto () {
      this.$refs.formCadastroProduto.preencherDadosProdutoNfe(this.produtoAtual);
      this.dialogoCadastroProduto = true;
    },
    fecharDialogoCadastroProduto (status) {
      if (status == 'cadastrado') {
        this.produtoAtual.codigo = this.produtoNfe.codigo;
        this.produtoAtual.descricao = this.produtoNfe.descricao;
        this.produtoAtual.unidade = this.produtoNfe.unidade;
        this.produtoAtual.ncm = this.produtoNfe.ncm.codigo;
        this.produtoAtual.produto = this.produtoNfe._id;
      }

      this.dialogoCadastroProduto=false;
    },
    buscarCfop () {
      this.$store.dispatch('compra/buscarCfop', this.produtoAtual.cfop);
    },
    recalcularImpostos() {
      this.$store.commit('compra/recalcularImpostos');
    },
    buscarPis() {
      let pis = this.constantes.PIS.find((pis_atual) => {
        return pis_atual.value == this.produtoAtual.pis
      });

      this.produtoAtual.pis_descricao = pis.text;
    },
    buscarCofins() {
      let cofins = this.constantes.COFINS.find((cofins_atual) => {
        return cofins_atual.value == this.produtoAtual.cofins
      });

      this.produtoAtual.cofins_descricao = cofins.text;
    },
  },
  computed: {
    ...mapState('compra', {
      compra: 'compraAtual',
      carregando: 'carregando',
      produtoAtual: 'produtoAtual'
    }),

    ...mapState('produto', {
      produtoNfe: 'produtoNfe'
    }),
  }
  }
</script>

<style>
  
</style>
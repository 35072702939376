<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-else v-model="valid" ref="formBaixaContaAPagar" lazy-validation>
      <dialog-pagamento :dialog="exibirDialogPagamento" :valorMaximo="valorMaximo" @fechar="exibirDialogPagamento = false" @adicionarPagamento="adicionarPagamento"></dialog-pagamento>
      <v-container fluid>
        <v-widget title="Informações do Fornecedor Selecionado">
          <template v-slot:widget-content>
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-text-field label="CNPJ/CPF" :value="fornecedor.cnpj_cpf | formatarCnpj" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="9">
                <v-text-field label="Razão Social do Fornecedor" :value="fornecedor.razao_nome" readonly></v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-widget>
        <v-widget title="Contas a Pagar em Aberto" class="mt-3">
          <template v-slot:widget-content>
            <v-data-table v-model="contasAPagarSelecionadas" :headers="headersContasAPagar" :items="contasAPagar" show-select item-key="documento" hide-default-footer :search="busca" :loading="carregando">
              <template v-slot:item="props">
                <tr>
                  <td>
                    <v-simple-checkbox :ripple="false" color="primary" :value="props.isSelected" @input="props.select($event)"></v-simple-checkbox>
                  </td>
                  <td>{{ props.item.documento }}</td>
                  <td><v-chip :color="verificarVencimento(props.item.dataVencimento)">{{ props.item.dataVencimento | formatarData }}</v-chip></td>
                  <td>{{ props.item.saldo | formatarMoeda }}</td>
                  <td>{{ props.item.valor | formatarMoeda }}</td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </v-widget>
        <v-widget title="Resumo de Pagamento" class="mt-3">
          <template v-slot:widget-header-action>
            <v-chip color="red" class="mr-3">A Pagar {{ valorAPagar | formatarMoeda}}</v-chip>
            <v-chip color="primary" class="mr-3">Pago {{ valorPago | formatarMoeda}}</v-chip>
            <v-chip color="warning" class="mr-3">Saldo {{ saldo | formatarMoeda}}</v-chip>
            <v-btn color="primary" @click="exibirDialog()" :disabled="contasAPagarSelecionadas.length === 0">Pagamento</v-btn>
          </template>
          <template v-slot:widget-content>
            <v-data-table :headers="headersPagamentos" :items="pagamentos" hide-default-footer>
              <template v-slot:item="props">
                <tr>
                  <td>{{ props.item.forma }}</td>
                  <td>{{ props.item.valor | formatarMoeda }}</td>
                  <td>
                    <v-icon @click="removerPagamento(props.item)">delete</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </template>
          <template v-slot:widget-footer-action>
            <v-btn @click="cancelar()">{{constantes.BOTAO_CANCELAR}}</v-btn>
            <v-btn color="primary" :loading="carregando" @click="baixar" :disabled="!valid || saldo != 0">Salvar</v-btn>
          </template>
        </v-widget>
      </v-container>
    </v-form>  
  </div>
</template>


<script>

import {mapState, mapGetters, mapActions} from "vuex";
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import formatador from '@/util/formatador';
import DialogPagamento from '@/views/contasapagar/DialogPagamento';
import moment from 'moment';

export default {
  
  components:{
    DialogPagamento
  },

  data() {
    return {
      loading: false,
      contasAPagarSelecionadas: [],
      headersContasAPagar: [
        { text: 'Documento', value: 'documento' },
        { text: 'Vencimento', value: 'dataVencimento' },
        { text: 'Saldo', value: 'saldo' },
        { text: 'Valor', value: 'valor' }
      ],
      headersPagamentos: [
        { text: 'Forma de Pagamento', value: 'forma' },
        { text: 'Valor', value: 'valor' },
        { text: 'Remover', value: 'name', sortable: false }
      ],
      pagamentos: [],
      busca: '',
      valid: false,
      validarDocumento: [v => !!v || "Documento Obrigatório"],
      validarValor: [v => (v && v.match('^[0-9]+(\,[0-9]{1,2})?$') !== null) || "Valor Inválido"],
      constantes: constantes,
      valorMaximo: 0,
      exibirDialogPagamento: false,
    }
  },

  computed: {

    ...mapState('contaAPagar', {
      fornecedor: 'fornecedorAtual',
      contasAPagar: 'contasAPagarFornecedor',
      carregando: 'carregando'
    }),

    valorAPagar(){
      let valorAPagar = this.contasAPagarSelecionadas.reduce((acumulador, conta) => acumulador + conta.saldo, 0);
      return conversor.stringParaNumero(formatador.formatarMoeda(valorAPagar));
    },
    valorPago(){
      return this.pagamentos.reduce((acumulador, pagamento) => acumulador + pagamento.valor, 0);
    },
    saldo(){
      let pagar = this.valorAPagar;
      let pago = this.valorPago;
      return pagar - pago;
    }
  },

  methods: {

    async carregarContasAPagarFornecedor(){
      await this.$store.dispatch('contaAPagar/carregarContasAPagarFornecedor');
    },

    verificarVencimento(vencimento){

      let emDia = moment().isBefore(vencimento);
      if(emDia){
        return 'primary';
      }
      else{
        return 'error';
      }
    },
    
    async baixar() {
      if (this.$refs.formBaixaContaAPagar.validate()) {
        try{
          await this.$store.dispatch('contaAPagar/salvarBaixaContaAPagar', { contasAPagar: this.contasAPagarSelecionadas, pagamentos: this.pagamentos});
          this.$router.push({ name: 'contasAPagar' });
          this.contasAPagarSelecionadas = [];
          this.pagamentos = [];
        }
        catch(erro){
          console.log(erro);
          console.log('Erro ao baixar');
        }
      }
    },

    cancelar() {
      this.$store.commit('contaAPagar/estadoInicialBaixa');
      this.$router.push({ name: 'contasAPagar' });
    },

    exibirDialog(){
      this.valorMaximo = this.saldo;
      this.exibirDialogPagamento = true;
    },

    adicionarPagamento(pagamento){

      let existe = this.pagamentos.find(pag => pag.forma === pagamento.forma);
      if(existe){
        existe.valor += pagamento.valor;
      }
      else{
        this.pagamentos.push(pagamento);
      }
      this.exibirDialogPagamento = false;
    },
    
    removerPagamento(pagamento){

      let index = this.pagamentos.findIndex(p => p.forma === pagamento.forma);
      if(index !== -1){
        this.pagamentos.splice(index, 1);
      }
    }
  },

  async created(){
    
    try{
      
      this.loading = true;
      
      let fornecedor = this.$store.state.contaAPagar.fornecedorAtual;
      let estaBaixando = this.$router.currentRoute.path.indexOf('baixar') !== -1 ? true : false;
  
      if(estaBaixando && (!fornecedor._id || fornecedor._id === '')){
        this.$store.commit('setMensagemErro', 'Selecione uma conta para baixar');
        this.$router.push({ name: 'contasAPagar' });
      }
      else if(estaBaixando){
        await this.carregarContasAPagarFornecedor();
      }

      if(!estaBaixando){
        this.$store.commit('contaAPagar/estadoInicialBaixa');
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
};
</script>
import Vue from 'vue'
import Router from 'vue-router'

import RotasCrud from './crud';

import RotasGeral from './geral';
import RotasSistema from './sistema';
import RotasProdutos from './produtos';
import RotasParticipantes from './participantes';
import RotasCofins from './cofins';
import RotasPis from './pis';
import RotasNotas from './notas';
import RotasManifestos from './manifestos';
import RotasFiliais from './filiais';
import RotasConfiguracoes from './configuracoes';
import RotasEmpresasAdmin from './empresas_admin';
import RotasTransportes from './transportes';
import RotasEstoques from './estoques';
import RotasIBPT from './ibpt';
import RotasCFOP from './cfop';
import RotasUsuarios from './usuarios';
import RotasDispositivos from './dispositivos';
import RotasGrupos from './grupos';
import RotasVendedores from './vendedores';
import RotasFabricantes from './fabricantes';
import RotasContasAReceber from './contasareceber';
import RotasContasAPagar from './contasapagar';
import RotasCaixa from './caixa';
import RotasCheques from './cheques';
import RotasVendas from './vendas';
import RotasCompras from './compras';
import RotasContasCorrentes from './contascorrentes';
import RotasBancos from './bancos';
import RotasCores from './cores';
import RotasTamanhos from './tamanhos';
import RotasMVAs from './mvas';
import RotasCentroCustos from './centroscustos';
import RotasCFOPSCompraEntrada from './cfopscompraentrada';
import RotasPlanosContas from './planoscontas';
import RotasLocaisEstoque from './locaisestoque';
import RotasPedidosClientes from './pedidosclientes';
import RotasOrdensServicos from './ordensservicos';
import RotasPedidosCompra from './pedidoscompra';
import RotaMovimentoProducao from './movimentoproducao';
import RotasRemessasCheques from './remessasCheques';
import RotaGraficos from './graficos';

import RouterGuards from './router_guards';

Vue.use(Router)

const router = new Router({
  scrollBehavior(to, from, savedPosition){
    return { x: 0, y: 0 };
  },

  routes: [
    {
      path: '*',
      name: 'Erro404',
      component: () => import( /* webpackChunkName: "Erro404" */ '@/views/404'),
      meta: { requiresAuth: true, nivel: 1}
    },
    ...RotasCrud,
    ...RotasGeral,
    ...RotasSistema,
    ...RotasProdutos,
    ...RotasParticipantes,
    ...RotasCofins,
    ...RotasPis,
    ...RotasNotas,
    ...RotasManifestos,
    ...RotasFiliais,
    ...RotasConfiguracoes,
    ...RotasEmpresasAdmin,
    ...RotasTransportes,
    ...RotasEstoques,
    ...RotasIBPT,
    ...RotasCFOP,
    ...RotasUsuarios,
    ...RotasDispositivos,
    ...RotasGrupos,
    ...RotasVendedores,
    ...RotasFabricantes,
    ...RotasContasAReceber,
    ...RotasContasAPagar,
    ...RotasCaixa,
    ...RotasCheques,
    ...RotasVendas,
    ...RotasCompras,
    ...RotasContasCorrentes,
    ...RotasBancos,
    ...RotasCores,
    ...RotasTamanhos,
    ...RotasMVAs,
    ...RotasCentroCustos,
    ...RotasCFOPSCompraEntrada,
    ...RotasPlanosContas,
    ...RotasLocaisEstoque,
    ...RotasPedidosClientes,
    ...RotasOrdensServicos,
    ...RotasPedidosCompra,
    ...RotaMovimentoProducao,
    ...RotasRemessasCheques,
    ...RotaGraficos
  ]
});

router.beforeEach(RouterGuards);

export default router;
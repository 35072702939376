import axios from '@/util/axios';
import constantes from '@/util/constantes';
import locaisEstoqueApi from '@/services/locaisEstoqueApi'

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {
    locaisEstoque: [],
    localEstoqueAtual: {
      nome: ''
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await locaisEstoqueApi.listar(options, rootState.empresaAtual);
        commit('SET_LOCAISESTOQUE', response);
        commit('SET_TOTAL_LOCAISESTOQUE', response.total);
      } catch (error) {
        commit('SET_LOCAISESTOQUE', []);
        commit('setMensagemErro', 'Não foi possível listar os Local de Estoques', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({commit, state, rootState}){
      commit('SET_CARREGANDO', true);

      try{
        let localEstoque = Object.assign({}, state.localEstoqueAtual);
        let response = await locaisEstoqueApi.salvar(localEstoque, rootState.empresaAtual);
        commit('ATUALIZAR_LOCALESTOQUE', response);
        commit('setMensagemSucesso', 'Local de Estoque salvo com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', 'Erro ao salvar o Local de Estoque', {root: true});
        throw erro;
      }
    },
    async atualizar({commit, rootState}, localEstoque) {
      await locaisEstoqueApi.atualizar(localEstoque, rootState.empresaAtual)
        .then((response) => {
          commit('ATUALIZAR_LOCALESTOQUE', localEstoque);
          commit('setMensagemSucesso', 'Local de Estoque atualizado com sucesso', {root: true});
        })
        .catch((erro) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', 'Erro ao atualizar o Local de Estoque', {root: true});
        });
    },
    async excluir({commit, rootState}, localEstoque){
      commit('SET_CARREGANDO', true);
      await locaisEstoqueApi.excluir(localEstoque._id, rootState.empresaAtual)
        .then((response) => {
          commit('EXCLUIR_LOCALESTOQUE', localEstoque._id);
          commit('setMensagemSucesso', 'Local de Estoque removido com sucesso', {root: true});
        })
        .catch((erro) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
        });
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_LOCAISESTOQUE(state, localEstoque) {
      state.locaisEstoque = localEstoque;
      state.carregando = false;
    },
    SET_TOTAL_LOCAISESTOQUE(state, total) {
      state.totalLocaisEstoque = total;
    },
    novo(state){
      state.modoEdicao = false;
      state.localEstoqueAtual = {
          nome: ''
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.modoEdicao = true;
      state.localEstoqueAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_LOCALESTOQUE(state, localEstoque) {
      state.locaisEstoque.unshift(localEstoque);
      state.carregando = false;
    },
    ATUALIZAR_LOCALESTOQUE(state, localEstoque) {
      let localEstoque_encontrado = state.locaisEstoque.find(localEstoque_ => localEstoque_.id == localEstoque.id);
      if (localEstoque_encontrado) {
        localEstoque_encontrado = localEstoque;
      }
      state.carregando = false;
    },
    EXCLUIR_LOCALESTOQUE(state, id) {
      let index = state.locaisEstoque.findIndex(localEstoque => localEstoque._id == id);
      if (index > -1) {
        state.locaisEstoque.splice(index, 1);
      }
      state.carregando = false;
    },
    estadoInicial(state){
      state.localEstoqueAtual = {
        nome: ''
      }
      state.carregando = false;
    }
  }
}
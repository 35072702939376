import configuracoesApi from '@/services/configuracoesApi'

export default{
  namespaced: true,

  state: {
    configuracoes: [],
    configuracaoAtual: {
      descricao: '',
      config: ''
    },
    carregando: false,
    totalConfiguracoes: 0,

    // CONFIGURAÇÕES EM USO
    configAniversariantes: false,
  },

  getters: {
    // 
  },

  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await configuracoesApi.listar(rootState.empresaAtual, options);
        commit('SET_CONFIGURACOES', response.configuracoes);
        commit('SET_TOTAL_CONFIGURACOES', response.total);
      } catch (error) {
        commit('SET_CONFIGURACOES', []);
        commit('setMensagemErro', 'Não foi possível listar as configurações', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({commit, state, rootState}){
      try{
        commit('SET_CARREGANDO', true);
        let configuracao = Object.assign({}, state.configuracaoAtual);
        let response = await configuracoesApi.salvar(rootState.empresaAtual, configuracao);
        commit('ADICIONAR_CONFIGURACAO', response);
        commit('nova');
        commit('setMensagemSucesso', 'Configuração salva com sucesso', {root: true});
      } catch(erro) {
        commit('setMensagemErro', "Erro ao salvar a configuração", {root: true});
        throw erro;
      } finally {
        commit('SET_CARREGANDO', false);        
      }
    },
    async consultarStatus({commit, rootState}, configuracao){
      try{
        let response = await configuracoesApi.consultarStatus(rootState.empresaAtual, configuracao);
        commit('CONFIG_ANIVERSARIANTES', response);
      } catch(erro) {
        commit('setMensagemErro', "Erro ao consultar o status da configuração", {root: true});
        throw erro;
      } finally {
        commit('SET_CARREGANDO', false);        
      }
    },
    async alterarStatus({commit, rootState, state}){
      try{
        commit('SET_CARREGANDO', true);
        let configuracao = Object.assign({}, state.configuracaoAtual);
        await configuracoesApi.alterarStatus(rootState.empresaAtual, configuracao);
        commit('setMensagemSucesso', 'Configuração atualizada com sucesso', {root: true});
      } catch(erro) {
        commit('setMensagemErro', "Erro ao alterar o status da configuração", {root: true});
        throw erro;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async excluir({commit, rootState}, configuracao){
      try {
        await configuracoesApi.excluir(rootState.empresaAtual, configuracao._id)
        commit('EXCLUIR_CONFIGURACAO', configuracao._id);
        commit('setMensagemSucesso', 'Cheque removido com sucesso', {root: true});
      } catch(erro) {
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
  },

  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_CONFIGURACOES(state, configuracoes) {
      state.configuracoes = configuracoes;
      state.carregando = false;
    },
    SET_TOTAL_CONFIGURACOES(state, total) {
      state.totalConfiguracoes = total;
    },
    SET_CONFIGURACAO_ATUAL(state, configuracao) {
      state.configuracaoAtual = configuracao;
    },
    ATUALIZAR_STATUS(state, atual){
      state.configuracoes.forEach(configuracao => {
        if(configuracao._id == atual._id){
          configuracao.ativo = atual.ativo;
        }
      });
      state.carregando = false;
    },
    nova(state){
      state.configuracaoAtual = {
        descricao: '',
        config: ''
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.configuracaoAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_CONFIGURACAO(state, configuracao) {
      state.configuracoes.unshift(configuracao);
      state.carregando = false;
    },
    EXCLUIR_CONFIGURACAO(state, id) {
      let index = state.configuracoes.findIndex(configuracao => configuracao._id == id);
      if (index > -1) {
        state.configuracoes.splice(index, 1);
      }
    },

    // CONFIGURAÇÕES EM USO
    CONFIG_ANIVERSARIANTES(state, valor) {
      state.configAniversariantes = valor;
    }
  }
}
export default [
  {
    path: '/configuracoes/usuarios',
    name: 'usuarios',
    component: () => import( /* webpackChunkName: "listaEmpresas" */ '@/views/usuarios/ListaUsuarios'),
    meta: { requiresAuth: true, nivel: 2}
  },
  {
    path: '/configuracoes/usuarios/novo',
    name: 'formularioUsuarioNovo',
    component: () => import( /* webpackChunkName: "formularioEmpresa" */ '@/views/usuarios/FormularioUsuario'),
    meta: { requiresAuth: true, nivel: 2}
  },
  {
    path: '/configuracoes/usuarios/editar',
    name: 'formularioUsuarioEditar',
    component: () => import( /* webpackChunkName: "formularioEmpresa" */ '@/views/usuarios/FormularioUsuario'),
    meta: { requiresAuth: true, nivel: 2}
  },
];

<template>
  <div>
    <v-text-field :loading="loading" label="Buscar Fabricante" :value="value" append-icon="search" @keyup.enter="abrirDialogo" @input="onInput" @click="abrirDialogo" @click:append="abrirDialogo" data-cy="campo-fabricante"></v-text-field>

    <dialog-buscar-fabricante ref="dialogoBuscarFabricante" @fabricanteSelecionado="adicionarFabricanteDialog" :busca="value"></dialog-buscar-fabricante>
  </div>
</template>

<script>
  
  import axios from "@/util/axios";
  import dialogBuscarFabricante from '@/components/dialogs/DialogBuscarFabricante';
  
  export default {
    name: 'BuscarFabricante',
    props: {
      value: {
        type: [Number,String],
        default: null
      },
    },

    components: {
      dialogBuscarFabricante,
    },

    data() {
      return {
        loading: false
      }
    },

    methods: {
      onInput(value) {
        this.$emit('input', value);
      },

      abrirDialogo() {
        this.$refs.dialogoBuscarFabricante.abrir();
      },

      /* consultarFabricante(event){

        let busca = event.target.value;
        
        this.loading = true;
        if (busca) {

          axios.get(`/ibpt/filtro/${ncm}`)
          .then(response => {
            if(response.data.length > 0){
              this.$emit('fabricanteSelecionado', response.data[0]);
            }
            else{
              this.$store.commit('setMensagemErro', 'Fabricante não encontrado');
            }
            this.loading = false;
          })
          .catch((erro) => {
            this.loading = false;
            this.$store.commit('setMensagemErro', erro.response.data.motivo);
          });
        } else {
          this.$refs.dialogoBuscarFabricante.abrir();
          this.loading = false;
        }
      }, */

      adicionarFabricanteDialog(fabricante) {
        this.$emit('fabricanteSelecionado', fabricante);
      }, 
    }
  }
</script>

<style scoped>

</style>
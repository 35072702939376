import Vuex from "vuex";
import Vue from "vue";

import axios from "@/util/axios";
import moment from "moment";
import constantes from "@/util/constantes";

import modules from "./modules";

function processError(error) {
  const error_object = {
    code: 0,
    message: "",
    stack: null,
  };

  if (typeof error == "string") {
    error_object.message = error;
  }

  if (typeof error == "object") {
    if (error.status) {
      error_object.code = error.status;
      error_object.message = error.statusText;
      error_object.stack = error.data;
    }

    if (error.name) {
      error_object.code = 0;
      error_object.message = `${error.name} - ${error.message}`;
      error_object.stack = error.stack;
    }
  }

  return error_object;
}

Vue.use(Vuex);

export default new Vuex.Store({
  modules,

  //equivalente ao data
  state: {
    //tudo que não pode ser isolado em um módulo vem aqui.
    usuario: {
      nome: "",
      email: "",
      foto: "",
      empresas: [],
      nivel_acesso: "",
    },
    empresaAtual: "",
    snackbar: {
      show: false,
      text: "",
      color: "",
    },
    statusSefaz: false,
    statusSefazMDFe: false,
    carregando: false,
    certificadoVencido: false,
    version: process.env.VERSION || 0,
  },

  //equivalente ao computed properties
  getters: {
    //tudo que não pode ser isolado em um módulo vem aqui.
    usuarioLogado(state) {
      if (state.usuario) {
        return state.usuario.nome + " - " + state.usuario.nivel_acesso;
      } else {
        return "";
      }
    },

    empresaAtual(state) {
      if (state.empresaAtual) {
        return state.empresaAtual; // posteriormente colocar nível de acesso.
      } else {
        return "";
      }
    },
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    definirEmpresaPadrao({ commit, rootState }, idEmpresa) {
      let empresa = {
        id: idEmpresa,
      };

      commit("setCarregando", true);
      axios
        .put("/usuarios/alterar_empresa_padrao", empresa)
        .then((response) => {
          let resultado = response.data;
          if (resultado.status == 200) {
            commit("setEmpresaPadrao", idEmpresa);
            commit("setMensagemSucesso", resultado.motivo, { root: true });
          } else {
            commit("setMensagemErro", resultado.motivo, { root: true });
          }
          commit("setCarregando", false);
        })
        .catch((erro) => {
          commit("setCarregando", false);
          commit("setMensagemErro", erro.response.data.motivo, { root: true });
        });
    },

    alterarEmpresa({ commit }, empresa) {
      commit("setCarregando", true);
      axios
        .get("/empresas/" + empresa._id + "/certificado")
        .then((response) => {
          let dias = moment(response.data.validade, "MMMM DD hh:mm:ss YYYY GMT").diff(moment(), "days");
          if (dias <= 30) {
            let mensagem = "";
            if (dias == 0) {
              mensagem = "Seu Certificado vence hoje";
            } else if (dias < 0) {
              mensagem = "Seu Certificado já venceu";
            } else {
              mensagem = "Seu Certificado se vence em " + dias + " dias.";
            }
            commit("setMensagemErro", mensagem);
          }

          if (dias < 0) {
            commit("setCertificadoVencido", true);
          } else {
            commit("setCertificadoVencido", false);
          }

          commit("setEmpresaAtual", empresa);
          commit("setCarregando", false);
        })
        .catch((erro) => {
          commit("setCarregando", false);
          commit("setMensagemErro", "Falha ao consultar Certificado", { root: true });
        });
    },

    //tudo que não pode ser isolado em um módulo vem aqui.
    alterarSenha({ commit, state }, dados) {
      commit("setCarregando", true);

      let atualizar = Object.assign({}, state.usuario);
      atualizar.nova_senha = dados.nova_senha;
      atualizar.senha = dados.senha_atual;

      axios
        .put("/usuarios", atualizar)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            commit("setCarregando", false);
            commit("logout", dados);
            commit("setMensagemSucesso", "Informações Alteradas com Sucesso", { root: true });
          }
        })
        .catch((erro) => {
          commit("setCarregando", false);
          commit("setMensagemErro", erro.response.data.motivo, { root: true });
        });
    },

    carregarFoto({ commit }, foto) {
      commit("setCarregando", true);
      var reader = new FileReader();
      var vm = this;
      reader.onload = (e) => {
        vm.commit("setFoto", e.target.result);
        vm.commit("setCarregando", false);
      };
      reader.readAsDataURL(foto);
    },

    consultarStatusServico({ commit, state }, notificar) {
      if (state.empresaAtual._id) {
        commit("setCarregando", true);
        axios
          .get("/servicos/status")
          .then((response) => {
            commit("setCarregando", false);
            if (notificar) {
              commit("setMensagemSucesso", response.data.motivo, { root: true });
            }
            if (response.data.status == 107) {
              commit("setStatusServico", true);
            } else {
              commit("setStatusServico", false);
            }
          })
          .catch((erro) => {
            commit("setCarregando", false);
            commit("setMensagemErro", erro.response.data.motivo, { root: true });
            commit("setStatusServico", false);
          });
      }
    },

    consultarStatusServicoMDFe({ commit, state }, notificar) {
      if (state.empresaAtual._id) {
        commit("setCarregando", true);
        axios
          .get("/servicos/mdfe/status")
          .then((response) => {
            commit("setCarregando", false);
            if (notificar) {
              commit("setMensagemSucesso", response.data.motivo, { root: true });
            }
            if (response.data.status == 107) {
              commit("setStatusServicoMDFe", true);
            } else {
              commit("setStatusServicoMDFe", false);
            }
          })
          .catch((erro) => {
            commit("setCarregando", false);
            commit("setMensagemErro", erro.response.data.motivo, { root: true });
            commit("setStatusServicoMDFe", false);
          });
      }
    },

    consultarDestinadas({ commit, state }) {
      if (state.empresaAtual._id) {
        commit("setCarregando", true);
        axios
          .get(`/destinadas/consultar_destinadas`)
          .then((response) => {
            commit("setCarregando", false);
          })
          .catch((erro) => {
            commit("setCarregando", false);
            commit("setMensagemErro", erro.response.data.motivo, { root: true });
          });
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    setCertificadoVencido(state, status) {
      state.certificadoVencido = status;
    },
    setEmpresaPadrao(state, idEmpresa) {
      state.usuario.empresa_padrao = idEmpresa;
      localStorage.setItem("usuario", JSON.stringify(state.usuario));
    },
    setAmbiente(state, ambiente) {
      state.empresaAtual.ambiente = ambiente;
      localStorage.setItem("empresaAtual", JSON.stringify(state.empresaAtual));
    },
    //tudo que não pode ser isolado em um módulo vem aqui.
    setUsuario(state, usuario) {
      state.usuario = usuario;
      if (usuario.empresas.length > 0) {
        usuario.empresas.forEach((element) => {
          if (element.empresa._id === usuario.empresa_padrao) {
            state.empresaAtual = element.empresa;
            state.usuario.nivel_acesso = element.nivel_acesso;
          }
        });

        // utilizado para recuperar a informação durante os reloads
        localStorage.setItem("usuario", JSON.stringify(usuario));
        localStorage.setItem("empresaAtual", JSON.stringify(state.empresaAtual));
      }
    },
    setUsuarioAdmin(state, usuario) {
      state.usuario = usuario;
      state.empresaAtual = {
        _id: "",
        fantasia: "",
        logotipo: constantes.LOGO_PADRAO,
        tema: "green",
      };

      // utilizado para recuperar a informação durante os reloads
      localStorage.setItem("usuario", JSON.stringify(usuario));
      localStorage.setItem("empresaAtual", JSON.stringify(state.empresaAtual));
    },
    setFoto(state, foto) {
      state.usuario.foto = foto;
    },
    setEmpresaAtual(state, empresa) {
      state.empresaAtual = {
        _id: empresa._id,
        ambiente: empresa.ambiente,
        fantasia: empresa.fantasia,
        logotipo: empresa.logotipo,
        plano: empresa.plano,
        tema: empresa.tema,
      };
      state.usuario.empresas.forEach((element) => {
        if (element.empresa._id === empresa._id) {
          state.usuario.nivel_acesso = element.nivel_acesso;
        }
      });

      // utilizado para recuperar a informação durante os reloads
      localStorage.setItem("usuario", JSON.stringify(state.usuario));
      localStorage.setItem("empresaAtual", JSON.stringify(state.empresaAtual));
    },
    logout(state, dados) {
      let tipo = state.usuario.nivel_acesso;

      state.usuario = null;
      state.empresaAtual = null;

      localStorage.removeItem("usuario");
      localStorage.removeItem("empresaAtual");
      localStorage.removeItem("token");

      if (tipo === "ANRON") {
        dados.router.push("/admin");
      } else {
        dados.router.push("/login");
      }
    },
    setCarregando(state, valor) {
      state.carregando = valor;
    },
    setStatusServico(state, valor) {
      state.statusSefaz = valor;
    },
    setStatusServicoMDFe(state, valor) {
      state.statusSefazMDFe = valor;
    },
    setMensagemErro(state, error) {
      error = processError(error);
      // console.error(error);

      state.snackbar.text = `Erro: ${error.code} - ${error.message}`;
      state.snackbar.color = "red";
      state.snackbar.show = true;
    },
    setMensagemSucesso(state, mens) {
      state.snackbar.text = mens;
      state.snackbar.color = "primary";
      state.snackbar.show = true;
    },
  },
});

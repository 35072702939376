import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual) {
    return axios.get(`/locaisEstoque?` + qs.stringify(options))
      .then(response => response.data);
  }

  function salvar(localEstoque, empresaAtual) {
    return axios.post(`/locaisEstoque`, localEstoque)
      .then(response => response.data);
  }

  function atualizar(localEstoque, empresaAtual) {
    return axios.put(`/locaisEstoque/${localEstoque._id}`, localEstoque)
      .then(response => response.data);
  }

  function excluir(id, empresaAtual) {
    return axios.delete(`/locaisEstoque/${id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    salvar,
    atualizar,
    excluir
  }
export default [
  {
    path: '/tabelas/cofins',
    name: 'cofins',
    component: () => import( /* webpackChunkName: "listaCofins" */ '@/views/cofins/ListaCOFINS'),
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/tabelas/cofins/novo',
    name: 'formularioCOFINSNovo',
    component: () => import( /* webpackChunkName: "formularioCofins" */ '@/views/cofins/FormularioCOFINS'),
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/tabelas/cofins/editar',
    name: 'formularioCOFINSEditar',
    component: () => import( /* webpackChunkName: "formularioCofins" */ '@/views/cofins/FormularioCOFINS'),
    meta: { requiresAuth: true, nivel: 1}
  },
];
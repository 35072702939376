<template>
<v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable eager>
  <v-card>
    <v-toolbar flat dark color="primary">
      <v-toolbar-title>Buscar Produto Depósito</v-toolbar-title>
    </v-toolbar>
    <v-toolbar flat color="white">
      <v-text-field ref="input" flat filled rounded dense single-line hide-details clearable v-model="busca" @keyup.enter="buscarDescricaoProduto($event, busca)" max-width="300px" prepend-inner-icon="search" label="Buscar Produtos" @focus="onFocus"></v-text-field>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text style="height: 400px">
      <v-data-table hide-default-footer :headers="headers" :items="produtos" item-key="_id" :loading="carregando" disable-pagination>
        <template #item="{ item:produto }">
          <tr @click="selecionarProduto(produto)" style="cursor: pointer" class="pt-1">
            <td>{{ produto.codigo | formatarPadZero(5) }}</td>
            <td>{{ produto.descricao }}</td>
            <td>{{ produto.unidade }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="fechar()" text>Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";
import axios from '@/util/axios';

export default {
    
    data(){
      return {
        headers: [
            { text: "Código", value: "codigo", sortable: false },
            { text: "Descrição", value: "descricao", sortable: false },
            { text: "Unidade", value: "unidade", sortable: false }
        ],
        busca: '',
        dialog: false,
        cnpjFilial: '',
        produtos: [],
        carregando: false
      }
    },

    computed: {

    },

    methods: {

      buscarDescricaoProduto(e, descricao){

        if(this.cnpjFilial && this.cnpjFilial !== ''){
          if(descricao !== ''){
            
            let empresa = this.$store.state.empresaAtual;
            axios.get(`/${empresa._id}/${this.cnpjFilial}/produtos/descricao/${descricao}`)
            .then(response => {
              this.produtos = response.data;
              this.carregando = false;
            })
            .catch(erro => {
              this.carregando = false;
              this.$store.commit('setMensagemErro', erro.response.data.motivo);
            });
  
            this.selecionarInput(e);
          }
          else{
            this.$store.commit('setMensagemErro', 'Busca vazia');
          }
        }
        else{
          this.$store.commit('setMensagemErro', 'Filial de Destino não informada');
        }
      },

      selecionarProduto(produto){
        this.$emit('produtoSelecionado', produto.historico);
        this.fechar();
      },

      abrir(cnpjFilial){
        this.cnpjFilial = cnpjFilial;
        this.dialog = true;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);
      },

      fechar(){
        this.dialog = false;
        this.$emit('fechar');
      },

      onFocus(e) {
        this.selecionarInput(e);
      },

      selecionarInput(e) {
        e.target.selectionStart = 0;
        e.target.selectionEnd   = e.target.value.length;
      }
    }
}
</script>
<template>
  <div>
    <v-container>
      <v-subheader class="text-subtitle-1 text--primary">
        MVAs
      </v-subheader>
      <v-card>
        <v-toolbar flat color="white">
          <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="novo">Novo</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="mvas" :search="busca" :loading="carregando">
          <template v-slot:item="{item}">
            <tr>
              <td>{{ item.ncm.codigo }} - {{ item.ncm.descricao }}</td>
              <td>{{ item.mercado_interno | formatarPercentual }}</td>
              <td>{{ item.mercado_externo_4 | formatarPercentual }}</td>
              <td>{{ item.mercado_externo_7 | formatarPercentual }}</td>
              <td>{{ item.mercado_externo_12 | formatarPercentual }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="item._id + 20" @click="editar(item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="item._id + 40" @click="excluir(item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
</div>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    name: "ListaMva",
    data () {
      return {
        headers: [
          { text: 'NCM', value: 'ncm.descricao' },
          { text: 'M. Interno', value: 'mercado_interno' },
          { text: 'M. Ext. 4%', value: 'mercado_externo_4' },
          { text: 'M. Ext. 7%', value: 'mercado_externo_7' },
          { text: 'M. Ext. 12%', value: 'mercado_externo_12' },
          { text: 'Ações', value: 'name', align: 'center', sortable: false }
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        }
      }
    },

    watch: {
      options: {
        handler () {
          this.listar();
        },
        deep: true,
      },
    },

    computed: {
      ...mapState('mva', {
        mvas: 'mvas',
        carregando: 'carregando',
        total: 'totalMvas'
      })
    },

    methods: {
      async listar(){
        await this.$store.dispatch('mva/listar', this.options);
      },

      excluir(conta){
        this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('mva/excluir', conta);
          }
        });
      },

      editar(mva){
        this.$store.commit('mva/editar', mva);
        this.$router.push({name: 'formularioMvaEditar'});
      },

      novo(){
        this.$store.commit('banco/novo');
        this.$router.push({name: 'formularioMvaNovo'});
      }
    },
  }
</script>
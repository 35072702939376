import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual) {
    return axios.get(`/bancos?` + qs.stringify(options))
      .then(response => response.data);
  }

  function salvar(banco, empresaAtual) {
    return axios.post(`/bancos`, banco)
      .then(response => response.data);
  }

  function atualizar(banco, empresaAtual) {
    return axios.put(`/bancos/${banco._id}`, banco)
      .then(response => response.data);
  }

  function excluir(id, empresaAtual) {
    return axios.delete(`/bancos/${id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    salvar,
    atualizar,
    excluir
  }
<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="nao" persistent>
    <v-card>
      <v-toolbar dark flat dense color="primary">
        <v-toolbar-title class="white--text">{{ titulo }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon style="cursor: pointer" @click="nao">close</v-icon>
      </v-toolbar>
      <v-card-text v-show="!!mensagem" style="min-height: 100px" class="pa-4">
        <div class="body-1">{{ mensagem }}</div>
      </v-card-text>
      <v-divider class="ml-4 mr-4"></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn depressed class="mr-2" @click.native="nao">Não</v-btn>
        <v-btn depressed color="primary darken-1" @click.native="sim" data-cy="confirmar">Sim</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    mensagem: null,
    titulo: null,
    options: {
      color: "primary",
      width: 500,
    },
  }),

  methods: {
    open(titulo, mensagem, options) {
      this.dialog = true;
      this.titulo = titulo;
      this.mensagem = mensagem;
      this.options = Object.assign(this.options, options);

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    sim() {
      this.resolve(true);
      this.dialog = false;
    },

    nao() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>

import axios from 'axios';
import config from './config';

const axiosInstance = axios.create({
    baseURL: config.URL_ANRON_API,
    timeout: config.TIMEOUT,
    withCredentials: true
});

axiosInstance.interceptors.request.use(
    config => {
        config.headers['x-acess-token'] = localStorage.getItem('token');
        return config;
    },
    error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  const response_error = {
    status: error.response.status,
    statusText: error.response.statusText,
    data: error.response.data
  }

  console.error('response_error: ', response_error);
  return Promise.reject(response_error);
});


export default axiosInstance;
import axios from '@/util/axios';
import axiosCep from 'axios';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import formatador from '@/util/formatador';

export default{
    
  namespaced: true,

    //equivalente ao data
  state: {

    participantes: [],
    participanteAtual: {
      tipo: constantes.CLIENTE_FORNECEDOR,
      cnpj_cpf: '',
      razao_nome: '',
      inscricao: '',
      rg: '',
      suframa: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      codigo_cidade: '',
      estado: '',
      pais: '',
      codigo_pais: '',
      telefone: '',
      celular: '',
      limite_credito: '',
      email: '',
      nascimento: '',
      foto: '',
      observacoes: '',
      produtoscontrato: [],
      data_cadastro: Date.now(),
      ativo: true
    },
    cidades: [],
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    carregando_cidade: false,
    totalParticipantes: 0
  },

    //equivalente ao computed properties
  getters: {

    estaEditando(state){
      return state.modoEdicao;
    },
    
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },

    listaCidades(state) {
      return state.cidades.map(cidade => { 
        return {
          text: cidade.cidade, 
          value: cidade.codigo_cidade 
        };
      });
    }
  }, 

    //equivalente aos methods
    //responsável por chamar a api externa
  actions: {

    async filtrarCidadesEstado({commit}, estado){

      try {

        commit('setCarregandoCidades', true);
        let cidades = await axios.get(`/cidades/estado/${estado}`).then(response => response.data);
        commit('setCidades', cidades);

      } catch (error) {
        commit('setCarregandoCidades', false);
        commit('setMensagemErro', 'Falha ao buscar cidades', {root: true});
        throw error;
      } finally {
        commit('setCarregandoCidades', false);
      }
    },
      
    consultarCadastro({commit, rootState, dispatch}, cnpj){

      let consulta = {
        cnpj: cnpj
      };

      commit('setCarregando', true);
      axios.post('/servicos/consultar_cadastro', consulta)
      .then((response) => {
        let resultado = response.data;
        if(resultado.status == '111'){
          commit('setDadosParticipante', resultado.empresa);
          let cep = resultado.empresa.endereco.cep;
          if(cep.length === 8){
            commit('setCarregando', true);
            axiosCep.get(`https://viacep.com.br/ws/${cep}/json/`)
            .then(responseCep => {
              commit('setCarregando', false);
              commit('setEndereco', responseCep.data);
              dispatch('filtrarCidadesEstado', responseCep.data.uf)
            })
            .catch(error => {
              commit('setCarregando', false);
            });
          }

          if(resultado.empresa.situacao == 0){
            commit('setMensagemErro', 'Contribuinte não habilitado na SEFAZ', {root: true});        
          }
        }
        commit('setCarregando', false);
      })
      .catch((erro) => {
        console.log(erro);
        commit('setCarregando', false);
        commit('setMensagemErro', 'Falha ao consultar CNPJ', {root: true});
      });
    },
      
    carregar({commit, rootState}, options){

      commit('setCarregando', true);

      let url = `/participantes?page=${options.page}&pageSize=${options.itemsPerPage}`;
      if(options.query && options.query !== ''){
        url = `${url}&query=${options.query}`;
      }

      axios.get(url)
      .then((response) => {
        commit('setTotalParticipantes', response.data.total);
        commit('setParticipantes', response.data.participantes);
      })
      .catch((erro) => {
        commit('setParticipantes', []);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });  
    },

    carregarClientes({commit, rootState}){

      commit('setCarregando', true);
      axios.get('/participantes/tipos/Cliente')
      .then((response) => {
        commit('setParticipantes', response.data);
      })
      .catch((erro) => {
        commit('setParticipantes', []);
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });  
    },

    buscarNomeCliente({commit, rootState}, busca){

      commit('setCarregando', true);
      axios.get(`/participantes/clientes/filtro/${busca}`)
      .then((response) => {
        commit('setParticipantes', response.data);
      })
      .catch((erro) => {
        commit('setParticipantes', []);
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });  
    },

    async salvar({commit, state, rootState}){
        
      commit('setCarregando', true);

      let participante = Object.assign({}, state.participanteAtual);
      participante.limite_credito = conversor.stringParaNumero(participante.limite_credito);
      participante.cnpj_cpf = participante.cnpj_cpf.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
      participante.cep = participante.cep.replace('.', '').replace('-', '');
      participante.telefone = participante.telefone.replace('(', '').replace(')', '').replace('-', '');
      participante.celular = participante.celular.replace('(', '').replace(')', '').replace('-', '');

      if(state.modoEdicao){
        try {
          let response = await axios.put(`/participantes`, participante);
          let retorno = response.data;
          if(retorno.status){
            commit('setMensagemErro', retorno.motivo, {root: true});
            commit('setCarregando', false);
          }
          else{
            commit('atualizar', retorno);
            commit('setMensagemSucesso', 'Cliente/Fornecedor Atualizado com Sucesso', {root: true});
          }
        } catch (erro) {
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        } finally {
          commit('setCarregando', false);
        }
      }
      else{
        try {
          delete(participante._id);
          let response = await  axios.post('/participantes', participante);
          console.log(response);
          let retorno = response.data;
          if(retorno.status){
            commit('setMensagemErro', retorno.motivo, {root: true});
            commit('setCarregando', false);
          }
          else{
            commit('adicionar', retorno);
            commit('setMensagemSucesso', 'Cliente/Fornecedor Cadastrado com Sucesso', {root: true});
          }
        } catch (erro) {
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        } finally {
          commit('setCarregando', false);
        }
      }
    },

    remover({commit, rootState}, remover){
      commit('setCarregando', true);
      axios.delete('/participantes', {
        data: { _id: remover._id }
      })
      .then((response) => {
        commit('remover', remover);
        commit('setMensagemSucesso', 'Cliente/Fornecedor Removido com Sucesso', {root: true});
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    }
  },

    //responsável por alterar o state.
  mutations: {

    setCidades(state, cidades){
      state.cidades = cidades;
    },
    setCidade(state, codigo_cidade){
      let cidade = state.cidades.find(cidade => cidade.codigo_cidade == codigo_cidade);

      state.participanteAtual.cidade = cidade.cidade;
      state.participanteAtual.codigo_cidade = cidade.codigo_cidade;
      state.participanteAtual.codigo_estado = cidade.codigo_estado;
      state.participanteAtual.pais = 'Brasil';
      state.participanteAtual.codigo_pais = 1058;
    },
    setParticipantes(state, participantes){
      state.participantes = participantes;
      state.carregando = false;
    },
    setTotalParticipantes(state, total){
      state.totalParticipantes = total;
    },
    setDadosParticipante(state, participante){
      state.participanteAtual.inscricao = participante.ie;
      state.participanteAtual.razao_nome = participante.razao;
      state.participanteAtual.numero = participante.endereco.numero;
      state.participanteAtual.cep = participante.endereco.cep;
    },

    setEndereco(state, endereco){
      state.participanteAtual.logradouro = endereco.logradouro;
      state.participanteAtual.bairro = endereco.bairro;
      state.participanteAtual.estado = endereco.uf;
      state.participanteAtual.cidade = endereco.localidade;
      state.participanteAtual.codigo_cidade = endereco.ibge;
      state.participanteAtual.pais = 'Brasil';
      state.participanteAtual.codigo_pais = 1058;
    },

    setCarregando(state, valor){
      state.carregando = valor;
    },

    setCarregandoCidades(state, valor){
      state.carregando_cidade = valor;
    },
    remover(state, removido){
      state.participantes = state.participantes.filter(participante => participante._id !== removido._id);
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.participanteAtual = {
        tipo: constantes.CLIENTE_FORNECEDOR,
        cnpj_cpf: '',
        razao_nome: '',
        inscricao: '',
        rg: '',
        suframa: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        codigo_cidade: '',
        estado: '',
        pais: '',
        codigo_pais: '',
        telefone: '',
        celular: '',
        limite_credito: '',
        email: '',
        nascimento: '',
        foto: '',
        observacoes: '',
        produtoscontrato: [],
        data_cadastro: Date.now(),
        ativo: true
      }
    },
    editar(state, participante){
      participante.limite_credito = formatador.formatarValor(participante.limite_credito);
      if (!participante.produtoscontrato) {
        delete participante.produtocontrato
        participante.produtoscontrato = [];
      }
      state.modoEdicao = true;
      state.participanteAtual = participante;
    },
    adicionar(state, participante){
      state.participantes.push(participante);
      state.carregando = false;
    },
    atualizar(state, atual){
      state.participantes.forEach(participante => {
        if(participante._id === atual._id){
            participante = atual;
        }
      });
      state.carregando = false;
    },
    ADICIONAR_PRODUTO_CONTRATO(state, produto){
      if (state.participanteAtual.produtoscontrato.length > 0) {
        let index = state.participanteAtual.produtoscontrato.findIndex(produto_ => produto_._id == produto._id);
        if (index > -1) {
          state.participanteAtual.produtoscontrato.splice(index, 1, produto);
        } else {
          state.participanteAtual.produtoscontrato.unshift(produto);
        }
      } else {
        state.participanteAtual.produtoscontrato.unshift(produto);
      }
      state.carregando = false;
    },
    EXCLUIR_PRODUTO_CONTRATO(state, id){
      let index = state.participanteAtual.produtoscontrato.findIndex(produto => produto._id == id);
      if (index > -1) {
        state.participanteAtual.produtoscontrato.splice(index, 1);
      }
      state.carregando = false;
    },
    estadoInicial(state){
      state.participanteAtual = {
        tipo: constantes.CLIENTE_FORNECEDOR,
        cnpj_cpf: '',
        razao_nome: '',
        inscricao: '',
        rg: '',
        suframa: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        codigo_cidade: '',
        estado: '',
        pais: '',
        codigo_pais: '',
        telefone: '',
        celular: '',
        limite_credito: '',
        email: '',
        nascimento: '',
        foto: '',
        observacoes: '',
        produtoscontrato: [],
        data_cadastro: Date.now(),
        ativo: true
      }
      state.cidades = [];
    }
  }
}
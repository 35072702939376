<template>
  <div>
    <v-text-field :loading="loading" :label="label" ref="cnpjParticipante" v-mask="['###.###.###-##', '##.###.###/####-##']" :value="value" append-icon="search" @keyup.enter="consultarCnpjParticipante($event)" @input="onInput" @click:append="abrirDialogo " @click="abrirDialogo"> </v-text-field>

    <dialog-buscar-participante ref="dialogBuscarParticipante" @participanteSelecionado="adicionarParticipanteDialog" @fechar="dialogo = !dialogo"></dialog-buscar-participante>
  </div>
</template>

<script>
  
  import axios from "@/util/axios";
  import DialogBuscarParticipante from '@/components/dialogs/DialogBuscarParticipante';
  import constantes from '../util/constantes';
  
  export default {
    name: 'BuscarParticipante',
    props: {
      value: {
        type: [Number,String],
        default: null
      },
      label: {
        type: String,
        default: 'CNPJ/CPF'
      },
    },

    components: {
      DialogBuscarParticipante,
    },

    data() {
      return {
        dialogo: false,
        loading: false
      }
    },

    methods: {
      focus() {
        this.$refs.cnpjParticipante.focus();
      },

      onInput(value) {
        this.$emit('input', value);
      },

      abrirDialogo() {
        this.$refs.dialogBuscarParticipante.abrir();
      },

      consultarCnpjParticipante(event){
        let cnpjCpf = event.target.value;
        if(cnpjCpf && cnpjCpf.length >= 14 && cnpjCpf.length <= 18){

          cnpjCpf = cnpjCpf.replace('.', '').replace('.', '').replace('/', '').replace('-', '');

          this.loading = true;
          let empresa = this.$store.state.empresaAtual;
          axios.get(`/${empresa._id}/participantes/tipos/${constantes.CLIENTE}/cnpj_cpf/${cnpjCpf}`)
          .then(response => {
            this.$emit('participanteSelecionado', response.data);
            this.loading = false;
          })
          .catch(erro => {
            this.loading = false;
            // this.$store.commit('setMensagemErro', erro.response.data.motivo);
          });
        }
        else{
          // commit('setMensagemErro', 'CNPJ/CPF Incompleto', {root: true});
        }
      },

      adicionarParticipanteDialog(participante) {
        this.$emit('participanteSelecionado', participante);
      }, 
    }
  }
</script>

<style scoped>

</style>
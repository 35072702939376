<template>
<v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable>
  <v-card>
    <v-toolbar flat dark color="primary">
      <v-toolbar-title>Buscar CEST</v-toolbar-title>
    </v-toolbar>
    <v-toolbar flat color="white">
      <v-text-field ref="input" flat filled rounded dense single-line hide-details clearable v-model="busca" @keyup.enter="buscarDescricaoCest(busca)" max-width="300px" prepend-inner-icon="search" label="Buscar CEST" @focus="onFocus"></v-text-field>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text style="height: 400px">
      <v-data-table hide-default-footer :headers="headers" :items="cests" item-key="_id" :loading="carregando" disable-pagination>
        <template #item="{ item }">
          <tr @click="selecionarCest(item)" style="cursor: pointer" class="pt-1">
            <td>{{ item.cest | formatarPadZero(5) }}</td>
            <td>{{ item.descricao }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="fechar()" text>Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";
import formatador from '../../util/formatador';

export default {

  props: {
    busca: {
      type: [Number,String],
      default: null
    },
  },
    
  data(){
    return {
      headers: [
        { text: "Código", value: "codigo", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false }
      ],
      // busca: '',
      dialog: false
    }
  },

  computed: {

    ...mapState('cest', {
      cests: 'cests',
      carregando: 'carregando'
    })
  },

  mounted () {
    this.$store.commit('cest/setCests', []);
  },

  methods: {

    ...mapActions('cest', {
      buscarDescricaoCest: 'buscarDescricaoCest'
    }),

    selecionarCest(cest){
      this.$emit('cestSelecionado', cest);
      this.fechar();
    },

      abrir(){
        this.dialog = true;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);

        if (this.busca) {
          this.buscarDescricaoCest(this.busca);
        }
      },

      fechar(){
        this.dialog = false;
        this.$emit('fechar');
      },

      onFocus(e) {
        this.selecionarInput(e);
      },

      selecionarInput(e) {
        e.target.selectionStart = 0;
        e.target.selectionEnd   = e.target.value.length;
      }
  }
}
</script>
<template>
  <v-form ref="formUsuario" lazy-validation>
    <v-container grid-list-md fluid>
      <v-widget title="Minha Conta" :temCampoObrigatorio="true">
        <div slot="widget-content">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-card class="mb-4 pa-4">
                <v-img contain height="200" v-bind:src="usuario.foto" @click="escolherArquivo()" style="cursor: pointer"></v-img>
                <v-card-actions>
                  <input id="inputArquivo" type="file" :multiple="false" hidden @change="onFileSelected" />
                  <v-btn icon @click="escolherArquivo()">
                    <v-icon>edit</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="Nome" v-model="usuario.nome" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="E-mail" v-model="usuario.email" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="Nível de Acesso" v-model="usuario.nivel_acesso" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field type="password" label="Senha Atual *" v-model="senha_atual"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field type="password" label="Nova Senha *" v-model="senha"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field type="password" label="Confirmar Senha *" v-model="confirmacao"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <template v-slot:widget-footer-action>
          <v-card-actions class="pa-2">
            <v-spacer></v-spacer>
            <v-btn depressed @click="cancelar()" class="mr-2">{{ constantes.BOTAO_CANCELAR }}</v-btn>
            <v-btn depressed color="primary" :loading="carregando" :disabled="!valido" @click="enviar()">Salvar</v-btn>
          </v-card-actions>
        </template>
      </v-widget>
    </v-container>
  </v-form>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import constantes from "@/util/constantes";

export default {

  data: () => ({
    constantes: constantes,
    senha_atual: "",
    senha: "",
    confirmacao: "",
    foto: "",
  }),

  computed: {
    ...mapState({
      usuario: "usuario",
      carregando: "carregando",
    }),

    valido() {
      if (this.senha !== "" && this.confirmacao !== "" && this.senha === this.confirmacao) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    enviar() {
      this.$store.dispatch("alterarSenha", { router: this.$router, senha_atual: this.senha_atual, nova_senha: this.senha });
    },

    cancelar() {
      this.$router.go(-1);
    },

    enviarConteudo(dados) {
      this.$store.commit("setFoto", dados);
    },

    onFileSelected(evento) {
      const arquivo = evento.target.files[0];
      if (arquivo) {
        this.$store.dispatch("carregarFoto", arquivo);
      }
    },

    escolherArquivo() {
      document.getElementById("inputArquivo").click();
    },
  },
};
</script>

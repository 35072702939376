<template>
  <div>
    <v-container fluid>
      <v-card>  
        <v-toolbar flat color="white">
          <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrarVendedores($event)" max-width="300px" prepend-inner-icon="search" label="Buscar Vendedor"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="novo">Novo Vendedor</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="vendedores" :search="busca" :loading="carregando">
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.nome }}</td>
              <td>{{ props.item.comissao | formatarPercentual }}</td>
              <td><v-icon :color="props.item.ativo === true ? 'primary' : 'red'">brightness_1</v-icon></td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="props.item._id + 20" @click="editar(props.item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="props.item._id + 40" @click="excluir(props.item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
    
</template>

<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    data () {
      return {
        headers: [
          { text: 'Nome', value: 'nome' },
          { text: 'Comissão', value: 'comissao' },
          { text: 'Ativo', value: 'ativo' },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        }
      }
    },

    watch: {
      options: {
        handler () {
          this.listar();
        },
        deep: true,
      },
    },

    computed: {
      ...mapState('vendedor', {
        vendedores: 'vendedores',
        carregando: 'carregando',
        total: 'totalVendedores'
      })
    },

    methods: {
      ...mapActions('vendedor', {
        editarVendedor: 'editar',
        novoVendedor: 'novo'
      }),

      listar(){
        this.$store.dispatch('vendedor/listar', this.options);
      },

      limparBusca(){
        this.listar();
      },

      filtrarVendedores(event){
        let newOptions = {
          ...this.options,
          query: this.busca
        }

        this.$store.dispatch('vendedor/listar', newOptions);
        setTimeout(() => {
          event.target.select();
        }, 300);
      },

      excluir(vendedor){
        this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('vendedor/excluir', vendedor);
          }
        });
      },

      editar(vendedor){
        this.$store.commit('vendedor/editar', vendedor);
        this.$router.push({name: 'formularioVendedorEditar'});
      },

      novo(){
        this.$store.commit('vendedor/novo');
        this.$router.push({name: 'formularioVendedorNovo'});
      }
    }
  }
</script>
import ListaFabricantes from '@/views/fabricantes/ListaFabricantes';
import FormularioFabricante from '@/views/fabricantes/FormularioFabricante';

export default [
    {
      path: '/cadastros/fabricantes',
      name: 'fabricante',
      component: ListaFabricantes,
      meta: { requiresAuth: true, nivel: 1}
    },
    {
      path: '/cadastros/fabricantes/novo',
      name: 'formularioFabricanteNovo',
      component: FormularioFabricante,
      meta: { requiresAuth: true, nivel: 1}
    },
    {
      path: '/cadastros/fabricantes/editar',
      name: 'formularioFabricanteEditar',
      component: FormularioFabricante,
      meta: { requiresAuth: true, nivel: 1}
    },
];
const Menu =  [
    { cabecalho: 'Início', nivel: 1 },
    {
      titulo: 'Painel',
      grupo: 'Início',
      icone: 'dashboard',
      nome: 'Dashboard',
      path: '/home',
      nivel: 1
    },
    { cabecalho: 'Cadastros', nivel: 1 },
    { 
      nome: 'Filiais', 
      titulo: 'Filiais', 
      path: '/filiais', 
      icone: 'domain', 
      grupo: 'Cadastros', 
      nivel: 1
    },
    { 
      nome: 'Estoques', 
      titulo: 'Estoques', 
      path: '/movimentacoes/estoquesDeposito', 
      icone: 'mdi-home-city', 
      grupo: 'Cadastros', 
      nivel: 1 
    },
    { cabecalho: 'Fiscal', nivel: 1 },
    {
      titulo: 'Notas Fiscais',
      grupo: 'Fiscal',
      nome: 'Nota',
      icone: 'chrome_reader_mode',
      path: '/notas',
      nivel: 1
    },
    {
      titulo: 'Manifesto de Documentos',
      grupo: 'Fiscal',
      nome: 'Manifestos',
      icone: 'cached',
      path: '/manifestos',
      nivel: 1
    },
    {
      titulo: 'Emitente',
      grupo: 'Emitente',
      nome: 'Emitente',
      icone: 'domain',
      path: '/emitente',
      nivel: 2
    }
  ];
  
  export default Menu;
  
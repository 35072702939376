import constantes from './constantes';
import moment from 'moment';

moment.locale('pt-br');

class Conversor{
    
    tempoDecorrido(data){
        if(data){
            return moment(data).fromNow();
        }
        else{
            return '';
        }
    }

    stringParaNumero(strValor){

        let retorno = strValor = strValor + '';
        retorno = retorno.replace('%', '');
        retorno = retorno.replace('.', '');
        retorno = retorno.replace(',', '.');
        retorno = retorno.replace('R$', '');
        retorno = retorno.trim();

        return Number(retorno);
    }

    ufParaCodigo(uf){
        let encontrou = constantes.ESTADOS.find(elemento => { return elemento.text === uf });
        if(encontrou){
            return encontrou.value;
        }
        else{
            return -1;
        }
    }

    codigoParaUf(codigo){
        let encontrou = constantes.ESTADOS.find(elemento => { return elemento.value === codigo});
        if(encontrou){
            return encontrou.text;
        }
        else{
            return '';
        }
    }

    ufParaNome(uf){
        let encontrou = constantes.ESTADOS_NOME.find(elemento => { return elemento.text === uf});
        if(encontrou){
            return encontrou.value;
        }
        else{
            return '';
        }
    }

    codigoParaModeloFrete(codigo){
        let retorno = '';
        if(codigo == 0){
            retorno = '0-Emitente';
        }
        else if(codigo == 1){
            retorno = '1-Dest/Rem';
        }
        else if(codigo == 2){
            retorno = '2-Terceiros';
        }
        else if(codigo == 9){
            retorno = '9-Sem Frete'
        }
        return retorno;
    }

    codigoParaAmbiente(codigo){
        let encontrou = constantes.AMBIENTES.find(elemento => { return elemento.value === codigo});
        if(encontrou){
            return encontrou.text;
        }
        else{
            return '';
        }
    }

    codigoParaTipoOperacao(codigo){

        let encontrou = constantes.TIPOS_NOTA.find(elemento => { return elemento.value == codigo});
        if(encontrou){
            return encontrou.text;
        }
        else{
            return '';
        }
    }
}

let conversor = new Conversor();

export default conversor;
<template>

  <div>
    <v-text-field v-model="data_formatada" v-bind="$attrs">
      <template v-slot:prepend-inner>
        <!-- <v-btn icon color="primary"> -->
          <v-icon @click="modal = !modal">mdi-calendar</v-icon>
        <!-- </v-btn> -->
      </template>
    </v-text-field>
      
    <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px">
      <v-date-picker v-model="date" scrollable @change="onChange">
        <v-btn icon color="primary" @click="setHoje">
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false">Cancelar</v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
      </v-date-picker>
    </v-dialog>
    
  </div>


</template>

<script>

  export default {
    name: 'as-date-picker',
    props: {
      value: {
        type: String,
        default: null
      },
    },
    data() {
      return {
        data_formatada: null,
        menu: false,
        modal: false,
        // date: '2019-02-01'
      }
    },

    methods: {
      onChange(value) {
        this.data_formatada = this.dataBR(value);
        this.menu = false;
      },

      setHoje() {
        let data = new Date();
        this.date = data.getFullYear() + '-' + (data.getMonth() + 1).toString().padStart(2, '0') + '-' + data.getDate().toString().padStart(2, '0');
      },

      dataBR(date) {
        if (!date) {
          return '';
        }
        
        return (new Date(date)).toLocaleDateString('pt-br', { timeZone: 'GMT' });
      },
    },

    computed: {
      date: {
        get () {
          this.data_formatada = this.dataBR(this.value);
          return this.value ? this.value.substr(0, 10) : null;
        },
        set (val) {
          // this.dateFormatted = this.formatDate(val);
          this.$emit('input', val);
        }
      },
    },

  }
</script>

<style scoped>

</style>
import ListaVendas from "@/views/vendas/ListaVendas";
import VendasDetalhe from "@/views/vendas/VendasDetalhe";
import FormularioVenda from "@/views/vendas/FormularioVenda";
import FormularioAcertoVenda from '@/views/vendas/FormularioAcertoVenda';


export default [
  {
    path: "/movimentacoes/vendas",
    name: "vendas",
    component: ListaVendas,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/movimentacoes/vendas/detalhar",
    name: "vendasDetalhe",
    component: VendasDetalhe,
    meta: { requiresAuth: true, nivel: 1 },
  },
 /*  {
    path: "/movimentacoes/vendas/nova",
    name: "formularioVendaNova",
    component: FormularioVenda,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/movimentacoes/vendas/editar",
    name: "formularioVendaEditar",
    component: FormularioVenda,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/movimentacoes/vendas/acerto",
    name: "formularioVendaAcerto",
    component: FormularioAcertoVenda,
    meta: { requiresAuth: true, nivel: 1 },
  }, */
];

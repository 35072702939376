import axios from '@/util/axios';
import formatador from '@/util/formatador';

export default{
    
    namespaced: true,

    //equivalente ao data
    state: {

        carregando: false,
        destinadas: [],
        destinadaAtual: {

        },
        totalDestinadas: 0
    },

    //equivalente ao computed properties
    getters: {

    },

    //equivalente aos methods
    //responsável por chamar a api externa
    actions: {

        carregarDestinadas({commit, rootState}, options){


            let url = `/destinadas/listar_destinadas?page=${options.page}&pageSize=${options.itemsPerPage}`;
            if(options.query && options.query !== ''){
                url = `${url}&query=${options.query}`;
            }
            if(options.status){
                url = `${url}&status=${options.status}`;
            }

            commit('setCarregando', true);
            axios.get(url)
            .then((response) => {
                commit('setDestinadas', response.data.destinadas);
                commit('setTotalDestinadas', response.data.total);
            })
            .catch((erro) => {
                commit('setDestinadas', []);
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        },

        downloadNfe({commit, rootState}, nota){

            let consulta = {
                chaveNfe: nota.chave
            }

            commit('setCarregando', true);
            axios.post('/servicos/downloadNfe', consulta)
            .then(response => {
                let retorno = response.data;
                if(retorno.status == 138){
                    
                    commit('setMensagemSucesso', 'Download Iniciado', {root: true});
                    
                    let a = document.createElement('a');
                    a.download = nota.chave + '.xml';
                    a.type = 'application/xml';
                    a.href = 'data:application/xml;base64,' + retorno.doc.nota;
                    a.click();
                }
                else{
                    commit('setMensagemErro', erro.response.data.motivo, {root: true});
                }
                commit('setCarregando', false);
            })
            .catch(erro => {
                commit('setCarregando', false);
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        },

        manifestacaoDestinatario({commit, rootState}, manif){

            let manifestacao = {
                id: manif.idDestinada,
                idLote: '01',
                tpEvento: manif.tpEvento,
                chaveNfe: manif.chaveNfe,
                verEvento: '1.00',
                justificativa: manif.justificativa
            }

            commit('setCarregando', true);
            axios.post('/servicos/recepcao_evento_manif_dest', manifestacao)
            .then((response) => {
              let resultado = response.data;
              if(resultado.evento){
                if(resultado.evento.status == 135){
                    commit('setNotaManifestada', manif.idDestinada);
                    commit('setMensagemSucesso', resultado.evento.motivo, {root: true});
                }
                else{
                  commit('setMensagemErro', resultado.evento.motivo, {root: true});
                }
                commit('setCarregando', false);
              }
              else{
                commit('setMensagemErro', resultado.motivo, {root: true});
              }
              commit('setCarregando', false);
            })
            .catch((erro) => {
                commit('setCarregando', false);
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        }
    },

    //responsável por alterar o state.
    mutations: {

        setCarregando(state, valor){
          state.carregando = valor;
        },

        setDestinadas(state, destinadas){
            state.destinadas = destinadas;
            state.carregando = false;
        },

        setTotalDestinadas(state, total){
            state.totalDestinadas = total;
        },

        setNotaManifestada(state, idDestinada){
            let notaDestinada = state.destinadas.find(destinada => destinada._id == idDestinada);
            if(notaDestinada){
                notaDestinada.status = 'manifestada';
            }
        }
    }
}
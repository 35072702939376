<template>
  <v-dialog v-model="dialog" max-width="480px" @keydown.esc="fechar" persistent >
    <v-form v-model="valid" ref="formSubGrupo" lazy-validation>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title class="white--text">Adicionar Subgrupo</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field label="Nome do Subgrupo" v-model="subgrupo.nome" :rules="[rules.obrigatorio]" autofocus @keypress.enter="adicionarSubgrupo"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="fechar()">Cancelar</v-btn>
          <v-btn color="primary" @click="adicionarSubgrupo()">Adicionar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import * as regras from '@/util/validacaoFormulario';

  export default {
    name: "DialogoAdicionarSubGrupo",  
    props: ['dialog'],
    data(){
      return {
        valid: false,
        rules: {...regras},
        subgrupo: {
          nome: ''
        } 
      }
    },
    methods: {
      adicionarSubgrupo(){
        if(this.$refs.formSubGrupo.validate()){
          this.$emit('subgrupoAdicionado', this.subgrupo);
          this.subgrupo = {
            nome: ''
          };
        }
      },
      fechar(){
        this.subgrupo = {
          nome: ''
        };
        this.$emit('fechar');
      }
    }
  }
</script>

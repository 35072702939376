<template>
  <v-navigation-drawer
    v-model="drawer"
    id="appDrawer"
    persistent
    fixed
    app
    enable-resize-watcher
    :mini-variant="mini"
    :clipped="clipped"
    :dark="$vuetify.dark"
    class="custom-scroll"
  >
    <dialog-inutilizacao
      :dialog="mostrarInutilizarIntervalo"
      @fechar="mostrarInutilizarIntervalo = false"
      @inutilizar="inutilizar"
    ></dialog-inutilizacao>

    <template v-slot:prepend>
      <v-toolbar
        color="primary darken-2"
        class="elevation-4"
        dark
        @click="$store.state.usuario.nivel_acesso === 'ANRON' ? mudarRota('/empresasAdmin') : mudarRota('/home')"
        style="cursor: pointer; z-index: 5"
      >
        <img src="@/assets/easyweb-white.svg" height="36" />
        <div class="ml-2 mt-3 caption">v{{ $store.state.version }}</div>
      </v-toolbar>

      <v-list dense>
        <v-list-group no-action group="minhaconta">
          <template v-slot:activator>
            <v-list-item-avatar v-if="$store.state.usuario.foto">
              <v-img v-bind:src="$store.state.usuario.foto"></v-img>
            </v-list-item-avatar>

            <v-list-item-avatar v-else color="primary" size="40">
              <span class="white--text subtitle-1">
                {{ $store.state.usuario.nome.charAt(0) }}
              </span>
            </v-list-item-avatar>

            <v-list-item-title>{{ $store.state.usuario.nome.toUpperCase() }}</v-list-item-title>
          </template>

          <v-list-item :to="{ name: 'minhaconta' }">
            <v-list-item-content>
              <v-list-item-title style="font-weight: normal">Minha Conta</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>account_box</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item @click="sair">
            <v-list-item-content>
              <v-list-item-title style="font-weight: normal">Sair</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>exit_to_app</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-divider></v-divider>
    </template>


    <template v-slot:default>

      <v-card flat class="py-2" v-if="!is_prod">
        <v-row dense>
          <v-col cols="12" align="center" justify="center">
            <menu-buttons :plano="$store.state.empresaAtual.plano" @update:plano="setDrawerMenu"></menu-buttons>
          </v-col>

          <v-col cols="12" align="center" justify="center">
            <v-btn small outlined tile color="primary" :to="{name: 'CrudLista'}">Exemplo CRUD</v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item-group color="primary">
          <!-- Usuário do sistema -->

          <template v-for="(item, i) in menusAcessiveis">
            <!--Elementos de Divisão e Subheaders-->
            <v-subheader v-if="item.cabecalho" :key="i" class="pl-4 pt-2 mt-1">{{ item.cabecalho }}</v-subheader>
            <v-divider v-else-if="item.divider" :key="i" class="ml-4"></v-divider>

            <!-- Elementos com sublinks -->
            <v-list-group
              v-else-if="item.items"
              :key="item.nome"
              no-action
              :prepend-icon="item.icone"
              :group="item.grupo"
              color="primary"
            >
              <template v-slot:activator>
                <v-list-item-content :data-cy="item.titulo">
                  <v-list-item-title>{{ item.titulo }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-divider></v-divider>

              <v-list-item
                v-for="(subItem, i) in item.items"
                :key="i"
                :to="subItem.path"
                :data-cy="subItem.titulo"
                :title="subItem.titulo"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <span style="font-weight: normal">{{ subItem.titulo }}</span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="subItem.icone">
                  <v-icon>{{ subItem.icone }}</v-icon>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
              
            </v-list-group>

            <v-list-item
              v-else-if="!item.hidden"
              :to="item.path"
              rel="noopener"
              :key="item.nome"
              :data-cy="item.titulo"
            >
              <v-list-item-icon v-if="item.icone">
                <v-icon :class="item.iconeClass" :size="item.iconeSize || 24">{{ item.icone }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.titulo }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </template>

    <template v-slot:append v-if="false">
      <v-footer color="white">
        v{{ $store.state.version }}
      </v-footer>  
    </template>
  </v-navigation-drawer>
</template>

<script>
import menu from "./menus/menu";
import menuNfe from "./menus/menuNfe";
import menuDeposito from "./menus/menuDeposito";
import menuUsuario from "./menus/menuUsuario";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import DialogInutilizacao from "@/views/notasfiscais/DialogInutilizacao";
import constantes from "@/util/constantes";
import { IS_PROD } from "@/util/config";

export default {
  name: "app-drawer",
  components: {
    VuePerfectScrollbar,
    DialogInutilizacao
  },
  props: {
    expanded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mostrarInutilizarIntervalo: false,
      mostrarMenuUsuario: false,
      mini: false,
      drawer: true,
      fixed: true,
      clipped: false,
      menu: menu,
      menuNfe: menuNfe,
      menuDeposito: menuDeposito,
      menuUsuario: menuUsuario,
    };
  },

  computed: {

    computeGroupActive() {
      return true;
    },

    sideToolbarColor() {
      return this.$vuetify.options.extra.sideNav;
    },

    menusAcessiveis() {

      let usuario = this.$store.state.usuario;
      if (usuario) {
        let plano = this.$store.state.empresaAtual.plano;
        if (plano === "nfe") {
          //plano de emissor de nfe somente, não faz sentido nível de usuário.
          let menus = [];
          if (usuario.nivel_acesso === "NORMAL") {
            menus = this.menuNfe.filter(menu => menu.nivel === 1);
          } else if (usuario.nivel_acesso === "ADMINISTRADOR") {
            menus = this.menuNfe.filter(
              menu => menu.nivel === 1 || menu.nivel === 2
            );
          }
          return menus;
        } else if (plano === constantes.MODO_DEPOSITO) {
          let menus = [];
          if (usuario.nivel_acesso === "NORMAL") {
            menus = this.menuDeposito.filter(menu => menu.nivel === 1);
          } else if (usuario.nivel_acesso === "ADMINISTRADOR") {
            menus = this.menuDeposito.filter(
              menu => menu.nivel === 1 || menu.nivel === 2
            );
          }
          return menus;
        } else {
          let menus = [];
          if (usuario.nivel_acesso === "NORMAL") {
            menus = this.menu.filter(menu => menu.nivel === 1);
          } else if (usuario.nivel_acesso === "ADMINISTRADOR") {
            menus = this.menu.filter(
              menu => menu.nivel === 1 || menu.nivel === 2
            );
          } else if (usuario.nivel_acesso === "ANRON") {
            menus = this.menu.filter(menu => menu.nivel === 3);
          }
          return menus;
        }
      }
    },

    is_prod() {
      return IS_PROD;
    }
  },

  created() {
    window.getApp.$on("APP_DRAWER_TOGGLED", () => {
      this.drawer = !this.drawer;
    });
  },

  methods: {
    inutilizar(inutilizar) {
      this.mostrarInutilizarIntervalo = false;
      this.$store.dispatch("nota/inutilizarIntervalo", inutilizar);
    },

    mudarRota(path) {
      this.$router.push(path);
    },

    alterarDrawer() {
      this.drawer = !this.drawer;
    },

    executeCallback(acao) {
      if (acao === "inutilizar") {
        this.mostrarInutilizarIntervalo = true;
      }
    },

    sair() {
      this.$root.$confirmacao
        .open("Logout", "Tem certeza que deseja sair?")
        .then(confirmado => {
          if (confirmado) {
            this.$store.commit("logout", { router: this.$router });
          }
        });
    },

    setDrawerMenu(plano) {
      let empresa = {...this.$store.state.empresaAtual};
      empresa.plano = plano;
      this.$store.commit('setEmpresaAtual', empresa);
    }
  }
};
</script>

<style scoped>
</style>

import axios from '@/util/axios';
import constantes from '@/util/constantes';
import bancosApi from "@/services/bancosApi";

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {
    bancos: [],
    bancoAtual: {
      codigo: '',
      descricao: ''
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalBancos: 0
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await bancosApi.listar(options, rootState.empresaAtual);
        commit('SET_BANCOS', response.bancos);
        commit('SET_TOTAL_BANCOS', response.total);
      } catch (error) {
        console.log(error)
        commit('SET_BANCOS', []);
        commit('setMensagemErro', 'Não foi possível listar os bancos', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({commit, state, rootState}){
      commit('SET_CARREGANDO', true);

      try{
        let banco = Object.assign({}, state.bancoAtual);
        let response = await bancosApi.salvar(banco, rootState.empresaAtual);
        commit('ATUALIZAR_BANCO', response);
        commit('setMensagemSucesso', 'Banco salvo com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    },
    async atualizar({commit, rootState}, banco) {
      await bancosApi.atualizar(banco, rootState.empresaAtual)
        .then((response) => {
          commit('ATUALIZAR_BANCO', banco);
          commit('setMensagemSucesso', 'Banco atualizado com sucesso', {root: true});
        })
        .catch((erro) => {
          commit('SET_CARREGANDO', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
        });
    },
    async excluir({commit, rootState}, banco){
        commit('SET_CARREGANDO', true);
        await bancosApi.excluir(banco._id, rootState.empresaAtual)
          .then((response) => {
            commit('EXCLUIR_BANCO', banco._id);
            commit('setMensagemSucesso', 'Banco removido com sucesso', {root: true});
          })
          .catch((erro) => {
            commit('SET_CARREGANDO', false);
            commit('setMensagemErro', erro.response.data.motivo, {root: true});
          });
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_BANCOS(state, bancos) {
      state.bancos = bancos;
      state.carregando = false;
    },
    SET_TOTAL_BANCOS(state, total) {
      state.totalBancos = total;
    },
    SET_BANCO(state, bancoAtual) {;
      state.bancoAtual = bancoAtual
    },
    EXCLUIR_BANCO(state, id) {
      let index = state.bancos.findIndex(banco => banco._id == id);
      if (index > -1) {
        state.bancos.splice(index, 1);
      }
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.bancoAtual = {
        codigo: '',
        descricao: ''
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.modoEdicao = true;
      state.bancoAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_BANCO(state, banco) {
      state.bancos.unshift(banco);
      state.carregando = false;
    },
    ATUALIZAR_BANCO(state, banco) {
      let banco_encontrada = state.bancos.find(banco_ => banco_.id == banco.id);
      if (banco_encontrada) {
        banco_encontrada = banco;
      }
      state.carregando = false;
    },
    estadoInicial(state){
      state.bancoAtual = {
        codigo: '',
        descricao: ''
      }
      state.carregando = false;
    }
  }
}
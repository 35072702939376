<template>
  <div>
    <v-text-field :loading="loading" label="Código" ref="codigoProduto" :value="value" append-icon="search" @keyup.enter="consultarCodigoProduto($event)" @input="onInput" @click:append="abrirDialogo" autocomplete="off"> </v-text-field>

    <dialog-buscar-produto ref="dialogoBuscarProduto" :dialog="dialogo" @produtoSelecionado="adicionarProdutoDialog"></dialog-buscar-produto>
  </div>
</template>

<script>
  
  import axios from "@/util/axios";
  import DialogBuscarProduto from '@/components/dialogs/DialogBuscarProduto';
  
  export default {
    name: 'BuscarProduto',
    props: {
      value: {
        type: [Number,String],
        default: null
      },
    },

    components: {
      DialogBuscarProduto,
    },

    data() {
      return {
        dialogo: false,
        loading: false
      }
    },

    methods: {
      onInput(value) {
        this.$emit('input', value);
      },

      abrirDialogo() {
        this.$refs.dialogoBuscarProduto.abrir();
      },

      setFocus(){
        setTimeout(() => {
          this.$refs.codigoProduto.focus();
        }, 300);
      },

      consultarCodigoProduto(event){
        
        let codigo = event.target.value;
        if(codigo && codigo !== ''){
          
          this.loading = true;
          let empresa = this.$store.state.empresaAtual;
          axios.get(`/${empresa._id}/produtos/codigos/${codigo}`)
          .then(response => {
            this.$emit('produtoSelecionado', response.data);
            this.loading = false;
          })
          .catch(erro => {
            this.loading = false;
            this.$store.commit('setMensagemErro', erro.response.data.motivo);
          });
        }
        else{
          this.$store.commit('setMensagemErro', 'Código Inválido');
        }
      },

      adicionarProdutoDialog(produto) {
        this.$emit('produtoSelecionado', produto);
      }, 
    }
  }
</script>

<style scoped>

</style>
<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" persistent scrollable>
      <v-card>
        <v-toolbar flat dark dense color="primary">
          <v-toolbar-title>Informar Pagamento</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-1">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="CNPJ/CPF" v-model="contaAPagar.participante.cnpj_cpf" v-mask="['###.###.###-##', '##.###.###/####-##']" disabled hide-details></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field label="Fornecedor" v-model="contaAPagar.participante.razao_nome" disabled hide-details></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-text-field label="Documento" v-model="contaAPagar.documento" disabled hide-details></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <campo-data label="Cadastro" v-model="contaAPagar.data_cadastro" disabled hide-details></campo-data>
            </v-col>
            <v-col cols="12" sm="2">
              <campo-data label="Vencimento" v-model="contaAPagar.data_vencimento" disabled hide-details></campo-data>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor" v-model="contaAPagar.valor" :decimais="2" ref="valor" disabled hide-details></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor Pago" v-model="contaAPagar.valor_pago" :decimais="2" ref="valor" disabled hide-details></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Valor Restante" v-model="contaAPagar.valor_restante" :decimais="2" ref="valor" disabled hide-details></as-campo-valor>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"></v-divider>
          <v-card>
            <v-toolbar flat dark color="primary" dense>
              <v-toolbar-title>Pagamentos</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="lighten-2 ml-2" depressed @click="abrirDialogNovoPagamento" :disabled="contaAPagar.valor_restante==0">Novo Pagamento</v-btn>
            </v-toolbar>
            <v-card-text>
              <v-data-table :headers="headers" :items="contaAPagar.pagamentos" hide-default-footer disable-pagination>
                <template v-slot:item="props">
                  <tr>
                    <td>{{ props.item.data | formatarData }}</td>
                    <td>{{ props.item.valor_pago | formatarMoeda }}</td>
                    <td>{{ props.item.tipo }}</td>
                    <td>{{ props.item.observacao }}</td>
                    <td class="text-center">
                      <v-menu top right transition="slide-x-transition">
                        <template v-slot:activator="{ on }">
                          <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                        </template>
                        <v-list dense>
                          <v-list-item :key="props.item._id" @click="abrirDialogoExcluirPagamento(props.item)">
                            <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                            <v-list-item-title>Remover</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog=false" text>Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogNovoPagamento" max-width="800px" persistent scrollable>
      <v-card>
        <v-toolbar flat dark color="primary" dense>
          <v-toolbar-title>Novo Pagamento</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-1">
          <v-form v-model="valid" ref="formPagamento" lazy-validation>
            <v-row dense>
              <v-col cols="12" sm="3">
                <campo-data label="Data" v-model="pagamento.data_cadastro" ref="datacadastro" @keypress.native.enter="$refs.juros.focus()" :rules="[rules.obrigatorio]"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor label="Juros" v-model="pagamento.juros" @blur="calcularValorPago" :decimais="2" ref="juros" @keypress.native.enter="$refs.multa.focus()" :rules="[rules.valorPositivo]"></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor label="Multa" v-model="pagamento.multa" @blur="calcularValorPago" :decimais="2" ref="multa" @keypress.native.enter="$refs.desconto.focus()" :rules="[rules.valorPositivo]"></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="3">
                <as-campo-valor label="Desconto" v-model="pagamento.desconto" @blur="calcularValorPago" :decimais="2" ref="desconto" @keypress.native.enter="$refs.valorpago.focus()" :rules="[rules.valorPositivo]"></as-campo-valor>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="3">
                <as-campo-valor label="Valor Pago" v-model="pagamento.valor_pago" :decimais="2" ref="valorpago" @keypress.native.enter="salvarPagamento" :rules="[rules.obrigatorio, rules.valorMaiorQueZero]"></as-campo-valor>
              </v-col>
              <v-col cols="12" sm="9">
                <v-select :items="tipos" v-model="pagamento.tipo" label="Tipo" ref="tipo" @keypress.native.enter="$refs.obs.focus()" :rules="[rules.obrigatorio]"></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-textarea label="Observação" v-model="pagamento.observacao" ref="obs" rows="2"></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogNovoPagamento=false" text>Cancelar</v-btn>
          <v-btn color="primary" @click="salvarPagamento" :disabled="valid==false">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions} from "vuex";
  import moment from 'moment';
  import * as regras from '@/util/validacaoFormulario';
  import axios from '@/util/axios';
  import { deepClone } from "@/util/helpers";

  export default {
    name: 'DialogPagamento',
    data () {
      return {
        dialog: false,
        dialogNovoPagamento: false,
        headers: [
          { text: 'Data', value: 'data', width: '20%', sortable: false },
          { text: 'Valor', value: 'valor', width: '20%', sortable: false },
          { text: 'Tipo', value: 'tipo', width: '20%', sortable: false },
          { text: 'Observações', width: '35%', value: 'observacao', sortable: false },
          { text: 'Ações', width: '5%', value: 'acoes', sortable: false },
        ],
        pagamento: {
          data_cadastro: '',
          juros: 0,
          multa: 0,
          desconto: 0,
          valor_pago: 0,
          tipo: 'Dinheiro',
          observacao: '',
        },
        valid: true,
        tipos:['Dinheiro', 'Boleto', 'Cheque', 'Duplicata', 'Nota Promissória', 'Vale', 'Imposto', 'Outros'],
        rules: {...regras},
      }
    },
    computed: {
      ...mapState('contaAPagar', {
        contaAPagar: 'contaAtual',
        carregando: 'carregando'
      }),

      ...mapGetters('contaAPagar', {
        estaEditando: 'estaEditando',
        textoBtnConfirmar: 'textoBtnConfirmar'
      })
    },
    methods: {
      abrir () {
        this.dialog = true;
      },
      abrirDialogNovoPagamento () {
        this.pagamento = {
          data_cadastro: moment().format('YYYY-MM-DD'),
          juros: 0,
          multa: 0,
          desconto: 0,
          valor_pago: this.contaAPagar.valor_restante,
          tipo: 'Dinheiro',
          observacao: '',
          conta_a_pagar: this.contaAPagar._id
        }
        this.dialogNovoPagamento = true;
        setTimeout(() => {
          this.$refs.valorpago.focus();
        }, 500);
      },
      calcularValorPago () {
       this.pagamento.valor_pago = this.contaAPagar.valor_restante   + this.pagamento.multa + this.pagamento.juros - this.pagamento.desconto;
      },
      async salvarPagamento () {
        try{
          if (this.$refs.formPagamento.validate()) {
            let response = await axios.post(`/contasAPagar/${this.contaAPagar._id}/pagamentos/salvar`, this.pagamento);
            let retorno = response.data;
  
            if(retorno.status){
              this.$store.commit('setMensagemErro', retorno.motivo, {root: true});
              this.$store.commit('contaAPagar/setCarregando', false);
            }else{
              this.dialogNovoPagamento = false;
              this.$store.commit('contaAPagar/atualizar', retorno);
              this.$store.commit('setMensagemSucesso', 'Pagamento salvo com sucesso', {root: true});
            }
          }
        }
        catch(erro){
          this.$store.commit('contaAPagar/setCarregando', false);
          this.$store.commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
          console.log(erro);
        }
      },
      abrirDialogoExcluirPagamento (pagamento_selecionado) {
        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover este pagamento?')
        .then((confirmado) => {
          if(confirmado){
            console.log('SIM, É PRA EXCLUIR')
            this.excluirPagamento(pagamento_selecionado);
          }
        });
      },
      async excluirPagamento (pagamento_selecionado) {
        try {
          let response = await axios.delete(`/contasAPagar/${this.contaAPagar._id}/pagamentos/${pagamento_selecionado._id}`);
          let retorno = response.data;
  
          if(retorno.status){
            this.$store.commit('setMensagemErro', retorno.motivo, {root: true});
            this.$store.commit('contaAPagar/setCarregando', false);
          }else{
            this.dialogNovoPagamento = false;
            this.$store.commit('contaAPagar/atualizar', retorno);
            this.$store.commit('setMensagemSucesso', 'Pagamento excluído com sucesso', {root: true});
          }
        } catch (erro) {
          this.$store.commit('contaAPagar/setCarregando', false);
          this.$store.commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
          console.log(erro);
        }
      }
    },
  }
</script>

<style>
  
</style>
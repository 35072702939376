<template>
  <div>
    <v-container fluid>
      <v-widget title="Detalhamento de Venda" :temCampoObrigatorio="false">
        <div slot="widget-header-action">
          <h2 :style="cupom.situacao!='Autorizada' ? 'color:red' : ''">{{ cupom.situacao }}</h2>
        </div>
        <div slot="widget-content">
          <v-row dense>
            <v-col cols="1">
              <v-text-field v-model="cupom.numero" name="numero" label="Número" autocomplete="off" readonly></v-text-field>
            </v-col>
            <v-col cols="2">
              <campo-data label="Data" v-model="cupom.data_emissao" ref="data" readonly></campo-data>
            </v-col>
            <v-col cols="8" v-if="cupom.cliente">
              <v-text-field v-model="cupom.cliente.razao_nome" name="cliente" label="Cliente" autocomplete="off" readonly></v-text-field>
            </v-col>
            <v-col cols="6" v-else>
              <v-text-field v-model="consumidor" name="cliente" label="Cliente" autocomplete="off" readonly></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="formas_pagamento[cupom.forma_pagamento].value" name="forma_pagamento" label="Forma de Pagamento Principal" autocomplete="off" readonly></v-text-field>
            </v-col>
          </v-row>

          <v-divider class="mt-2 mb-5"></v-divider>

          <v-row dense>
            <v-col cols="12" sm="8">
              <v-card>
                <v-toolbar flat dark color="primary" dense>
                  <v-toolbar-title>Produtos</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-data-table :headers="headers_produtos" :items="cupom.produtos" hide-default-footer disable-pagination fixed-header height="200px">
                    <template v-slot:item="{item}">
                      <tr>
                        <td class="text-center">{{ item.codigo }}</td>
                        <td>{{ item.descricao }}</td>
                        <td class="text-center">{{ item.quantidade | formatarValor }}</td>
                        <td class="text-center">{{ item.valor | formatarMoeda }}</td>
                        <td class="text-center">{{ item.subtotal | formatarMoeda }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card>
                <v-toolbar flat dark color="primary" dense>
                  <v-toolbar-title>Formas de Pagamento</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-data-table :headers="headers_pagamentos" :items="cupom.pagamentos" hide-default-footer disable-pagination fixed-header height="200px">
                    <template v-slot:item="{item}">
                      <tr>
                        <td>{{ item.forma }}</td>
                        <td class="text-center">{{ item.valor | formatarMoeda }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-divider class="mt-2 mb-5"></v-divider>

          <v-row dense>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Acréscimo %" v-model="cupom.acrescimo_percentual" :decimais="2" readonly></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Acréscimo R$" v-model="cupom.acrescimo" :decimais="2" readonly></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Desconto %" v-model="cupom.desconto_percentual" :decimais="2" readonly></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Desconto R$" v-model="cupom.desconto" :decimais="2" readonly></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Total Produtos" v-model="cupom.total_produtos" :decimais="2" readonly></as-campo-valor>
            </v-col>
            <v-col cols="12" sm="2">
              <as-campo-valor label="Total Cupom" v-model="cupom.total_cupom" :decimais="2" readonly></as-campo-valor>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field v-model="cupom.vendedor" name="vendedor" label="Vendedor" autocomplete="off" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="cupom.terminal" name="terminal" label="Terminal" autocomplete="off" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field v-model="cupom.loja" name="loja" label="Loja" autocomplete="off" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea label="Observações" ref="observacoes" v-model="cupom.observacao" rows="2" readonly></v-textarea>
            </v-col>
          </v-row>
          <v-row dense v-show="cupom.situacao=='Cancelada'">
            <v-col cols="12">
              <v-textarea label="Motivo Cancelamento" ref="motivo_cancelamento" v-model="cupom.observacao" rows="2" readonly></v-textarea>
            </v-col>
          </v-row>
        </div>
        <template v-slot:widget-footer-action>
          <v-btn color="primary" @click="voltar">Voltar</v-btn>
        </template>
      </v-widget>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import constantes from '@/util/constantes';

export default {
  name: 'VendasDetalhe',
  data () {
    return {
      consumidor: 'CONSUMIDOR',
      formas_pagamento: constantes.FORMAS_PAGAMENTO,
      headers_pagamentos: [
        { text: 'Forma de Pagamento', value: 'forma', sortable: false, width: "80%" },
        { text: 'Valor', value: 'valor', sortable: false, width: "20%" },
      ],
      headers_produtos: [
        { text: 'Código', value: 'codigo', sortable: false, width: "10%", align: 'center' },
        { text: 'Descrição', value: 'descricao', sortable: false, width: "45%" },
        { text: 'Quantidade', value: 'quantidade', sortable: false, width: "15%", align: 'center' },
        { text: 'Valor', value: 'valor', sortable: false, width: "15%", align: 'center' },
        { text: 'Subtotal', value: 'subtotal', sortable: false, width: "15%", align: 'center' },
      ],
    }
  },
  computed: {
    ...mapState('cupons', {
      cupom: 'cupom',
      carregando: 'carregando',
    })
  },
  methods: {
    voltar () {
      this.$router.push({ name: 'vendas'});
    }
  },
}
</script>

<style>
  
</style>
<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>Buscar CFOP</v-toolbar-title>
      </v-toolbar>
      <v-toolbar flat color="white">
        <v-text-field ref="input" flat filled rounded dense single-line hide-details clearable v-model="busca_local" @keyup.enter="buscarDescricaoCfop(busca_local)" max-width="300px" prepend-inner-icon="search" label="Buscar CFOP" @focus="onFocus"></v-text-field>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="height: 400px" class="pt-1">
        <v-data-table hide-default-footer :server-items-length="total" :headers="headers" :items="cfops" :loading="carregando" disable-pagination>
          <template v-slot:item="{item}">
            <tr @click="selecionarCfop(item)" style="cursor: pointer">
              <td>{{ item.cfop }}</td>
              <td>{{ item.descricao }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="fechar()" text>Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState, mapGetters, mapActions } from "vuex";
  import formatador from '@/util/formatador';

  export default {
    name: 'dialogBuscarCfop',

    props: {
      busca: {
        type: [Number,String],
        default: null
      },
    },
    
    data(){
      return {
        busca_local: '',
        headers: [
          { text: "Código", value: "codigo", sortable: false },
          { text: "Descrição", value: "descricao", sortable: false }
        ],
        options: {
          itemsPerPage: 10,
          page: 1
        },
        dialog: false
      }
    },

    computed: {
      ...mapState('cfop', {
        cfops: 'itens',
        carregando: 'carregando',
        total: 'totalCfops'
      }),
    },

    mounted () {
      this.$store.commit('cfop/setItens', []);
    },

    watch: {
      busca (value) {
        this.busca_local = value;
      }
    },

    methods: {
      ...mapActions('cfop', {
        buscarDescricaoCfop: 'buscarDescricaoCfop'
      }),

      selecionarCfop(cfop){
        this.$emit('cfopSelecionado', cfop);
        this.fechar();
      },

      abrir() {
        this.dialog = true;
        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);

        if (this.busca) {
          this.buscarDescricaoCfop(this.busca);
        }
      },

      fechar(){
        this.dialog = false;
        this.$emit('fechar');
      },

      onFocus(e) {
        this.selecionarInput(e);
      },

      selecionarInput(e) {
        e.target.selectionStart = 0;
        e.target.selectionEnd   = e.target.value.length;
      }
    }
  }
</script>
<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="white">
          <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" max-width="300px" prepend-inner-icon="search" label="Buscar Conta a Pagar"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="novo">Nova Conta a Pagar</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :headers="headers" :items="contasAPagar" :search="busca" :loading="carregando">
          <template v-slot:item="props">
            <tr>
              <td class="text-center">{{ props.item.documento }}</td>
              <td class="text-center">{{ props.item.participante.cnpj_cpf | formatarCnpj }}</td>
              <td>{{ props.item.participante.razao_nome }}</td>
              <td class="text-center"><v-chip :color="verificarVencimento(props.item.data_vencimento)">{{ props.item.data_vencimento | formatarData }}</v-chip></td>
              <td class="text-center">{{ props.item.valor | formatarMoeda }}</td>
              <td class="text-center">{{ props.item.valor_pago | formatarMoeda }}</td>
              <td class="text-center">{{ props.item.valor_restante | formatarMoeda }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="props.item._id + 10" @click="baixar(props.item)">
                      <v-list-item-icon><v-icon>monetization_on</v-icon></v-list-item-icon>
                      <v-list-item-title>Pagar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="props.item._id + 20" @click="editar(props.item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="props.item._id + 40" @click="remover(props.item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <dialog-pagamento ref="dialogoPagamento"></dialog-pagamento>
  </div>
</template>

<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';
  import moment from 'moment';
  import DialogPagamento from './DialogPagamento';

  export default {
    components: {DialogPagamento},
    data () {
      return {
        headers: [
          { text: 'Documento', value: 'documento', sortable: false, align: 'center' },
          { text: 'CNPJ/CPF', value: 'participante.cnpj_cpf', align: 'center' },
          { text: 'Fornecedor', value: 'participante.razao_nome' },
          { text: 'Vencimento', value: 'data_vencimento', align: 'center' },
          { text: 'Valor', value: 'valor', align: 'center' },
          { text: 'Valor Pago', value: 'valor_pago', align: 'center' },
          { text: 'Valor Restante', value: 'valor_restante', align: 'center' },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        headerHistorico: [
          { text: 'Data do Pagamento', value: 'pagamento.data' },
          { text: 'Forma de Pagamento', value: 'forma' },
          { text: 'Valor', value: 'valor' }
        ],
        constantes: constantes,
        busca: '',
        tipo: {text: 'Duplicata', value: constantes.DUPLICATA}
      }
    },

    computed: {
      ...mapState('contaAPagar', {
        contasAPagar: 'contasAPagar',
        carregando: 'carregando'
      })
    },

    watch: {

      tipo(v){
        if(v && !this.carregando){
          this.$store.dispatch('contaAPagar/carregar', v);
        }
      }
    },

    methods: {

      remover(conta){

        this.$root.$confirmacao.open('Remover', 'Tem certeza que deseja remover?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('contaAPagar/remover', conta);
          }
        });
      },

      verificarVencimento(vencimento){

        let emDia = moment().isBefore(vencimento);
        if(emDia){
          return 'secondary';
        }
        else{
          return 'error';
        }
      },

      editar(conta){
        this.$store.commit('contaAPagar/editar', conta);
        this.$router.push({name: 'formularioContaAPagarEditar'});
      },

      novo(){
        this.$store.commit('contaAPagar/novo');
        this.$router.push({name: 'formularioContaAPagarNova'});
      },

      baixar(conta){
        this.$store.commit('contaAPagar/editar', conta);
        this.$refs.dialogoPagamento.abrir();
      }
    },

    created(){
      this.$store.dispatch('contaAPagar/carregar', this.tipo.value);
    }
  }
</script>
import axios from '@/util/axios';
import constantes from '@/util/constantes';
import clientjs from 'clientjs';

export default{
    
    namespaced: true,

    //equivalente ao data
    state: {

        dispositivos: [],
        dispositivoAtual: {
            chave: '',
            nome: '',
            navegador: '',
            versao_navegador: '',
            sistema_operacional: '',
            versao_sistema_operacional: '',
            observacao: '',
            data_geracao: ''
        },
        constantes: constantes,
        modoEdicao: false,
        carregando: false
    },

    //equivalente ao computed properties
    getters: {

        estaEditando(state){
            return state.modoEdicao;
        },
        textoBtnConfirmar(state){
            if(state.modoEdicao){
                return state.constantes.BOTAO_SALVAR_EDICAO;
            }
            else{
                return state.constantes.BOTAO_SALVAR_NOVO;
            }
        }
    },

    //equivalente aos methods
    //responsável por chamar a api externa
    actions: {
        
        carregar({commit, rootState}){

            commit('setCarregando', true);
            axios.get(`/dispositivos`)
            .then((response) => {
                commit('setDispositivos', response.data);
            })
            .catch((erro) => {
                commit('setDispositivos', []);
                commit('setCarregando', false);
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });  
        },
        lerInformacoes({commit}){
            commit('setCarregando', true);
            
            let client = new ClientJS();
            let chave = client.getFingerprint();
            let navegador = client.getBrowser();
            let versao_navegador = client.getBrowserVersion();
            let sistema_operacional = client.getOS();
            let versao_sistema_operacional = client.getOSVersion();

            let dispositivo = {
                chave: chave,
                nome: '',
                navegador: navegador,
                versao_navegador: versao_navegador,
                sistema_operacional: sistema_operacional,
                versao_sistema_operacional: versao_sistema_operacional,
                observacao: ''
            }

            commit('setDispositivo', dispositivo);
        },
        async salvar({commit, state, rootState}){
            
            commit('setCarregando', true);
            let dispositivo = Object.assign({}, state.dispositivoAtual);
            
            if(state.modoEdicao){
                
                try{
                    let response = await axios.put('/dispositivos', dispositivo);
                    let result = response.data;
                    commit('atualizar', result);
                    commit('setMensagemSucesso', 'Dispositivo atualizado com sucesso', {root: true});
                }
                catch(erro){
                    commit('setCarregando', false);
                    commit('setMensagemErro', erro.response.data.motivo, {root: true});
                    throw erro;
                }
            }
            else{

                try{
                    delete(dispositivo._id);
                    let response = await axios.post('/dispositivos', dispositivo);
                    let result = response.data;
                    commit('adicionar', result);
                    commit('setMensagemSucesso', 'Dispositivo cadastrado com sucesso', {root: true});
                }
                catch(erro){
                    commit('setCarregando', false);
                    commit('setMensagemErro', erro.response.data.motivo, {root: true});
                    throw erro;
                }
            }
        },
        remover({commit, rootState}, remover){
            commit('setCarregando', true);
            axios.delete('/dispositivos', {
                data: { _id: remover._id }
            })
            .then((response) => {
                commit('remover', remover);
                commit('setMensagemSucesso', 'Dispositivo Removido com Sucesso', {root: true});
            })
            .catch((erro) => {
                commit('setCarregando', false);
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        }
    },

    //responsável por alterar o state.
    mutations: {

        setDispositivos(state, dispositivos){
            state.dispositivos = dispositivos;
            state.carregando = false;
        },
        setDispositivo(state, dispositivo){
            state.dispositivoAtual = dispositivo;
            state.carregando = false;
        },
        setCarregando(state, valor){
            state.carregando = valor;
        },
        remover(state, removido){
            state.dispositivos = state.dispositivos.filter(dispositivo => dispositivo._id !== removido._id);
            state.carregando = false;
        },
        novo(state){
            state.modoEdicao = false;
            state.dispositivoAtual = {
                chave: '',
                nome: '',
                navegador: '',
                versao_navegador: '',
                sistema_operacional: '',
                versao_sistema_operacional: '',
                observacao: '',
                data_geracao: ''
            }
            state.carregando = false;
        },
        editar(state, editar){
            state.modoEdicao = true;
            state.dispositivoAtual = editar;
            state.carregando = false;
        },
        adicionar(state, dispostivo){
            state.dispositivos.push(dispostivo);
            state.carregando = false;
        },
        atualizar(state, atual){
            state.dispositivos.forEach(dispositivo => {
                if(dispositivo._id === atual._id){
                    dispositivo = atual;
                }
            });
            state.carregando = false;
        },
        estadoInicial(state){
            state.dispositivoAtual = {
                chave: '',
                nome: '',
                navegador: '',
                versao_navegador: '',
                sistema_operacional: '',
                versao_sistema_operacional: '',
                observacao: '',
                data_geracao: ''
            }
            state.carregando = false;
        }
    }
}
<template>
  <v-dialog v-model="dialog" max-width="960px" @keydown.esc="fechar">
    <v-form ref="formTransferenciaContaCorrente" lazy-validation>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title class="white--text">Detalhamento do Caixa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-tabs v-model="abaAtiva">
            <v-tab :key="1">Vendas</v-tab>
            <v-tab :key="2">Recebimentos</v-tab>
            <v-tab :key="3">Lançamentos</v-tab>
            <v-spacer></v-spacer>
          </v-tabs>
          <v-tabs-items v-model="abaAtiva">
            <v-divider></v-divider>
            <v-tab-item :key="1+100" eager>
              <v-data-table fixed-header height="300px" style="padding-bottom: 20px" hide-default-footer :options.sync="options" :server-items-length="detalhamento.vendas.length" :headers="headers" :items="detalhamento.vendas" :loading="carregando" :no-data-text="'Sem resultados!'" :item-key="'_id'">
                <template v-slot:item="{item}">
                    <tr>
                      <td style="width: 190px">
                          <i class="fa fa-calendar fa-1x"></i> {{ item.data | formatarDataHora }}                
                      </td>
                      <td style="padding-left: 0 !important">
                          <b>{{ item.descricao }}</b><br>
                      </td>
                      <td>{{ item.formaPagamento }}</td>
                      <td v-if="item.venda">Recebimento de Venda</td>
                      <td class="text-right ">
                        <span class="mr-2"><b>{{ item.valor | formatarMoeda }}</b> </span>
                        <span v-if="item.tipo == 'Crédito'" class="primary--text font-weight-bold">C</span>
                        <span v-if="item.tipo == 'Débito'" class="red--text font-weight-bold">D</span>
                      </td>
                    </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item :key="1+200" eager>
              <v-data-table fixed-header height="300px" style="padding-bottom: 20px" hide-default-footer :options.sync="options" :server-items-length="detalhamento.recebimentos.length" :headers="headers" :items="detalhamento.recebimentos" :loading="carregando" :no-data-text="'Sem resultados!'" :item-key="'_id'">
                <template v-slot:item="{item}">
                    <tr>
                      <td style="width: 190px">
                          <i class="fa fa-calendar fa-1x"></i> {{ item.data | formatarDataHora }}                
                      </td>
                      <td style="padding-left: 0 !important">
                          <b>{{ item.descricao }}</b><br>
                      </td>
                      <td>{{ item.formaPagamento }}</td>
                      <td v-if="item.recebimento">Baixa CR</td>
                      <td class="text-right ">
                        <span class="mr-2"><b>{{ item.valor | formatarMoeda }}</b> </span>
                        <span v-if="item.tipo == 'Crédito'" class="primary--text font-weight-bold">C</span>
                        <span v-if="item.tipo == 'Débito'" class="red--text font-weight-bold">D</span>
                      </td>
                    </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item :key="1+300" eager>
              <v-data-table fixed-header height="300px" style="padding-bottom: 20px" hide-default-footer :options.sync="options" :server-items-length="detalhamento.lancamentos.length" :headers="headers" :items="detalhamento.lancamentos" :loading="carregando" :no-data-text="'Sem resultados!'" :item-key="'_id'">
                <template v-slot:item="{item}">
                    <tr>
                      <td style="width: 190px">
                          <i class="fa fa-calendar fa-1x"></i> {{ item.data | formatarDataHora }}                
                      </td>
                      <td style="padding-left: 0 !important">
                          <b>{{ item.descricao }}</b><br>
                      </td>
                      <td>{{ item.formaPagamento }}</td>
                      <td>{{ item.tipo === constantes.CREDITO ? 'Suprimento' : 'Sangria' }}</td>
                      <td class="text-right ">
                        <span class="mr-2"><b>{{ item.valor | formatarMoeda }}</b> </span>
                        <span v-if="item.tipo == 'Crédito'" class="primary--text font-weight-bold">C</span>
                        <span v-if="item.tipo == 'Débito'" class="red--text font-weight-bold">D</span>
                      </td>
                    </tr>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="fechar()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    name: "DialogoDetalhamentoCaixa",
    props: ['dialog', 'detalhamento'],
    data() { 
      return {
        constantes: constantes,
        headers: [
          { text: 'Data', value: 'data' },
          { text: 'Descrição', value: 'descricao' },
          { text: 'Forma Pgto.', value: 'formaPagamento' },
          { text: 'Tipo', value: 'tipo' },
          { text: 'Valor', value: 'valor', align: 'right' },
        ],
        options: {
          itemsPerPage: 10,
          page: 1
        },
        abaAtiva: 0
      }
    },
    computed: {
      ...mapState('caixa', {
        carregando: 'carregando'
      }),
    },
    methods: {
      fechar(){
        this.$emit('fechar');
      },
    },
    async created(){
      try{
        // 
      }
      catch(erro){
        console.log(erro);
      }
      finally{
        // 
      }
    }
  }
</script>
import ListaPlanosConta from '@/views/planosconta/ListaPlanosConta';
import FormularioPlanoContas from '@/views/planosconta/FormularioPlanoContas';

export default [
  {
    path: '/tabelas/planosConta',
    name: 'planoscontas',
    component: ListaPlanosConta,
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/tabelas/planosConta/novo',
    name: 'formularioPlanoContaNovo',
    component: FormularioPlanoContas,
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/tabelas/planosConta/editar',
    name: 'formularioPlanoContaEditar',
    component: FormularioPlanoContas,
    meta: { requiresAuth: true, nivel: 1}
  },
];
import store from '@/store/store';

const RouterGuards = (to, from, next) => {

  if(to.path !== '/login'){
    if(!store.state.empresaAtual || store.state.empresaAtual === ''){
      store.state.empresaAtual = JSON.parse(localStorage.getItem('empresaAtual'));
    }
    if(!store.state.usuario || store.state.usuario.nome === ''){
      store.state.usuario = JSON.parse(localStorage.getItem('usuario'));
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {

    let token = localStorage.getItem('token');

    if (!token) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        }
      });
      return false;
    }

    let tokenExpiration = JSON.parse(atob(token.split('.')[1]));
    var horaAtual = Math.round(new Date().getTime() / 1000);
    if (tokenExpiration['exp'] < horaAtual) {
      console.log('erro token inválido ou não existente');
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        }
      });
    } else {
      next();
    }
  } else {
    next();
  }
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    
    let nivel = -1;
    if(store.state.usuario.nivel_acesso === 'ADMINISTRADOR'){
      nivel = 2;
    }
    else if(store.state.usuario.nivel_acesso === 'NORMAL'){
      nivel = 1;
    }
    else if(store.state.usuario.nivel_acesso === 'ANRON'){
      nivel = 3;
    }

    if(to.matched.some(record => record.meta.nivel > nivel)){
      console.log('erro nivel de acesso');
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        }
      });
    }
  }
};

export default RouterGuards;
import ListaCheques from '@/views/cheques/ListaCheques';
import FormularioCheque from '@/views/cheques/FormularioCheque';

export default [
    {
      path: '/movimentacoes/cheques',
      name: 'cheques',
      component: ListaCheques,
      meta: { requiresAuth: true, nivel: 1}
    },
    {
      path: '/movimentacoes/cheques/novo',
      name: 'formularioChequeNovo',
      component: FormularioCheque,
      meta: { requiresAuth: true, nivel: 1}
    },
    {
      path: '/movimentacoes/cheques/editar',
      name: 'formularioChequeEditar',
      component: FormularioCheque,
      meta: { requiresAuth: true, nivel: 1}
    },
];
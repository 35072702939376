<template>
  <div id="v-widget">
    <v-card :min-height="altura">
      <v-toolbar flat dark color="primary" dense v-if="enableHeader">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <slot name="widget-header-action"></slot>
      </v-toolbar>

      <v-divider v-if="enableHeader"></v-divider>

      <v-card-text :class="contentBg">
        <slot name="widget-content"></slot>
        <span v-if="temCampoObrigatorio">Os campos marcados com (*) são obrigatórios</span>
      </v-card-text>

      <v-divider class="ml-4 mr-4" v-if="$slots['widget-footer-action']"></v-divider>

      <v-card-actions v-if="$slots['widget-footer-action']">
        <v-spacer></v-spacer>
        <slot name="widget-footer-action"></slot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "v-widget",
  props: {
    title: {
      type: String,
    },
    enableHeader: {
      type: Boolean,
      default: true,
    },
    contentBg: {
      type: String,
      default: "white",
    },
    temCampoObrigatorio: {
      type: Boolean,
      default: false,
    },
    altura: {
      type: String,
      default: ''
    }
  },

  data() {
    return {};
  },
  computed: {},
};
</script>

<template>
  <v-dialog v-model="visualizar" max-width="700px" @keydown.esc="fechar" persistent>
    <v-card>
      <v-toolbar dense flat dark color="primary">
        <h3>{{ notificacao.titulo }}</h3>
        <v-spacer></v-spacer>
        <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
      </v-toolbar>
      <v-list-item class="ma-2 mb-0">
        <v-layout column class="ma-0">
          <v-layout row class="ma-0">
            <v-list-item-icon class="ma-auto mr-4">
              <span class="white--text headline">
                <v-list-item-avatar
                  class="ma-0"
                  v-if="notificacao.tipo == 'Aviso'"
                  color="warning"
                >
                  <v-icon dark>fas fa-info</v-icon>
                </v-list-item-avatar>

                <v-list-item-avatar
                  class="ma-0"
                  v-else-if="notificacao.tipo == 'Informativo'"
                  color="accent"
                >
                  <v-icon dark>fas fa-info</v-icon>
                </v-list-item-avatar>

                <v-list-item-avatar class="ma-0" v-else color="red">
                  <v-icon dark>fas fa-exclamation</v-icon>
                </v-list-item-avatar>
              </span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ notificacao.titulo }}</v-list-item-title>
              <v-list-item-subtitle
                style="text-transform: capitalize;"
              >{{ notificacao.created_at | tempoDecorrido }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
              <v-list-item-action class="ma-0">{{ notificacao.created_at | formatarDataHora }}</v-list-item-action>
            </v-list-item-action>
            <v-list-item-action >
                <v-icon v-if="notificacao.lida" dark color="grey" v-on:click.stop="marcarComoNaoLida(notificacao)">drafts</v-icon>
                <v-icon v-else dark color="black" v-on:click.stop="marcarComoLida(notificacao)">markunread</v-icon>
            </v-list-item-action>
          </v-layout>
          <v-container
            :class="$vuetify.breakpoint.smAndUp ? 'ml-7 pl-7 pt-0' : 'ml-0 pl-0 pt-0 pr-0'"
          >
            <v-divider class="mb-4"></v-divider>
            <label class="pr-6"
              v-for="(linha, idx) in notificacao.conteudo.split('|')"
              :key="idx"
            >{{ linha }}<br></label>
          </v-container>
        </v-layout>
      </v-list-item>

    </v-card>
  </v-dialog>


</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({}),

  computed: {
    ...mapState("notificacao", {
      visualizar: "visualizar",
      notificacao: "notificacaoAtual"
    })
  },

  methods: {
    marcarComoLida(notificacao) {
      this.$store.dispatch("notificacao/marcarLida", notificacao);
    },

    marcarComoNaoLida(notificacao){
      this.$store.dispatch('notificacao/marcarNaoLida', notificacao)
    },

    fechar() {
      this.$store.commit("notificacao/fechar");
    }
  }
};
</script>
import ListaMva from "@/views/mva/ListaMva";
import FormularioMva from "@/views/mva/FormularioMva";

export default [
  {
    path: "/tabelas/mvas",
    name: "mvas",
    component: ListaMva,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/tabelas/mvas/novo",
    name: "formularioMvaNovo",
    component: FormularioMva,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/tabelas/mvas/editar",
    name: "formularioMvaEditar",
    component: FormularioMva,
    meta: { requiresAuth: true, nivel: 1 },
  },
];

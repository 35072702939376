import axios from '@/util/axios';
import moment from 'moment';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import formatador from '@/util/formatador';
import remessasChequesApi from '@/services/remessasChequesApi'

export default{
  namespaced: true,

  //equivalente ao data
  state: {
    remessas: [],
    remessaAtual: {
      numero: '',
      data: moment().format('YYYY-MM-DD'),
      conta: '',
      valor: '',
      cheques: [],
      chequesEmCaixa: [],
      observacoes: ''
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalRemessasCheques: 0,
    contascorrentes: []
  },

  //equivalente ao computed properties
  getters: {
    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async listar({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await remessasChequesApi.listar(options, rootState.empresaAtual);
        commit('SET_REMESSAS', response.remessas);
        commit('SET_TOTAL_REMESSAS', response.total);
      } catch (error) {
        commit('SET_REMESSAS', []);
        commit('setMensagemErro', 'Não foi possível listar os remessas', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async listarContasCorrentes({commit, rootState}) {
      try {
        commit('SET_CARREGANDO');
        let response = await remessasChequesApi.listarContasCorrentes(rootState.empresaAtual);
        commit('SET_CONTAS_CORRENTES', response.contas);
      } catch (error) {
        commit('SET_CONTAS_CORRENTES', []);
        commit('setMensagemErro', 'Não foi possível listar as contas correntes', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async listarChequesEmCaixa({commit, rootState}, options) {
      try {
        commit('SET_CARREGANDO');
        let response = await remessasChequesApi.listarChequesEmCaixa(options, rootState.empresaAtual);
        commit('SET_CHEQUES_EM_CAIXA', response.cheques);
      } catch (error) {
        commit('SET_CHEQUES_EM_CAIXA', []);
        commit('setMensagemErro', 'Não foi possível listar as contas correntes', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async salvar({commit, state, rootState}){
      try{
        commit('SET_CARREGANDO', true);
        let remessa = Object.assign({}, state.remessaAtual);
            remessa.valor = conversor.stringParaNumero(remessa.valor);
        let response = await remessasChequesApi.salvar(remessa, rootState.empresaAtual);
        commit('ATUALIZAR_REMESSA', response);
        commit('setMensagemSucesso', 'Remessa salva com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', "Erro ao salvar a remessa", {root: true});
        throw erro;
      }
    },
    async atualizar({commit, rootState}, remessa) {
      try {
        remessa.valor = conversor.stringParaNumero(remessa.valor);
        await remessasChequesApi.atualizar(remessa, rootState.empresaAtual)
        commit('ATUALIZAR_REMESSA', remessa);
        commit('setMensagemSucesso', 'Remessa atualizada com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      }
    },
    async excluir({commit, rootState}, remessa){
      try {
        await remessasChequesApi.excluir(remessa._id, rootState.empresaAtual)
        commit('EXCLUIR_REMESSA', remessa._id);
        commit('setMensagemSucesso', 'Cheque removido com sucesso', {root: true});
      }
      catch(erro){
        commit('SET_CARREGANDO', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      }
    },
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },
    SET_REMESSAS(state, remessas) {
      state.remessas = remessas;
      state.carregando = false;
    },
    SET_TOTAL_REMESSAS(state, total) {
      state.totalRemessasCheques = total;
    },
    EXCLUIR_REMESSA(state, id) {
      let index = state.remessas.findIndex(remessa => remessa._id == id);
      if (index > -1) {
        state.remessas.splice(index, 1);
      }
      state.carregando = false;
    },
    SET_CONTAS_CORRENTES(state, contascorrentes) {
      state.contascorrentes = contascorrentes;
      state.carregando = false;
    },
    SET_CHEQUES_EM_CAIXA(state, cheques) {
      state.remessaAtual.chequesEmCaixa = cheques;
      state.carregando = false;
    },
    SET_VALOR_TOTAL(state, total) {
      state.remessaAtual.valor = total.valor;
    },
    setConta(state, conta) {
      state.remessaAtual.conta = conta;
    },
    nova(state){
      state.modoEdicao = false;
      state.remessaAtual = {
        numero: '',
        data: moment().format('YYYY-MM-DD'),
        conta: '',
        valor: '',
        cheques: [],
        chequesEmCaixa: [],
        observacoes: ''
      }
      state.carregando = false;
    },
    editar(state, editar){
      editar.valor = formatador.formatarValor(editar.valor);
      state.modoEdicao = true;
      state.remessaAtual = editar;
      state.carregando = false;
    },
    ADICIONAR_CHEQUE_REMESSA(state, cheque) {
      state.remessaAtual.cheques.unshift(cheque);
      let index = state.remessaAtual.chequesEmCaixa.findIndex(cheque_ => cheque_._id == cheque._id);
      if (index > -1) {
        state.remessaAtual.chequesEmCaixa.splice(index, 1);
      }
      state.carregando = false;
    },
    REMOVER_CHEQUE_REMESSA(state, cheque) {
      state.remessaAtual.chequesEmCaixa.unshift(cheque);
      let index = state.remessaAtual.cheques.findIndex(cheque_ => cheque_._id == cheque._id);
      if (index > -1) {
        state.remessaAtual.cheques.splice(index, 1);
      }
    },
    SET_REMESSA_ATUAL(state, remessa) {
      state.remessaAtual = remessa;
    },
    ADICIONAR_REMESSA(state, remessa) {
      state.remessas.unshift(remessa);
      state.carregando = false;
    },
    ATUALIZAR_REMESSA(state, remessa) {
      let index = state.remessas.findIndex(remessa_ => remessa_._id == remessa._id);
      if (index > -1) {
        state.remessas.splice(index, 1, remessa);
      }
      state.carregando = false;
    },
    atualizarStatus(state, atual){
      state.remessas.forEach(remessa => {
        if(remessa._id == atual._id){
          remessa.status = atual.status;
        }
      });
      state.carregando = false;
    },
    estadoInicial(state){
      state.remessaAtual = {
        numero: '',
        data: moment().format('YYYY-MM-DD'),
        conta: '',
        valor: '',
        cheques: [],
        chequesEmCaixa: [],
        observacoes: ''
      }
      state.carregando = false;
    }
  }
}
<template>
  <div>
    <v-text-field :loading="loading" :label="label" ref="codigoBanco" v-mask="['###']" :value="value" append-icon="search" @keyup.enter="consultarCodigoBanco($event)" @input="onInput" @click:append="abrirDialogo"> </v-text-field>

    <dialog-buscar-banco ref="dialogBuscarBanco" @bancoSelecionado="adicionarBancoDialog" @fechar="dialogo = !dialogo"></dialog-buscar-banco>
  </div>
</template>

<script>
  
  import axios from "@/util/axios";
  import DialogBuscarBanco from '@/components/dialogs/DialogBuscarBanco';
  import constantes from '../util/constantes';
  
  export default {
    name: 'BuscarBanco',
    props: {
      value: {
        type: [Number,String],
        default: null
      },
      label: {
        type: String,
        default: 'Código Banco'
      },
    },

    components: {
      DialogBuscarBanco,
    },

    data() {
      return {
        dialogo: false,
        loading: false
      }
    },

    methods: {
      focus() {
        this.$refs.codigoBanco.focus();
      },

      onInput(value) {
        this.$emit('input', value);
      },

      abrirDialogo() {
        this.$refs.dialogBuscarBanco.abrir();
      },

      consultarCodigoBanco(event){
        let codigo = event.target.value;
        if(codigo && codigo.length > 0){
          this.loading = true;
          let empresa = this.$store.state.empresaAtual;
          axios.get(`/${empresa._id}/bancos/consultar?codigo=${codigo}`)
          .then(response => {
            let resultado = response.data;
            if(resultado.length > 0){
              this.$emit('bancoSelecionado', resultado[0]);
            }
            else{
              // this.$store.commit('setMensagemErro', 'Banco não encontrado');
            }
            this.loading = false;
          })
          .catch(erro => {
            this.loading = false;
            // this.$store.commit('setMensagemErro', erro.response.data.motivo);
          });
        }
        else{
          // commit('setMensagemErro', 'Código vazio', {root: true});
        }
      },

      adicionarBancoDialog(banco) {
        this.$emit('bancoSelecionado', banco);
      }, 
    }
  }
</script>
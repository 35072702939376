import ListaCFOPCompraEntrada from '@/views/cfopscompra/ListaCFOPCompraEntrada';
import FormularioCFOPCompraEntrada from '@/views/cfopscompra/FormularioCFOPCompraEntrada';

export default [
  {
    path: '/tabelas/mapeamentos',
    name: 'mapeamentos',
    component: ListaCFOPCompraEntrada,
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/tabelas/mapeamentos/novo',
    name: 'formularioMapeamentoNovo',
    component: FormularioCFOPCompraEntrada,
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/tabelas/mapeamentos/editar',
    name: 'formularioMapeamentoEditar',
    component: FormularioCFOPCompraEntrada,
    meta: { requiresAuth: true, nivel: 1}
  }
];
<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="white">
          <v-text-field flat solo single-line hide-details clearable v-model="busca" max-width="300px" prepend-inner-icon="search" label="Buscar Movimento Estoque"></v-text-field>
          <v-spacer></v-spacer>
          <v-menu :close-on-content-click="false" v-model="menuDataInicio" :nudge-right="40" lazy transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
            <v-text-field flat solo hide-details single-line v-model="dataInicioFormatada" slot="activator" label="Data Inícial" prepend-icon="event" readonly></v-text-field>
            <v-date-picker locale="pt-BR" v-model="dataInicio" no-title @input="menuDataInicio = false" ></v-date-picker>
          </v-menu>
          <v-menu :close-on-content-click="false" v-model="menuDataFim" :nudge-right="40" lazy transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
            <v-text-field flat solo hide-details single-line v-model="dataFimFormatada" slot="activator" label="Data Final" prepend-icon="event" readonly></v-text-field>
            <v-date-picker locale="pt-BR" :min="dataInicio" v-model="dataFim" no-title @input="menuDataFim = false" ></v-date-picker>
          </v-menu>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table item-key="_id" :headers="headers" :items="movimentosEstoque" :search="busca" :loading="carregando">
          <template v-slot:item="props">
            <td>{{ props.item.usuario.nome }}</td>
            <td>{{ props.item.tipo }}</td>
            <td>{{ props.item.produto.descricao }}</td>
            <td v-if="props.item.compra">Entrada</td>
            <td v-else-if="props.item.venda || props.item.cupom">Saída</td>
            <td>{{ props.item.data | formatarDataHora }}</td>
            <td>{{ props.item.quantidade }}</td>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
</div>
</template>

<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';
  import moment from 'moment';

  export default {
    data () {
      return {
        headers: [
          { text: 'Usuário', value: 'usuario.nome' },
          { text: 'Tipo', value: 'tipo' },
          { text: 'Descrição do Produto', value: 'produto.descricao' },
          { text: 'Origem', value: 'origem' },
          { text: 'Data', value: 'data' },
          { text: 'Quantidade', value: 'quantidade' }
        ],
        constantes: constantes,
        busca: '',
        menuDataInicio: false,
        menuDataFim: false,
        dataInicio: '',
        dataInicioFormatada: '',
        dataFim: '',
        dataFimFormatada: '',
      }
    },

    computed: {
        ...mapState('movimentoEstoque', {
            movimentosEstoque: 'movimentosEstoque',
            carregando: 'carregando'
        })
    },

    watch:{

      dataInicio(v){
        if(v && v != ''){
          this.dataInicioFormatada = moment(this.dataInicio).format('DD/MM/YY');
          if(!this.carregando && this.dataFim && this.dataFim != ''){
            if(moment(this.dataInicio).isBefore(this.dataFim)){
              this.$store.dispatch('movimentoEstoque/carregar', { dataInicio: this.dataInicio, dataFim: this.dataFim });
            }
            else{
              this.dataFim = this.dataInicio;
            }
          }
        }
      },
      dataFim(v){
        if(v && v != ''){
          this.dataFimFormatada = moment(this.dataFim).format('DD/MM/YY');
          if(!this.carregando){
            this.$store.dispatch('movimentoEstoque/carregar', { dataInicio: this.dataInicio, dataFim: this.dataFim });
          }
        }
      }
    },

    methods: {
        ...mapActions('movimentoEstoque', {
            carregarMovimentosEstoque: 'carregar'
        })
    },

    created(){
      //chamada a action através do dispatch
      let data = moment().format('YYYY-MM-DD');
      this.dataInicio = data;
      this.dataFim = data;
      this.carregarMovimentosEstoque({ dataInicio: data, dataFim: data });
    }
  }
</script>
<template>
  <div>
    <v-container fluid>
      <v-subheader class="text-subtitle-1 text--primary">
        Grupos
      </v-subheader>
      <v-card>
        <v-toolbar flat color="white">
          <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrarGrupos($event)" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="novo">Novo Grupo</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="grupos" :search="busca" :loading="carregando">
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.nome }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon @click.stop="" v-on="on">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="props.item._id + 20" @click="editar(props.item)">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="props.item._id + 40" @click="excluir(props.item)">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
  </v-container>
  </div>
</template>

<script>

import {mapState, mapGetters, mapActions} from 'vuex';
import constantes from '@/util/constantes';

export default {
  name: "ListaGrupo",
  data () {
    return {
      headers: [
        { text: 'Nome', value: 'nome', width: '90%', sortable: false },
        { text: 'Ações', value: '', width: '10%', align: 'center', sortable: false }
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1
      },
      footerOptions: {
        'items-per-page-options': constantes.ITEMS_POR_PAGINA 
      }
    }
  },

  watch: {
    options: {
      handler () {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('grupo', {
      grupos: 'grupos',
      carregando: 'carregando',
      total: 'totalGrupos'
    })
  },

  methods: {

    listar(){
      this.$store.dispatch('grupo/listar', this.options);
    },

    limparBusca(){
      this.listar();
    },

    filtrarGrupos(event){
      let newOptions = {
        ...this.options,
        query: this.busca
      }

      this.$store.dispatch('grupo/listar', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    excluir(grupo){
      this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?')
      .then((confirmado) => {
        if(confirmado){
          this.$store.dispatch('grupo/excluir', grupo);
        }
      });
    },

    editar(grupo){
      this.$store.commit('grupo/editar', grupo);
      this.$router.push({name: 'formularioGrupoEditar'});
    },

    novo(){
      this.$store.commit('grupo/novo');
      this.$router.push({name: 'formularioGrupoNovo'});
    }
  },
}
</script>
<template>
  <div>
    <v-container>
      <v-row style="margin-top: 50px" v-if="loading">
        <v-col cols="12" class="text-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>

      <v-form v-else v-model="valid" ref="formCentroCusto" lazy-validation>
        <v-container fluid>
          <v-widget title="Informações do Centro de Custos">
            <template v-slot:widget-content>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-text-field label="Descrição do Centro de Custo" v-model="centroCusto.descricao" :rules="[rules.obrigatorio]"></v-text-field>
                </v-col>
              </v-row>
            </template>
          </v-widget>

          <v-widget title="Subcentro de Custo" class="mt-3">
            <template v-slot:widget-header-action>
              <v-btn text @click="dialogoSubcentro = true">Novo</v-btn>
            </template>
            <template v-slot:widget-content>
              <v-data-table class="pb-3" v-if="centroCusto.subCentrosCustos && centroCusto.subCentrosCustos.length > 0" :headers="headers" :items="centroCusto.subCentrosCustos" hide-default-footer>
                <template v-slot:item="{item}">
                  <tr>
                    <td>{{ item.descricao }}</td>
                    <td class="text-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="removerSubcentroCusto(item._id)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Excluir</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </template>
            <template v-slot:widget-footer-action>
              <v-btn text @click="cancelar">Cancelar</v-btn>
              <v-btn color="primary" :loading="carregando" @click="salvar">Salvar</v-btn>
            </template>
          </v-widget>
        </v-container>
      </v-form>
    </v-container>
    <dialog-adicionar-sub-centro-custo :dialog="dialogoSubcentro" @subCentroAdicionado="adicionarSubCentro" @fechar="dialogoSubcentro = false" />
  </div>
</template>

<script>
  import DialogAdicionarSubCentroCusto from './DialogAdicionarSubCentroCusto';
  import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
  import * as regras from '@/util/validacaoFormulario';
  import constantes from '@/util/constantes';

  export default {
    name: "FormularioCentroCusto",
    components:{
      DialogAdicionarSubCentroCusto
    },

    data: () => ({
      valid: false,
      loading: false,
      rules: {...regras},
      constantes: constantes,
      headers: [
        { text: 'Subcentro', value: 'nome', width: '90%', sortable: false },
        { text: '', value: 'name', width: '10%', align: 'center', sortable: false }
      ],
      dialogoSubcentro: false,
    }),

    computed: {
      ...mapState('centroCusto', {
        centroCusto: 'centrocustoAtual',
        carregando: 'carregando',
      }),
      ...mapGetters('centroCusto', {
        estaEditando: 'estaEditando',
        textoBtnConfirmar: 'textoBtnConfirmar'
      })
    },

    methods: {
      adicionarSubCentro(subcentro){
        this.dialogoSubcentro = false;
        this.$store.commit('centroCusto/adicionarSubcentro', subcentro);
      },
      
      removerSubcentroCusto(subcentro){
        this.$store.commit('centroCusto/removerSubcentro', subcentro);
      },
      
      async salvar() {
        if(this.$refs.formCentroCusto.validate()){
          try{
            if (this.centroCusto._id) {
              await this.$store.dispatch('centroCusto/atualizar', this.centroCusto);
            } else {
              await this.$store.dispatch('centroCusto/salvar', this.centroCusto);
            }
            this.$router.push({ name: 'centroscusto' })
          }
          catch(erro){
            console.log('Erro ao salvar', erro);
          }
        }
      },

      cancelar() {
        this.$store.commit('centroCusto/estadoInicial');
        this.$router.push({ name: 'centroscusto' });
      }
    },

    async created(){
      try{
        this.loading = true;
        let centro = this.$store.state.centroCusto.centrocustoAtual;
        let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
    
        if(estaEditando && (!centro._id || centro._id === '')){
          this.$store.commit('setMensagemErro', 'Selecione um centro de custo para editar');
          this.$router.push({ name: 'centroscusto' });
        }
        if(!estaEditando){
          this.$store.commit('centroCusto/estadoInicial');
        }
      }
      catch(erro){
        console.log(erro);
      }
      finally{
        this.loading = false;
      }
    }
  }
</script>
import crudApi from "@/services/crudApi";
import { deepClone } from "@/util/helpers";

const item = {
  codigo: '',
  descricao: '',
  valor: 0.00,
  estoque: 0,
  data_cadastro: '',
  produto_codigo: '',
  produto_descricao: ''
}

export default {

  namespaced: true,

  state: {
    item: {...item},
    itens: [],
    total: 0,
    carregando: false
  },

  getters: {

  },

  mutations: {
    SET_ITENS(state, itens) {
      state.itens = itens;
    },

    SET_TOTAL_ITENS(state, total) {
      state.total = total;
    },

    SET_ITEM(state, item) {;
      state.item = item
    },

    ADICIONAR_ITEM(state, item) {
      state.itens.unshift(item);
    },

    ATUALIZAR_ITEM(state, item) {
      let item_encontrado = state.itens.find(item_ => item_.id == item.id);
      if (item_encontrado) {
        item_encontrado = item;
      }
    },

    EXCLUIR_ITEM(state, id) {
      let index = state.itens.findIndex(item => item._id == id);
      if (index > -1) {
        state.itens.splice(index, 1);
      }
    },

    SET_CARREGANDO(state, status = true) {
      state.carregando = status;
    },

    ZERAR(state) {
      state.item = {...item};
      // state.item = deepClone(item);
    }
  },

  actions: {
    async listar({commit}, options) {
      try {

        commit('SET_CARREGANDO');
        let response = await crudApi.listar(options);
        commit('SET_ITENS', response.itens);
        commit('SET_TOTAL_ITENS', response.total);

      } catch (error) {

        commit('SET_ITENS', []);
        commit('setMensagemErro', 'Não foi possível listar os itens', {root: true});
        throw error;
      
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    
    async encontrarPeloId({commit}, id) {
      try {
        commit('SET_CARREGANDO');
        let item = await crudApi.encontrarPeloId(id);
        commit('SET_ITEM', item);
      } catch (error) {
        commit('setMensagemErro', 'Não foi possível abrir o item', {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    
    async salvar({commit}, item) {
      let novoItem = await crudApi.salvar(item);
      commit('ADICIONAR_ITEM', novoItem);
    },
    
    async atualizar({commit}, item) {
      try {
        await crudApi.atualizar(item, item._id);
        commit('ATUALIZAR_ITEM', item);
        commit('setMensagemSucesso', 'Item atualizado com sucesso', {root: true});
      } catch (error) {
        commit('setMensagemErro', 'Não foi possível atualizar o item', {root: true});
      }
    },
    
    async excluir({commit}, id) {
      try {
        commit('SET_CARREGANDO');
        await crudApi.excluir(id);
        commit('EXCLUIR_ITEM', id);
        commit('setMensagemSucesso', 'Item excluído com sucesso', {root: true});
      } catch (error) {
        commit('setMensagemErro', 'Não foi possível excluir o item', {root: true});
      } finally {
        commit('SET_CARREGANDO', false);
      }
    }
  }

};
import axios from '@/util/axios';
import constantes from '@/util/constantes';

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {

    cofins: [],
    cofinsAtual: {
      codigo: '',
      descricao: ''
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false
  },

  //equivalente ao computed properties
  getters: {

    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {

      
    async carregar({commit, rootState}){

      commit('setCarregando', true);

      try{

        let response = await axios.get('/cofins');
        commit('setCofins', response.data);
      }
      catch(erro){
        commit('setCofins', []);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      }
    },
    async salvar({commit, state, rootState}, dados){
        
      commit('setCarregando', true);

      let cofins = Object.assign({}, state.cofinsAtual);

      if(state.modoEdicao){

        try{

          let response = await axios.put('/cofins', cofins);
          let retorno = response.data;
          if(retorno.status){
            commit('setMensagemErro', retorno.motivo, {root: true});
            commit('setCarregando', false);
          }
          else{
            commit('atualizar', retorno);
            commit('setMensagemSucesso', 'COFINS Atualizado com Sucesso', {root: true});
          }
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
      else{
          
        delete(cofins._id);
        commit('setCarregando', true);

        try{

          let response = await axios.post('/cofins', cofins);
          let retorno = response.data;
          if(retorno.status){
            commit('setMensagemErro', retorno.motivo, {root: true});
            commit('setCarregando', false);
          }
          else{
            commit('adicionar', retorno);
            commit('setMensagemSucesso', 'COFINS Cadastrado com Sucesso', {root: true});
          }
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
    },
    remover({commit, rootState}, remover){
      commit('setCarregando', true);
      axios.delete('/cofins', {
        data: { _id: remover._id }
      })
      .then((response) => {
        commit('remover', remover);
        commit('setMensagemSucesso', 'COFINS Removido com Sucesso', {root: true});
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    }
  },

  //responsável por alterar o state.
  mutations: {

    setCofins(state, cofins){
      state.cofins = cofins;
      state.carregando = false;
    },
    setCarregando(state, valor){
      state.carregando = valor;
    },
    remover(state, removido){
      state.cofins = state.cofins.filter(cof => cof._id !== removido._id);
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.cofinsAtual = {
        codigo: '',
        descricao: ''
      }
      state.carregando = false;
    },
    editar(state, editar){
      state.modoEdicao = true;
      state.cofinsAtual = editar;
      state.carregando = false;
    },
    adicionar(state, cofins){
      state.cofins.push(cofins);
      state.carregando = false;
    },
    atualizar(state, atual){
      state.cofins.forEach(cof => {
        if(cof._id === atual._id){
          cof = atual;
        }
      });
      state.carregando = false;
    },
    estadoInicial(state){
      state.cofinsAtual = {
        codigo: '',
        descricao: ''
      }
      state.carregando = false;
    }
  }
}
<template>
  <div>
    <v-container>
      <v-row style="margin-top: 50px" v-if="loading">
        <v-col cols="12" class="text-center">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
      <v-form v-else v-model="valid" ref="formFabricante" >
        <v-container fluid>
          <v-widget title="Informações do Fabricante" :temCampoObrigatorio="true">
            <template v-slot:widget-content>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-text-field label="Nome do Fabricante *" v-model="fabricante.nome" :rules="[rules.obrigatorio]" autofocus @keypress.enter="salvar"></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:widget-footer-action>
              <v-btn text @click="cancelar()">Cancelar</v-btn>
              <v-btn color="primary" :loading="carregando" @click="salvar()">Salvar</v-btn>
            </template>
          </v-widget>
        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions} from "vuex";
  import * as regras from '@/util/validacaoFormulario';
  import constantes from '@/util/constantes';

  export default {

    data: () => ({
      valid: false,
      rules: {...regras},
      constantes: constantes,
      loading: false
    }),

    computed: {
      ...mapState('fabricante', {
        fabricante: 'fabricanteAtual',
        carregando: 'carregando'
      }),
      
      ...mapGetters('fabricante', {
        estaEditando: 'estaEditando',
        textoBtnConfirmar: 'textoBtnConfirmar'
      })
    },

    methods: {
      async salvar() {
        if (this.$refs.formFabricante.validate()) {
          try{
            if (this.fabricante._id) {
              await this.$store.dispatch('fabricante/atualizar', this.fabricante);
            } else {
              await this.$store.dispatch('fabricante/salvar', this.fabricante);
            }
            this.$router.push({ name: 'fabricante' });
          }
          catch(erro){
            console.log('Houve um erro', erro);
          }
        }
      },

      cancelar() {
        this.$store.commit('fabricante/estadoInicial');
        this.$router.push({name: 'fabricante'});
      }
    },

    async created(){
      try{
        this.loading = true;
        let fabricante = this.$store.state.fabricante.fabricanteAtual;
        let estaEditando = this.$router.currentRoute.path.indexOf('editar') !== -1 ? true : false;
    
        if(estaEditando && (!fabricante._id || fabricante._id === '')){
          this.$store.commit('setMensagemErro', 'Selecione um fabricante para editar');
          this.$router.push({ name: 'fabricante' });
        }
        
        if(!estaEditando){
          this.$store.commit('fabricante/estadoInicial');
        }
      }
      catch(erro){
        console.log(erro);
      }
      finally{
        this.loading = false;
      }
    }
  }
</script>
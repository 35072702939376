import axios from '@/util/axios';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import formatador from '@/util/formatador';
import Vue from 'vue';

export default{
    
    namespaced: true,

    //equivalente ao data
    state: {

        itens: [],
        itemAtual: {
            codigo: '',
            codigo_barra: '',
            descricao: '',
            descricao_orcamento: '',
            unidade: '',
            ncm: {
                codigo: '',
                descricao: ''
            },
            cest: {
                cest: '',
                descricao: ''
            },
            grupo: {
              subgrupos: [],
            },
            subgrupo: '',
            fabricante: '',
            referencia_fabricante: '',
            fator_conversao: {
                entrada: null,
                saida: null
            },
            valor_custo: 0,
            lucro: 0,
            ipi: 0,
            ii: 0,
            frete: {
                tipo: 'valor',
                valor: 0
            },
            outros: 0,
            valor_vista: 0,
            valor_prazo: 0,
            valor: 0,
            sit_tributaria_cupom: '',
            iat: '',
            ippt: '',
            imagem: '',
            estoque: 0,
            estoque_minimo: 0,
            localizacao: '',
            origem: 0,
            cst_icms: '',
            cst_pis_entrada: '',
            cst_pis_saida: '',
            cst_cofins_entrada: '',
            cst_cofins_saida: '',
            csosn: '',
            aliq_icms: 0,
            aliq_pis_entrada: 0,
            aliq_pis_saida: 0,
            aliq_cofins_entrada: 0,
            aliq_cofins_saida: 0
        },
        grupos: [],
        fabricantes: [],
        pis: [],
        cofins: [],
        configuracao_tributaria: {},
        constantes: constantes,
        modoEdicao: false,
        carregando: false,
        totalProdutos: 0,
        produtos_busca: [],
        produtoNfe: {},
    },

    //equivalente ao computed properties
    getters: {

        estaEditando(state){
            return state.modoEdicao;
        },
        textoBtnConfirmar(state){
            if(state.modoEdicao){
                return state.constantes.BOTAO_SALVAR_EDICAO;
            }
            else{
                return state.constantes.BOTAO_SALVAR_NOVO;
            }
        }
    },

    //equivalente aos methods
    //responsável por chamar a api externa
    actions: {
        
        carregar({commit, rootState}, options){
            commit('setCarregando', true);
            
            let url = `/produtos?page=${options.page}&pageSize=${options.itemsPerPage}`;
            if(options.query && options.query !== ''){
                url = `${url}&query=${options.query}`;
            }

            axios.get(url)
            .then((response) => {
                commit('setTotalProdutos', response.data.total);
                commit('setItens', response.data.produtos);
            })
            .catch((erro) => {
                commit('setItens', []);
                commit('setCarregando', false)
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });  
        },
        buscarDescricaoProduto({commit, rootState}, params){
            commit('setCarregando', true);
            axios.get(`/produtos/descricao?busca=${params.busca}&proprios=${params.produtosProprios}&ncm=${params.ncm}`)
            .then((response) => {
                commit('setProdutosBusca', response.data);
            })
            .catch((erro) => {
                commit('setProdutosBusca', []);
                commit('setCarregando', false)
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });  
        },
        carregarPis({commit, rootState}){
            commit('setCarregando', true);
            axios.get('/pis')
            .then((response) => {
                commit('setPis', response.data);
            })
            .catch((erro) => {
                commit('setPis', []);
                commit('setCarregando', false)
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        },
        carregarCofins({commit, rootState}){
            commit('setCarregando', true);
            axios.get('/cofins')
            .then((response) => {
                commit('setCofins', response.data);
            })
            .catch((erro) => {
                commit('setCofins', []);
                commit('setCarregando', false)
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        },
        carregarConfiguracoesTributarias({commit, state, rootState}){

            commit('setCarregando', true);
            axios.get('/empresas/' + rootState.empresaAtual._id)
            .then((response) => {
                let empresa = response.data;
                commit('setConfiguracaoTributaria', empresa.configuracao_tributaria);
            })
            .catch((erro) => {
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        },
        async salvar({commit, state, rootState}){
            
            commit('setCarregando', true);
            
            let produto = Object.assign({}, state.itemAtual);

            produto.ncm = produto.ncm._id;
            produto.cest = produto.cest._id;

            if(state.modoEdicao){

                try{
                    let response = await axios.put(`/produtos`, produto);
                    let retorno = response.data;
                    if(retorno.status){
                        commit('setMensagemErro', retorno.motivo, {root: true});
                        commit('setCarregando', false);
                    }
                    else{
                        commit('atualizar', response.data);
                        commit('setMensagemSucesso', 'Produto Atualizado com Sucesso', {root: true});
                    }
                }
                catch(erro){
                    commit('setCarregando', false);
                    commit('setMensagemErro', erro.response.data.motivo, {root: true});
                    throw erro;
                }
            }
            else{
                
                try{
                    delete(produto._id);
                    produto.grupo = produto.grupo._id;
                    produto.fabricante = produto.fabricante._id;
                    let response = await axios.post(`/produtos`, produto);
                    let retorno = response.data;
                    commit('adicionar', retorno);
                    commit('setProdutoNfe', retorno);
                    commit('setMensagemSucesso', 'Produto Cadastrado com Sucesso', {root: true});
                }
                catch(erro){
                    commit('setCarregando', false);
                    commit('setMensagemErro', erro.response.data.motivo, {root: true});
                    throw erro;
                }
            }
        },
        remover({commit, rootState}, remover){
            commit('setCarregando', true);
            axios.delete('/produtos', {
                data: { '_id': remover._id }
            })
            .then((response) => {
                commit('remover', remover);
                commit('setMensagemSucesso', 'Produto Removido com Sucesso', {root: true});
            })
            .catch((erro) => {
                commit('setCarregando', false);
                commit('setMensagemErro', erro.response.data.motivo, {root: true});
            });
        },
        async ajustarEstoque ({commit, rootState, state}, ajuste) {
          commit('setCarregando', true);

          let produto = Object.assign({}, state.itemAtual);

          let response = await axios.post(`/produtos/${produto._id}/ajustarestoque`, ajuste);
          let retorno = response.data;
          if(retorno.status){
            commit('setMensagemErro', retorno.motivo, {root: true});
            commit('setCarregando', false);
          }
          else{
            commit('atualizar', response.data);
            commit('setMensagemSucesso', 'Ajuste Realizado com Sucesso', {root: true});
          }
        }
    },

    //responsável por alterar o state.
    mutations: {
      setItemAtual (state, produto) {
        state.itemAtual = produto;
      },
      setItens(state, itens){
          state.itens = itens;
          state.carregando = false;
      },
      setProdutosBusca(state, produtosBusca){
        state.produtos_busca = produtosBusca;
        state.carregando = false;
    },
      setTotalProdutos(state, total){
          state.totalProdutos = total;
      },
      setCarregando(state, valor){
          state.carregando = valor;
      },
      setNcm(state, ncm){
          state.itemAtual.ncm = ncm;
          state.carregando = false;
      },
      setCest(state, cest){
          state.itemAtual = Object.assign({}, state.itemAtual, { cest: cest});
          state.carregando = false;
      },
      setFabricante(state, fabricante) {
        state.itemAtual.fabricante = fabricante;
        state.carregando = false;
      },
      setPis(state, pis){
          let arrayPis = [];
          pis.forEach(p => {
              arrayPis.push({text: p.codigo + ' - ' + p.descricao, value: p.codigo })
          })
          state.pis = arrayPis;
          state.carregando = false;
      },
      setCofins(state, cofins){
          let arrayCofins = [];
          cofins.forEach(c => {
              arrayCofins.push({text: c.codigo + ' - ' + c.descricao, value: c.codigo })
          })
          state.cofins = arrayCofins;
          state.carregando = false;
      },
      setConfiguracaoTributaria(state, config){

          state.configuracao_tributaria = config;
          if(config.regime_tributario == 1 || config.regime_tributario == 2){
              if(!state.modoEdicao){
                  state.itemAtual.csosn = config.csosn;
                  // state.itemAtual.aliq_icms = formatador.formatarValor(config.aliq_icms);
                  state.itemAtual.aliq_icms = config.aliq_icms;
              }
          }
          state.carregando = false;
      },
      remover(state, removido){
          state.itens = state.itens.filter(item => item.codigo !== removido.codigo);
          state.carregando = false;
      },
      novo(state){
          state.modoEdicao = false;
          state.itemAtual = {
              codigo: '',
              codigo_barra: '',
              descricao: '',
              descricao_orcamento: '',
              unidade: '',
              ncm: {
                  codigo: '',
                  descricao: ''
              },
              cest: {
                  cest: '',
                  descricao: ''
              },
              grupo: '',
              subgrupo: '',
              fabricante: '',
              referencia_fabricante: '',
              fator_conversao: {
                  entrada: null,
                  saida: null
              },
              valor_custo: 0,
              lucro: 0,
              ipi: 0,
              ii: 0,
              frete: {
                  tipo: 'valor',
                  valor: 0
              },
              outros: 0,
              valor_vista: 0,
              valor_prazo: 0,
              valor: 0,
              sit_tributaria_cupom: '',
              iat: '',
              ippt: '',
              imagem: '',
              estoque: '',
              estoque_minimo: '',
              localizacao: '',
              origem: 0,
              cst_icms: '',
              cst_pis_entrada: '',
              cst_pis_saida: '',
              cst_cofins_entrada: '',
              cst_cofins_saida: '',
              csosn: '',
              aliq_icms: 0,
              aliq_pis_entrada: 0,
              aliq_pis_saida: 0,
              aliq_cofins_entrada: 0,
              aliq_cofins_saida: 0,
          }
          state.carregando = false;
      },
      editar(state, editar){
          
          state.modoEdicao = true;

          if(!editar.cest){
              editar.cest = {
                  cest: '',
                  descricao: ''
              };
          }

          if (!editar.grupo){
            editar.grupo = {
              subgrupos: []
            }
          }

          if (!editar.fabricante){
            editar.fabricante = {
              nome: ''
            }
          }

          state.itemAtual = editar;
          state.carregando = false;
      },
      adicionar(state, item){
          state.itens.push(item);
          state.carregando = false;
      },
      atualizar(state, atual){
        let index = state.itens.findIndex(produto => {
          return produto._id == atual._id;
        });

        if (index > -1) {
          state.itens.splice(index, 1, atual);
        }
        state.carregando = false;
      },
      estadoInicial(state){
          state.itemAtual = {
              codigo: '',
              codigo_barra: '',
              descricao: '',
              descricao_orcamento: '',
              unidade: '',
              ncm: {
                  codigo: '',
                  descricao: ''
              },
              cest: {
                  cest: '',
                  descricao: ''
              },
              grupo: '',
              subgrupo: '',
              fabricante: '',
              referencia_fabricante: '',
              fator_conversao: {
                  entrada: nul,
                  saida: nul
              },
              valor_custo: 0,
              lucro: 0,
              ipi: 0,
              ii: 0,
              frete: {
                  tipo: 'valor',
                  valor: 0
              },
              outros: 0,
              valor_vista: 0,
              valor_prazo: 0,
              valor: 0,
              sit_tributaria_cupom: '',
              iat: '',
              ippt: '',
              imagem: '',
              estoque: '',
              estoque_minimo: '',
              localizacao: '',
              origem: 0,
              cst_icms: '',
              cst_pis_entrada: '',
              cst_pis_saida: '',
              cst_cofins_entrada: '',
              cst_cofins_saida: '',
              csosn: '',
              aliq_icms: 0,
              aliq_pis_entrada: 0,
              aliq_pis_saida: 0,
              aliq_cofins_entrada: 0,
              aliq_cofins_saida: 0
          }
          state.carregando = false;
      },
      setInsumos(state, insumos) {
        console.log('setInsumos', insumos);
        state.itemAtual.insumos = [];
        state.itemAtual.insumos = insumos;
        state.carregando = false;
      },
      setProdutoNfe (state, produtoNovo) {
        state.produtoNfe = produtoNovo;
      }
    }
}

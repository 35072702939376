import axios from '@/util/axios';
import axiosCep from 'axios';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import moment from 'moment';

export default{
    
  namespaced: true,

    //equivalente ao data
  state: {

    emitente: {
      cnpj: '',
      fantasia: '',
      razao: '', 
      ie: '', 
      im: '',
      cnae: '',
      descricao_cnae: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      codigo_cidade: '',
      uf: '',
      estado: '',
      pais: '',
      codigo_pais: '',
      logotipo: '',
      tema: '',
      versao: '4.00',
      ambiente: '',
      tipo_certificado: 'A1',
      formato_impressao: 1,
      certificado: '',
      frete_padrao: '9',
      forma_pagamento_padrao: '0',
      senha: '',
      telefone: '',
      email: '',
      ativa: true,
      plano: 'nfe',
      calculo_custo: constantes.CALCULO_MEDIA,
      configuracao_tributaria: {
        ramo_atividade: '',
        config_cfop_padrao: '1',
        cfop_padrao: '',
        regime_tributario: '',
        csosn: '102',
        serie_padrao: '',
        usa_icms_padrao: true,
        usa_icms_st_padrao: true,
        usa_pis_entrada_padrao: true,
        usa_pis_saida_padrao: true,
        usa_cofins_entrada_padrao: true,
        usa_cofins_saida_padrao: true,
        cst_icms: '00',
        cst_icms_st: '00',
        cst_pis_entrada: '01',
        cst_pis_saida: '01',
        cst_cofins_entrada: '01',
        cst_cofins_saida: '01',
        aliq_icms: 18.00,
        aliq_icms_st: 0,
        aliq_pis_entrada: 1.65,
        aliq_pis_saida: 1.65,
        aliq_cofins_entrada: 7.60,
        aliq_cofins_saida: 7.60,
        aliq_cred_sn: 18,
        info_complementar: '',
        empresa: null
      }
    },
    certificado: null,
    cidades: [],
    constantes: constantes,
    conversor: conversor,
    modoEdicao: false,
    carregando: false,
    carregando_cidade: false,
    certificadoCarregado: false,
  },

    //equivalente ao computed properties
  getters: {

    diasRestantesCertificado(state){
      let dataValidade = state.certificado.validade;
      if(dataValidade && dataValidade !== ''){
        let dias = moment(dataValidade).diff(moment(), 'days');
        if(dias < 0){
          return 'Vencido';
        }
        else if(dias === 0){
          return '1';
        }
        else{
          return dias;
        }
      }
    },
    listaCidades(state) {
      return state.cidades.map(cidade => { 
        return {
          text: cidade.cidade, 
          value: cidade.codigo_cidade 
        };
      });
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {

    async filtrarCidadesEstado({commit}, estado){

      try {

        commit('setCarregandoCidades', true);
        let cidades = await axios.get(`/cidades/estado/${estado}`).then(response => response.data);
        commit('setCidades', cidades);

      } catch (error) {
        commit('setCarregandoCidades', false);
        commit('setMensagemErro', 'Falha ao buscar cidades', {root: true});
        throw error;
      } finally {
        commit('setCarregandoCidades', false);
      }
    },

    async consultarCertificadoEmitente({commit, rootState}){

      commit('setCarregando', true);
      
      try{

        let response = await axios.get(`/empresas/certificado`);
        let retorno = response.data;
        if(retorno.status === 200){
          commit('setCertificado', retorno);
          commit('setCarregando', false);
        }
        else{
          commit('setCarregando', false);
          commit('setMensagemErro', 'Falha ao tentar Carregar Certificado', {root: true});
        }
      }
      catch(erro){
        commit('setCarregando', false);
        commit('setMensagemErro', 'Falha ao tentar Carregar Certificado', {root: true});
        throw erro;
      }
    },

    processarCertificado({commit, rootState}, certificado){

      commit('setCarregando', true);
      axios.put('/empresas/certificado', certificado)
      .then((response) => {
        let result = response.data;
        if(result.status === 200){
          commit('setCertificado', result);
          commit('setCarregando', false);
          commit('setMensagemSucesso', 'Certificado Atualizado com Sucesso', { root: true });
        }
        else{
          commit('setCarregando', false);
          commit('setMensagemErro', 'Falha ao processar certificado', { root: true });
        }
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    },

    consultarCnae({commit}, cnae){
      commit('setCarregando', true);
      axiosCep.get('https://servicodados.ibge.gov.br/api/v2/cnae/subclasses/' + cnae)
      .then((response) => {
        let dadosCnae = response.data;
        if(dadosCnae.length > 0){
          commit('setDescricaoCnae', dadosCnae);
        }
        else{
          commit('setMensagemErro', 'CNAE não Encontrado', {root: true});    
        }
        commit('setCarregando', false);
      })
      .catch((err) => {
        commit('setCarregando', false);
        commit('setMensagemErro', 'Falha ao tentar consultar CNAE', {root: true});
      });
    },

    async carregarEmitente({commit}, idEmitente){
      
      commit('setCarregando', true);
      
      try{

        let response = await axios.get(`/empresas/${idEmitente}`);
        let retorno = response.data;
        commit('setEmitente', retorno);
      }
      catch(erro){
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      }
    },

    carregarLogo({commit}, logotipo){

      commit('setCarregando', true);
      var reader = new FileReader();
      var vm = commit;
      reader.onload = (e) => {
        vm('setLogotipo', e.target.result);
        vm('setCarregando', false);
      }
      reader.readAsDataURL(logotipo);
    },

    async salvar({commit, state, rootState}){

      let emitente = Object.assign({}, state.emitente);
      emitente.cnpj = emitente.cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
      emitente.cep = emitente.cep.replace('.', '').replace('-', '');
      emitente.cnae = emitente.cnae.replace('-', '').replace('/', '');
      emitente.telefone = emitente.telefone.replace('(', '').replace(')', '').replace('-', '');

      commit('setCarregando', true);
      
      try{
        let response = await axios.put('/empresas', emitente);
        let retorno = response.data;
        
        //altera o ambiente da empresa atual para forçar a mudança do tema
        commit('setAmbiente', retorno.ambiente, {root: true});

        commit('setMensagemSucesso', 'Emitente Atualizado com Sucesso', {root: true});
        commit('setCarregando', false);
      }
      catch(erro){
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    }
  },

  //responsável por alterar o state.
  mutations: {

    setCidades(state, cidades){
      state.cidades = cidades;
    },
    setCidade(state, codigo_cidade){
      let cidade = state.cidades.find(cidade => cidade.codigo_cidade == codigo_cidade);
      
      state.emitente.cidade = cidade.cidade;
      state.emitente.codigo_cidade = cidade.codigo_cidade;
      state.emitente.uf = cidade.codigo_estado;
      state.emitente.pais = 'Brasil';
      state.emitente.codigo_pais = 1058;
    },
    setCertificado(state, certificado){
      state.certificado = certificado;
    },
    setEmitente(state, emitente){
      state.emitente = emitente;
      state.carregando = false;
    },
    setDadosEmpresa(state, empresa){
      state.emitente.cnae = empresa.cnae;
      state.emitente.ie = empresa.ie + '-' + empresa.uf;
      state.emitente.razao = empresa.razao;
      state.emitente.fantasia = empresa.fantasia;
      state.emitente.numero = empresa.endereco.numero;
      state.emitente.cep = empresa.endereco.cep;

      let encontrado = constantes.REGIMES_TRIBUTARIOS.find(regime => { return regime.text.toLowerCase().indexOf(empresa.regime.toLowerCase()) !== -1 });
      if(encontrado){
          state.empresaAtual.configuracao_tributaria.regime_tributario = encontrado.value;
      }
    },
    setLogotipo(state, logo){
      state.empresaAtual.logotipo = logo;
    },
    setEndereco(state, endereco){
        
      state.emitente.logradouro = endereco.logradouro;
      state.emitente.bairro = endereco.bairro;
      state.emitente.uf = conversor.ufParaCodigo(endereco.uf);
      state.emitente.estado = endereco.uf;
      state.emitente.cidade = endereco.localidade;
      state.emitente.codigo_cidade = endereco.ibge;
      state.emitente.pais = 'Brasil';
      state.emitente.codigo_pais = 1058;
    },
    setDescricaoCnae(state, dadosCnae){
      state.emitente.descricao_cnae = dadosCnae[0].descricao;
    },
    setCarregandoCidades(state, valor){
      state.carregando_cidade = valor;
    },
    setCarregando(state, valor){
      state.carregando = valor;
    },
    setInfoComplementar(state, infoComplementar){
      state.emitente.configuracao_tributaria.info_complementar = infoComplementar;
    },
    atualizarConfigCfop(state){
      if(state.emitente.configuracao_tributaria.config_cfop_padrao === '1'){ // cfop definido pelo ramo de atividade
        if(state.emitente.configuracao_tributaria.ramo_atividade === '1'){ // comércio
          state.emitente.configuracao_tributaria.cfop_padrao = '5102';
        }
        else if(state.emitente.configuracao_tributaria.ramo_atividade === '3'){ //industria
          state.emitente.configuracao_tributaria.cfop_padrao = '5101';
        }
      }
    },
    remover(state, removido){
      state.empresas = state.empresas.filter(empresa => empresa._id !== removido._id);
      state.carregando = false;
    }
  }
}
import axios from '@/util/axios';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import cidadesEstadosApi from '../../services/cidadesEstadosApi';

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {
    estados: [],
    cidades: [],
    cidadeAtual: {
      
    },
    constantes: constantes,
    modoEdicao: false,
    carregando: false
  },

  //equivalente ao computed properties
  getters: {
    //    
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    tratarConteudo({commit}, dados){
      commit('SET_CARREGANDO', true);

      let linhas = dados.split('\n');
      let cabecalhos = linhas[0];
      let keys = cabecalhos.split(';');
      
      let objetos = [];
      for(var indiceLinha = 1; indiceLinha < linhas.length; indiceLinha++){
        let linha = linhas[indiceLinha];
        let campos = linha.split(';');
        let objeto = {};
        for(var i = 0; i < keys.length; i++){
          let keyFormatada = keys[i].replace('"', '');
          keyFormatada = keyFormatada.replace('"', '');
          keyFormatada = keyFormatada.replace('\r', '');
          let campoFormatado = campos[i].replace('"', '');
          campoFormatado = campoFormatado.replace('"', '');
          campoFormatado = campoFormatado.replace('\r', '');
          objeto[keyFormatada] = campoFormatado;
        }
        objetos.push(objeto);
      }

      axios.post('/cidades', objetos)
      .then(response => {
          commit('SET_CIDADES', response.data);
          commit('SET_CARREGANDO', false);
      })
      .catch(err => {
          commit('setMensagemErro', err.response.data.motivo, {root: true});
          commit('SET_CARREGANDO', false);
      });
    },
    carregarEstados({commit}){
      commit('SET_CARREGANDO', true);
      try{
        let estados = [];
        constantes.ESTADOS.forEach(elemento => {
          let estado = {
            codigo: elemento.value,
            uf: elemento.text,
            nome: conversor.ufParaNome(elemento.text)
          }
          estados.push(estado);
        });
        commit('SET_ESTADOS', estados);
      }
      catch(erro){
        throw erro;
      }
    },
    async carregarItens({commit}){
      try {
        commit('SET_CARREGANDO', true);
        let response = await cidadesEstadosApi.listar();
        commit('SET_CIDADES', response);
      } catch (error) {
        console.log(error)
        commit('SET_CIDADES', []);
        commit('setMensagemErro', error.response.data.motivo, {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    },
    async exibirCidades({commit}, estado){
      try {
        commit('SET_CARREGANDO');
        let response = await cidadesEstadosApi.exibirCidades(estado);
        commit('SET_CIDADES', response);
      } catch (error) {
        console.log(error)
        commit('SET_CIDADES', []);
        commit('setMensagemErro', error.response.data.motivo, {root: true});
        throw error;
      } finally {
        commit('SET_CARREGANDO', false);
      }
    }
  },

  //responsável por alterar o state.
  mutations: {
    SET_CARREGANDO(state, valor){
      state.carregando = valor
    },
    SET_CIDADES(state, cidades){
      state.cidades = cidades;
      state.carregando = false;
    },
    SET_ESTADOS(state, estados){
      state.estados = estados;
      state.carregando = false;
    },
    detalhar(state, cidade){
      state.cidadeAtual = cidade;
    }
  }
}
<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent >
    <v-form v-model="valid" ref="formFiltroCheques" lazy-validation>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title class="white--text">Filtro</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar">close</v-icon>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-row dense>
              <v-col xs="12" sm="6">
                <v-select 
                  :items="['Em Caixa', 'Compensado', 'Repassado', 'Devolvido', 'Devolvido Pago']"
                  v-model="filtro.status"
                  label="Status"
                  persistent-hint
                  return-object
                  single-line
                  @change="definirLabelData"
                ></v-select>
              </v-col>
              <v-col xs="12" sm="6">
                <campo-data :label="label_data" v-model="filtro.data"></campo-data>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-switch
                  v-model="filtro.porPeriodo"
                  label="Por período"
                ></v-switch>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row dense v-show="filtro.porPeriodo">
              <v-col>
                <campo-data label="Data início" v-model="filtro.data_inicio"></campo-data>
              </v-col>
              <v-col>
                <campo-data label="Data fim" v-model="filtro.data_fim"></campo-data>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col>
            <v-btn @click="limpar()" text>Limpar</v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="fechar" text>Cancelar</v-btn>
            <v-btn color="primary" @click="confirmar()">OK</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import constantes from '@/util/constantes';
  import moment from 'moment';
  import * as regras from '@/util/validacaoFormulario';

  export default {
    name: "DialogoFiltroCheques",
    props: ['dialog'],
    data() { 
      return {
        valid: false,
        rules: {...regras},
        constantes: constantes,
        filtro: {
          status: 'Em Caixa',
          data: moment().format('YYYY-MM-DD'),
          data_inicio: moment().startOf('month').format('YYYY-MM-DD'),
          data_fim: moment().format('YYYY-MM-DD'),
          porPeriodo: false
        },
        label_data: 'Data Vencimento',
      }
    },

    computed: {
      cadastro(){
        if(this.filtro.cadastro != ''){
          let cadastro = moment(this.filtro.cadastro).format('DD/MM/YY');
          return cadastro;
        }
        else{
          return '';
        }
      }
    },

    methods: {
      fechar(){
        this.$emit('limpar');
        this.$emit('fechar');
      },

      definirLabelData(status) {
        switch (status) {
          case 'Em Caixa':
            this.label_data = 'Data Vencimento';
            break;
          case 'Compensado':
            this.label_data = 'Data Compensação';
            break;
          case 'Repassado':
            this.label_data = 'Data Repasse';
            break;
          case 'Devolvido':
            this.label_data = 'Data Devolução';
            break;
          case 'Devolvido Pago':
            this.label_data = 'Data Devolução';
            break;
        }
      },

      confirmar(){
        if(this.$refs.formFiltroCheques.validate()){
          this.$emit('filtrar', this.filtro);
        }
      },

      limpar(){
        this.filtro = {
          status: 'Em Caixa',
          data: moment().format('YYYY-MM-DD'),
          data_inicio: moment().startOf('month').format('YYYY-MM-DD'),
          data_fim: moment().format('YYYY-MM-DD'),
          porPeriodo: false
        }
      }
    }
  }
</script>
<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent >
    <v-form v-model="valid" ref="formInutilizacao" lazy-validation>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title class="white--text">Inutilização de Intervalo</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
      </v-toolbar>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field label="Série" v-model="inutilizacao.serie" :rules="obrigatorio" mask="###"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field label="Núm. Inicial" v-model="inutilizacao.nInicial" :rules="obrigatorio"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field label="Núm. Final" v-model="inutilizacao.nFinal" :rules="obrigatorio"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12">
            <v-textarea label="Justificativa" v-model="inutilizacao.justificativa" :rules="validarJustificativa" placeholder="Justificativa para inutilização"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="fechar()" text :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary" @click="inutilizar" :loading="loading">Inutilizar</v-btn>
      </v-card-actions>
    </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  
  export default {
    
    data: () => ({
      dialog: false,
      valid: false,
      validarJustificativa: [v => (v && v.length > 15) || "Justificativa deve conter pelo menos 15 Caracteres"],
      obrigatorio: [v => !!v || "Orbigatório"],
      inutilizacao: {
        serie: '',
        nInicial: '',
        nFinal: '',
        justificativa: ''
      },
      loading: false
    }),

    methods: {

      abrir() {
        this.dialog = true;
      },

      fechar(){
        this.dialog = false;
        this.limpar();
        this.$refs.formInutilizacao.resetValidation();
      },

      async inutilizar(){
        if(this.$refs.formInutilizacao.validate()){
          try {
            this.loading = true;
            await this.$store.dispatch('nota/inutilizarIntervalo', this.inutilizacao);
            this.limpar();
            this.fechar();
          } catch (error) {
            
          } finally {
             this.loading = false;
          }
          
        }
      },

      limpar(){
        this.inutilizacao = {
          serie: '',
          nInicial: '',
          nFinal: '',
          justificativa: ''
        }
      }
    }
  }
</script>
export default [
  {
    path: '/movimentacoes/producao',
    name: 'ListaMovimentoProducao',
    component: () => import( '@/views/movimentoproducao/ListaMovimentoProducao'),
    meta: { requiresAuth: true, nivel: 1}
  },
  /* {
    path: '/movimentacoes/producao/novo',
    name: 'MovimentoProducaoNovo',
    component: () => import('@/views/movimentoproducao/FormularioMovimentoProducao'),
    meta: { requiresAuth: true, nivel: 1}
  }, */
  /* {
    path: '/movimentacoes/pedidoscompra/editar',
    name: 'PedidosCompraEditar',
    component: () => import('@/views/pedidoscompra/FormularioPedidosCompra'),
    meta: { requiresAuth: true, nivel: 1}
  } */
];
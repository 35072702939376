import conversor from './conversor';
import moment from 'moment';

class Formatar{

    formatarPlaca(placa){
        return placa.substr(0, 3) + '-' + placa.substr(3, 4);
    }

    formatarPlanoConta(plano){
        if(plano && plano.length === 6){
            return plano.substr(0, 1) + '.' + plano.substr(1, 2) + '.' + plano.substr(3, 3);
        }
        else{
            return '';
        }
    }

    formatarPadZero(valor, quantidade){
        return valor.toString().padStart(quantidade, '0')
    }

    formatarValor(valor){
        if(valor){
            return valor.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, useGrouping: false});
        }
        else{
            return '0,00';
        }
    }
    
    formatarValorUS(valor, decimais){
        valor    = typeof valor !== 'undefined' ? valor : '0';
        decimais = typeof decimais !== 'undefined' ? decimais : 2;

        if (typeof valor === 'number') {
            return parseFloat(valor.toFixed(decimais));
            // valor = valor.toString();
        }

        valor = valor.replace(/[.]/g, '').replace(',', '.');
        valor = this.formatarValor(valor,decimais,'.','');

        return parseFloat(valor);
    }
    
    formatarMoeda(valor){
        if(valor){
            return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'});
        }
        else{
            return 'R$ 0,00';
        }
    }

    formatarPercentual(percentual){
        if(percentual){
            return percentual.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2}) + '%';
        }
        else{
            return '0%';
        }
    }

    formatarCnpj(cnpj){
        if(cnpj){
            if(cnpj.length == 14){
                return cnpj.substr(0, 2) + '.' + cnpj.substr(2, 3) + '.' + cnpj.substr(5, 3) + '/' + cnpj.substr(8, 4) + '-' + cnpj.substr(12, 2);
            }
            else if(cnpj.length == 11){
                return cnpj.substr(0, 3) + '.' + cnpj.substr(3, 3) + '.' + cnpj.substr(6, 3) + '-' + cnpj.substr(9, 2);
            }
            else{
                return '';
            }
        }
        else{
            return ''
        }
    }

    formatarCep(cep){
        if(cep.length == 8){
            return cep.substr(0, 2) + '.' + cep.substr(2, 3) + '-' + cep.substr(5, 3);
        }
        else{
            return '';
        }
    }

    formatarTelefone(telefone){
        if(telefone){
            if(telefone.length == 10){
                return '(' + telefone.substr(0, 2) + ') ' + telefone.substr(2, 4) + '-' + telefone.substr(6, 4);
            }
            else if(telefone.length == 11){
                return '(' + telefone.substr(0, 2) + ') ' + telefone.substr(2, 5) + '-' + telefone.substr(7, 4); 
            }
            else{
                return '';
            }
        }
        else{
            return '';
        }
    }

    formatarModeloFrete(modelo){
        return conversor.codigoParaModeloFrete(modelo);
    }

    formatarChave(chave){

        if(chave.length == 44){
            return chave.substr(0, 4)  + ' ' + chave.substr(4, 4)  + ' ' + chave.substr(8, 4)  + ' ' + chave.substr(12, 4) + ' ' +
                   chave.substr(16, 4) + ' ' + chave.substr(20, 4) + ' ' + chave.substr(24, 4) + ' ' + chave.substr(28, 4) + ' ' +
                   chave.substr(32, 4) + ' ' + chave.substr(36, 4) + ' ' + chave.substr(40, 4);
        }
        else{
            return '';
        }
    }

    formatarData(data){
        if(data){
            return moment(data).utc().format('DD/MM/YYYY');
        }
        else{
            return '';
        }
    }

    formatarHora(datetime){
        if(datetime){
            return moment(datetime).format('HH:mm:ss');
        }
        else{
            return '';
        }
    }

    formatarDataHora(datetime){
        if(datetime){
            return moment(datetime).format('DD/MM/YYYY HH:mm')
        }
        else{
            return '';
        }
    }
}

let formatar = new Formatar();

export default formatar;
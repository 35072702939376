<template>
  <v-app id="login" class="primary">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img src="../assets/logo.png" alt="Anron Software" width="120" height="120">
                  <h1 class="flex my-4 primary--text">Easy Web</h1>
                </div>                
                <v-form v-model="valid" ref="formLogin">
                  <v-text-field autofocus append-icon="person" label="E-Mail" type="text" :rules="validaEmail" v-model="usuario.email"></v-text-field>
                  <v-text-field append-icon="lock" label="Senha" type="password" :rules="validaSenha" v-model="usuario.senha" @keypress.enter="validar(usuario)"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn block color="primary" @click="validar(usuario)" :loading="carregando">Acessar</v-btn>
              </v-card-actions>
            </v-card>
            <v-snackbar :timeout="10000" bottom multi-line color="red" v-model="showErro">{{ erro }}
              <v-btn dark text @click.native="showErro = false" icon><v-icon>close</v-icon></v-btn>
            </v-snackbar>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import {mapState} from 'vuex';

import axios from "@/util/axios";
import colors from 'vuetify/es5/util/colors';
import validar from '@/util/validador';

export default {
  data() {
    return {
      valid: false,
      validaEmail: [v => !!v || "Informe o E-Mail"],
      validaSenha: [v => !!v || "Informe a Senha"],
      usuario: {
        email: '',
        senha: ''
      },
      showErro: false,
      erro: '',
      carregando: false,
      colors: colors
    };
  },

  methods: {

    validar(usuario){
      if(this.$refs.formLogin.validate()){
        this.logar(usuario);
      }
      else{

      }
    },

    logar(usuario) {
      this.carregando = true;
      
      let logarUsuario = Object.assign({}, this.usuario);
      logarUsuario.senha = btoa(logarUsuario.senha);
      
      axios.post("/loginAdmin", logarUsuario)
      .then(response => {

        localStorage.setItem("token", response.data.token);

        this.$store.commit("setUsuarioAdmin", {
          nome: response.data.nome,
          email: this.usuario.email,
          foto: response.data.foto,
          nivel_acesso: response.data.nivel_acesso,
        });

        this.$vuetify.theme.primary = this.colors['green'].base;
        this.$cookies.set("token", response.data.token, "1d");
        this.$router.replace({ path: "/empresasAdmin" });

        this.$store.commit('setMensagemSucesso', 'Bem Vindo(a) ' + response.data.nome);
      })
      .catch(erro => {

        if(erro.response){
          this.erro = erro.response.data.motivo;
          this.showErro = true;
        }
        else{
          this.erro = erro.message;
          this.showErro = true;
        }
        localStorage.removeItem("token");
      })
      .then(() => {
        this.carregando = false;
      });
    },

    ...mapState({
      snackbar : 'snackbar'
    })
  }
};
</script>

<style scoped lang="css">
  #login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
  }
</style>
<template>
  <div>
    <v-text-field :loading="loading" label="Buscar CEST" :value="value" append-icon="search" @keyup.enter="consultarCodigoCest($event)" @input="onInput" @click:append="abrirDialogo" data-cy="campo-ncm" maxlength="7"></v-text-field>

    <dialog-buscar-cest ref="dialogoBuscarCEST" @cestSelecionado="adicionarCestDialog" :busca="value"></dialog-buscar-cest>
  </div>
</template>

<script>
  
  import axios from "@/util/axios";
  import DialogBuscarCest from '@/components/dialogs/DialogBuscarCest';
  
  export default {
    name: 'BuscarCest',
    props: {
      value: {
        type: [Number,String],
        default: null
      },
    },

    components: {
      DialogBuscarCest,
    },

    data() {
      return {
        dialogo: false,
        loading: false
      }
    },

    methods: {
      onInput(value) {
        this.$emit('input', value);
      },

      abrirDialogo() {
        this.$refs.dialogoBuscarCEST.abrir();
      },

      consultarCodigoCest(event){

        let cest = event.target.value;
        
        this.loading = true;
        if(cest && cest.length === 7){

         axios.get(`/cest/filtro/${cest}`)
          .then(response => {
            if(response.data.length > 0){
              this.$emit('cestSelecionado', response.data[0]);
            }
            else{
              this.$store.commit('setMensagemErro', 'CEST não encontrado');
            }
            this.loading = false;
          })
          .catch((erro) => {
            this.loading = false;
            this.$store.commit('setMensagemErro', erro.response.data.motivo);
          });
        }
        else{
          this.$refs.dialogoBuscarCEST.abrir();
          this.loading = false;
        }
      },

      adicionarCestDialog(ncm) {
        this.$emit('cestSelecionado', ncm);
      }, 
    }
  }
</script>

<style scoped>

</style>
import crud from "./modules/crud";

import pedidosClientes from "./modules/pedidosClientes";
import pedidosCompra from "./modules/pedidosCompra";

import sefaz from "./modules/sefaz";
import produto from "./modules/produto";
import participante from "./modules/participante";
import empresa from "./modules/empresa";
import transportador from "./modules/transportador";
import dispositivo from "./modules/dispositivo";
import notificacao from "./modules/notificacao";
import importacao from "./modules/importacao";
import nota from "./modules/nota";
import notaDeposito from "./modules/notaDeposito";
import dashboard from "./modules/dashboard";
import manifesto from "./modules/manifesto";
import arquivo from "./modules/arquivo";
import ibpt from "./modules/ibpt";
import cfop from "./modules/cfop";
import cidadeEstado from "./modules/cidadeEstado";
import cofins from "./modules/cofins";
import pis from "./modules/pis";
import ncm from "./modules/ncm";
import cest from "./modules/cest";
import emitente from "./modules/emitente";
import estoque from "./modules/estoque";
import filial from "./modules/filial";
import usuario_sistema from "./modules/usuario";
import estoqueDeposito from "./modules/estoqueDeposito";

// Módulos removidos do EasyPDVWeb para compor o Fisc-e

import contaCorrente from "./modules/contaCorrente";
import banco from "./modules/banco";
import cor from "./modules/cor";
import tamanho from "./modules/tamanho";
import mva from "./modules/mva";
import grupo from "./modules/grupo";
import centroCusto from "./modules/centroCusto";
import vendedor from "./modules/vendedor";
import cfopCompraEntrada from "./modules/cfopCompraEntrada";
import fabricante from "./modules/fabricante";
import planoContas from "./modules/planoContas";
import localEstoque from "./modules/localEstoque";
import contaAReceber from "./modules/contaAReceber";
import caixa from "./modules/caixa";
import contaAPagar from "./modules/contaAPagar";
import cheque from "./modules/cheque";
import ordensServicos from "./modules/ordensServicos";
// import movimentoEstoque from './modules/movimentoEstoque';
import vendas from "./modules/vendas";
import compra from "./modules/compra";
import configuracao from "./modules/configuracao";
import graficos from "./modules/graficos";

import movimentoProducao from "./modules/movimentoProducao";
import cupons from "./modules/cupons";

import remessasCheques from "./modules/remessasCheques";

export default {
  crud,
  sefaz,
  produto,
  participante,
  empresa,
  nota,
  notaDeposito,
  dashboard,
  manifesto,
  arquivo,
  transportador,
  dispositivo,
  notificacao,
  importacao,
  ibpt,
  cfop,
  cidadeEstado,
  cofins,
  pis,
  ncm,
  cest,
  emitente,
  estoque,
  filial,
  usuario_sistema,
  estoqueDeposito,
  contaCorrente,
  banco,
  cor,
  tamanho,
  mva,
  grupo,
  centroCusto,
  vendedor,
  cfopCompraEntrada,
  fabricante,
  planoContas,
  localEstoque,
  contaAReceber,
  caixa,
  contaAPagar,
  cheque,
  // movimentoEstoque,
  ordensServicos,
  vendas,
  compra,
  pedidosClientes,
  pedidosCompra,
  graficos,

  movimentoProducao,
  cupons,
  configuracao,
  remessasCheques,
};

import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual) {
    return axios.get(`/contasAReceber?` + qs.stringify(options))
      .then(response => response.data);
  }
  
  function filtrar(options, cliente, empresaAtual) {
    return axios.get(`/contasAReceber/${cliente._id}/filtrar?` + qs.stringify(options))
      .then(response => response.data);
  }

  function salvar(conta, empresaAtual) {
    return axios.post(`/contasAReceber`, conta)
      .then(response => response.data);
  }

  function atualizar(conta, empresaAtual) {
    return axios.put(`/contasAReceber/${conta._id}`, conta)
      .then(response => response.data);
  }

  function excluir(id, empresaAtual) {
    return axios.delete(`/contasAReceber/${id}`)
      .then(response => response.data);
  }
  
  function salvarBaixa(conta, recebimento, empresaAtual) {
    return axios.post(`/contasAReceber/${conta._id}/recebimentos/baixar`, recebimento)
      .then(response => response.data);
  }

  function excluirRecebimento(conta, recebimento, empresaAtual) {
    return axios.delete(`/contasAReceber/${conta._id}/recebimentos/${recebimento._id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    filtrar,
    salvar,
    atualizar,
    excluir,
    salvarBaixa,
    excluirRecebimento
  }
import axios from '@/util/axios';

  function vendasPorMes(empresaAtual, periodo) {
    return axios.post(`/graficos/vendasPorMes`, periodo)
      .then(response => response.data);
  }
  
  function vendasPorIntervaloData(empresaAtual, periodo) {
    return axios.post(`/graficos/vendasPorIntervaloData`, periodo)
      .then(response => response.data);
  }

  export default {
    vendasPorMes,
    vendasPorIntervaloData,
  }
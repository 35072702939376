import axios from '@/util/axios';
import moment from 'moment';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import formatador from '@/util/formatador';

export default{
    
  namespaced: true,

  //equivalente ao data
  state: {

    contasAPagar: [],
    contasAPagarFornecedor: [],
    contaAtual: {
      participante: {},
      documento: '',
      data_cadastro: moment().format('YYYY-MM-DD'),
      data_vencimento: '',
      valor: 0,
      valor_restante: 0,
      valor_pago: 0,
      nota_fiscal: '',
      tipo: 'Duplicata',
      observacao: ''
    },
    fornecedores: [],
    fornecedorAtual: {},
    constantes: constantes,
    modoEdicao: false,
    carregando: false
  },

  //equivalente ao computed properties
  getters: {

    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {

    async carregar({commit, rootState}, tipo){

      commit('setCarregando', true);
      
      try{

        // let response = await axios.get(`/contasAPagar/tipos/${tipo}`);
        let response = await axios.get(`/contasAPagar`);
        commit('setContasAPagar', response.data);
      }
      catch(erro){
        commit('setContasAPagar', []);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    },
    async carregarContasAPagarFornecedor({commit, state, rootState}){

      commit('setCarregando', true);
      
      try{

        let response = await axios.get(`/contasAPagar/fornecedores/${state.fornecedorAtual._id}`);
        commit('setContasAPagarFornecedor', response.data);
      }
      catch(erro){
        commit('setContasAPagarFornecedor', []);
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    },
    async salvar({commit, state, rootState}){
        
      commit('setCarregando', true);

      let contaAPagar = Object.assign({}, state.contaAtual);
      contaAPagar.valor = conversor.stringParaNumero(contaAPagar.valor);

      if(state.modoEdicao){

        try{

          let response = await axios.put(`/contasAPagar`, contaAPagar);
          let retorno = response.data;
          if(retorno.status){
            commit('setMensagemErro', retorno.motivo, {root: true});
            commit('setCarregando', false);
          }
          else{
            commit('atualizar', retorno);
            commit('setMensagemSucesso', 'Conta a Pagar Atualizada com Sucesso', {root: true});
          }
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
      else{
          
        delete(contaAPagar._id);
        try{

          let response = await axios.post(`/contasAPagar`, contaAPagar);
          let retorno = response.data;
          if(retorno.status){
            commit('setMensagemErro', retorno.motivo, {root: true});
            commit('setCarregando', false);
          }
          else{
            commit('adicionar', retorno);
            commit('setMensagemSucesso', 'Conta a Pagar Cadastrada com Sucesso', {root: true});
          }
        }
        catch(erro){
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          commit('setCarregando', false);
          throw erro;
        }
      }
    },
    remover({commit, rootState}, remover){
      commit('setCarregando', true);
      console.log('REMOVER', remover._id);

      axios.delete('/contasAPagar/' + remover._id)
      .then((response) => {
        commit('remover', remover);
        commit('setMensagemSucesso', 'Conta a Pagar Removida com Sucesso', {root: true});
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    },
    async salvarBaixaContaAPagar({commit, rootState, state}, dados){
        
      let pagamentos = dados.pagamentos;
      let contasAPagar = dados.contasAPagar;

      let baixa = {
        contasAPagar: contasAPagar,
        formasPagamento: pagamentos
      }

      commit('setCarregando', true);
      
      try{

        let response = await axios.post(`/contasAPagar/baixar`, baixa);
        let retorno = response.data;
        if(retorno.status){
          commit('setMensagemErro', retorno.motivo, {root: true});
          commit('setCarregando', false);
        }
        else{
          retorno.forEach(contaAPagar => {
            commit('atualizar', contaAPagar);
          });
          commit('setCarregando', false);
          commit('setMensagemSucesso', 'Conta a Pagar Atualizada com Sucesso', {root: true});
        }
      }
      catch(erro){
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
        throw erro;
      }
    }
  },

  //responsável por alterar o state.
  mutations: {

    setContasAPagar(state, contas){
      state.contasAPagar = contas;
      state.carregando = false;
    },
    setContasAPagarFornecedor(state, contas){
      state.contasAPagarFornecedor = contas;
      state.carregando = false;
    },
    setParticipante(state, participante) {
      state.contaAtual.participante = participante;
    },
    setFornecedorAtual(state, fornecedor){
      state.fornecedorAtual = fornecedor;
      state.carregando = false;
    },
    setCarregando(state, valor){
      state.carregando = valor;
    },
    remover(state, removido){
      state.contasAPagar = state.contasAPagar.filter(contaAPagar => contaAPagar._id !== removido._id);
      state.carregando = false;
    },
    novo(state){
      state.modoEdicao = false;
      state.contaAtual = {
        participante: {},
        documento: '',
        data_cadastro: moment().format('YYYY-MM-DD'),
        data_vencimento: '',
        valor: 0,
        valor_restante: 0,
        valor_pago: 0,
        nota_fiscal: '',
        tipo: 'Duplicata',
        observacao: ''
      }
      state.carregando = false;
    },
    editar(state, editar){

      // editar.valor = formatador.formatarValor(editar.valor);
      // editar.data_vencimento = moment(editar.data_vencimento).format('YYYY-MM-DD');

      state.modoEdicao = true;
      state.contaAtual = editar;
      state.carregando = false;
    },
    adicionar(state, contaAPagar){
      state.contasAPagar.push(contaAPagar);
      state.carregando = false;
    },
    atualizar(state, atual){
      let index = state.contasAPagar.findIndex((conta) => {
        return conta._id == atual._id;
      });

      if (index > -1) {
        state.contasAPagar.splice(index, 1, atual);
      }

      state.contaAtual = atual;

      state.carregando = false;
    },
    estadoInicial(state){
      state.contaAtual = {
        participante: {},
        documento: '',
        data_cadastro: moment().format('YYYY-MM-DD'),
        data_vencimento: '',
        valor: 0,
        valor_restante: 0,
        valor_pago: 0,
        nota_fiscal: '',
        tipo: 'Duplicata',
        observacao: ''
      }
      state.carregando = false;
    },
    estadoInicialBaixa(state){
      state.contasAPagarFornecedor = [];
      state.fornecedorAtual = {};
      state.carregando = false;
    }
  }
}
<template>
  <div>
    <v-dialog v-model="dialog" max-width="780px" persistent scrollable>
      <v-card>
        <v-toolbar flat dark dense color="primary">
          <v-toolbar-title>Filtro</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-form ref="formFiltro" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <buscar-participante :label="'Cliente'" ref="busca_participante" v-model="cliente.cnpj_cpf" @participanteSelecionado="clienteSelecionado" />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="cliente.razao_nome" label="Nome Cliente" placeholder=" " ref="cliente_nome" readonly hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-select v-model="filtro.exibir" label="Exibir" :items="itens_exibir" hide-details></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="limpar()" text>Limpar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="fechar" :disabled="carregando">Cancelar</v-btn>
          <v-btn color="primary" :loading="carregando" @click="filtrar">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: 'DialogoFiltro',
    data () {
      return {
        dialog: false,
        options: {
          itemsPerPage: 10,
          page: 1
        },
        itens_exibir: [
          'Todos',
          'Em Aberto',
          'Vencidos',
          'Pagos',
          'Pagos Parcialmente'
        ],
        filtro: {
          cliente: '',
          exibir: 'Todos'
        },
      }
    },
    computed: {
      ...mapState('contaAReceber', {
        contas: 'contasAReceber',
        cliente: 'clienteAtual',
        carregando: 'carregando'
      }),
    },
    methods: {
      abrir () {
        this.dialog = true;
      },
      
      fechar() {
        this.dialog = false;
      },

      limpar() {
        this.$store.commit('contaAReceber/SET_CLIENTE', {});
        this.filtro = {
          cliente: {},
          exibir: 'Todos'
        },
        this.$emit('limpar');
        this.dialog = false;
      },
      
      async filtrar(){
        if (this.cliente._id) {
          let newOptions = {
            ...this.options,
            ...this.filtro
          }
          this.$emit('filtrar', newOptions);
          this.fechar();
        } else {
          this.$store.commit('setMensagemErro', 'Escolha um cliente');
        }
      },

      clienteSelecionado(cliente) {
        this.$store.commit('contaAReceber/SET_CLIENTE', cliente);
      }
    },
  }
</script>

<style>
  
</style>
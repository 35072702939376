import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import VueCookies from "vue-cookies";
import "./theme/impressoes.css";
import "@/plugins/echarts";
import "font-awesome/css/font-awesome.css";
import vuetify from "./plugins/vuetify";
import VueTheMask from "vue-the-mask";
import "@/components/componentes";
import "@/util/filtros";
import "@/assets/scrollbar.css";
import VueSocketIO from "vue-socket.io";

import config from './util/config';
import './registerServiceWorker'

let socketDebug = true;
// if(process.env.NODE_ENV === 'production'){
//   socketDebug = true;
// }

Vue.use(VueCookies);
Vue.use(VueTheMask);
// Vue.use(
//   new VueSocketIO({
//     debug: socketDebug,
//     connection: config.URL_SOCKET_IO
//   })
// );

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

if (window.Cypress) {
  window.app = app;
}

<template>
  <div>
    <v-subheader class="text-subtitle-1 text--primary">
      Ordens de Serviços
    </v-subheader>
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="white">
          <v-row>
            <v-col xs="12" sm="6">
              <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @keyup.enter="filtrarBusca($event)" @click:clear="limparBusca" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col xs="12" sm="4" class="text-right pr-0">
              <v-select :items="lista_status" filled dense hide-details v-model="status" @change="filtrar($event)"></v-select>
            </v-col>
            <v-col xs="12" sm="2" class="text-right">
              <v-btn color="primary" @click="novo">Nova</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :headers="headers" :options.sync="options" :server-items-length="total" :items="ordens_servicos" :loading="carregando" :footer-props="footerOptions">
          <template v-slot:item="{item}">
            <tr>
              <td class="text-center">{{ item.numero }}</td>
              <td class="text-center">{{ item.data | formatarData }}</td>
              <td class="text-center">{{ item.agendamento | formatarData }}</td>
              <td><b>{{ item.participante.razao_nome }}</b></td>
              <td class="text-center">{{ item.placa }}</td>
              <td class="text-center"><v-chip pill outlined><v-avatar left :color="definirCor(item.status)">{{definirLetra(item.status)}}</v-avatar>{{ item.status}}</v-chip></td>
              <td class="text-right"><b>{{ item.valor | formatarMoeda }}</b></td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition" @click.native.stop="">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item v-if="item.status=='Concluída'" :key="item._id + 10" @click="visualizar(item)" data-cy="editar">
                      <v-list-item-icon><v-icon>mdi-eye</v-icon></v-list-item-icon>
                      <v-list-item-title>Visualizar</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="item.status=='Pendente'" :key="item._id + 10" @click="editar(item)" data-cy="editar">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="item._id + 15" @click="excluir(item._id)" data-cy="remover">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Remover</v-list-item-title>
                    </v-list-item>
                    <v-list-item :key="item._id" @click="abrirDialogoFechamento(item)">
                      <v-list-item-icon><v-icon>mdi-checkbox-marked</v-icon></v-list-item-icon>
                      <v-list-item-title>Fechamento</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <dialogo-fechamento :dialog="dialogoFechamento" @fechar="cancelarFechamento" @finalizar="salvarFechamento" />
  </div>
</template>

<script>
  
  import DialogoFechamento from './DialogoFechamento';
  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    name: 'ListaOrdensServicos',
    components: {
      DialogoFechamento
    },
    data () {
      return {
        headers: [
          { text: 'Número', value: 'numero', sortable: false, width: '5%', align:'center' },
          { text: 'Data', value: 'data', width: '10%', align:'center' },
          { text: 'Agendamento', value: 'agendamento', width: '10%', align:'center' },
          { text: 'Cliente', value: 'cliente', width: '45%' },
          { text: 'Placa', value: 'quantidade', width: '15%', align:'center' },
          { text: 'Status', value: 'status', width: '10%', align:'center' },
          { text: 'Valor', value: 'valor', width: '10%', align:'right' },
          { text: 'Ações', value: 'acoes', align:'center',  sortable: false, width: '5%' }
        ],
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        },
        busca: '',
        contantes: constantes,
        lista_status: [
          {
            text: 'Todas',
            value: ''
          }, 
          {
            text: 'Pendentes',
            value: 'Pendente'
          }, 
          {
            text: 'Concluídas',
            value: 'Concluída'
          }, 
        ],
        status: '',
        dialogoFechamento: false,
        abaAtiva: 0,
        status_novo: '',
      }
    },

    watch: {
      options: {
        handler () {
          this.listar();
        },
        deep: true,
      },
    },

    computed: {
      ...mapState('ordensServicos', {
        ordens_servicos: 'ordens_servicos',
        ordem_servico: 'ordemServicoAtual',
        carregando: 'carregando',
        total: 'totalOrdensServicos',
      })
    },
    
    methods: {
      novo(){
        this.$store.commit('ordensServicos/ZERAR');
        this.$router.push({name: 'OrdensServicosNovo'});
      },
      listar(){
        this.$store.dispatch('ordensServicos/listar', this.options); 
      },
      limparBusca(){
        this.listar();
      },
      filtrar(event){
        let newOptions = {
          ...this.options,
          query: this.status
        }

        this.$store.dispatch('ordensServicos/listar', newOptions);
      },
      visualizar(ordem_servico_selecionada) {
        this.$store.commit('ordensServicos/SET_ORDEM_SERVICO', ordem_servico_selecionada);
        this.$store.commit('ordensServicos/SET_VISUALIZAR');
        this.$router.push({name: 'OrdensServicosEditar'});
      },
      editar(ordem_servico_selecionada) {
        this.$store.commit('ordensServicos/SET_ORDEM_SERVICO', ordem_servico_selecionada);
        this.$store.commit('ordensServicos/SET_VISUALIZAR', false);
        this.$router.push({name: 'OrdensServicosEditar'});
      },
      excluir(ordem_servico_id) {
        this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?')
        .then((confirmado) => {
          if (confirmado) {
            this.$store.dispatch('ordensServicos/excluir', ordem_servico_id);
          }
        });
      },
      abrirDialogoFechamento(ordem_servico_selecionada) {
        this.$store.commit('ordensServicos/SET_ORDEM_SERVICO', ordem_servico_selecionada);
        this.dialogoFechamento = true;
      },
      async salvarFechamento(fechamento) {
        this.dialogoFechamento = false;
        await this.$store.dispatch('ordensServicos/fechamento', fechamento);
      },
      cancelarFechamento() {
        this.dialogoFechamento = false;
      },
      definirCor(status) {
        if (status == 'Concluída') {
          return 'green';
        } else if (status == 'Pendente') {
          return 'white';
        } else if (status == 'Cancelada') {
          return 'red';
        }
      },
      definirLetra(status){
        if(status){
          return status.charAt(0);
        }else{
          return '';
        }
      },
    },
  }
</script>

<style>
  
</style>
<template>
<v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable>
  <v-card>
    <v-toolbar flat dark color="primary">
      <v-toolbar-title>Buscar Transportador</v-toolbar-title>
    </v-toolbar>
    <v-toolbar flat color="white">
      <v-text-field flat solo single-line hide-details clearable v-model="busca" @keyup.enter="buscarNomeTransportador(busca)" max-width="300px" prepend-inner-icon="search" label="Buscar Transportador"></v-text-field>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text style="height: 400px">
      <v-data-table hide-default-footer :headers="headers" :search="busca" :items="transportadores" item-key="_id" :loading="carregando">
        <template #item="{ item, isSelected, select}">
          <tr @click="selecionarTransportador(item)" style="cursor: pointer" class="pt-1">
            <td>{{ item.cnpj_cpf | formatarCnpj }}</td>
            <td>{{ item.razao_nome }}</td>
            <td>{{ item.cidade }}</td>
            <td>{{ item.estado }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="fechar()" text>Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";

export default {
    
    props: ['dialog'],

    data(){
      return {
        headers: [
            { text: "CNPJ / CPF", value: "cnpj_cpf", sortable: false },
            { text: "Razão / Nome", value: "razao_nome", sortable: false },
            { text: "Cidade", value: "cidade", sortable: false },
            { text: "Estado", value: "estado", sortable: false },
        ],
        busca: '',
      }
    },

    computed: {

      ...mapState('transportador', {
        transportadores: 'transportadores',
        carregando: 'carregando'
      })
    },

    methods: {

      buscarNomeTransportador(nome){

        if(nome !== ''){
          this.$store.dispatch('transportador/filtrarNomeTransportador', nome);
        }
        else{
          this.$store.commit('setMensagemErro', 'Busca vazia');
        }
      },

      selecionarTransportador(transportador){
        this.busca = '';
        this.$emit('transportadorSelecionado', transportador);
      },

      fechar(){
        this.busca = '';
        this.$emit('fechar');
      }
    }
}
</script>
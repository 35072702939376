import ListaCentroCusto from '@/views/centroscusto/ListaCentroCusto';
import FormularioCentroCusto from '@/views/centroscusto/FormularioCentroCusto';

export default [
  {
    path: '/tabelas/centrosCustos',
    name: 'centroscusto',
    component: ListaCentroCusto,
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/tabelas/centrosCustos/novo',
    name: 'formularioCentroCustoNovo',
    component: FormularioCentroCusto,
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/tabelas/centrosCustos/editar',
    name: 'formularioCentroCustoEditar',
    component: FormularioCentroCusto,
    meta: { requiresAuth: true, nivel: 1}
  },
];
<template>

  <v-text-field v-mask="'##/##/####'" v-model="dateFormatted" ref="campo" v-bind="$attrs" @input="onInput" @focus="onFocus" @blur="onBlur" @keypress.esc="dateFormatted = ''" @keypress="onKeypress" maxlength="10" :disabled="disabled">
    <template v-slot:prepend-inner>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">

        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-calendar</v-icon>
        </template>

        <v-date-picker v-model="date" no-title @input="menu = false" @change="onChange"></v-date-picker>
      </v-menu>
    </template>
  </v-text-field>
    
</template>

<script>
  export default {
    props: {
      value: {
        type: [String],
        default: null
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    data: (vm) => ({
      dateFormatted: null,
      menu: false,
      isFocused: false
    }),

    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },

      date: {
        get () {
          this.dateFormatted = this.formatDate(this.value);
          return this.value;
        },
        set (val) {
          // this.dateFormatted = this.formatDate(val);
          this.$emit('input', val);
        }
      },

      isSm() {
        return this.$vuetify.breakpoint.smAndDown;
      }
    },

    methods: {
      onInput() {
        if (this.dateFormatted) {
          // if (this.dateFormatted.length == 2) {
          //     this.dateFormatted = this.dateFormatted + '/' + (new Date().getMonth() + 1).toString().padStart(2, '0');
          //     this.selecionarMes();
          // } else if (this.dateFormatted.length == 5) {
          //     this.dateFormatted = this.dateFormatted + '/' + new Date().getFullYear();
          //     this.selecionarAno();
          // }

          if (this.dateFormatted.length == 10) {
              this.$emit('input', this.parseDate(this.dateFormatted));
          }
        }
      },

      onFocus(e) {
        if (!this.isSm) {
          e.target.selectionStart = 0;
          e.target.selectionEnd   = e.target.value.length;
          this.isFocused = true;
        }
      },

      focus () {
        this.$refs.campo.focus();
      },

      onBlur() {
        if (this.dateFormatted) {
          if (this.dateFormatted.length == 10) {
            this.date = this.parseDate(this.dateFormatted); 
          } else if (this.dateFormatted.length > 0 && this.dateFormatted.length < 10 && this.date != '') {
           this.dateFormatted = this.formatDate(this.date);
          } else {
            this.date = this.dateFormatted = '';
          }
        } else {
          this.date = this.dateFormatted;
        }

        this.isFocused = false;
      },

      onChange() {
        this.$emit('change', this.data);
      },
      
      onKeypress(event) {
        this.$emit('keypress', event);
      },

      selecionarAno() {
        setTimeout(() => {
          this.$refs.campo.$el.querySelector('input').selectionStart = 6;
          this.$refs.campo.$el.querySelector('input').selectionEnd   = 10;
        }, 0);
      },

      selecionarMes() {
        setTimeout(() => {
          this.$refs.campo.$el.querySelector('input').selectionStart = 3;
          this.$refs.campo.$el.querySelector('input').selectionEnd   = 5;
        }, 0);
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.substr(0, 10).split('-')
        return `${day}/${month}/${year}`
      },

      parseDate (date) {
        if (!date) return null

        const [year, month, day] = date.substr(0, 10).split('/').reverse();
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }
    }
  }
</script>

<style scoped>
</style>

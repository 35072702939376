var validaInscricao = require('inscricaoestadual');
import conversor from './conversor';
import moment from 'moment';

class Validar{

    //Espera receber o cpf a ser validado sem nenhum tipo de separador.
    cpf(strCpf){

        strCpf = strCpf.replace('.', '');
        strCpf = strCpf.replace('.', '');
        strCpf = strCpf.replace('-', '');

        let soma;
        let resto;
        soma = 0;
        if (strCpf === "00000000000" || strCpf === "11111111111" || strCpf === "22222222222" || strCpf === "33333333333" || 
            strCpf === "44444444444" || strCpf === "55555555555" || strCpf === "66666666666" || strCpf === "77777777777" || 
            strCpf === "88888888888" || strCpf === "99999999999"){
                return false;
        }
        
        for (let i = 1; i <= 9; i++){ 
            soma = soma + parseInt(strCpf.substring(i - 1, i)) * (11 - i);
        }
        resto = (soma * 10) % 11;
        
        if ((resto === 10) || (resto === 11)){
            resto = 0;
        }
        if (resto != parseInt(strCpf.substring(9, 10)) ){
            return false;
        }
        
        soma = 0;
        for (let i = 1; i <= 10; i++){
            soma = soma + parseInt(strCpf.substring(i - 1, i)) * (12 - i);  
        }
        resto = (soma * 10) % 11;
        
        if ((resto == 10) || (resto == 11)){
            resto = 0;
        }
        if (resto != parseInt(strCpf.substring(10, 11))){
            return false;
        }
        return true;
    }

    //Espera receber o cnpj a ser validado sem nenhum tipo de separador.
    cnpj(strCnpj){

        strCnpj = strCnpj.replace('.', '');
        strCnpj = strCnpj.replace('.', '');
        strCnpj = strCnpj.replace('/', '');
        strCnpj = strCnpj.replace('-', '');

        let numeros, digitos, soma, i, resultado, pos, tamanho, digitos_iguais;
        if(strCnpj === '00000000000000' || strCnpj === '11111111111111' || strCnpj === '22222222222222' || 
           strCnpj === '33333333333333' || strCnpj === '44444444444444' || strCnpj === '55555555555555' || 
           strCnpj === '66666666666666' || strCnpj === '77777777777777' || strCnpj === '88888888888888' || 
           strCnpj === '99999999999999'){
               return false;
        }
        
        digitos_iguais = 1;
        if (strCnpj.length < 14 && strCnpj.length < 15)
            return false;
        for (i = 0; i < strCnpj.length - 1; i++){
            if (strCnpj.charAt(i) != strCnpj.charAt(i + 1)){
                digitos_iguais = 0;
                break;
            }
        }    
        if (!digitos_iguais){
            tamanho = strCnpj.length - 2;
            numeros = strCnpj.substring(0,tamanho);
            digitos = strCnpj.substring(tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (i = tamanho; i >= 1; i--){
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2) pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0)) return false;
            
            tamanho = tamanho + 1;
            numeros = strCnpj.substring(0,tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (i = tamanho; i >= 1; i--){
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2) pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1)) return false;
            
            return true;
        }
        else{
            return false;
        }
    }

    codigoBarras(numero){

        if(numero.length == 8 || numero.length == 12 || numero.length == 13 || numero.length == 14){
            if(numero.length == 8){
                numero = '000000' + numero.toString();
            }
            else if(numero.length == 12){
                numero = '00' + numero.toString();
            }
            else if(numero.length == 13){
                numero = '0' + numero.toString();
            }

            let somaPares = 0;
            let somaImpares = 0;
            for(let index = 1; index < 14; index++){
                if(index % 2 == 0){
                    somaPares += Number(numero.charAt(index - 1));
                }
                else{
                    somaImpares += Number(numero.charAt(index - 1));
                }
            }
            let resultado = (somaImpares * 3) + somaPares;
            let resto = resultado % 10;
            let dv = 10 - resto;
            if(numero.charAt(13) == dv){
                return true;
            }
            else{
                return false;
            }
        }
        else{
            return false
        }
    }
    
    sigla(strSigla){
        //Estados
        let todosEstados = ['AC','AL','AP','AM','BA','CE','DF','ES','GO','MA','MT','MS','MG','PA','PB','PR','PE','PI','RJ','RN','RS','RO','RR','SC','SP','SE','TO'];
        if(todosEstados.indexOf(strSigla) !== -1){
            return true;
        }
        else{
            return false;
        }
    }

    inscricaoEstadual(valor){
        try{
            let dados = valor.split('-');
            let result = false;
            if(dados.length > 1 && dados[1] !== ''){
                result = validaInscricao(dados[0], dados[1]);
            }
            else{
                result = false;
            }
            return result;
        }
        catch(err){
            return false;
        }
    }

    email(strEmail){

        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(strEmail)){
            return true;
        }
        else{
            return false;
        }
    }

    chaveNfe(chave){
        
        if(!chave) return false;
        if(chave.length !== 44) return false;

        let digitoVerificador;
        let pesos = [4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
        let soma = 0;
        for(let indice = 0; indice < 43; indice++){
            soma += chave[indice] * pesos[indice];
        }
        let resto = soma % 11;
        if(resto === 0 || resto === 1){
            digitoVerificador = 0;
        }
        else{
            digitoVerificador = 11 - resto;
        }

        if(chave[43] != digitoVerificador) return false;

        return true;
    }

    percentual(valor){

        let numerico = conversor.stringParaNumero(valor);
        if(numerico < 100){
            return true;
        }
        else{
            return false;
        }
    }

    dataNascimento(data){

        data = data.replace('/', '').replace('/', '');
        if(data.length === 8){

            let dataInformada = moment(data, 'DD/MM/YYYY');
            let formatoValido = dataInformada.isValid();
            if(formatoValido){

                let idade = moment().diff(dataInformada, 'years');
                if(idade > 15 && idade < 101){
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                return false;
            }
        }
        else{
            return false;
        }
    }

    caracteresEspeciais (string) {
        if (string.match(/[^a-zA-Z0-9 ]/g)) {
            return false;
        } else {
            return true;
        }
    }
}

var validar = new Validar();

export default validar

import axios from '@/util/axios';
import qs from "qs";

    function listar(options, empresaAtual) {
      return axios.get(`/fabricantes?` + qs.stringify(options))
        .then(response => response.data);
    }

    function encontrarPeloId(id, empresaAtual) {
      return axios.get(`/fabricantes/${id}`)
        .then(response => response.data);
    }

    function salvar(fabricante, empresaAtual) {
      return axios.post(`/fabricantes`, fabricante)
        .then(response => response.data);
    }

    function atualizar(fabricante, empresaAtual) {
      return axios.put(`/fabricantes/${fabricante._id}`, fabricante)
        .then(response => response.data);
    }

    function excluir(id, empresaAtual) {
      return axios.delete(`/fabricantes/${id}`)
        .then(response => response.data);
    }

export default {
  listar,
  encontrarPeloId,
  salvar,
  atualizar,
  excluir
}
<template>
    <div>
      <v-widget class="mb-5" v-show="exibir" title="Vendas Por Mês" content-bg="white">
        <template v-slot:widget-header-action>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                text
                dark
                fab
                v-bind="attrs"
                v-on="on"
                @click="$emit('esconder')"
              >
                mdi-notification-clear-all
              </v-icon>
            </template>
            <span>Esconder</span>
          </v-tooltip>
        </template>
        <div slot="widget-content" class="fillWidth">
          <v-row dense>
            <v-col cols="12" xs="12" sm="5">
              <v-row dense>
                <v-col cols="12" xs="12" sm="8">
                  <v-select label="Mês inicial" ref="mes_inicial" :items="constantes.MESES" v-model="filtro.mes_inicial"></v-select>
                </v-col>
                <v-col cols="12" xs="6" sm="4">
                  <v-select label="Ano" ref="ano_inicial" :items="constantes.ANOS" v-model="filtro.ano_inicial"></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="5">
              <v-row dense>
                <v-col cols="12" xs="12" sm="8">
                  <v-select label="Mês final" ref="mes_final" :items="constantes.MESES" v-model="filtro.mes_final"></v-select>
                </v-col>
                <v-col cols="12" xs="6" sm="4">
                  <v-select label="Ano" ref="ano_final" :items="constantes.ANOS" v-model="filtro.ano_final"></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="text-center pt-5" cols="12" xs="6" sm="2">
              <v-btn block color="primary" @click="carregar()">OK</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="carregando" class="fillHeight">
            <v-col cols="12" class="text-center ma-auto">
              <v-progress-circular color="primary" :size="72" indeterminate :width="7"></v-progress-circular>
              <p class="subtitle-1 mt-3">Carregando ...</p>
            </v-col>
          </v-row>
          <v-row dense v-else>
            <v-col cols="12" xs="12" sm="12" class="">
              <chart
                class="fillWidth"
                v-if="!carregando && vendasPorMes.length > 0"
                :options="
                  graficos.graficoBarra(
                    vendasPorMes.map((venda) => venda._id),
                    vendasPorMes.map((venda) => venda.total)
                  )
                "
              ></chart>
            </v-col>
          </v-row>
        </div>
      </v-widget>
    </div>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import graficos from "@/graficos/graficos";
  import constantes from '@/util/constantes';
  import formatador from '@/util/formatador';
  import moment from 'moment';

  export default {
    name: 'GraficoVendasPorMes',
    props: ['exibir'],
    data() {
      return {
        constantes: constantes,
        graficos: graficos,
        chartOptionsBar: {
          xAxis: {
            data: []
          }
        },
        filtro: {
          mes_inicial: '',
          mes_final: '',
          ano_inicial: '',
          ano_final: '',
        }
      }
    },

    mounted() {
      this.filtro.mes_inicial = String(1+moment().month());
      this.filtro.mes_final = String(1+moment().month());
      this.filtro.ano_inicial = String(moment().year());
      this.filtro.ano_final = String(moment().year());
      
      if (String(this.filtro.mes_inicial).length < 2) {
        this.filtro.mes_inicial = formatador.formatarPadZero(this.filtro.mes_inicial, 2);
      }
      if (String(this.filtro.mes_final).length < 2) {
        this.filtro.mes_final = formatador.formatarPadZero(this.filtro.mes_final, 2);
      }
      this.carregar();
    },

    computed: {
      ...mapState("graficos", {
        vendasPorMes: "vendasPorMes",
        carregando: "carregando",
      })
    },

    methods: {
      filtroPeriodo() {
        let data1 = this.filtro.ano_inicial + '-' + this.filtro.mes_inicial + '-01';
        let data2 = this.filtro.ano_final + '-' + this.filtro.mes_final + '-01';
        let data_inicial = moment(data1).startOf('month').format('YYYY-MM-DD');
        let data_final = moment(data2).endOf('month').format('YYYY-MM-DD');

        return this.filtro.periodo = {
          data_inicial: data_inicial,
          data_final: data_final
        }
      },

      carregar() {
        this.$store.dispatch('graficos/vendasPorMes', this.filtroPeriodo());
      },
    }
  }

</script>


<style scoped>
  .fillHeight {
    height: 100%;
  }
  .fillWidth {
    width: 100%;
  }
  .positionAbsolute {
    position: absolute;
  }
  .semDados {
    position: absolute;
    top: 50%;
    text-align: center;
    width: 220px;
    left: calc(50% - 110px);
  }
</style>
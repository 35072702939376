import ListaOrdensServicos from '@/views/ordens-servicos/ListaOrdensServicos';
import FormularioOrdensServicos from '@/views/ordens-servicos/FormularioOrdensServicos';

export default [
    {
      path: '/movimentacoes/ordens-servicos',
      name: 'OrdensServicosLista',
      component: ListaOrdensServicos,
      meta: { requiresAuth: true, nivel: 1}
    },
    {
      path: '/movimentacoes/ordens-servicos/novo',
      name: 'OrdensServicosNovo',
      component: () => import( '@/views/ordens-servicos/FormularioOrdensServicos'),
      meta: { requiresAuth: true, nivel: 1}
    },
    {
      path: '/movimentacoes/ordens-servicos/editar',
      name: 'OrdensServicosEditar',
      component: () => import( /* webpackChunkName: "CrudEditar" */ '@/views/ordens-servicos/FormularioOrdensServicos'),
      meta: { requiresAuth: true, nivel: 1}
    }
];
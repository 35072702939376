import axios from '@/util/axios';
import qs from "qs";

  function listar(empresaAtual, options) {
    return axios.get(`/configuracoes?` + qs.stringify(options))
      .then(response => response.data);
  }
  
  function salvar(empresaAtual, configuracao) {
    return axios.post(`/configuracoes`, configuracao)
      .then(response => response.data);
  }

  function consultarStatus(empresaAtual, configuracao) {
    return axios.get(`/configuracoes/${configuracao}`)
      .then(response => response.data);
  }
  
  function alterarStatus(empresaAtual, configuracao) {
    return axios.put(`/configuracoes/${configuracao._id}/status`)
      .then(response => response.data);
  }

  function excluir(empresaAtual, id) {
    return axios.delete(`/configuracoes/${id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    salvar,
    consultarStatus,
    alterarStatus,
    excluir
  }
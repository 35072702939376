import axios from '@/util/axios';
import qs from "qs";

  function listar(options, empresaAtual) {
    return axios.get(`/remessasCheques?` + qs.stringify(options))
      .then(response => response.data);
  }
  
  function listarContasCorrentes(empresaAtual) {
    return axios.get(`/remessasCheques/listar-contas-correntes`)
      .then(response => response.data);
  }
  
  function listarChequesEmCaixa(options, empresaAtual) {
    return axios.get(`/remessasCheques/cheques-em-caixa?` + qs.stringify(options))
      .then(response => response.data);
  }

  function salvar(remessa, empresaAtual) {
    return axios.post(`/remessasCheques`, remessa)
      .then(response => response.data);
  }

  function atualizar(remessa, empresaAtual) {
    return axios.put(`/remessasCheques/${remessa._id}`, remessa)
      .then(response => response.data);
  }

  function excluir(id, empresaAtual) {
    return axios.delete(`/remessasCheques/${id}`)
      .then(response => response.data);
  }

  export default {
    listar,
    listarContasCorrentes,
    listarChequesEmCaixa,
    salvar,
    atualizar,
    excluir
  }
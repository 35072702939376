export default [
  {
    path: '/tabelas/pis',
    name: 'pis',
    component: () => import( /* webpackChunkName: "listaPis" */ '@/views/pis/ListaPIS'),
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/tabelas/pis/novo',
    name: 'formularioPISNovo',
    component: () => import( /* webpackChunkName: "formularioPis" */ '@/views/pis/FormularioPIS'),
    meta: { requiresAuth: true, nivel: 1}
  },
  {
    path: '/tabelas/pis/editar',
    name: 'formularioPISEditar',
    component: () => import( /* webpackChunkName: "formularioPis" */ '@/views/pis/FormularioPIS'),
    meta: { requiresAuth: true, nivel: 1}
  },
];
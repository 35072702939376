<template>
  <v-dialog v-model="dialog" max-width="960px" @keydown.esc="fechar">
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title class="white--text">Aniversariantes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
      </v-toolbar>
      <v-card-text>
        <v-row dense>
          <v-col class="pt-3" cols="12" xs="12" sm="4">
            <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @keyup.enter="filtrarBusca($event)" @click:clear="limparBusca" prepend-inner-icon="search" label="Busca"></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4">
            <v-select label="Mês" ref="mes_aniversariantes" :items="meses" v-model="mesSelecionado" @change="setMesAniversariantes"></v-select>
          </v-col>
        </v-row>
        <v-data-table fixed-header height="350px" :options.sync="options" :server-items-length="aniversariantes.length" :headers="headers" :items="aniversariantes" :loading="carregando" :footer-props="footerOptions" :no-data-text="'Sem resultados!'" :item-key="'_id'">
          <template v-slot:item="{item}">
            <tr>
              <td style="width: 190px">
                <i class="fas fa-birthday-cake"></i> {{ dataFormatadaSemAno(item.nascimento) }}                
              </td>
              <td>
                <b>{{ item.razao_nome }}</b><br>
              </td>
              <td>{{ item.cidade }}-{{ item.estado }}</td>
              <td class="text-center">

              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="fechar()">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment';
  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '../../util/constantes';

  export default {
    name: "DialogoAniversariantes",
    props: ['dialog', 'mes'],
    data: () => ({
      constantes: constantes,
      headers: [
        { text: 'Data', value: 'nascimento', width: '18%' },
        { text: 'Nome', value: 'razao_nome' },
        { text: 'Cidade', value: 'cidade' },
        { text: '', value: 'acoes' },
      ],
      options: {
        itemsPerPage: 10,
        page: 1
      },
      footerOptions: {
        'items-per-page-options': constantes.ITEMS_POR_PAGINA 
      },
      busca: '',
      abaAtiva: 1,
      meses: [{text: 'Janeiro', value: 1},
                {text: 'Fevereiro', value: 2},
                {text: 'Março', value: 3},
                {text: 'Abril', value: 4},
                {text: 'Maio', value: 5},
                {text: 'Junho', value: 6},
                {text: 'Julho', value: 7},
                {text: 'Agosto', value: 8},
                {text: 'Setembro', value: 9},
                {text: 'Outubro', value: 10},
                {text: 'Novembro', value: 11},
                {text: 'Dezembro', value: 12}],
      mesSelecionado: Number
    }),
    
    computed: {
      ...mapState('dashboard', {
        aniversariantes: 'aniversariantes',
        mes_aniversariantes: 'mes_aniversariantes',
        carregando: 'carregandoAniversariantes'
      }),
    },
    
    methods: {
      fechar(){
        this.$store.commit('dashboard/SET_MES_ANIVERSARIANTES', moment().month()+1);
        this.mesSelecionado = moment().month()+1;
        this.$emit('fechar');
      },

      async listar() {
        await this.$store.dispatch('dashboard/aniversariantes', this.options, this.mes_aniversariantes);
      },

      async filtrarBusca(){
        let newOptions = {
          ...this.options,
          query: this.busca
        }

        this.$store.dispatch('dashboard/aniversariantes', newOptions, this.mes_aniversariantes);
        setTimeout(() => {
          event.target.select();
        }, 300);
      },

      setMesAniversariantes(mes) {
        this.mesSelecionado = mes;
        this.$store.commit('dashboard/SET_MES_ANIVERSARIANTES', mes);
      },
      
      dataFormatadaSemAno(data){
        if(data){
          return moment(data).utc().format('DD/MM');
        } else {
          return '';
        }
      },

      limparBusca(){
        this.listar();
      },
    },

    watch: {
      dialog(val) {
        if (val) {
          this.listar();
        }
      },
      
      mesSelecionado: {
        handler () {
          this.listar();
        },
        deep: true,
      },
    },

    async created(){
      try{
        this.mesSelecionado = this.mes_aniversariantes;
      }
      catch(erro){
        console.log(erro);
      }
      finally{
        // 
      }
    }
  }
</script>
<template>
  <div>
    <v-dialog v-model="dialog" max-width="960px" @keydown.esc="fechar">
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title class="white--text">Configurações</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
        </v-toolbar>
        <v-card-text class="pb-0" style="min-height: 535px;" height="100%">
          <v-row dense>
            <v-col class="pt-3" cols="12" xs="12" sm="4">
              <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @keyup.enter="filtrarBusca($event)" @click:clear="limparBusca" prepend-inner-icon="search" label="Busca"></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-right pt-4" cols="12" xs="12" sm="4">
              <v-btn color="primary" @click="nova">Nova</v-btn>
            </v-col>
          </v-row>
          <v-tabs v-model="abaAtiva">
            <v-tab :key="1">Lista</v-tab>
            <v-tab :key="2">Outras</v-tab>
            <v-spacer></v-spacer>
          </v-tabs>
          <v-tabs-items v-model="abaAtiva">
            <v-divider></v-divider>
            <v-tab-item :key="1+99" eager>
              <v-container grid-list-md fluid>
                <v-data-table disable-sort fixed-header height="350px" :options.sync="options" :server-items-length="configuracoes.length" :headers="headers" :items="configuracoes" :loading="carregando" :footer-props="footerOptions" :no-data-text="'Sem resultados!'" :item-key="'_id'">
                  <template v-slot:item="{item}">
                    <tr>
                      <td style="">
                        <i class="fas fa-cog"></i> {{ item.descricao }}                
                      </td>
                      <td>
                        <b>{{ item.config }}</b><br>
                      </td>
                      <td class="text-center">
                        <v-row>
                          <v-col cols="6" sm="6">
                            <v-checkbox
                              class="mt-0 pt-2"
                              v-model="item.ativo"
                              color="primary"
                              hide-details
                              @change="alterarStatus(item)"
                            ></v-checkbox>
                          </v-col>
                          <v-col class="text-left pl-2 pt-3" cols="6" sm="6">
                            <v-btn
                              class="pt-0"
                              icon
                              color="green"
                              @click="excluir(item)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-container>
            </v-tab-item>
            <v-tab-item :key="1+999" eager>
              <v-container grid-list-md fluid>
                <v-row dense>
                  <v-col class="text-center" style="margin-top: 40px" cols="12">
                    <h3>Em desenvolvimento</h3>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="fechar()">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="exibirDialogoNovaConfiguracao" max-width="600px" persistent>
      <v-form ref="formConfiguracao" lazy-validation>
        <v-card>
          <v-toolbar flat dark dense color="primary">
            <v-toolbar-title>Configuração</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4">
            <v-row dense>
              <v-col cols="8" xs="12" sm="6">
                <v-text-field label="Descrição" ref="descricao" v-model="configuracao.descricao" :rules="[rules.obrigatorio]" @keypress.enter="$refs.configuracao.focus()"></v-text-field>
              </v-col>
              <v-col cols="4" xs="12" sm="6">
                <v-text-field label="Configuração" ref="configuracao" v-model="configuracao.config" :rules="[rules.obrigatorio]" @keypress.enter="salvarConfiguracao"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
          <v-btn text @click="exibirDialogoNovaConfiguracao = false" :disabled="carregando">Cancelar</v-btn>
          <v-btn color="primary" :disabled="carregando" :loading="carregando" @click="salvarConfiguracao">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  import * as regras from '@/util/validacaoFormulario';
  import constantes from '../util/constantes';
  import moment from 'moment';

  export default {
    name: "DialogoConfiguracoes",
    props: ['dialog'],
    data: () => ({
      rules: regras,
      constantes: constantes,
      headers: [
        { text: 'Descrição', value: 'descricao', width: '36%' },
        { text: 'Config', value: 'config' },
        { text: '', value: 'acoes', width: '11.9%' },
      ],
      options: {
        itemsPerPage: 10,
        page: 1
      },
      footerOptions: {
        'items-per-page-options': constantes.ITEMS_POR_PAGINA 
      },
      busca: '',
      abaAtiva: 0,
      exibirDialogoNovaConfiguracao: false
    }),

    computed: {
      ...mapState('configuracao', {
        configuracoes: 'configuracoes',
        configuracao: 'configuracaoAtual',
        carregando: 'carregando'
      }),
    },
    
    methods: {
      fechar(){
        this.$emit('fechar');
      },

      async listar() {
        await this.$store.dispatch('configuracao/listar', this.options);
      },

      async filtrarBusca(){
        let newOptions = {
          ...this.options,
          query: this.busca
        }

        this.$store.dispatch('configuracao/listar', newOptions);
        setTimeout(() => {
          event.target.select();
        }, 300);
      },

      async alterarStatus(configuracao) {
        this.$store.commit('configuracao/SET_CONFIGURACAO_ATUAL', configuracao)
        await this.$store.dispatch('configuracao/alterarStatus', this.configuracao);
      },

      nova() {
        this.$store.commit('configuracao/nova');
        this.exibirDialogoNovaConfiguracao = true
        setTimeout(() => {
          this.$refs.descricao.focus();
        },100)
      },

      async salvarConfiguracao() {
        if (this.$refs.formConfiguracao.validate()) {
          try {
            await this.$store.dispatch('configuracao/salvar', this.configuracao);
            this.exibirDialogoNovaConfiguracao = false;
          } catch (error) {
            console.log(error);
          }
        }
      },

      async excluir(configuracao) {
        this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?')
          .then((confirmado) => {
            if(confirmado){
              this.$store.dispatch('configuracao/excluir', configuracao);
            }
          });
      },

      limparBusca(){
        this.listar();
      },
    },

    watch: {
      dialog(val) {
        if (val) {
          this.listar();
        }
      }
    },

    async created(){
      try{
        this.mesSelecionado = this.mes_aniversariantes;
      } catch(erro) {
        console.log(erro);
      } finally {
        // 
      }
    }
  }
</script>

import axios from '@/util/axios';
import axiosCep from 'axios';
import constantes from '@/util/constantes';
import conversor from '@/util/conversor';
import moment from 'moment';

export default{
    
  namespaced: true,

    //equivalente ao data
  state: {

    filiais: [],
    filialAtual: {
      cnpj: '',
      fantasia: '',
      razao: '', 
      ie: '', 
      im: '',
      cnae: '',
      descricao_cnae: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      codigo_cidade: '',
      uf: '',
      estado: '',
      pais: '',
      codigo_pais: '',
      logotipo: '',
      tema: 'green',
      versao: '4.00',
      ambiente: '',
      tipo_certificado: 'A1',
      formato_impressao: '1',
      certificado: '',
      frete_padrao: '9',
      forma_pagamento_padrao: '0',
      senha: '',
      telefone: '',
      email: '',
      ativa: true,
      plano: 'nfe',
      calculo_custo: constantes.CALCULO_MEDIA,
      configuracao_tributaria: {
        ramo_atividade: '',
        config_cfop_padrao: '1',
        cfop_padrao: '',
        regime_tributario: '',
        csosn: '102',
        serie_padrao: '',
        usa_icms_padrao: true,
        usa_icms_st_padrao: true,
        usa_pis_entrada_padrao: true,
        usa_pis_saida_padrao: true,
        usa_cofins_entrada_padrao: true,
        usa_cofins_saida_padrao: true,
        cst_icms: '00',
        cst_icms_st: '00',
        cst_pis_entrada: '01',
        cst_pis_saida: '01',
        cst_cofins_entrada: '01',
        cst_cofins_saida: '01',
        aliq_icms: 18.00,
        aliq_icms_st: 0,
        aliq_pis_entrada: 1.65,
        aliq_pis_saida: 1.65,
        aliq_cofins_entrada: 7.60,
        aliq_cofins_saida: 7.60,
        aliq_cred_sn: 18,
        info_complementar: '',
        empresa: null
      }
    },
    cidades: [],
    constantes: constantes,
    conversor: conversor,
    modoEdicao: false,
    carregando: false,
    carregando_cidade: false
  },

    //equivalente ao computed properties
  getters: {

    estaEditando(state){
      return state.modoEdicao;
    },
    textoBtnConfirmar(state){
      if(state.modoEdicao){
        return state.constantes.BOTAO_SALVAR_EDICAO;
      }
      else{
        return state.constantes.BOTAO_SALVAR_NOVO;
      }
    },
    listaCidades(state) {
      return state.cidades.map(cidade => { 
        return {
          text: cidade.cidade, 
          value: cidade.codigo_cidade 
        };
      });
    }
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {

    carregar({commit}){
        
      commit('setCarregando', true);

      axios.get('/empresas')
      .then((response) => {
        commit('setFiliais', response.data);
      })
      .catch((erro) => {
        commit('setFiliais', []);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });  
    },

    async filtrarCidadesEstado({commit}, estado){

      try {

        commit('setCarregandoCidades', true);
        let cidades = await axios.get(`/cidades/estado/${estado}`).then(response => response.data);
        commit('setCidades', cidades);

      } catch (error) {
        commit('setCarregandoCidades', false);
        commit('setMensagemErro', 'Falha ao buscar cidades', {root: true});
        throw error;
      } finally {
        commit('setCarregandoCidades', false);
      }
    },

    consultarCnae({commit}, cnae){
      commit('setCarregando', true);
      axiosCep.get('https://servicodados.ibge.gov.br/api/v2/cnae/subclasses/' + cnae)
      .then((response) => {
        let dadosCnae = response.data;
        if(dadosCnae.length > 0){
          commit('setDescricaoCnae', dadosCnae);
        }
        else{
          commit('setMensagemErro', 'CNAE não Encontrado', {root: true});    
        }
        commit('setCarregando', false);
      })
      .catch((err) => {
        commit('setCarregando', false);
        commit('setMensagemErro', 'Falha ao tentar consultar CNAE', {root: true});
      });
    },

    consultarCadastro({commit, rootState, dispatch}, cnpj){

      let consulta = {
        cnpj: cnpj
      };

      commit('setCarregando', true);
      axios.post('/servicos/consultar_cadastro', consulta)
      .then((response) => {
        let resultado = response.data;
        if(resultado.status == '111'){
          commit('setDadosFilial', resultado.empresa);
          let cep = resultado.empresa.endereco.cep;
          if(cep.length === 8){
            commit('setCarregando', true);
            axiosCep.get(`https://viacep.com.br/ws/${cep}/json/`)
            .then(responseCep => {
              commit('setCarregando', false);
              commit('setEndereco', responseCep.data);
              dispatch('filtrarCidadesEstado', responseCep.data.uf)
            })
            .catch(error => {
              commit('setCarregando', false);
            });
          }

          if(resultado.empresa.situacao == 0){
            commit('setMensagemErro', 'Contribuinte não habilitado na SEFAZ', {root: true});        
          }
        }
        commit('setCarregando', false);
      })
      .catch((erro) => {
        commit('setCarregando', false);
        commit('setMensagemErro', 'Falha ao consultar CNPJ', {root: true});
      });
    },

    async salvar({commit, rootState, state}){

      let filial = Object.assign({}, state.filialAtual);
      filial.cnpj = filial.cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
      filial.cep = filial.cep.replace('.', '').replace('-', '');
      filial.cnae = filial.cnae.replace('-', '').replace('/', '');
      filial.telefone = filial.telefone.replace('(', '').replace(')', '').replace('-', '');

      commit('setCarregando', true);

      if(state.modoEdicao){

        try{
          let response = await axios.put(`/empresas/filiais`, filial);
          let retorno = response.data;
          
          commit('atualizar', retorno);
          commit('setMensagemSucesso', 'Filial Atualizada com Sucesso', {root: true});
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
      else{

        try{
          delete(filial._id);
          let response = await axios.post(`/empresas/filiais`, filial);
          let retorno = response.data;
          
          commit('adicionar', retorno);
          commit('setMensagemSucesso', 'Filial Cadastrada com Sucesso', {root: true});
        }
        catch(erro){
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
          throw erro;
        }
      }
    }
  },

  //responsável por alterar o state.
  mutations: {

    setCidades(state, cidades){
      state.cidades = cidades;
    },
    setDadosFilial(state, filial){
      state.filialAtual.ie = filial.ie;
      state.filialAtual.razao = filial.razao;
      state.filialAtual.numero = filial.endereco.numero;
      state.filialAtual.cep = filial.endereco.cep;
      state.filialAtual.cnae = filial.cnae;
    },
    setCidade(state, codigo_cidade){
      let cidade = state.cidades.find(cidade => cidade.codigo_cidade == codigo_cidade);
      
      state.filialAtual.cidade = cidade.cidade;
      state.filialAtual.codigo_cidade = cidade.codigo_cidade;
      state.filialAtual.uf = cidade.codigo_estado;
      state.filialAtual.pais = 'Brasil';
      state.filialAtual.codigo_pais = 1058;
    },
    setEndereco(state, endereco){
        
      state.filialAtual.logradouro = endereco.logradouro;
      state.filialAtual.bairro = endereco.bairro;
      state.filialAtual.uf = conversor.ufParaCodigo(endereco.uf);
      state.filialAtual.estado = endereco.uf;
      state.filialAtual.cidade = endereco.localidade;
      state.filialAtual.codigo_cidade = endereco.ibge;
      state.filialAtual.pais = 'Brasil';
      state.filialAtual.codigo_pais = 1058;
    },
    setDescricaoCnae(state, dadosCnae){
      state.filialAtual.descricao_cnae = dadosCnae[0].descricao;
    },
    setCarregandoCidades(state, valor){
      state.carregando_cidade = valor;
    },
    setCarregando(state, valor){
      state.carregando = valor;
    },
    setInfoComplementar(state, infoComplementar){
      state.filialAtual.configuracao_tributaria.info_complementar = infoComplementar;
    },
    setFiliais(state, filiais){
      state.filiais = filiais;
      state.carregando = false;
    },
    atualizarConfigCfop(state){
      if(state.filialAtual.configuracao_tributaria.config_cfop_padrao === '1'){ // cfop definido pelo ramo de atividade
        if(state.filialAtual.configuracao_tributaria.ramo_atividade === '1'){ // comércio
          state.filialAtual.configuracao_tributaria.cfop_padrao = '5102';
        }
        else if(state.filialAtual.configuracao_tributaria.ramo_atividade === '3'){ //industria
          state.filialAtual.configuracao_tributaria.cfop_padrao = '5101';
        }
      }
    },
    adicionar(state, filial){
      state.filiais.push(filial);
      state.carregando = false;
    },
    atualizar(state, atual){
      state.filiais.forEach(filial => {
        if(filial._id === atual._id){
          filial = atual;
        }
      });
      state.carregando = false;
    },
    remover(state, removido){
      state.filiais = state.filiais.filter(filial => filial._id !== removido._id);
      state.carregando = false;
    },
    nova(state){
      state.modoEdicao = false;
      state.filialAtual = {
        cnpj: '',
        fantasia: '',
        razao: '', 
        ie: '', 
        im: '',
        cnae: '',
        descricao_cnae: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        codigo_cidade: '',
        uf: '',
        estado: '',
        pais: '',
        codigo_pais: '',
        logotipo: '',
        tema: 'green',
        versao: '4.00',
        ambiente: '',
        tipo_certificado: 'A1',
        formato_impressao: '1',
        certificado: '',
        frete_padrao: '9',
        forma_pagamento_padrao: '0',
        senha: '',
        telefone: '',
        email: '',
        ativa: true,
        plano: 'nfe',
        calculo_custo: constantes.CALCULO_MEDIA,
        configuracao_tributaria: {
          ramo_atividade: '',
          config_cfop_padrao: '1',
          cfop_padrao: '',
          regime_tributario: '',
          csosn: '102',
          serie_padrao: '',
          usa_icms_padrao: true,
          usa_icms_st_padrao: true,
          usa_pis_entrada_padrao: true,
          usa_pis_saida_padrao: true,
          usa_cofins_entrada_padrao: true,
          usa_cofins_saida_padrao: true,
          cst_icms: '00',
          cst_icms_st: '00',
          cst_pis_entrada: '01',
          cst_pis_saida: '01',
          cst_cofins_entrada: '01',
          cst_cofins_saida: '01',
          aliq_icms: 18.00,
          aliq_icms_st: 0,
          aliq_pis_entrada: 1.65,
          aliq_pis_saida: 1.65,
          aliq_cofins_entrada: 7.60,
          aliq_cofins_saida: 7.60,
          aliq_cred_sn: 18,
          info_complementar: '',
          empresa: null
        }
      }
      state.carregando = false;
    },
    editar(state, editar){

      state.modoEdicao = true;
      state.filialAtual = editar;
      state.carregando = false;
    },
    estadoInicial(state){
      state.filialAtual = {
        cnpj: '',
        fantasia: '',
        razao: '', 
        ie: '', 
        im: '',
        cnae: '',
        descricao_cnae: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        codigo_cidade: '',
        uf: '',
        estado: '',
        pais: '',
        codigo_pais: '',
        logotipo: '',
        tema: 'green',
        versao: '4.00',
        ambiente: '',
        tipo_certificado: 'A1',
        formato_impressao: '1',
        certificado: '',
        frete_padrao: '9',
        forma_pagamento_padrao: '0',
        senha: '',
        telefone: '',
        email: '',
        ativa: true,
        plano: 'nfe',
        calculo_custo: constantes.CALCULO_MEDIA,
        configuracao_tributaria: {
          ramo_atividade: '',
          config_cfop_padrao: '1',
          cfop_padrao: '',
          regime_tributario: '',
          csosn: '102',
          serie_padrao: '',
          usa_icms_padrao: true,
          usa_icms_st_padrao: true,
          usa_pis_entrada_padrao: true,
          usa_pis_saida_padrao: true,
          usa_cofins_entrada_padrao: true,
          usa_cofins_saida_padrao: true,
          cst_icms: '00',
          cst_icms_st: '00',
          cst_pis_entrada: '01',
          cst_pis_saida: '01',
          cst_cofins_entrada: '01',
          cst_cofins_saida: '01',
          aliq_icms: 18.00,
          aliq_icms_st: 0,
          aliq_pis_entrada: 1.65,
          aliq_pis_saida: 1.65,
          aliq_cofins_entrada: 7.60,
          aliq_cofins_saida: 7.60,
          aliq_cred_sn: 18,
          info_complementar: '',
          empresa: null
        }
      }
    }
  }
}
export default [
  {
    path: '/configuracoes/dispositivos',
    name: 'dispositivos',
    component: () => import( /* webpackChunkName: "listaDispositivos" */ '@/views/dispositivos/ListaDispositivos'),
    meta: { requiresAuth: true, nivel: 2}
  },
  {
    path: '/configuracoes/dispositivos/novo',
    name: 'formularioDispositivoNovo',
    component: () => import( /* webpackChunkName: "formularioDispositivo" */ '@/views/dispositivos/FormularioDispositivo'),
    meta: { requiresAuth: true, nivel: 2}
  },
  {
    path: '/configuracoes/dispositivos/editar',
    name: 'formularioDispositivoEditar',
    component: () => import( /* webpackChunkName: "formularioDispositivo" */ '@/views/dispositivos/FormularioDispositivo'),
    meta: { requiresAuth: true, nivel: 2}
  }
];
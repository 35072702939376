import ListaBanco from "@/views/bancos/ListaBanco";
import FormularioBanco from "@/views/bancos/FormularioBanco";

export default [
  {
    path: "/tabelas/bancos",
    name: "bancos",
    component: ListaBanco,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/tabelas/bancos/novo",
    name: "formularioBancoNovo",
    component: FormularioBanco,
    meta: { requiresAuth: true, nivel: 1 },
  },
  {
    path: "/tabelas/bancos/editar",
    name: "formularioBancoEditar",
    component: FormularioBanco,
    meta: { requiresAuth: true, nivel: 1 },
  }
];

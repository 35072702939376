import axios from '@/util/axios';
import constantes from '@/util/constantes';

export default{
  namespaced: true,

  //equivalente ao data
  state: {
    itens: [],
    itemAtual: {},
    constantes: constantes,
    modoEdicao: false,
    carregando: false,
    totalCfops: 0
  },

  //equivalente ao computed properties
  getters: {
    // 
  },

  //equivalente aos methods
  //responsável por chamar a api externa
  actions: {
    async buscarDescricaoCfop({commit, rootState}, cfop){
      try {
        commit('setCarregando');
        let response = await axios.get('/cfop/' + cfop + '/' + '1');
        commit('setCfops', response.data);
      } catch(erro) {
        console.log(erro)
        commit('setCfops', []);
        commit('setMensagemErro', "Erro ao buscar CFOP", {root: true});
      } finally {
        commit('setCarregando', false);
      }
    },
    tratarConteudo({commit}, dados){
      
      commit('setCarregando', true);
      let cfopEntradaCompra = constantes.CFOP_ENTRADA_COMPRA;
      let cfopEntradaConsignacao = constantes.CFOP_ENTRADA_CONSIGNACAO;
      let cfopEntradaDevolucao = constantes.CFOP_ENTRADA_DEVOLUCAO
      let cfopEntradaRemessaIndust = constantes.CFOP_ENTRADA_REMESSA_INDUSTRIALIZACAO;
      let cfopEntradaTransferencia = constantes.CFOP_ENTRADA_TRANSFERENCIA;

      let cfopSaidaVenda = constantes.CFOP_SAIDA_VENDA;
      let cfopSaidaConsignacao = constantes.CFOP_SAIDA_CONSIGNACAO;
      let cfopSaidaDevolucao = constantes.CFOP_SAIDA_DEVOLUCAO;
      let cfopSaidaRemessaIndust = constantes.CFOP_SAIDA_REMESSA_INDUSTRIALIZACAO;
      let cfopSaidaTransferencia = constantes.CFOP_SAIDA_TRANSFERENCIA;

      let linhas = dados.split('\n');
      let cabecalhos = linhas[0];
      let keys = cabecalhos.split(',');

      let objetos = [];
      for(var indiceLinha = 1; indiceLinha < linhas.length; indiceLinha++){
        let linha = linhas[indiceLinha];
        let campos = linha.split(',');
        let objeto = {};
        for(var i = 0; i < keys.length; i++){
          if(i === 5){
              objeto['indDevol'] = campos[i];
          }
          else{
              objeto[keys[i]] = campos[i];
          }
        }
        
        if(objeto.cfop.charAt(0) === '1' || objeto.cfop.charAt(0) === '2' || objeto.cfop.charAt(0) === '3'){
          if(cfopEntradaCompra.find(cfop => cfop === objeto.cfop)){
              objeto.natureza = 'COMPRA';
          }
          else if(cfopEntradaConsignacao.find(cfop => cfop === objeto.cfop)){
              objeto.natureza = 'CONSIGNACAO';
          }
          else if(cfopEntradaDevolucao.find(cfop => cfop === objeto.cfop)){
              objeto.natureza = 'DEVOLUCAO';
          }
          else if(cfopEntradaRemessaIndust.find(cfop => cfop === objeto.cfop)){
              objeto.natureza = 'REMESSA';
          }
          else if(cfopEntradaTransferencia.find(cfop => cfop === objeto.cfop)){
              objeto.natureza = 'TRANSFERENCIA';
          }
          else{
              objeto.natureza = '';
          }
        }
        else if(objeto.cfop.charAt(0) === '5' || objeto.cfop.charAt(0) === '6' || objeto.cfop.charAt(0) === '7'){
          if(cfopSaidaVenda.find(cfop => cfop === objeto.cfop)){
              objeto.natureza = 'VENDA';
          }
          else if(cfopSaidaConsignacao.find(cfop => cfop === objeto.cfop)){
              objeto.natureza = 'CONSIGNACAO';
          }
          else if(cfopSaidaDevolucao.find(cfop => cfop === objeto.cfop)){
              objeto.natureza = 'DEVOLUCAO';
          }
          else if(cfopSaidaRemessaIndust.find(cfop => cfop === objeto.cfop)){
              objeto.natureza = 'REMESSA';
          }
          else if(cfopSaidaTransferencia.find(cfop => cfop === objeto.cfop)){
              objeto.natureza = 'TRANSFERENCIA';
          }
          else{
              objeto.natureza = '';
          }
        }
        objetos.push(objeto);
      }

      axios.post('/cfop', objetos)
        .then(response => {
          commit('setItens', response.data);
          commit('setCarregando', false);
          commit('setMensagemSucesso', 'CFOPs Cadastrados com Sucesso', {root: true});
        })
        .catch(erro => {
          commit('setCarregando', false);
          commit('setMensagemErro', erro.response.data.motivo, {root: true});
        });
    },
    carregarItens({commit}){
      
      commit('setCarregando', true);
      axios.get('/cfop')
      .then(response => {
        commit('setItens', response.data);
        commit('setCarregando', false);
      })
      .catch(erro => {
        commit('setItens', []);
        commit('setCarregando', false);
        commit('setMensagemErro', erro.response.data.motivo, {root: true});
      });
    },
    detalhar({commit}, dados){
      commit('setCarregando', true);
      dados.router.push('/cfop/detalhar');
      commit('detalhar', dados.item);
      commit('setCarregando', false);
    }
  },

  //responsável por alterar o state.
  mutations: {
    setCarregando(state, valor){
      state.carregando = valor
    },
    setCfops(state, itens){
      state.itens = itens;
      state.totalCfops = itens.length;
    },
    setItens(state, itens){
      itens.forEach(item => {
        state.itens.push(item);
      });
    },
    detalhar(state, item){
      state.itemAtual = item;
    }
  }
}
export default [
  {
    path: '/ibpt',
    name: 'listaImportarIbpt',
    component: () => import( /* webpackChunkName: "listaImportacaoIBPT" */ '@/views/cadastros/ListaImportarIBPT'),
    meta: { requiresAuth: true, nivel: 2}
  },
  {
    path: '/ibpt/detalhar',
    name: 'formularioIbptDetalhar',
    component: () => import( /* webpackChunkName: "formularioIBPT" */ '@/views/cadastros/FormularioIbpt'),
    meta: { requiresAuth: true, nivel: 2}
  }
];
<template>
  <v-dialog v-model="visualizarTodas" @keydown.esc="fecharTodas" persistent max-width="900px">
    <v-card>
      <v-toolbar dense flat dark color="primary">
        <h3>Histórico de Notificações</h3>
        <v-spacer></v-spacer>
        <v-icon style="cursor: pointer" @click="fecharTodas()">close</v-icon>
      </v-toolbar>
      <v-card-text style="overflow: hidden; height: 80vh" class="pa-2 pb-4">
        <v-container grid-list-lg class="pa-0 ma-0" fill-height fluid style="overflow: hidden">
          <historico-notificacao :notificacoes="todasNotificacoes"></historico-notificacao>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import HistoricoNotificacao from "@/components/notificacao/HistoricoNotificacao";

export default {
  components: {
    HistoricoNotificacao,
  },

  data: () => ({}),

  computed: {
    ...mapState("notificacao", {
      visualizarTodas: "visualizarTodas",
      todasNotificacoes: "todasNotificacoes",
    }),
  },

  methods: {
    fecharTodas() {
      this.$store.commit("notificacao/fecharTodas");
    },
  },
};
</script>
